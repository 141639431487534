import GAME from "models/block/game";
import { checkFlag } from "utils/flags";

/**
 * Dynamic Blocks
 * Dynamic blocks are blocks whose existence is determined by what's on the stage
 */

/**
 * Gets code block representing all possible dynamic objects
 * @return {Array}            returns a list of object blocks JSON data (to be created by the factory)
 */
export async function getDynamicBlocks() {
  const game = await GAME.promiseGame();
  let objects = game.model.get("objects");

  // map objects to block data
  const blocks = objects
    .filter((object, i, list) => {
      // filter duplicates of the same class
      if (object.getClass() !== null) {
        return (
          list.findIndex(other => other.getClass() === object.getClass()) === i
        );
      } else {
        return true;
      }
    })
    .map(object => object.getBlockData());

  // add tiles
  game.map.tilesets.forEach(tileset => {
    for (let i = 0; i < tileset.total; i++) {
      const index = i + tileset.firstgid;

      if (index > 100) {
        return; // we only allow up to 100 tiles
      }

      blocks.push({
        type: "texture",
        value: index,
        groups: ["year 3", "year 4", "year 5", "year 6"],
        category: "textures",
      });
    }
  });

  // add null key for editorial
  blocks.push({
    type: "key",
    value: null,
    groups: ["year 2", "year 3", "year 4", "year 5", "year 6"],
    category: "keys",
    "editorial-only": true,
  });

  if (!checkFlag("APP_NEW_CODE_CHEST")) {
    // add variable set and change
    blocks.forEach(block => {
      if (block.type === "variable") {
        // assign-variable block
        blocks.push({
          type: "assign-variable",
          category: "variables",
          args: {
            arguments: [
              {
                type: "variable",
                target: block.target,
                "hard-lock": true,
              },
            ],
          },
        });

        // change-variable block
        blocks.push({
          type: "change-variable",
          category: "variables",
          args: {
            arguments: [
              {
                type: "variable",
                target: block.target,
                "hard-lock": true,
              },
            ],
          },
        });
      }
    });
  }

  // add `dynamic` group to code blocks
  blocks.forEach(block => {
    if (!block.groups) {
      block.groups = ["dynamic"];
    }
  });

  return blocks;
}
