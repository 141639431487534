/**
 * Unpacks the value of a variable if neccessary, otherwise just return the input
 *
 * @param {*} variable - The potential variable to unpack
 */
export function unpack(val) {
  if (Array.isArray(val)) {
    val = val[0];
    return unpack(val);
  }

  if (val && val.model && val.model.get("type") === "variable") {
    return val._value;
  } else {
    return val;
  }
}
