import Backbone from "custom/backbone-bundle";
import BlockCache from "./utils/block-cache";
import PrototypeModel from "models/prototype-model";
import TestablePrototype from "models/prototypes/testable";
import _ from "underscore";
import deepOmit from "utils/deep-omit";
import interfaceChannel from "views/block/channels/interface-channel";
import settings from "globals/settings";
import { generateConsistentUUID, isValidUUID } from "utils/generate-uuid";
import { console as blockConsole } from "globals/console";
import { task } from "globals/task";
import { user } from "globals/user";
import { actionHistory } from "globals/action-history";
import { hasTaxonomy } from "../../../../src/utils/taxonomy";
import { checkFlag } from "../../../../src/utils/flags";
import { glossary } from "globals/glossary";
import { isAuthor } from "utils/is-author";
import GAME from "../game";
import { helpWindow } from "../../../../src/views/block/ui-elements/help-window/help-window";

/**
 * recursive function that finds the topmost parent of a block
 * @param  {Backbone.Model} model - The block
 * @return {Backbone.Model}       - The topmost parent
 *                                  This will in most cases be a code canvas or code wall
 *                                  though for standalone blocks this will be the block itself
 */
function findParent(model) {
  const parent =
    model instanceof CodeBlock ? model.getParent() : model.get("parent");

  return parent ? findParent(parent) : model;
}

function findTopLevelBlock(model) {
  // find the top-level block parent from anywhere in the code
  const parent =
    model instanceof CodeBlock ? model.getParent() : model.get("parent");
  if (parent) {
    if (parent.get("free-form")) {
      return model;
    } else {
      return findTopLevelBlock(parent);
    }
  } else {
    return model;
  }
}

/**
 * superclass for code blocks
 * backbone-relational will create a submodel of the appropriate type
 */
const CodeBlock = PrototypeModel.extend(TestablePrototype).extend({
  subModelTypes: {
    // objects
    object: "Blocks/Object",
    this: "Blocks/This",
    "object-named": "Blocks/ObjectNamed",
    cloned: "Blocks/Cloned",

    // commands
    command: "Blocks/Command",

    // operators
    operator: "Blocks/Operator",

    // events
    event: "Blocks/Event",

    // control blocks
    "if-else": "Blocks/IfElse",
    if: "Blocks/If",
    repeat: "Blocks/Repeat",
    "for-each": "Blocks/ForEach",

    // variables
    variable: "Blocks/Variable",
    "assign-variable": "Blocks/AssignVariable",
    "change-variable": "Blocks/ChangeVariable",

    // not actual blocks
    placeholder: "Blocks/Placeholder",
    separator: "Blocks/Separator",

    // primitives aka. values
    number: "Blocks/Primitive/Number",
    texture: "Blocks/Primitive/Texture",
    vector: "Blocks/Primitive/Vector",
    angle: "Blocks/Primitive/Angle",
    null: "Blocks/Primitive/Null",
    key: "Blocks/Primitive/Key",
    string: "Blocks/Primitive/String",
    boolean: "Blocks/Primitive/Boolean",
    turn: "Blocks/Primitive/Turn",
    sound: "Blocks/Primitive/Sound",
  },

  relations: TestablePrototype.relations.concat([
    {
      type: Backbone.HasOne,
      key: "position",
      parse: true,
      relatedModel: PrototypeModel,
    },
    {
      type: Backbone.HasOne,
      key: "jigsaw-fill",
      relatedModel: CodeBlock,
      parse: true,
      runTests: true,
      reverseRelation: {
        key: "parent-jigsaw",
        includeInJSON: false,
        type: Backbone.HasOne,
      },
    },
    {
      type: Backbone.HasMany,
      key: "blockLinks",
      relatedModel: CodeBlock,
      includeInJSON: [`id`, "type"],
    },
  ]),

  defaults: {
    /**
     * The major version of this code block
     * used to determine when backwards-compatible code needs to run
     *
     * Increment this whenever changes are made to the data model that would
     * not be backwards-compatible
     *
     * Note: this property may be missing on older data, in those cases, the
     * version is inferred from the presence of other properties.
     * see `_parseV2toV3` and `_parseV1toV2`
     */
    __v: 4,

    /**
     * The position of the code block while on a free-form canvas
     * Ignored when the block is nested inside another block
     */
    position: { x: 0, y: 0 },

    /**
     * The category of this block.
     * Used to group code blocks in the code-chest
     */
    category: null,

    /**
     * A list of groups this block belongs to.
     * Used to group code blocks in the code-chest
     */
    groups: [],

    /**
     * Locks a block in place, preventing it from being dragged
     * Used by content creators for lessons
     * */
    locked: false,

    /**
     * A hard-locked block is never draggable, not even to content creators,
     * this is used to create fixed block combinations that cannot be pulled
     * apart such as `app.print()`
     */
    "hard-lock": false,

    /**
     * A rotation applied to this block due to invalid placement
     * When `0`, the block is placed correctly
     */
    "invalid-rotation": 0,

    /**
     * Causes this block to create a clone of itself when dragged out of the code-wall
     * Only applies to blocks in the code-wall
     */
    clones: false,

    /**
     * Denotes this block as being a clone
     * (created from a block that `clones` in the code-wall)
     */
    cloned: false,

    /**
     * Determine whether this block has been `used`
     * A block that has been `used` will be hidden from the wall until it is deleted from the canvas
     */
    used: false,

    /**
     * Marks this code block as being critical
     * Critical blocks can never be fully deleted (as doing so might make completing the task impossible)
     */
    critical: false,

    /**
     * Jigsaw blocks serve as placeholders
     * They allow content creators to provide scaffolded content to the user
     */
    jigsaw: false,

    /**
     * Holds the code block that is placed on top of this jigsaw block
     */
    "jigsaw-fill": null,

    /**
     * True for blocks in toodal
     */
    "in-toodal": false,

    /**
     * True for blocks in the wall that are linked to jigsaw blocks on the canvas
     */
    "jigsaw-clone": false,

    /**
     * Distractor behave exactly like jigsaw blocks except except that they hold the `wrong` answer
     * This is used by content creators to add extra challenge to jigsaw lessons
     */
    distractor: false,

    /**
     * True for blocks in the wall that are linked to distractor blocks on the canvas
     */
    "distractor-clone": false,

    /**
     * Block only available to content creators
     */
    "editorial-only": false,

    /**
     * Block is not ready for production
     * Used by experimental blocks that haven't been implemented fully
     */
    incomplete: false,
    /**
     * Secret blocks are not available on their own
     * These blocks are only available as a combination of blocks
     * (defined in `views/block/ui-elements/code/code-chest/code-chest-blocks/`)
     */
    secret: false,

    /**
     * Marks this block as being deprecated
     * It won't be available anywhere except for any content that has been
     * created with it
     */
    deprecated: false,

    /**
     * Optional list of feature flags that controls availability of this block
     * in the code-chest
     */
    "feature-flags": [],

    /**
     * True while the code of this block is running
     * Allows code blocks to light up
     */
    active: false,

    /**
     * Used to highlight blocks
     * For instance, when the user mouseovers it
     */
    highlight: false,

    /**
     * whether this block has triggered an error
     */
    error: false,

    /**
     * whether this block has triggered a warning
     */
    warning: false,

    /**
     * Disables code execution for this block
     * Usually caused by block syntax errors
     */
    disabled: false,

    interactive: true,

    /**
     * Holds a list of connections between code blocks in various locations
     */
    blockLinks: [],
  },

  /**
   * A CodeBlock can have a JSON blueprint
   * When a CodeBlock loads its data from the DB, it is extended with the blueprint, ensuring that it conforms to it
   * This allows CodeBlocks to be changed even once they have been saved to the DB
   */
  blueprint: null,

  initialize(options) {
    PrototypeModel.prototype.initialize.call(this, options);

    this._conformToBlueprint();

    this.set("childScopeKeys", this.getChildScopeKeys());

    // debounce the deactivation method to keep blocks active for a minimum amount of time once activated
    this.deactivate = _.debounce(
      this.deactivate.bind(this),
      this._activeDuration,
    );

    this._cache = new BlockCache();

    // remove active state when code highlighting is disabled
    this.listenTo(settings, "change:code-highlighting-enabled", () => {
      if (!settings.get("code-highlighting-enabled")) {
        this.set("active", false);
      }
    });

    // restores V2 links as V3 once both sides of the link have been initialized
    if (this.get("v2Links")) {
      this.get("v2Links").forEach(link =>
        this.link(CodeBlock.findModel(link.id)),
      );
      this.unset("v2Links");
    }
  },

  /**
   * Calls toJSON on this model
   * if options.shallow is true, relations will be excluded
   */
  toJSON(options = {}) {
    if (options.shallow) {
      const clone = {};
      const relations = this.getRelations().map(r => r.key);

      for (const [key, value] of Object.entries(this.attributes)) {
        if (!relations.includes(key)) {
          if (typeof value === "object") {
            clone[key] = JSON.parse(JSON.stringify(value));
          } else {
            clone[key] = value;
          }
        }
      }

      return clone;
    } else {
      return PrototypeModel.prototype.toJSON.call(this, options);
    }
  },

  /** clean up data prior to init */
  parse(data, options) {
    if (!(options && options.skipParse)) {
      data.blockLinks = data.blockLinks || [];
      data.groups = data.groups || [];

      this._parseV1toV2(data);
      this._parseV2toV3(data);
      this._parseV3toV4(data);
      this._validateId(data);
      this._parseCloned(data);

      data.active = false;
      data.highlight = false;
      data.error = false;
      data.disabled = false;
      data.selected = false;
      data.warning = false;

      // generate a unique `id`
      delete data._id;
    }

    return Object.assign({}, this.defaults, data);
  },

  // ensures we have uuids instead of backbone ids.
  _validateId(data) {
    data.id = isValidUUID(data.id) ? data.id : generateConsistentUUID(data.id);
    if (data.v2Links) {
      data.v2Links.forEach(item => {
        item.id = !isValidUUID(item.id)
          ? generateConsistentUUID(item.id)
          : item.id;
      });
    }

    if (data.blockLinks) {
      data.blockLinks.forEach(item => {
        item.id = !isValidUUID(item.id)
          ? generateConsistentUUID(item.id)
          : item.id;
      });
    }
  },

  // handles cloned items
  _parseCloned(data = {}) {
    if (!task.get("isClone")) {
      return;
    }

    data.id = generateConsistentUUID(data.id);

    if (data.v2Links) {
      data.v2Links.forEach(item => {
        item.id = generateConsistentUUID(item.id);
      });
    }

    if (data.blockLinks) {
      data.blockLinks.forEach(item => {
        item.id = generateConsistentUUID(item.id);
      });
    }

    if (data.target) {
      if (typeof data.target === "string") {
        data.target = generateConsistentUUID(data.target);
      } else if (
        data.target.key &&
        (data.target.type === "text" || data.target.type === "button")
      ) {
        data.target.key = generateConsistentUUID(data.target.key);
      }
    }
  },

  // transforms v2 data to v3
  _parseV3toV4(data = {}) {
    if (data.__v >= 4) {
      return;
    }

    if (data.cloned) {
      data.critical = false;
    }

    data.__v = 4;
  },

  // transforms v2 data to v3
  _parseV2toV3(data = {}) {
    if (data.__v >= 3) {
      return;
    }

    // get rid of `blockID` property
    data.id = generateConsistentUUID(data.blockID);
    delete data.blockID;

    // store blockLinks on temporary property, as this will be restored later
    data.v2Links = data.blockLinks.map(id => ({
      id: generateConsistentUUID(id),
    }));
    data.blockLinks = [];

    data.__v = 3;
  },

  // transforms v1 data to v2
  _parseV1toV2(data = {}) {
    if (data.__v || data.blockID) {
      return;
    }

    // generate a consistent UUID based off of old `id`
    data.blockID = generateConsistentUUID(data.id);

    let other;

    // fix old distractor data
    if (data.distractorLink) {
      other = generateConsistentUUID(data.distractorLink);

      if (data["distractor-link"]) {
        data["distractor-clone"] = true;
      } else {
        data.distractor = true;
      }
    }

    // fix old jigsaw data
    if (data.jigsawLink) {
      other = generateConsistentUUID(data.jigsawLink);

      if (!data.jigsaw) {
        data["jigsaw-clone"] = true;
      }
    }

    // fix old clone blocks
    if (data.restoreId) {
      other = generateConsistentUUID(data.restoreId);
    }

    // if we have another block this one needs to be linked to, add it to the restoreLinks
    // we will attempt to restore these links at a later point once all the data and views have been initialized
    if (other) {
      data.blockLinks.push(other);
    }

    delete data["distractor-link"];
    delete data.distractorLink;
    delete data.jigsawLink;
    delete data.restoreId;
  },

  /**
   * Creates a backbone relation
   * @param  {string} type  - The type of Backbone Relation to create
   * @param  {string} key   - The key for this Backbone relation
   * @return {Object}       Returns an object with Backbone relational configuration
   */
  createRelation(type, key) {
    let model;

    switch (type) {
      case "arguments":
        model = require("./components/arguments").default;
        break;
      case "scope":
        model = require("./components/block-scope").default;
        break;
      default:
        throw new Error(`Invalid relation type: ${type}`);
    }

    return {
      type: Backbone.HasOne,
      key: key,
      parse: true,
      runTests: true,
      relatedModel: model.extend({}),
      reverseRelation: {
        key: "parent",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    };
  },

  /** @private extend this block with its blueprint, ensuring that it conforms to it */
  _conformToBlueprint() {
    if (!this.blueprint) {
      return;
    }

    const { args, groups, category, secret } = this.blueprint;
    const featureFlags = this.blueprint["feature-flags"];
    delete this.blueprint.args;
    delete this.blueprint.groups;
    delete this.blueprint.category;
    delete this.blueprint["feature-flags"];
    delete this.blueprint.secret;

    this.set(this.blueprint);

    // conform arguments to blueprint
    if (args) {
      if (this.has("args")) {
        const blockArgs = this.get("args").get("arguments");

        for (
          let i = 0;
          i < Math.max(blockArgs.length, args.arguments.length);
          i++
        ) {
          const BPArg = args.arguments[i];
          const arg = blockArgs.at(i);

          if (!arg && BPArg) {
            // add missing argument
            blockArgs.add(BPArg);
          }

          if (arg && BPArg) {
            // conform existing argument
            arg.set(BPArg);
          }

          if (arg && !BPArg) {
            // remove deleted argument
            blockArgs.remove(arg);
          }
        }
      } else {
        this.set("args", args);
      }
    }

    this.blueprint.args = args;
    this.blueprint.groups = groups;
    this.blueprint.category = category;
    this.blueprint["feature-flags"] = featureFlags;
    this.blueprint.secret = secret;
  },

  /** check whether this block allows another block to be placed on top of it */
  accepts(model) {
    // jigsaw blocks can't be combined when in edit mode
    if (settings.get("editable") && this.get("jigsaw")) {
      return false;
    }

    // only jigsaw blocks can accept other blocks
    if (!this.get("jigsaw")) {
      return false;
    }

    // jigsaw blocks only accept blocks of the same type
    // TODO: potential issue where blocks of different types but same shapes cannot be placed inside similar jigsaws? eg. number cannot be placed inside string jigsaw
    return this.get("type") === model.get("type");
  },

  // Utility Getters
  // -------------

  /**
   * find the direct parent of this block
   * @return {Backbone.Model|null}  - The direct parent of this CodeBlock or null if it has no parent
   *                                  This can be a Block Argument, a Block Scope or another CodeBlock
   */
  getParent() {
    return (
      this.get("parent") ||
      this.get("parent-jigsaw") ||
      this.get("parent-scope") ||
      this.get("parent-argument")
    );
  },

  /**
   * similar to `getParent` but instead finds the closest CodeBlock ignoring Scopes, Arguments, etc...
   * Cached during runtime for performance
   * @return {CodeBlock|null}    - The Closest parent CodeBlock or null
   */
  getParentBlock() {
    return this._cache.get("getParentBlock()", () => {
      let parent = this.getParent();

      while (parent && !(parent instanceof CodeBlock)) {
        if (typeof parent.getParent === "function") {
          parent = parent.getParent();
        } else {
          parent = parent.get("parent");
        }
      }

      return parent;
    });
  },

  /**
   * Check whether the current task is in a free form environment.
   * Free form environments are:
   * - free coder
   * - user app (when user is author of app)
   * - build steps
   * @returns {Boolean} True if we're in a free form environment
   */
  _isInFreeFormTask() {
    return (
      hasTaxonomy(task, "task-type.build") ||
      hasTaxonomy(task, "use-type.free-code") ||
      (hasTaxonomy(task, "use-type.user-generated") && isAuthor(user, task))
    );
  },

  /**
   * returns context menu link items for related code-block
   * @return {Any[]|[]}    - The Closest parent CodeBlock or null
   */
  async getContextMenuItems(source = "codeBlock") {
    const sourceFilters = {
      codeBlock: [
        "use block",
        "add code",
        "edit",
        "delete",
        "copy",
        "help",
        "separate",
        "switch",
        "copy solution",
        "play sound",
      ],
      codeBin: ["delete", "copy", "help"],
    };

    const isOnForeground =
      this.getTopLevelScope() && this.getTopLevelScope().get("isForeground");

    const isOnBackground =
      this.getTopLevelScope() && this.getTopLevelScope().get("isBackground");

    return [
      {
        name: "use block",
        text: "Add code",
        icon: "circle-plus",
        isAvailable:
          checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE") && this.isInWall(),
        isDisabled: true,
        action: () => this.useFromWall(),
      },
      {
        name: "add code",
        text: "Add code",
        icon: "circle-plus",
        isAvailable:
          checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE") && !this.isInWall(),
        isDisabled: !this.canHaveCodeAdded(),
        action: () => this.addCode(),
      },
      {
        name: "edit",
        text: "Edit",
        icon: "comet_edit",
        isAvailable: checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE"),
        isDisabled: !this.canHaveCodeEdited(),
        action: () => this.editCode(),
      },
      {
        name: "delete",
        text: "Delete",
        icon: "comet_delete",
        isAvailable: true,
        isDisabled: !this.canBeDeleted(),
        action: () => {
          const { blockRecycleBin } = require("globals/block-recycle-bin");
          this.move(blockRecycleBin);
        },
      },
      {
        name: "separate",
        text: "Separate code",
        icon: "comet_expand",
        isAvailable: checkFlag("BLOCK_CONTEXT_MENU_SEPARATE_CODE"),
        isDisabled: !this.canBeSeparated(),
        action: () => this.separate(),
      },
      {
        name: "copy",
        text: "Duplicate code",
        icon: "comet_copy",
        isAvailable: true,
        isDisabled: !this.canBeCopied(),
        action: () => this.copy(),
      },
      {
        name: "help",
        text: "Help",
        icon: "comet_help",
        isAvailable: checkFlag("BLOCK_HELP"),
        isDisabled: !(await this.isHelpAvailable()),
        action: () => this.openHelp(),
      },
      {
        name: "copy solution",
        text: "Copy to solution",
        icon: "comet_share",
        isAvailable:
          settings.get("editable") && (isOnForeground || isOnBackground),
        isDisabled: !this.isInCanvas(),
        action: () => {
          const block = this._copy();
          block.move(this.getSolution());
        },
      },
      {
        name: "switch",
        text: "Send to background",
        icon: "comet_share",
        isAvailable: settings.get("editable") && isOnForeground,
        isDisabled: !this.isInCanvas(),
        action: () => this.move(this.getBackground()),
      },
      {
        name: "switch",
        text: "Send to foreground",
        icon: "comet_share",
        isAvailable: settings.get("editable") && isOnBackground,
        isDisabled: !this.isInCanvas(),
        action: () => this.move(this.getForeground()),
      },
      {
        name: "play sound",
        text: "Play sound",
        icon: "comet_play",
        isAvailable:
          checkFlag("SOUND_BLOCK_PREVIEW") &&
          this.get("type") === "object" &&
          this.get("target")?.type === "sound",
        isDisabled: false,
        action: () => this.playSound(),
      },
    ].filter(
      item => item.isAvailable && sourceFilters[source].indexOf(item.name) > -1,
    );
  },

  /**
   * Returns the active canvas
   */
  activeCanvas() {
    const background = this.getBackground();
    return background && background.get("active")
      ? background
      : this.getForeground();
  },

  /**
   * Returns the inactive canvas
   */
  inactiveCanvas() {
    const background = this.getBackground();
    return background && background.get("active")
      ? this.getForeground()
      : background;
  },

  /**
   * Returns if any child has jigsaw
   */
  _hasJigsawChild() {
    return (
      this.getChildBlocks().filter(childBlock => childBlock.get("jigsaw"))
        .length > 0
    );
  },

  /**
   * finds the topmost scope of this block
   * Cached during runtime for performance
   */
  getTopLevelScope() {
    return this._cache.get("getTopLevelScope()", () => findParent(this));
  },

  /**
   * finds the top most parent block of this block
   * Cached during runtime for performance
   */
  getTopLevelBlock() {
    return this._cache.get("getTopLevelBlock()", () => findTopLevelBlock(this));
  },

  /** find the code wall (if this block is in an environment that has access to it) */
  getWall() {
    const blockCoder = this.getBlockCoder();
    return blockCoder && blockCoder.get("snippets");
  },

  /** find the foreground code (if this block is in an environment that has access to it) */
  getForeground() {
    const blockCoder = this.getBlockCoder();
    return blockCoder && blockCoder.get("input");
  },

  /** find the background code (if this block is in an environment that has access to it) */
  getBackground() {
    const blockCoder = this.getBlockCoder();
    return blockCoder && blockCoder.get("background-code");
  },

  /** find the solution code (if this block is in an environment that has access to it) */
  getSolution() {
    const blockCoder = this.getBlockCoder();
    return blockCoder && blockCoder.get("solution-code");
  },

  /** find the block coder (if this block is in an environment that has access to it) */
  getBlockCoder() {
    return task.getComponent("models/block");
  },

  /** returns an array of all child relations that have a BlockScope */
  getChildScopeKeys() {
    const BlockScope = require("./components/block-scope").default;

    const keys = this.relations.map(rel => {
      const child = this.get(rel.key);

      if (child instanceof BlockScope && !rel.isAutoRelation) {
        return rel.key;
      }
    });

    return _.compact(keys);
  },

  /** returns the pseudo code of this block */
  getPseudoCode(indent = "") {
    return indent + "[?]"; // no-op to be implemented by specific blocks
  },

  /**
   * Returns a list of all nested child blocks
   * Including itself
   */
  getChildBlocks(list = []) {
    list.push(this);

    if (this.get("jigsaw-fill")) {
      this.get("jigsaw-fill").getChildBlocks(list);
    }

    return list;
  },

  /** returns a list of glossary taxonomies pertinent to this code block */
  getGlossaryTaxonomy() {
    const blockType = this.get("type");
    const blockFn = this.get("fn");
    const childStringBlock = this.__getChildFirstStringBlock();
    const glossaryTaxonomies = [
      `glossary-type.code-block.${blockType}.${blockFn}`,
    ];
    if (
      blockType === "command" &&
      ["changeBy", "get", "set"].indexOf(blockFn) > -1
    ) {
      if (
        childStringBlock &&
        childStringBlock.get("type") === "string" &&
        childStringBlock.get("value")
      ) {
        glossaryTaxonomies.push(
          `glossary-type.object-property.${childStringBlock.get("value")}`,
        );
      }
      glossaryTaxonomies.push("glossary-type.object-property");
    }
    glossaryTaxonomies.push(
      `glossary-type.code-block.${blockType}`,
      `glossary-type.code-block`,
    );
    return glossaryTaxonomies;
  },

  __getChildFirstStringBlock() {
    try {
      return this.get("args").get("arguments").at(0).get("code");
    } catch (e) {
      return null;
    }
  },

  async getGlossaryTerms() {
    const taxonomies = this.getGlossaryTaxonomy();
    const terms = [
      ...new Set(taxonomies.map(key => glossary.getItems(key)).flat(1)),
    ];
    return terms;
  },

  // Utility Flags
  // -------------

  /** returns true if this block is a C-block */
  isCBlock() {
    return this.get("type") === "event" || Boolean(this.get("control-block"));
  },

  /** returns true if the block is anywhere on the code wall */
  isInWall() {
    const topLevel = this.getTopLevelScope();
    return Boolean(topLevel && topLevel.get("isWall"));
  },

  /** returns true only if the block is DEEPLY nested inside the wall */
  isNestedInWall() {
    const topLevel = this.getTopLevelScope();
    return Boolean(
      topLevel && topLevel.get("isWall") && topLevel !== this.getParent(),
    );
  },

  /** returns true if the block is nested or directly on a free-form canvas */
  isInCanvas() {
    const topLevel = this.getTopLevelScope();
    return Boolean(topLevel && topLevel.get("free-form"));
  },

  /** true when this block is used as an argument */
  isInArgumentSlot() {
    return this.has("parent-argument");
  },

  /** returns an array of all the blocks nested inside this block's argument slots */
  getArgumentBlocks() {
    if (this.get("args")) {
      return this.get("args")
        .get("arguments")
        .models.map(slot => slot.get("code"));
    } else {
      return [];
    }
  },

  /** interface function to be extended by specific implementation */
  isEditable() {
    return false;
  },

  isSound() {
    return false;
  },

  /**
   * determines whether a block has help or not
   */
  async isHelpAvailable() {
    if (this.get("jigsaw")) {
      return false;
    }

    try {
      const terms = await this.getGlossaryTerms();
      return terms.length > 0;
    } catch (e) {
      return false;
    }
  },

  async openHelp() {
    const terms = await this.getGlossaryTerms();
    if (checkFlag("BLOCK_HELP_NEW_PANEL")) {
      interfaceChannel.trigger("panel-manager:panel:open:help-panel", {
        block: this,
      });
    } else {
      helpWindow.open(terms);
    }
  },

  playSound() {
    // no-op (to be overridden by individual block types)
  },

  /** determines whether a block is a jigsaw */
  isJigsaw() {
    return Boolean(this.get("jigsaw")) || Boolean(this.get("jigsaw-clone"));
  },

  /** true if we are currently in 'jigsaw mode' */
  isInJigsawMode() {
    const blockCoder = this.getBlockCoder();
    return Boolean(
      blockCoder && blockCoder.get("interaction-mode") === "jigsaw",
    );
  },

  /** determines whether a block has a jigsaw-fill */
  isJigsawFilled() {
    return Boolean(this.get("jigsaw") && this.get("jigsaw-fill"));
  },

  /**
   * Determines if this block can be deleted by the user
   */
  canBeDeleted() {
    if (settings.get("editable")) {
      return true; // in editorial edit mode all blocks can be deleted
    }

    if (task.get("locked")) {
      return false;
    }

    if (!this.isInCanvas()) {
      return false;
    }

    if (this.get("locked")) {
      return false;
    }

    if (this.get("hard-lock")) {
      return false;
    }

    if (
      (this.get("jigsaw") && !this.get("jigsaw-clone")) ||
      (this.get("distractor") && !this.get("distractor-clone"))
    ) {
      return false;
    }

    if (this.get("critical") && !this._isInFreeFormTask()) {
      return false;
    }

    // blocks can only be deleted if all of their children can too (except for jigsaw/distractor clones)
    if (
      this.getChildBlocks()
        .filter(block => block !== this)
        .map(block => block._canBeDeletedAsChild())
        .includes(false) &&
      !this.get("jigsaw-clone") &&
      !this.get("distractor-clone")
    ) {
      return false;
    }

    return true;
  },

  /** determines whether this block can also be deleted when it is the child of a parent block that wants to be deleted */
  _canBeDeletedAsChild() {
    return this.get("locked") || this.get("hard-lock") || this.canBeDeleted();
  },

  /**
   * Determines if this block can be copied by the user
   */
  canBeCopied() {
    if (!this.canBeDeleted()) {
      // if a block can't be deleted, then it also can't be cloned
      // this prevents users from creating copies that they can't get rid of
      return false;
    }

    if (!this._isInFreeFormTask()) {
      return false;
    }

    return true;
  },

  /**
   * determines whether this block can be moved to another code canvas (foreground/background)
   */
  canBeMovedToOtherCanvas() {
    if (!settings.get("editable")) {
      return false; // only accessible in editorial edit mode
    }

    return true;
  },

  /** determines whether this block can be separated */
  canBeSeparated() {
    if (this.isInJigsawMode()) {
      return false;
    }

    if (this.get("locked")) {
      return false;
    }

    return this.getParent()?.get("free-form");
  },

  separate() {
    interfaceChannel.trigger("separate-block", this);
  },

  /**
   * Checks whether this block is the same as another one
   * Compares the blocks by functionality, not whether they are exactly the same instance
   * @param  {CodeBlock} block - The other block to compare to this one
   * @return {Boolean}         True if both blocks have the same functionality
   */
  isSame(block) {
    // some preliminary checking
    if (!block || this.constructor !== block.constructor) {
      return false;
    }

    // check whether arguments are the same
    if (this.has("args") && !this.get("args").isSame(block.get("args"))) {
      return false;
    }

    // base comparison
    return Boolean(
      this.get("isInput") === block.get("isInput") &&
        this.get("type") === block.get("type") &&
        this.get("fn") === block.get("fn"),
    );
  },

  /**
   * Determines whether code can be added to this block
   * This method can be extended by individual block types
   */
  canHaveCodeAdded() {
    const blockCoder = this.getBlockCoder();

    if (!blockCoder) {
      return false;
    }

    if (!this.isInCanvas()) {
      return false;
    }

    if (blockCoder.get("interaction-mode") === "jigsaw") {
      return false;
    }

    return (
      blockCoder.get("settings").get("has-code-wall") ||
      blockCoder.get("settings").get("has-code-chest")
    );
  },

  /**
   * Add code to this code block
   */
  addCode() {
    if (!this.canHaveCodeAdded()) {
      return;
    }

    interfaceChannel.trigger("add-code:open", this);
  },

  /**
   * Determines whether code can be edited in this block
   * This method can be extended by individual block types
   */
  canHaveCodeEdited() {
    return this.isEditable();
  },

  /**
   * Edit this code block
   */
  editCode() {
    if (!this.canHaveCodeEdited()) {
      return;
    }

    interfaceChannel.trigger("swap-code:open", this);
  },

  /**
   * Determines whether this block can be 'used'
   * By 'use' we mean moved from the wall to the code canvas
   * @returns {Boolean}
   */
  canBeUsedFromWall() {
    if (!this.isInWall()) {
      return false;
    }

    if (this.isNestedInWall()) {
      return false;
    }

    if (this.get("type") !== "event") {
      return false;
    }

    return true;
  },

  /**
   * 'Use' this block
   * Moves this block from the wall to the code canvas
   */
  useFromWall() {
    if (!this.canBeUsedFromWall()) {
      return;
    }

    const input = this.getBlockCoder().get("input");

    // TODO: calculate better position for block based on available space in canvas
    this.move(input, null, { x: 0, y: 0 });
  },

  // Moving Blocks via user interaction
  // ----------------------------------

  /**
   * Move a CodeBlock from its current parent to another one
   * Automatically detects which action must be performed
   * Adds the move action to actionHistory for undo-redo purposes
   * @param  {Backbone.Model} [to] - (optional) The new parent this CodeBlock will be added to, if omitted, the block will be deleted
   * @param  {Number|Object} [index] - (optional) The index to move the block at (when added to a collection)
   * @param  {Object} [position] - (optional) The position to move the block at (when added to free-form canvas)
   * @param  {"user"|"system"} [source=user] - (optional) Source of the move call
   *         "user": move performed by the user
   *         "system": move performed by the app, these are *not* added to the undo/redo history
   *
   * @return {CodeBlock} This CodeBlock or a clone or copy if one was created
   */
  move(to, index = 0, position = { x: 0, y: 0 }, source = "user") {
    let block, blockLink;
    let parent = this.getParent();

    // keep track of current location for action history
    let currentIndex = parent?.get("code")?.indexOf?.(this) || 0;
    const currentPosition = {
      x: this.get("position").get("x"),
      y: this.get("position").get("y"),
    };

    // if source is system, we do things specific to undo/redo
    // there is no need to check for the undoRedoflag here
    if (source === "system") {
      block = this;
      block._removeFromParent();
    } else {
      if (!parent) {
        // if this block has no parent, it must come from the code-chest
        // create a new copy of the block and use this one instead
        block = this._copy();
      } else if (this.isInWall() && !settings.get("editable")) {
        // if this block is coming from the wall and we are not in edit mode
        // create a clone with a reference to the original
        block = this._useWallblock();
      } else {
        // otherwise, move the block by also removing it from its current location
        block = this;
        block._removeFromParent();
      }
    }

    blockLink = block.get("blockLinks").at(0);
    if (to && to.get("isRecycleBin")) {
      // move child blocks to recycle bin, only if it's an user action.
      if (source === "user") {
        const childBlocks = block.getChildBlocks();
        childBlocks.forEach(child => {
          if (
            child.getParentBlock() === block &&
            child.get("blockLinks")?.length
          ) {
            child.move(to);
          }
        });
      }

      block.unlink();
    }

    block.get("position").set(position);
    block._addToParent(to, index);
    block._validateConfig();

    // apply rotation to the block if its placement is invalid
    if ((to && !block._validatePlacement(to)) || block.isInWall()) {
      const rot = Math.floor(Math.random() * 5) + 5;
      block.set("invalid-rotation", Math.random() > 0.5 ? rot : -rot);
    } else {
      block.set("invalid-rotation", 0);
    }

    this.addToActionHistory(
      {
        block: block,
        from: parent,
        type: "move",
        to,
        index: { old: currentIndex, new: index },
        position: { old: currentPosition, new: position },
        blockLink,
      },
      source,
    );

    return block;
  },
  /**
   * Changes the value of this block
   * no-op: method to be implemented by specific block types that can have their value changed
   *
   * @param {*} value - The new value
   * @param  {"user"|"system"} [source=user] - (optional) Source of the call
   *         "user": change performed by the user
   *         "system": change performed by the app, these are *not* added to the undo/redo history
   */
  // eslint-disable-next-line no-unused-vars
  changeValue(value, source = "user") {
    // no-op
  },

  /**
   * Adds an action to the action history
   */
  addToActionHistory(action, source = "user") {
    if (source === "user") {
      actionHistory.addItem(action);
    }
  },

  /**
   * deletes this block
   */
  delete() {
    // while in edit mode, deleting either side of a jigsaw or distractor also deletes the other side
    if (settings.get("editable")) {
      if (this.get("jigsaw") || this.get("distractor")) {
        this.get("blockLinks").forEach(block => block._delete());
      }
    }

    this._delete();
  },

  /**
   * Creates a copy of this code block and places it on the active stage
   * note: this is different from `_copy` which is only intended for internal use
   */
  copy() {
    const block = this._copy(); // create copy

    // adjust position of clone so that it's slightly offset from the original
    const x = this.get("position").get("x") + 25;
    const y = this.get("position").get("y") + 25;

    // add copy to stage
    block.move(this.activeCanvas(), null, { x, y });
    interfaceChannel.trigger("block-cloned");
  },

  /**
   * Determines whether this block can have focus
   * @returns {Boolean}
   */
  canHaveFocus() {
    if (this.has("jigsaw-fill")) {
      return false;
    }

    if (this.view?.isHidden()) {
      return false;
    }

    if (this.view?.el.closest(".super-block.hidden")) {
      return false;
    }

    if (this.get("used")) {
      return false;
    }

    return true;
  },

  /** Determines whether this model has custom keyboard controls */
  hasCustomKeyboardControls() {
    return true;
  },

  /**
   * Get the HORIZONTAL list of blocks the user can navigate to from this block
   * @param {[]} (chain) Leave this empty. It will get populated by the recursive nature of this function
   * @returns A list of blocks (containing the current block)
   */
  getHorizontalCodeChain(chain = []) {
    if (chain.includes(this)) {
      return chain;
    }

    chain.push(this);

    if (this.has("jigsaw-fill")) {
      this.get("jigsaw-fill").getHorizontalCodeChain(chain);
    }

    if (!this.get("jigsaw")) {
      if (this.has("args")) {
        this.get("args").getHorizontalCodeChain(chain);
      }
    }

    if (this.has("commands")) {
      this.get("commands").getHorizontalCodeChain(chain);
    }

    return chain;
  },

  /**
   * Get the VERTICAL list of blocks the user can navigate to from this block
   * @param {[]} (chain) Leave this empty. It will get populated by the recursive nature of this function
   * @returns A list of blocks (containing the current block)
   */
  getVerticalCodeChain(chain = []) {
    if (chain.includes(this)) {
      return chain;
    }

    chain.push(this);

    if (this.has("jigsaw-fill")) {
      this.get("jigsaw-fill").getVerticalCodeChain(chain);
    }

    this.getChildScopeKeys().forEach(key =>
      this.get(key).getVerticalCodeChain(chain),
    );

    return chain;
  },

  /**
   * Find the top-most block of the current HORIZONTAL code chain
   */
  getHorizontalCodeChainParent() {
    if (this.get("parent-jigsaw")) {
      return this.get("parent-jigsaw").getHorizontalCodeChainParent();
    }

    if (this.get("parent-argument")) {
      return this.get("parent-argument").getHorizontalCodeChainParent();
    }

    if (
      this.get("parent-scope") &&
      this.get("parent-scope").get("object-scope")
    ) {
      return this.get("parent-scope").getHorizontalCodeChainParent();
    }

    return this;
  },

  /**
   * Find the top-most block of the current VERTICAL code chain
   */
  getVerticalCodeChainParent() {
    if (this.get("parent-jigsaw")) {
      return this.get("parent-jigsaw").getVerticalCodeChainParent();
    }

    if (this.get("parent-argument")) {
      return this.get("parent-argument").getVerticalCodeChainParent();
    }

    if (this.get("parent-scope")) {
      const parent = this.get("parent-scope").getVerticalCodeChainParent();

      if (parent) {
        return parent;
      }
    }

    return this;
  },

  /** @private validates the configuration of an object based on its surroundings */
  _validateConfig() {
    if (this.isInWall()) {
      this.set("locked", false); //a block directly on the wall cannot be locked
    }

    if (!this.isInWall()) {
      this.set("clones", false); //a block can only clone directly from the wall
    }
  },

  /** @private notify the console of changed blocks so their errors can be disabled */
  _notifyChanged(changed = []) {
    changed.forEach(block => {
      if (block) {
        blockConsole.fix(block.cid);
      }
    });

    interfaceChannel.trigger("code-change");
  },

  /** @private removes this CodeBlock from its current parent */
  _removeFromParent() {
    const parent = this.getParent();
    const changed = [];

    if (!parent) {
      return;
    }

    //remove from a scope
    if (parent instanceof require("./components/block-scope").default) {
      changed.push(this);
      parent.get("code").remove(this);

      //remove from an argument
    } else if (parent instanceof require("./components/argument").default) {
      changed.push(this.getParentBlock());
      parent.unset("code");

      //remove from jigsaw block
    } else {
      parent.set("jigsaw-fill", null);
      changed.push(this);
      changed.push(parent);
      this._swapScopes(parent);
    }

    this._notifyChanged(changed);
  },

  /**
   * Adds this block to a parent
   * @private
   * @param {Backbone.Model} [parent] - The parent to add this block to, block will be deleted if omitted
   *                                    Must be a Block Argument, Block Scope or a Jigsaw Block
   */
  _addToParent(parent, index) {
    const changed = [];

    //add to scope
    if (parent.get("code") instanceof Backbone.Collection) {
      const options = {};

      //allow blocks to be added at a specific index
      if (typeof index === "number" && index > -1) {
        options.at = index;
      }

      // allow block to be recycled
      if (parent.get("isRecycleBin")) {
        interfaceChannel.trigger("block-recycled");
      }

      parent.get("code").add(this, options);

      //add to jigsaw-block
    } else if (parent.get("jigsaw")) {
      parent.set("jigsaw-fill", this);
      this._swapScopes(parent);
      changed.push(parent);
      changed.push(this);

      interfaceChannel.trigger("block-connected");

      //add to argument
    } else {
      parent.set("code", this);

      changed.push(this.getParentBlock());
    }

    this._notifyChanged(changed);
  },

  /**
   * Handles permanent deletion of a block
   * Don't call this if you intend to re-use this code block
   * Removes reference from the Relational store and destroys the view
   * If the block is marked as critical, it will be placed on the canvas instead
   * @private
   */

  _delete() {
    this._removeFromParent();

    // delete jigsaw fill
    if (this.get("jigsaw-fill")) {
      this.get("jigsaw-fill").delete();
    }

    // delete child scopes
    this.getChildScopeKeys().forEach(key => {
      if (this.has(key)) {
        this.get(key).delete();
      }
    });

    // delete arguments
    if (this.has("args")) {
      this.get("args").delete();
    }

    this.unlink();

    Backbone.Relational.store.unregister(this);

    if (this.view) {
      delete this.view;
    }
  },

  // Linking blocks
  // --------------

  /**
   * Creates a link between two blocks
   * Allows the blocks to be aware of one another
   *
   * Used for communication between blocks in the canvas & wall
   */
  link(block) {
    if (!block || block === this) {
      return;
    }

    this.get("blockLinks").add(block);
    block.get("blockLinks").add(this);

    this._determineUsed();
    block._determineUsed();
  },

  /**
   * Unlinks this block from all other blocks
   */
  unlink() {
    this.get("blockLinks").forEach(block => {
      block.get("blockLinks").remove(this);
      block._determineUsed();
    });
    this.get("blockLinks").reset([]);
    this._determineUsed();
  },

  /**
   * check whether a block has links
   * @return {Boolean} true if this block has links
   */
  hasLinks() {
    return this.get("blockLinks").length > 0;
  },

  /** updates `used` property of this block */
  _determineUsed() {
    if (!this.isInWall()) {
      return;
    }

    const used =
      !this.get("clones") &&
      this.get("blockLinks").find(block => block.get("cloned"));

    this.set("used", Boolean(used));
  },

  // Copying, Cloning & Using Blocks
  // -------------------------------

  _useWallblock(options = {}) {
    const block = this._copy(options);

    block.set({ cloned: true, critical: false });
    block.link(this);

    return block;
  },

  /**
   * creates a deep copy of this block
   * The copy will lose any reference it has to other blocks (clones/jigsaws)
   * @private
   * @param  {[type]} [options] - (optional) JSON data to be included into the new copy
   * @return {CodeBlock}        The copy
   */
  _copy(options = {}) {
    const data = deepOmit(this.toJSON(), ["id"]);
    Object.assign(data, options);

    // TODO: this only removes references on the parent block, but not nested child blocks?
    data.clones = false;
    data.cloned = false;
    data.used = false;
    data.jigsaw = false;
    data["jigsaw-fill"] = false;
    data.distractor = false;

    delete data.blockLinks;
    delete data.objectName;

    return CodeBlock.build(data, { parse: true });
  },

  /**
   * creates a shallow copy of this block
   * removes any reference to it's relations
   * @private
   * @return {CodeBlock}        The copy
   */
  _shallowCopy() {
    const data = this.toJSON({ shallow: true });
    delete data.id;
    const shallowCopied = CodeBlock.build(data, { parse: true });
    return shallowCopied;
  },

  // Jigsaw Block methods
  // -------------------

  /**
   * Do some maintenance work when a CodeBlock changes between `jigsaw` true/false
   * This method is called by the interface after user input
   */
  updateJigsaw() {
    // add a jigsaw link to the wall
    if (this.get("jigsaw")) {
      this._makeJigsawClone({ "jigsaw-clone": true });
      // or remove said jigsaw link from the wall
    } else {
      this.get("blockLinks").forEach(block => block._delete());
    }
  },

  updateDistractor() {
    if (this.get("distractor")) {
      this._makeJigsawClone({ "distractor-clone": true });
    } else {
      this.get("blockLinks").forEach(block => block._delete());
    }
  },

  _makeJigsawClone(config = {}) {
    config.jigsaw = false;
    config.clones = false;
    config.distractor = false;

    // make sure child scopes don't get copied, the copy has to be just the block itself and any arguments
    this.getChildScopeKeys().forEach(key => (config[key] = {}));

    const clone = this._copy(config);
    this.link(clone);
    this.getWall().get("code").add(clone);
  },

  /**
   * swaps the child scopes of a block and its parent block
   * this allows jigsaw blocks to be filled without having their scopes become those of the block they were filled with
   * @private
   * @param  {CodeBlock} parent - The parent jigsaw CodeBlock
   */
  _swapScopes(parent) {
    this.getChildScopeKeys().forEach(key => {
      const ownContent = this.get(key);
      const otherContent = parent.get(key);
      this.set(key, otherContent);
      parent.set(key, ownContent);
    });
  },

  // Executing Block's Code
  // ----------------------

  /**
   * prepares blocks for code execution
   * Called by `models/block` before executing any blocks
   * @private
   */
  preRun() {
    this._cache.clear();
    this._cache.on = true;
    this.running = true;

    this.set({
      error: null,
      warning: null,
      selected: false,
      disabled: false,
      active: false,
    });

    if (!this.isStaticTestsDefined) {
      this.isStaticTestsDefined = true;
      if (
        !(
          hasTaxonomy(task, "use-type.free-code") ||
          hasTaxonomy(task, "use-type.user-generated")
        ) ||
        isAuthor(user, task)
      ) {
        this._applyStaticTests();
      }
    }

    // reset and start listening to own tests
    const tests = this.get("tests");
    tests.forEach(test => test.reset());
    this.listenTo(tests, "change:passing", this._observeTests);

    // stop listening to tests once the game leaves Run mode
    this.listenToOnce(GAME, "state:Run", () => {
      this.listenToOnce(GAME, "state:Setup", () => {
        this.stopListening(tests);
      });
    });
  },

  /**
   * cleans up blocks after code execution
   * Called by `models/block` after code execution
   * @private
   */
  postRun() {
    this._cache.on = false;
    this.running = false;

    this.set({
      active: false,
    });
  },

  /**
   * Execute the code associated with this CodeBlock
   * no-op function that must be extended to run the actual code of a block
   * This method should never be called directly - use `run` instead
   * @private
   * @param  {*} scope           - Whatever the current scope of this CodeBlock is
   * @return {Promise}           Must return a Promise that resolves once the CodeBlock has finished executing
   */
  // eslint-disable-next-line
  async execute(scope, ...args) {
    return;
  },

  /**
   * Wrapper function around the `execute` method
   * Does some maintenance before and after executing this CodeBlock
   * @param {*} scope            - the scope of the current block
   * @param {...args}            - any number of extra arguments
   * @return {Promise}           Must return a Promise that resolves once the CodeBlock has finished executing
   */
  async run(scope = [], ...args) {
    // if this block has been marked as disabled, don't run its code
    if (this.get("disabled")) {
      return;
    }

    // a jigsaw block never runs its own code
    if (this.get("jigsaw")) {
      // though, if it's filled it will run that code instead
      if (this.get("jigsaw-fill")) {
        return this.get("jigsaw-fill").run(scope, ...args);
      } else {
        return;
      }
    }

    this.activate();

    try {
      const result = await this.execute(scope, ...args);
      this.deactivate();
      return result;
    } catch (err) {
      if (err.name === "CodeBlockWarning") {
        this.warn(err.message);
      } else {
        this.error(err);
      }
    }
  },

  //minimum time a block will stay active
  //this is both for performance reasons and to allow the user to see when a block activates
  _activeDuration: 150,

  /** activate the block - marking its code as 'currently being executed' */
  activate() {
    if (!settings.get("code-highlighting-enabled")) {
      return;
    }

    // cancel deactivation
    if (this.deactivate.cancel) {
      this.deactivate.cancel();
    }

    if (!this.get("active")) {
      this.set("active", true);
    }
  },

  /** deactivate the block once its code has been executed */
  deactivate() {
    if (this.get("active")) {
      this.set("active", false);
    }
  },

  /**
   * Trigger an error on this block
   * Prevents this code block from executing any further
   * @param  {Error|String} err - The error or a String message
   */
  error(err = "ERROR") {
    if (!this.running) {
      return;
    }

    // block already erroneous
    if (this.get("error")) {
      return;
    }

    // get error message from Error objects
    if (err instanceof Error) {
      err = err.message;
    }

    this.set({
      error: true,
      disabled: true,
      active: false,
    });

    blockConsole.error(err, this.cid);
  },

  /**
   * Triggers a warning on this block
   * Does NOT prevent code from executing
   * @param  {String} message - The warning message
   */
  warn(message = "WARNING") {
    if (!this.running) {
      return;
    }

    if (this.get("warning")) {
      return;
    }

    if (this.isInWall()) {
      return;
    }

    this.set("warning", true);
    blockConsole.warn(message, this.cid);
  },

  /**
   * Logs a message in the console
   * @param {String} message - The message to log
   */
  log(message) {
    blockConsole.log(message);
  },

  // Validation
  // ----------

  //define a message when extending block, this allows each block implementation to have its own error message
  //This message will be shown when the block is placed where it doesn't belong
  _placementMessage: "",

  /**
   * Checks whether this block is currently in a valid location
   * no-op function to be extended by specific block implementations
   * @private
   * @param  {Backbone.Model} parent - This block's parent
   * @return {Boolean}               true if placement is valid
   */
  // eslint-disable-next-line no-unused-vars
  _validatePlacement(parent) {
    return true;
  },

  /** @private observe tests and update error state accordingly */
  _observeTests() {
    const tests = this.get("tests");
    const failed = tests.findWhere({ passing: false });

    if (failed) {
      const message = failed.get("message");
      const failure = failed.get("type");

      switch (failure) {
        case "placement":
        case "jigsaw-empty":
          this.warn(message);
          this.set("disabled", true);
          break;

        case "required-use":
        case "jigsaw-fill":
          this.warn(message);
          break;

        case "arguments":
        case "deprecated":
        default:
          this.error(message);
          break;
      }

      // stop listening on the first failure
      this.stopListening(tests);
    }
  },

  /** @private define a list of static tests on this code block */
  _applyStaticTests() {
    this.defineStaticTest(
      { type: "placement" },
      { message: this._placementMessage },
    );

    this.defineStaticTest(
      { type: "arguments" },
      { message: require("./errors").default.MISSING_ARGUMENT },
    );

    this.defineStaticTest(
      { type: "required-use" },
      { message: require("./errors").default.REQUIRED_USE },
    );

    this.defineStaticTest(
      { type: "jigsaw-empty" },
      { message: require("./errors").default.JIGSAW_EMPTY },
    );

    this.defineStaticTest(
      { type: "jigsaw-fill" },
      { message: require("./errors").default.JIGSAW_WRONG },
    );

    this.defineStaticTest(
      { type: "deprecated" },
      { message: require("./errors").default.DEPRECATED },
    );
  },

  tests: _.extend({}, TestablePrototype.tests, {
    /** test whether this block is placed in a valid position */
    placement() {
      const parent = this.getParent();
      if (
        !parent ||
        this.isInWall() ||
        this.get("jigsaw") ||
        this.get("distractor") ||
        this.get("parent-jigsaw")
      ) {
        return Promise.resolve(); //we don't check placement for these scenarios
      } else if (this._validatePlacement(parent)) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    },

    /** test whether all arguments have been populated */
    arguments() {
      if (this.isInWall()) {
        return Promise.resolve();
      }

      if (this.has("args")) {
        const args = this.get("args").get("arguments");
        for (let i = 0; i < args.length; i++) {
          if (!args.at(i).get("code")) {
            return Promise.reject();
          }
        }
      }

      return Promise.resolve();
    },

    /** tests whether a jigsaw block was left empty */
    "jigsaw-empty"() {
      if (this.get("jigsaw") && !this.get("jigsaw-fill")) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },

    /** test whether a jigsaw was filled correctly */
    "jigsaw-fill"() {
      if (!this.get("jigsaw") || !this.get("jigsaw-fill")) {
        return Promise.resolve();
      }

      if (this.isSame(this.get("jigsaw-fill"))) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    },

    /** tests whether a required use block has been used the correct number of times */
    "required-use"() {
      const requiredUse = this.get("required-use");
      if (requiredUse && this.get("clones") && this.isInWall()) {
        if (this.get("blockLinks").length >= requiredUse) {
          return Promise.resolve();
        }
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },

    /** deprecated blocks never run and trigger an error instead */
    deprecated() {
      if (this.get("deprecated")) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    },
  }),
});

export default CodeBlock;
