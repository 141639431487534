import $ from "jquery";
import settings from "globals/settings";
import Backbone from "custom/backbone-bundle";
import save from "utils/save";

//component prototype to be inherited from
//by extending this view you can inherit some useful features described below
export default Backbone.View.extend({
  className: "coder-component",

  tagName: "section",

  //this.$el will have a cid property that matches the cid of the model
  attributes: function() {
    return {
      cid: this.model.cid,
    };
  },

  //to be extended or replaced
  initialize: function() {
    if (this.style) {
      this.useStyles();
    }
    this.listenTo(this.model, "change", this.render);
    this.listenTo(settings, "change:editable", this.render);
    this.render();
  },

  useStyles: function() {
    if (this.style) {
      if (Array.isArray(this.style)) {
        this.style.forEach(function(style) {
          style.use();
        });
      } else {
        this.style.use();
      }
      this._stylesLoaded = true;
    }
  },

  unuseStyles: function() {
    if (this.style && this._stylesLoaded) {
      if (Array.isArray(this.style)) {
        this.style.forEach(function(style) {
          style.unuse();
        });
      } else {
        this.style.unuse();
      }
      this._stylesLoaded = false;
    }
  },

  //to be extended or replaced
  render: function() {
    this.$el.html(
      this.template({
        model: this.model.toJSON(),
        settings: settings.toJSON(),
      }),
    );
  },

  remove: function() {
    this.unuseStyles();
    Backbone.View.prototype.remove.call(this);
  },

  //toggles a class on/off on this view by listening for a property on a model
  reflectSetting: function(model, prop, className) {
    this.listenTo(
      model,
      "change:" + prop,
      function() {
        this.$el.toggleClass(className, Boolean(model.get(prop)));
      }.bind(this),
    );

    if (model.get(prop)) {
      this.$el.addClass(className);
    }
  },

  //automatically saves DOM element values back to the model
  //for instance:
  // <input type="text" save="someprop"/>
  // this will set someprop on the model to the value of the input whenever it changes
  events: {
    "blur [save]": "_save",
  },

  //this method can parse values before they are saved
  //no-op function to be extended when inheriting from Component
  _parse: function(property, value) {
    return value;
  },

  _updateModel: function(event) {
    var property = $(event.target).attr("save");
    var properties = property.split(".");
    var value;

    var data = {};
    properties.forEach(prop => (data[prop] = this.model.get("prop")));

    if ($(event.target).is(":checkbox") || $(event.target).is(":radio")) {
      value = $(event.target).is(":checked");
    } else if ($(event.target).val()) {
      value = $(event.target)
        .val()
        .trim();
    } else if ($(event.target)[0].hasAttribute("data-value")) {
      value = $(event.target).attr("data-value");
    } else {
      value = $(event.target)
        .html()
        .trim();
    }

    var silent = true;
    if ($(event.target).attr("silent") === "false") {
      silent = false;
    }

    if ($(event.target).is("[type=number]")) {
      value = Number(value);
    }

    value = this._parse(property, value);

    if (properties.length === 1) {
      data[property] = value;
    } else if (properties.length === 2) {
      data[properties[0]] = this.model.get(properties[0]);
      if (typeof data[properties[0]] !== "object") {
        data[properties[0]] = {};
      }
      data[properties[0]][properties[1]] = value;
    }

    this.model.set(data, { silent: silent, source: this });
    save();
  },

  _save: function(event) {
    // Stop parent components also firing for this blur.
    event.stopPropagation();

    const $target = $(event.target);

    if ($target.is(":radio")) {
      const identifier = $target[0].name;
      const group = this.$el.find(`[name="${identifier}"]`);

      [...group]
        // filter out the 'none' radio button since we dont store this value in the model
        .filter(radio => radio.id !== "none")
        .map(radio => {
          // fake event to save state of all radio buttons in the same group
          this._updateModel({ target: radio });
        });
    } else {
      this._updateModel(event);
    }
  },
});
