/**
 * Applies `focus` class to intractable blocks based on mouse-over
 */
export class HighlightBlock {
  constructor(parent) {
    this.parent = parent;

    this.__focus;
  }

  onMove(e) {
    const focus = $(e.target)
      .closest(".draggable.interactive, .clickable.interactive")
      .closest(".argument, .super-block");

    if (this.__focus) {
      this.__focus.removeClass("hover");
    }

    this.__focus = focus;

    if (this.__focus) {
      this.__focus.addClass("hover");
    }
  }
}
