import BlockArgument from "./../components/argument";
import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

/**
 * THIS block
 * Limits the scope of an object block to only the one relevant to the current event
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "commands"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
    commands: {},
  }),

  blueprint: {
    type: "this",
    fn: "this",
    label: "this {arg}",
    description: "Target the object of this event",
    category: "scope",
    output: "object",
    args: {
      arguments: [
        {
          argumentSuggestions: [
            { type: "object", category: "image" },
            { type: "object", category: "sprite" },
          ],
        },
      ],
    },
  },

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
    this.get("commands").set("object-scope", true);
  },

  getPseudoCode(indent = "") {
    const commands = this.get("commands")
      .get("code")
      .map(block => block.getPseudoCode());

    const target = this.get("args").get("arguments").at(0).getPseudoCode();

    return `${indent}this ${target}${commands}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    this.get("commands").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    const args = await this.get("args").compute(scope);

    try {
      const object = await game.exec(this.get("fn"), scope, ...args);
      return [await this.get("commands").run(scope, object[0])];
    } catch (e) {
      this.warn(e.message);
      return [];
    }
  },

  _placementMessage: ERRORS.PLACEMENT_OBJECT,
  _validatePlacement(parent) {
    return (
      (parent instanceof BlockScope && !parent.get("free-form")) ||
      parent instanceof BlockArgument
    );
  },
});
