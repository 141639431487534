import Backbone from "custom/backbone-bundle";
import GAME from "models/block/game";

const canvas = document.createElement("canvas");

export default Backbone.View.extend({
  tagName: "img",
  className: "phaser-tile",

  initialize(number) {
    this.tileIndex = number;

    this.$el.attr("tile-index", number);

    this.listenTo(GAME, "ready:Setup", () => this.render());
    this.render();
  },

  async render() {
    this.$el.parent().addClass("loading");
    this.$el.removeClass("missing");

    const game = await GAME.promiseSetup();

    if (!game || !game.map) {
      return;
    }

    canvas.width = game.map.tileWidth;
    canvas.height = game.map.tileHeight;

    // find the tileset that holds the tile we're after
    const tileset = game.map.tilesets.find(
      tileset =>
        tileset.firstgid <= this.tileIndex &&
        tileset.firstgid + tileset.total > this.tileIndex,
    );

    if (!tileset) {
      this.$el.addClass("missing");
      this.$el.parent().removeClass("loading");
      return;
    }

    try {
      tileset.draw(canvas.getContext("2d"), 0, 0, this.tileIndex);
      this.el.src = canvas.toDataURL();
      this.$el.parent().removeClass("loading");
    } catch (e) {
      /* fail silently */
    }
  },
});
