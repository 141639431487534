export const ADD_OBJECT_BUTTONS = [
  // `add` buttons
  {
    type: "object",
    category: "image",
    isAddObjectButton: true,
    goTo: "image",
    target: { type: "image" },
    groups: ["year 1", "year 2", "year 3", "year 4", "year 5", "year 6"],
  },
  {
    type: "object",
    category: "button",
    isAddObjectButton: true,
    goTo: "button",
    target: { type: "button" },
    groups: ["year 2", "year 3", "year 4", "year 5", "year 6"],
  },
  {
    type: "object",
    category: "sprite",
    isAddObjectButton: true,
    goTo: "sprite",
    target: { type: "sprite" },
  },
  {
    type: "object",
    category: "sounds",
    isAddObjectButton: true,
    goTo: "sound",
    target: { type: "sounds" },
    groups: ["year 4", "year 5", "year 6"],
  },
  {
    type: "object",
    category: "text",
    isAddObjectButton: true,
    target: { type: "text" },
    groups: ["year 5", "year 6"],
  },
  {
    type: "variable",
    category: "variables",
    isAddObjectButton: true,
    target: { type: "variables" },
    groups: ["year 4", "year 5", "year 6"],
  },
  {
    type: "key",
    category: "keys",
    isAddObjectButton: true,
    goTo: "key",
    target: { type: "keys" },
    groups: ["year 2", "year 3", "year 4", "year 5", "year 6"],
  },
];
