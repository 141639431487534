import Modal from "../modal";
import { task } from "globals/task";
import interfaceChannel from "views/block/channels/interface-channel";
import { AppNamePlugin } from "../shared/app-name";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "custom-python-input"].join(" "),

  template: require("./custom-python-input.hbs"),
  style: require("./custom-python-input.styl"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "submit .custom-python-input-form": "pythonInput",
        "click .custom-python-input-confirm": "pythonInput",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    const title = this.getTask();
    this.$el.html(this.template({ title }));
    this.delegateEvents();
    this.$el.one("hidden.bs.modal", () => this._closed());
  },

  async open(newTask) {
    this.task = newTask;
    this.__value = null;
    Modal.prototype.open.call(this, this.getTask());
  },

  async pythonInput(e) {
    e.preventDefault();
    this.__value = this.$("input.user-input").val() || null;
    await this.close();
  },

  _closed() {
    interfaceChannel.trigger("custom-python-input", this.__value);
  },

  getTask() {
    return this.task || task;
  },
});
