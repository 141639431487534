import PrimitiveBlock from "./../primitive";

/**
 * this model represents a null primitive
 * null primitives are not accessible to the user, they are used to keep specific arguments empty (as they are not displayed by the view)
 */
export default PrimitiveBlock.extend({
  getPrimitiveValue() {
    return null;
  },
});
