import _ from "underscore";
import makeThumbnail from "./../utils/make-thumbnail";

export default function Animated(game, model) {
  Phaser.Sprite.call(this, game, 0, 0, model.get("phaserKey"));

  makeThumbnail.call(this, model);

  var animations = _.pairs(model.get("animations"));
  animations.reverse(); //reverse the array so that the first animation becomes the default

  animations.forEach(pair => {
    this.animations.add(
      pair[0],
      pair[1].frames,
      pair[1].frameRate,
      pair[1].loop,
    );
  });
}

Animated.prototype = Object.create(Phaser.Sprite.prototype);
Animated.prototype.constructor = Animated;
