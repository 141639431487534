import CodeBlock from "./../../code-block";
import PrimitiveBlock from "./../primitive";

/**
 * this model represents a vector primitive
 * vectors aren't really primitives, but for the purposes of block coding they are
 * They are an object: {
 *   x,
 *   y,
 *   angle
 * }
 */
export default PrimitiveBlock.extend({
  defaults: Object.assign({}, PrimitiveBlock.prototype.defaults, {
    x: 0,
    y: 0,
  }),

  initialize(options) {
    PrimitiveBlock.prototype.initialize.call(this, options);

    this.on("change:x change:y", this._validateXY);
    this._validateXY();
  },

  getPseudoCode() {
    return `${this.get("angle")}°`;
  },

  _validateXY() {
    let x = this.get("x");
    let y = this.get("y");

    //make sure X and Y are equal to -1, 0 or 1
    x = Math.max(-1, Math.min(1, Math.round(x)));
    y = Math.max(-1, Math.min(1, Math.round(y)));

    let angle = Math.atan2(y, x) * (180 / Math.PI);
    if (x === 0 && y === 0) {
      angle = null;
    }

    this.set({
      x: x,
      y: y,
      angle: Phaser.Math.wrapAngle(angle), //in degrees
    });
  },

  getPrimitiveValue() {
    return {
      x: this.get("x"),
      y: this.get("y"),
      angle: this.get("angle"),
      type: "vector", //complex primitives must return a type so that they can be validated
    };
  },

  changeValue(value, source) {
    const prev = { x: this.get("x"), y: this.get("y") };

    if (value instanceof CodeBlock) {
      if (value.get("type") !== this.get("type")) {
        throw Error("Can't update the value of this block to a different type");
      }

      value = { x: value.get("x"), y: value.get("y") };
    }

    this.set({
      x: value.x,
      y: value.y,
    });
    this.addToActionHistory(
      {
        block: this,
        type: "set",
        value: {
          new: value,
          old: prev,
        },
      },
      source,
    );
  },

  getToodalOptions() {
    return {
      title: "Vector",
      style: "vector",
      cacheKey: "presets:vector",
      input: null,
      blocks: [
        { x: -1, y: -1 },
        { x: 0, y: -1 },
        { x: 1, y: -1 },
        { x: -1, y: 0 },
        { x: null, y: null, "editorial-only": true },
        { x: 1, y: 0 },
        { x: -1, y: 1 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
      ].map(option => {
        return Object.assign(option, { type: "vector" });
      }),
    };
  },

  isSame(other) {
    const same = CodeBlock.prototype.isSame.call(this, other);

    return (
      same &&
      Number(this.get("x")) === Number(other.get("x")) &&
      Number(this.get("y")) === Number(other.get("y"))
    );
  },
});
