import PrimitiveBlock from "./../primitive";
import GAME from "models/block/game";
import { KEYS } from "./key-mapping";

/**
 * this model represents a key primitive
 * these map to PhaserJS Keyboard Keys
 */
export default PrimitiveBlock.extend({
  initialize(options) {
    PrimitiveBlock.prototype.initialize.call(this, options);

    this.on("change:value", this._updateLabel);
    this._updateLabel();
  },

  getPseudoCode() {
    return `[${this.get("label")}]`;
  },

  _updateLabel() {
    const value = Number(this.get("value"));
    let label = KEYS.find(key => key.keyCode === value);

    if (label) {
      label = label.label;
    } else {
      label = "?";
    }

    this.set("label", label);
  },

  // finds the objects on the stage that are linked to this key primitive
  async findKeyObject() {
    const game = await GAME.promiseGame();

    return game.objectGroup.children.filter(
      obj =>
        (obj.model.get("type") === "key" && this.get("keyCode")) ||
        this.get("value") === obj.model.get("keyCode"),
    );
  },

  getPrimitiveValue() {
    return Number(this.get("value"));
  },

  async getToodalOptions() {
    const game = await GAME.promiseGame();
    let keyCodes = game.objectGroup.children
      .filter(sprite => sprite.model.get("type") === "key")
      .map(sprite => sprite.model.get("keyCode"));
    keyCodes = [...new Set(keyCodes)]; // only unique values
    keyCodes.sort();

    // empty toodal
    if (!keyCodes.length) {
      return {
        title: "Key",
        style: "key",
        cacheKey: `presets:key:empty`,
        input: null,
        blocks: [],
        message: "When you add a key to your design it will appear here.",
      };
    }

    keyCodes.unshift(null);

    return {
      title: "Key",
      style: "key",
      cacheKey: `presets:key:${keyCodes.join("-")}`,
      input: null,
      blocks: keyCodes.map(keycode => ({
        "editorial-only": keycode === null,
        value: keycode,
        type: "key",
      })),
    };
  },
});
