import Modal from "../modal";
import { task } from "globals/task";
import determineTaskType from "utils/determine-task-type";
import { app } from "app";

export default Modal.extend({
  className: [Modal.prototype.className, "editorial-tools-modal"].join(" "),

  template: require("./editorial-tools.hbs"),
  style: require("./editorial-tools.styl"),

  events: {
    "click .add-instructions:not([disabled])": "addInstructions",
    "click .remove-instructions:not([disabled])": "removeInstructions",
    "click .discard-wall:not([disabled])": "discardWall",
    "click .unlock-blocks:not([disabled])": "unlockBlocks",
    "click .background-to-foreground:not([disabled])":
      "moveBackgroundToForeground",
  },

  render() {
    var type = determineTaskType(task.toJSON());

    this.$el.html(
      this.template({
        hasInstructions: Boolean(task.getComponent("models/instructions")),
        isBlock: type.language === "block",
        isAdvanced: type.type === "Advanced",
      }),
    );
  },

  async discardWall() {
    await this.close();

    const blockCoder = task.getComponent("models/block");
    if (!blockCoder) {
      return;
    }

    // can't discard wall unless interaction-mode = default
    if (blockCoder.get("interaction-mode") !== "default") {
      return;
    }

    // hide the wall
    blockCoder.get("settings").set("has-code-wall", false);

    // delete all blocks on the wall
    blockCoder
      .get("snippets")
      .get("code")
      .reset();

    this.close();
  },

  async unlockBlocks() {
    await this.close();

    const blockCoder = task.getComponent("models/block");
    if (!blockCoder) {
      return;
    }

    // can't unlock all blocks unless interaction-mode = default
    if (blockCoder.get("interaction-mode") !== "default") {
      return;
    }

    blockCoder
      .get("input")
      .getChildBlocks()
      .forEach(block => {
        block.set("locked", false);
        block.set("editable", true);
        block.set("critical", false);
      });

    // make sure the code is editable
    blockCoder.get("settings").set("edit-code", true);

    this.close();
  },

  async moveBackgroundToForeground() {
    await this.close();

    const blockCoder = task.getComponent("models/block");
    if (!blockCoder) {
      return;
    }

    const input = blockCoder.get("input").get("code");
    const background = blockCoder.get("background-code").get("code");

    if (background.length === 0) return;

    const code = background.remove(background.models);

    input.add(code);
  },

  async removeInstructions() {
    await this.close();
    app.destroyLayout();

    const instructions = task.getComponent("models/instructions");
    task.get("components").remove(instructions);

    app.setLayout();
  },

  async addInstructions() {
    await this.close();
    app.destroyLayout();

    const fixture = require("fixtures/components/instructions.json");
    task.get("components").add(fixture, { parse: true });

    app.setLayout();
  },
});
