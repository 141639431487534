import _ from "underscore";
import Backbone from "custom/backbone-bundle";
import { Test } from "models/test";

//this defines a model that can be tested
export default {
  relations: [
    {
      type: Backbone.HasMany,
      key: "tests",
      relatedModel: Test,
      reverseRelation: {
        key: "owner",
        includeInJSON: false,
      },
    },
  ],

  //runs all tests defined on this model and child testable models
  test: function () {
    var tests = this._sortTests(this._getTests());

    return Promise.all(
      tests.map(function (test) {
        return test.execute();
      }),
    )
      .then(
        function () {
          this.set("test-passing", true);
          return Promise.resolve();
        }.bind(this),
      )
      .catch(
        function (test) {
          this.set("test-passing", false);
          return Promise.reject(test);
        }.bind(this),
      );
  },

  //allows a model inheriting from testable to define a test that it MUST have
  // - to be called inside the initialize function of the model inheriting from testable
  defineStaticTest: function (filter, config) {
    var test = this.get("tests").findWhere(filter);
    config = _.extend({}, require("fixtures/test.json"), filter, config);

    if (test) {
      test.set(config);
    } else {
      this.get("tests").add(config, { parse: true });
    }
  },

  // returns an array of all tests on this model and child testable models (recursively)
  _getTests: function () {
    return _.flatten([
      this.get("tests").slice(),
      this._getTestableChildren().map(child => child._getTests()),
    ]);
  },

  // sort tests by their priority
  _sortTests: function (tests) {
    return _.sortBy(tests, function (test) {
      return test.get("priority");
    });
  },

  // returns an array of child-models that are testable
  _getTestableChildren: function () {
    // get related children
    let children = [
      this.getRelations()
        .filter(relation => relation.options.runTests)
        .map(relation =>
          relation.related ? relation.related.models || relation.related : null,
        ),
    ];

    // flatten
    children = children.flat(2);

    // unique values only
    children = [...new Set(children)];

    // testable children only
    children = children.filter(
      child => child && typeof child._getTests === "function",
    );

    // remove distractor blocks from testable children
    // TODO: remove bespoke check here, make this more agnostic
    children = children.map(child => {
      if (child.get("code")) {
        const code = child.get("code");
        if (code instanceof Backbone.Collection) {
          const distractors = code.models.filter(model =>
            model.get("distractor"),
          );
          code.remove(distractors);
        }
      }
      return child;
    });

    return children;
  },

  //returns the test function of given type
  getTestFunction: function (type) {
    if (this.tests && typeof this.tests[type] === "function") {
      return this.tests[type].bind(this);
    }

    throw new Error("Unknown test type - " + type);
  },

  //a list of test functions to be extended when inheriting from this prototype
  tests: {},
};
