import BlockArgument from "./../components/argument";
import CodeBlock from "./../code-block";
import settings from "globals/settings";
import { task } from "globals/task";
import { hasTaxonomy } from "utils/taxonomy";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

/**
 * VARIABLE block
 */
export default CodeBlock.extend({
  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    target: null,
  }),

  // executing the code associated with a variable will return its value
  async execute() {
    if (this.isInEventArgumentSlot()) {
      throw new Error(ERRORS.PLACEMENT_VAR_IN_EVENT_ARGS);
    }

    return this.findVariable();
  },

  isInEventArgumentSlot() {
    return this._cache.get(
      "isInEventArgumentSlot()",
      () =>
        this.isInArgumentSlot() &&
        this.getParentBlock()?.get("type") === "event",
    );
  },

  // this returns a reference to the variable
  async findVariable() {
    return game.exec("getVariable", this.get("target"));
  },

  isSame(other) {
    // test for similarity using base method first
    if (!CodeBlock.prototype.isSame.call(this, other)) {
      return false;
    }

    // then make sure both blocks target the same stage variable
    return this.get("target") === other.get("target");
  },

  changeValue(value, source) {
    const prev = { target: this.get("target") };

    if (value instanceof CodeBlock) {
      if (value.get("type") !== this.get("type")) {
        throw Error("Can't update the value of this block to a different type");
      }

      value = { target: value.get("target") };
    }

    this.set("target", value.target);
    this.addToActionHistory(
      {
        block: this,
        type: "set",
        value: {
          new: value,
          old: prev,
        },
      },
      source,
    );
  },

  getGlossaryTaxonomy() {
    return [
      `glossary-type.code-block.variable.variable`,
      `glossary-type.code-block.variable`,
      "glossary-type.code-block",
    ];
  },

  canHaveCodeAdded() {
    return false;
  },

  _placementMessage: ERRORS.PLACEMENT_VAR,
  _validatePlacement(parent) {
    return parent instanceof BlockArgument;
  },
  /**
   * Check whether this variable is editable
   * @return {Boolean}  true if this variable is editable
   */
  isEditable() {
    const topScope = this.getTopLevelScope();
    const blockCoder = topScope && topScope.get("block");

    // variable is missing context that allows it to be edited
    if (!topScope || !blockCoder || !task) {
      return false;
    }

    if (["variable"].indexOf(this.get("type")) < 0) {
      return false;
    }

    // variables cannot be edited unless they are inside a free-form scope (code canvas)
    if (!topScope.get("free-form")) {
      return false;
    }

    // variables are always editable while in CC edit mode
    if (settings.get("editable")) {
      return true;
    }

    // variable not editable because the code can't be edited at the top level
    if (!blockCoder.get("settings").get("edit-code")) {
      return false;
    }

    // variable not editable because the task is locked
    if (task.get("locked")) {
      return false;
    }

    // variable not editable because of jigsaw mode
    if (blockCoder.get("interaction-mode") === "jigsaw") {
      return false;
    }

    // we are in a free coding area or at the build step, the user can edit anything they want
    // unless we are in free coding area or at the build step, object is not editable
    if (
      hasTaxonomy(task, "use-type.free-code") ||
      hasTaxonomy(task, "use-type.user-generated") ||
      hasTaxonomy(task, "task-type.build")
    ) {
      return true;
    } else {
      return false;
    }
  },

  async getToodalOptions() {
    const topScope = this.getTopLevelScope();
    const blockCoder = topScope && topScope.get("block");
    const type = this.get("type");
    const target = this.get("target");
    const variables = blockCoder
      .get("objects")
      .filter(object => object.get("type") === "variable")
      .map(object => object.getBlockData());
    const keys = variables.map(variable => variable.target.split("-").join(""));

    return {
      title: type.charAt(0).toUpperCase() + type.slice(1),
      style: `${type}`,
      cacheKey: `presets:${type}:${target}:${keys.join("-")}`,
      input: null,
      message: !variables.length
        ? `There are no ${type}s to swap. Please add more ${type}s from the Design panel.`
        : null,
      blocks: variables,
    };
  },
});
