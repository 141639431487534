import Task from "models/task";
import { CLIPBOARD_STORAGE_KEY } from "models/task";
import { checkFlag } from "utils/flags";
import interfaceChannel from "views/block/channels/interface-channel";
import Modal from "../modal";
import BLANK_BLOCK from "fixtures/task-types/block-blank";
import { preloader } from "views/layouts/preloader/preloader";

export const LoadFromDeviceModal = Modal.extend({
  className: [Modal.prototype.className, "load-from-device"].join(" "),

  template: require("./load-from-device.hbs"),

  events: Object.assign(
    {
      "click .load-from-device-confirmed": "onConfirm",
      "change [name=block-coding-file]": "onSelectedFileChange",
    },
    Modal.prototype.events,
  ),

  onSelectedFileChange() {
    const file = this.$("[name=block-coding-file")[0].files[0];
    this.$(".load-from-device-confirmed").prop("disabled", !file);
  },

  async onConfirm(e) {
    e.preventDefault();

    const file = this.$("[name=block-coding-file")[0].files[0];

    await this.close();

    // depending on the SAVE flag~
    // - we need to either go straight to the saved app
    // - OR go to the free coder and paste in the copied code
    if (checkFlag("SAVE")) {
      this._importToNewApp(file);
    } else {
      this._importToFreeCode(file);
    }
  },

  async _importToNewApp(file) {
    try {
      let newTask = new Task(BLANK_BLOCK);
      const decodedData = await newTask.decodeFile(file);
      await newTask.importFromDecodedFile(decodedData);
      const result = await newTask.fork(file.name);
      interfaceChannel.trigger("proceed-to-user-app", result.id);
      await preloader.add("proceed to user app");
    } catch (e) {
      interfaceChannel.trigger("request:modal", "load-from-device-failed", e);
    }
  },

  async _importToFreeCode(file) {
    try {
      let newTask = new Task(BLANK_BLOCK);
      const decodedData = await newTask.decodeFile(file);
      localStorage[CLIPBOARD_STORAGE_KEY] = JSON.stringify(decodedData);
      interfaceChannel.trigger("proceed-to-free-coder");
      await preloader.add("proceed to free coder");
    } catch (e) {
      interfaceChannel.trigger("request:modal", "load-from-device-failed", e);
    }
  },
});
