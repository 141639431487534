import showdown from "showdown";
var converter = new showdown.Converter();

converter.setOption("simpleLineBreaks", true);
converter.setOption("strikethrough", true);

export default function (context, options) {
  let html = "";
  if (options && options.fn) {
    html = options.fn(converter.makeHtml(context));
  } else if (typeof context === "string") {
    html = converter.makeHtml(context);
  } else {
    html = converter.makeHtml(context.fn(this));
  }

  html = $("<div>").append($(html).addClass("markdown-text")).html();
  return html;
}
