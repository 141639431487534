import Lesson from "models/lesson";
import Task from "models/task";
import { user } from "globals/user";
import { hasTaxonomy } from "./taxonomy";
import { trackCodingEvent } from "utils/coding-event";
/**
 * Stores the completion of a Lesson and/or Task
 * @param {[Lesson|Task]} models
 */
export function storeCompletion(...models) {
  models = models.filter(model => canBeStored(model));

  if (models.length) {
    trackCodingEvent("completion", ...models);
  }
}

/**
 * Checks whether a completion can be stored
 * @param {Lesson|Task} model
 */
function canBeStored(model) {
  return (
    user.canSaveUserProgress() &&
    model &&
    model.fetched &&
    model.get("id") &&
    (model instanceof Task || model instanceof Lesson) &&
    isCompleted(model)
  );
}

/**
 * Checks whether a Lesson or Task has been completed
 * @param {Lesson|Task} model
 */
function isCompleted(model) {
  if (model instanceof Task) {
    return Boolean(model.get("completed"));
  } else if (model instanceof Lesson) {
    // for lessons, we need to iterate through the tasks while ignoring certain types
    return model
      .get("tasks")
      .filter(
        task =>
          task.get("type") !== "splash" &&
          !hasTaxonomy(task, `task-type.build`) &&
          !hasTaxonomy(task, `use-type.refresher`),
      )
      .map(task => isCompleted(task))
      .reduce((acc, curr) => acc && curr, true);
  }
}
