import makeThumbnail from "./../utils/make-thumbnail";
import { translateContent } from "utils/localisation";
import { makeTextBitmap } from "./../utils/make-text";
import _ from "underscore";

export default function Variable(game, model) {
  Phaser.Sprite.call(this, game, 0, 0, "UI:transparent-64");

  this._value = 0;

  this.renderVariable(model);

  this.__throttledRender = _.throttle(
    this.renderVariable.bind(this, model),
    100, // change this value to a higher number if quickly rendering variables becomes laggy
  );
  this.game.listener.listenTo(model, "change:name", this.__throttledRender);
}

Variable.prototype = Object.create(Phaser.Sprite.prototype);
Variable.prototype.constructor = Variable;

//render this sprite as a variable
Variable.prototype.renderVariable = function (model = this.model) {
  if (!this.game) return;
  const game = this.game;
  // Phasers Sprite.removeChildren() has a bug in our current version so doing this for now.
  if (this.children.length != 0) {
    this.children = [];
  }

  //store width for later...
  const width = this.width;

  this.width = this.height = 64;

  //create all the sub-sprites
  const varName = game.make.sprite(
    0,
    0,
    makeTextBitmap(game, translateContent(model.get("name"))),
  );

  const isObject = typeof this._value === "object";
  let parsedVal = "";

  if (isObject && this._value.type === "vector") {
    parsedVal = `X:${this._value.x}, Y:${this._value.y}`;
  }

  const varVal = game.make.sprite(
    0,
    0,
    makeTextBitmap(game, isObject ? parsedVal : translateContent(this._value)),
  );
  const padding = 2;
  const parts = [
    game.make.sprite(0, 0, "name-start"),
    game.make.sprite(0, 0, "name-middle"),
    game.make.sprite(0, 0, "value-start"),
    game.make.sprite(0, 0, "value-middle"),
    game.make.sprite(0, 0, "value-end"),
  ];

  //adjust size of middle bits to fit the text (with a minimum size)
  parts[1].width = Math.max(64, varName.width + padding * 2);
  parts[3].width = Math.max(64, varVal.width + padding * 2);

  //calculate and set the required width of the parent sprite
  this.width = parts.reduce((sum, sprite) => sum + sprite.width, 0);

  //add all child sprites at their correct location and scale
  parts.forEach((sprite, i) => {
    this.addChild(sprite);

    sprite.y -= 32;

    if (i > 0) {
      const prev = parts[i - 1];
      sprite.x += prev.x + prev.width;
    } else {
      sprite.x -= 32;
    }

    sprite.scale.x *= 64 / this.width;
  });

  //add varname
  this.addChild(varName);
  varName.anchor.setTo(0, 0.5);
  varName.y = -2;
  varName.x = parts[1].x + padding;
  varName.width = parts[1].width - padding * 2;

  //add var value
  this.addChild(varVal);

  varVal.anchor.setTo(0, 0.5);
  varVal.y = -6;
  varVal.x = parts[3].x + padding + 1;
  varVal.width = parts[3].width - padding * 2;

  //adjust the scale of the parent sprite
  const scale = game.map.tileHeight / this.height;
  this.scale.x *= scale;
  this.scale.y *= scale;

  //make sure the sprite remains left-aligned if the text changes
  this.x += (this.width - width) / 2;

  this.varName = model.get("name");

  makeThumbnail.call(this, model);
};
