import Animated from "./../objects/animated";
import Sprite from "./../objects/sprite";
import Button from "./../objects/button";
import Picture from "./../objects/picture";
import Text from "./../objects/text";
import Variable from "./../objects/variable";

//these are utility functions to help validate inputs within the phaser middleware
export const number = input =>
  typeof input === "number" && !Number.isNaN(input);
export const angle = number;
export const texture = number;
export const key = number;
export const time = number;

export const boolean = input => typeof input === "boolean";

export const string = input => typeof input === "string";

export const vector = input =>
  input &&
  typeof input.x === "number" &&
  typeof input.y === "number" &&
  input.type === "vector";

// any type of object
export const object = input =>
  physical(input) ||
  app(input) ||
  pointer(input) ||
  camera(input) ||
  sound(input);

// physical objects on the stage that can be manipulated (moved, rotated, collided, ...)
export const physical = input =>
  input instanceof Animated ||
  input instanceof Sprite ||
  input instanceof Button ||
  input instanceof Picture ||
  input instanceof Text;

export const classBased = input =>
  input instanceof Animated ||
  input instanceof Picture ||
  input instanceof Sprite;

// objects that can be animated
export const animated = input => input instanceof Animated;

// sound objects
// note: these should never be targeted directly, use SPEAKER.play(sound) instead
export const sound = input => input?.type === "sound";
export const soundObject = input => input?.type === "sound";

// a collider is an object that can have collisions; Object or Texture
export const collider = input => physical(input) || texture(input);

// the app object
export const app = input => input instanceof Phaser.Game;

// the camera object
export const camera = input => input?.type === "camera";

// the pointer object
export const pointer = input => input instanceof Phaser.Pointer;

export const variable = input => input instanceof Variable && "_value" in input;

export const sprite = input => input instanceof Phaser.Sprite;

import ERRORS from "./../../blocks/errors";

export const error = {
  number: ERRORS.RUNTIME_NUMBER_EXPECTED,
  angle: ERRORS.RUNTIME_ANGLE_EXPECTED,
  texture: ERRORS.RUNTIME_TEXTURE_EXPECTED,
  key: ERRORS.RUNTIME_KEY_EXPECTED,
  time: ERRORS.RUNTIME_TIME_EXPECTED,
  boolean: ERRORS.RUNTIME_BOOLEAN_EXPECTED,
  string: ERRORS.RUNTIME_STRING_EXPECTED,
  vector: ERRORS.RUNTIME_VECTOR_EXPECTED,
  object: ERRORS.RUNTIME_OBJECT_EXPECTED,
  sprite: ERRORS.RUNTIME_SPRITE_EXPECTED,
  physical: ERRORS.RUNTIME_OBJECT_EXPECTED,
  classBased: ERRORS.RUNTIME_CLASS_EXPECTED,
  collider: ERRORS.RUNTIME_COLLIDER_EXPECTED,
  variable: ERRORS.RUNTIME_VARIABLE_EXPECTED,
  variable_change: ERRORS.RUNTIME_VARIABLE_CHANGE,
  no_gravity: ERRORS.RUNTIME_NO_GRAVITY,
  sound_deprecated: ERRORS.DEPRECATED,

  command: ERRORS.RUNTIME_INVALID_COMMAND,
  sound_command: ERRORS.RUNTIME_SOUND_OBJECT_EXPECTED,
};
