import { View } from "backbone";
import $ from "jquery";

import BlockView from "views/block/blocks/code-block";
import CodeBlock from "models/block/blocks/code-block";
import game from "models/block/game";
import { translate } from "utils/localisation";
import { user } from "globals/user";
import playSound from "../../../../models/block/phaser-middleware/methods/playSound";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";

export const ToodalPreset = View.extend({
  template: require("./toodal-preset.hbs"),
  className: "preset",

  events: {
    "submit form": "onFormSubmit",
    "input input": "onInputChange",
    "click .option": "onOptionClick",
    "click .option-icon": "onOptionIconClick",
  },

  initialize(data) {
    this.custom = data.custom || [];
    this.message = data.message || null;

    this.blocks = (data.blocks || []).map(config => ({
      value: JSON.parse(JSON.stringify(config)),
      view: new BlockView(CodeBlock.build(config, { parse: true })),
    }));

    this.render(data);
    this.$el.addClass(data.style);
    this.$el.attr("data-cache-key", data.cacheKey);
  },

  render(data) {
    data.text = data.input === "text";
    data.number = data.input === "number";
    data.hasSounds = data.style === "sound";
    data.hasFrames = data.style === "frame";
    data.isObjectSound =
      !checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE") &&
      data.style === "object sound";

    this.$el.html(this.template(data));

    this.blocks.forEach((block, i) => {
      if (!block.value["editorial-only"] || user.isContentCreator()) {
        this.$(".option").eq(i).append(block.view.el);
      }
    });
  },

  show() {
    this.$el.addClass("active");
  },

  hide() {
    this.$el.removeClass("active");
  },

  bind(/** @type CodeBlock */ primitiveModel) {
    this.model = primitiveModel;
    this.$("input.preset-input").val(translate(this.model.get("value")));
    this.$("input").trigger("select");
  },

  onInputChange() {
    const value = this.$("input.preset-input").val();

    this.model.changeValue({ value });

    interfaceChannel.trigger("code-change");
  },

  onFormSubmit(e) {
    const value = this.$("input.preset-input").val();
    e.preventDefault();

    this.model.changeValue({ value });

    interfaceChannel.trigger("toodal:close");
    interfaceChannel.trigger("code-change");
  },

  onOptionClick(e) {
    const index = $(e.target).closest(".option").data("index");

    const option = this.blocks[index] || this.custom[index];

    if (!option) {
      return;
    }

    if (option.value["editorial-only"] && !user.isContentCreator()) {
      return;
    }

    if (option.value.type !== this.model.get("type")) {
      return;
    }

    this.model.changeValue(option.value);

    interfaceChannel.trigger("toodal:close");
    interfaceChannel.trigger("code-change");
  },

  onOptionIconClick(e) {
    const index = $(e.target).closest(".option").data("index");

    const option = this.custom[index];
    if (option && option.value) {
      if (option.value.type === "sound") {
        this.playSound(option.value.value);
      }
    }
    return false;
  },

  playSound(key) {
    playSound.call(game.game, null, key);
  },

  remove() {
    this.blocks.foreach(option => option.view.remove());
    View.prototype.remove.call(this);
  },
});
