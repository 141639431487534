import Backbone from "custom/backbone-bundle";
import ArgumentView from "./argument";

import translate from "utils/localisation";

export default Backbone.View.extend({
  tagName: "block-content",

  // we're doing everything in the initialize function because a block's body is not supposed to change at runtime
  initialize() {
    let label = this.model.get("label") || this.model.get("fn") || "";

    label = translate(label)
      .split(/({arg\d?}|{icon})/) // split label by placeholders
      .filter(str => Boolean(str)) // remove empties
      .map(this.mapString.bind(this)); // construct contents

    this.$el.html(label);

    this.createIcon();
    this.argumentViews = [];
    this.model
      .get("args")
      .get("arguments")
      .forEach(this.createArgument.bind(this));
  },

  //replaces a string with the correct HTML structure
  mapString(string) {
    if (/^{arg\d?}$/.test(string)) {
      // ARGUMENT

      // if the {arg} has a number, add it as a property to the element
      const match = string.match(/{arg(\d)}/);
      if (match) {
        return `<argument-slot index="${match[1]}"></argument-slot>`;
      } else {
        return `<argument-slot></argument-slot>`;
      }
    } else if (string === "{icon}") {
      // ICON
      if (this.model.get("icon")) {
        return `<block-icon></block-icon>`;
      } else {
        return "";
      }
    } else {
      //LABEL
      return `<label class="block-label">${string}</label>`;
    }
  },

  createIcon() {
    const icon = this.model.get("icon");

    if (!icon) {
      return;
    }

    let slot = this.$(">block-icon");
    if (!slot.length) {
      slot = $("<block-icon>");
      this.$el.prepend(slot);
    }

    $(slot).append(`<svg><use xlink:href="#${icon}"></use>`);
  },

  createArgument(model, index) {
    const view = new ArgumentView({ model: model });

    // grab slot with specific index
    let slot = this.$(`> argument-slot[index="${index + 1}"]`)[0];

    // otherwise grab slot from position index
    if (!slot) {
      slot = this.$("> argument-slot").get(index);
    }

    // otherwise create new slot
    if (!slot) {
      slot = $("<argument-slot>");
      this.$el.append(slot);
    }

    $(slot).append(view.$el);
    this.argumentViews.push(view);
  },

  remove() {
    this.argumentViews.forEach(view => view.remove());
    Backbone.View.prototype.remove.call(this);
  },
});
