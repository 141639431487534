import AnimatedObject from "./../objects/animated";
import ButtonObject from "./../objects/button";
import KeyObject from "./../objects/key";
import PictureObject from "./../objects/picture";
import SoundObject from "./../objects/sound";
import SpriteObject from "./../objects/sprite";
import TextObject from "./../objects/text";
import VariableObject from "./../objects/variable";
import setProp, { isCustom } from "./../utils/object-properties";
import { Z_TILE_GRID } from "./create-stage";

/**
 * This creates all the objects on the stage
 * @this (Phaser.Game)              - `this` is expected to be the Phaser game
 */
export function createObjects() {
  this.model.get("objects").forEach(model => createObject(this, model));
  sortObjects.call(this);
}

/**
 * Sorts the Sprites in the game based on how their models are ordered in the objects collection
 * @this (Phaser.Game)              - `this` is expected to be the Phaser game
 */
export function sortObjects() {
  this.model.get("objects").forEach((model, i) => {
    const objects = this.objectGroup.getAll("stageID", model.get("stageID"));
    objects.forEach(object => (object.z = i + Z_TILE_GRID + 1));
  });

  this.objectGroup.sort("z", Phaser.Group.SORT_DESCENDING);
}

/**
 * Factory method that creates an object of the correct type and adds it to the list of objects
 * @param  {Phaser.Game} game       - The Phaser game
 * @param  {Backbone.Model} model   - The Backbone model of the object
 * @param  {Object} options         - A config object that will be passed down to the object constructor
 * @return {Phaser.Sprite}          - The object that was created
 */
export function createObject(game, model, options = {}) {
  const sprite = createSprite(game, model, options);

  if (!sprite) {
    console.warn("Failed to create Phaser object for", model); // eslint-disable-line
    return;
  }

  sprite.heading = 0;
  sprite.anchor.setTo(0.5, 0.5);
  sprite.toString = objectToString;

  if (options.isClone) {
    sprite.runtimeDynamicClone = true;
  }

  initializeObjectProperties(sprite, model);

  game.objectGroup.add(sprite);

  return sprite;
}

/**
 * Initializes a Phaser Sprite by copying properties from the Backbone.Model onto the Sprite
 * @param {Phaser.Sprite} sprite
 * @param {Backbone.Model} model
 */
export function initializeObjectProperties(sprite, model) {
  sprite.model = model;
  sprite.stageID = model.get("stageID");
  sprite.phaserKey = model.get("phaserKey");
  sprite.type = model.get("type");

  // fixedToCamera must be set before any other properties are set (because it
  // affects how x/y and cameraX/cameraY behave) Additionally, it also can only
  // be set to true while we are in `run` mode
  sprite.fixedToCamera =
    sprite.game.mode === "run" && model.get("fixedToCamera");

  sprite.__custom = {};

  Object.keys(model.attributes).forEach(key => {
    if (sprite.runtimeDynamicClone && key === "objectName") {
      return; // objectName is unique and cannot be initialized on runtime clones
    } else if (!isCustom(key)) {
      try {
        setProp.call(sprite, key, model.get(key));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`could not initialize ${key} to ${model.get(key)} -`, e);
      }
    }
  });
}

/**
 * Creates a sprite of the correct type
 * @param  {Phaser.Game} game       - The game to add the sprite to
 * @param  {Backbone.Model} model   - The model of the object
 * @return {Phaser.Sprite}          - The Sprite that was created
 */
export function createSprite(game, model) {
  switch (model.get("type")) {
    case "image":
      return new PictureObject(game, model);
    case "spritesheet":
      return new AnimatedObject(game, model);
    case "sprite":
      return new SpriteObject(game, model);
    case "variable":
      return new VariableObject(game, model);
    case "button":
      return new ButtonObject(game, model);
    case "sound":
      return new SoundObject(game, model);
    case "text":
      return new TextObject(game, model);
    case "key":
      return new KeyObject(game, model);
    case "tileset":
    case "background":
      return null;
  }
}

/**
 * Custom toString method for stage objects
 */
function objectToString() {
  return `[Object: ${this.model.get("type")}]`;
}
