import "url-search-params-polyfill";

export class AppURL {
  constructor(href) {
    this.hash = href.split("#")[1];
    this.query = href.split("#")[0].split("?")[1];
    if (this.query) {
      this.query = this.query
        .split("&")
        .map(q => ({ key: q.split("=")[0], value: q.split("=")[1] }));
    } else {
      this.query = [];
    }
    this.path = href.split("#")[0].split("?")[0];
  }

  /**
   * The href of this URL
   */
  get href() {
    this.maintainQuery("locale");
    this.maintainQuery("embed-origin");

    let out = this.path;
    if (this.query.length) {
      out += `?${this.query
        .map(q => (q.value ? `${q.key}=${q.value}` : q.key))
        .join("&")}`;
    }

    if (this.hash) {
      out += `#${this.hash}`;
    }

    return out;
  }

  /**
   * Maintain a specific query param, even though the input href may be missing it
   */
  maintainQuery(key) {
    const value = new URLSearchParams(window.location.search).get(key);
    if (value) {
      this.addQuery(key, value);
    }
  }

  /**
   * Add a query param to this URL
   * @param {string} key the query param key
   * @param {string} [value] optional value
   */
  addQuery(key, value) {
    const existing = this.query.find(q => q.key === key);
    if (existing) {
      existing.value = value;
    } else {
      this.query.push({ key, value });
    }
    return this;
  }
}
