import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import _ from "underscore";

/**
 * FOREACH block
 * Loops over objects of the same class
 * To be used in conjunction with `this`-block
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "code"),
  ]),

  blueprint: require("./blueprints/for-each.json"),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
    code: {},
    "control-block": true,
  }),

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
  },

  getPseudoCode(indent = "") {
    const args = this.get("args").getPseudoCode();
    const code = this.get("code").getPseudoCode(indent);

    return `${indent}FOR EACH${args} ${code}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    this.get("code").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    this.activate();

    // compute arguments
    const args = await this.get("args").compute(scope);
    const objects = args[0]; // we're only interested in argument 0

    // asynchronously iterate and execute the code
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      await this.get("code").run([object, scope]);
    }

    this.deactivate();
  },

  getGlossaryTaxonomy() {
    return [
      "glossary-type.code-block.control.for-each",
      "glossary-type.code-block.control",
      "glossary-type.code-block",
    ];
  },

  _placementMessage: ERRORS.PLACEMENT_CONTROL,
  _validatePlacement(parent) {
    return parent instanceof BlockScope && !parent.get("free-form");
  },
});
