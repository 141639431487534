/**
 * Static Blocks
 * Static blocks are any blocks that are not defined by what is on the stage
 */
export const CODE_CHEST_PRIMITIVES = [
  // angles
  { type: "angle", value: null, category: "angles", "editorial-only": true },
  { type: "angle", value: -90, category: "angles" },
  { type: "angle", value: 0, category: "angles" },
  { type: "angle", value: 90, category: "angles" },

  // turns
  { type: "turn", value: null, "editorial-only": true, category: "angles" },
  { type: "turn", value: 10, category: "angles" },
  { type: "turn", value: -10, category: "angles" },

  // booleans
  {
    type: "boolean",
    value: null,
    category: "boolean",
    "editorial-only": true,
  },
  { type: "boolean", value: true, category: "boolean" },
  { type: "boolean", value: false, category: "boolean" },

  // numbers
  {
    type: "number",
    value: null,
    category: "numbers",
    "editorial-only": true,
  },
  { type: "number", value: 0, category: "numbers" },
  { type: "number", value: 1, category: "numbers" },
  { type: "number", value: 2, category: "numbers" },
  { type: "number", value: 3, category: "numbers" },
  { type: "number", value: 4, category: "numbers" },
  { type: "number", value: 5, category: "numbers" },
  { type: "number", value: 6, category: "numbers" },
  { type: "number", value: 7, category: "numbers" },
  { type: "number", value: 8, category: "numbers" },
  { type: "number", value: 9, category: "numbers" },
  { type: "number", value: 10, category: "numbers" },
  { type: "number", value: 100, category: "numbers" },

  // strings
  { type: "string", value: null, category: "string", "editorial-only": true },
  { type: "string", value: "text", category: "string" },

  // vectors
  {
    type: "vector",
    x: 0,
    y: 0,
    category: "vectors",
    "editorial-only": true,
  },
  { type: "vector", x: 0, y: -1, category: "vectors" },
  { type: "vector", x: 1, y: -1, category: "vectors" },
  { type: "vector", x: 1, y: 0, category: "vectors" },
  { type: "vector", x: 1, y: 1, category: "vectors" },
  { type: "vector", x: 0, y: 1, category: "vectors" },
  { type: "vector", x: -1, y: 1, category: "vectors" },
  { type: "vector", x: -1, y: 0, category: "vectors" },
  { type: "vector", x: -1, y: -1, category: "vectors" },

  {
    type: "texture",
    value: null,
    category: "textures",
    "editorial-only": true,
  },
];

export function addYearGroups(block) {
  switch (block.type) {
    case "vector":
      block.groups = [
        "year 1",
        "year 2",
        "year 3",
        "year 4",
        "year 5",
        "year 6",
      ];
      break;
    case "turn":
      block.groups = ["year 2", "year 3", "year 4", "year 5", "year 6"];
      break;
    case "number":
    case "texture":
    case "string":
      block.groups = ["year 3", "year 4", "year 5", "year 6"];
      break;
    case "angle":
      block.groups = ["year 4", "year 5", "year 6"];
      break;
    case "boolean":
      block.groups = ["year 6"];
      break;
  }
}

// give them all the correct year group
CODE_CHEST_PRIMITIVES.forEach(addYearGroups);

// make sure they are all editable
CODE_CHEST_PRIMITIVES.forEach(prim => (prim.editable = true));
