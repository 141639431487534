import _ from "underscore";
import { load } from "../utils/runtime-load";
import gameChannel from "../channels/game-channel";
import interfaceChannel from "views/block/channels/interface-channel";
import { setGradient } from "../utils/make-gradient";
import { checkFlag } from "utils/flags";

// define static layers
export const Z_BACKGROUND_IMAGE = 1;
export const Z_BACKGROUND_GRADIENT = 2;
export const Z_BACKGROUND_CLICK = 3;
export const Z_BACKGROUND_TILES = 4;
export const Z_GRID = 5;
export const Z_PIXEL_GRID = 6;
export const Z_TILE_GRID = 7;

export function resizeStage() {
  const size = getWorldSize.call(this);
  this.scale.setGameSize(size.width, size.height);
}

export function resizeCamera() {
  const model = this.model;
  const width = model.get("camera-width");
  const height = model.get("camera-height");
  const x = model.get("camera-x");
  const y = model.get("camera-y");
  const worldSize = getWorldSize.call(this);

  if (model.get("camera-enabled")) {
    this.scale.setGameSize(width, height);
    this.world.resize(worldSize.width, worldSize.height);
    this.camera.setPosition(x - width / 2, y - height / 2);
    this.camera.setBoundsToWorld();
  }
}

export function matchPseudoCameraToActualCamera() {
  const game = this.game;
  game.pseudoCamera.width = game.camera.width;
  game.pseudoCamera.height = game.camera.height;
  game.pseudoCamera.x = game.camera.x + game.camera.width / 2;
  game.pseudoCamera.y = game.camera.y + game.camera.height / 2;
}

export function getWorldSize() {
  let width = 100;
  let height = 100;

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    const el = window.document.getElementsByClassName("game-container")[0];
    if (el) {
      const bounds = el.getBoundingClientRect();
      width = bounds.width;
      height = bounds.height;
    }
  } else {
    const tilesetConfig = this.model.get("tileset-config") || {};
    const tileSize = tilesetConfig.tileSize || 32;
    width = this.model.get("width") * tileSize;
    height = this.model.get("height") * tileSize;
  }

  return {
    width,
    height,
  };
}

/**
 * rebuilds the stage
 * Uses the game's Backbone Model as config
 */
function buildStage() {
  resizeStage.call(this);

  setBackgroundColor.call(this);

  createBackgroundImage.call(this);
  createBackgroundGradient.call(this);
  createBackgroundClickLayer.call(this);

  setTileMap.call(this);
  createGrid.call(this);
  createCoordinateGrids.call(this);
  setHelpersVisibility.call(this);

  this.stageGroup.sort();

  const size = getWorldSize.call(this);
  repositionObjects.call(this, size.width, size.height);

  gameChannel.trigger("stage-built");
}

/**
 * Pulls objects that are off the stage partially back onto it
 * Should be called after the stage was resized, as objects may have been lost off the edges
 */
function repositionObjects(width, height) {
  if (!this.objectGroup) {
    return;
  }

  this.objectGroup.children.forEach(object => {
    const data = object.model.toJSON();

    // this only pulls objects about halfway back onto the stage but that should
    // be enough for users to fix their mistakes
    object.model.set({
      x: Math.min(width, data.x),
      y: Math.min(height, data.y),
    });
  });
}

/**
 * places an array of tile indexes on a map layer
 * @param  {number[]} tiles - An array of tile indexes
 * @param  {Object} map     - The tilemap object
 * @param  {string} layer   - The name of the layer
 */
function putTiles(tiles = [], map, layer) {
  tiles
    .map(key => (typeof key !== "number" ? 0 : key))
    .forEach((key, i) => {
      const x = i % map.width;
      const y = Math.floor(i / map.width);
      map.putTile(key, x, y, layer);
    });
}

/**
 * Creates a collision layer
 * @param  {number[]} tiles - An array of tile indexes
 * @param  {Object} map     - The tilemap object
 * @param  {number} i       - The index of the tile that needs to have collisions enabled
 */
function createCollisionLayer(tiles = [], map, i) {
  const name = Array.isArray(i) ? "collisions" : "collision" + i;
  const layer = map.createBlankLayer(
    name,
    map.width,
    map.height,
    map.tileWidth,
    map.tileHeight,
  );
  putTiles(tiles, map, name);
  map.setCollision(i, true, name);
  layer.visible = false;

  return layer;
}

/**
 * creates the grid
 */
function createGrid() {
  if (this.grid) {
    this.grid.destroy();
  }

  const map = this.map;
  const tileSize = map.tileWidth;
  const width = map.width;
  const height = map.height;
  const key = `UI:grid-${tileSize}`;

  // we add the grid to index 999
  map.addTilesetImage(key, null, tileSize, tileSize, 0, 0, 999);

  // create grid layer
  this.grid = map.createBlankLayer("grid", width, height, tileSize, tileSize);
  this.grid.z = Z_GRID;
  this.stageGroup.add(this.grid);

  // fill our layer with grid tiles
  putTiles(new Array(width * height).fill(999), map, "grid");
}

/**
 * Adds visible grid coordinates to the stage
 * @param  {Number} scale - The scale of the grid
 * @return {Phaser.Group}   The group containing the coordinates
 */
function createGridCoordinates(scale = 1, axis = "x") {
  if (axis !== "x" && axis !== "y") {
    axis = "x";
  }
  const game = this;
  const group = game.add.group(undefined, `coordinates-${axis}`);
  group.fixedToCamera = true;
  const leftMargin = 4;
  let fontSize = 16;

  // scale fontsize according to tile size but only if we have more than 8 tiles in height or width
  if (game.map.width > 8 || game.map.height > 8) {
    fontSize *= game.map.tileHeight / 32;
  }

  const style = {
    font: `bold ${fontSize}px Arial`,
    fill: "#fff",
    stroke: "#000",
    strokeThickness: 1,
    boundsAlignH: "center",
    boundsAlignV: "top",
  };

  if (axis === "x") {
    // x-axis
    for (let x = 0; x < game.map.width; x++) {
      const text = game.add.text(
        x * game.map.tileWidth + leftMargin,
        0,
        x * scale,
        style,
        group,
      );
      text.setTextBounds(null, null, text.width, game.map.tileHeight);
      text.anchor.setTo(0, 0);
      text.setShadow(1, 1, "#000", 0);
    }
  } else {
    style.boundsAlignH = "left";

    // y-axis
    for (let y = 1; y < game.map.height; y++) {
      const text = game.add.text(
        leftMargin,
        y * game.map.tileHeight,
        y * scale,
        style,
        group,
      );
      text.setTextBounds(null, null, game.map.tileWidth, game.map.tileHeight);
      text.anchor.setTo(0, 0);
      text.setShadow(1, 1, "#000", 0);
    }
  }

  return group;
}

/**
 * creates the pixel and tile coordinate grids
 * disposes of the old ones if needed
 */
function createCoordinateGrids() {
  if (this.pixelCoordinatesX) {
    this.pixelCoordinatesX.destroy();
  }
  if (this.pixelCoordinatesY) {
    this.pixelCoordinatesY.destroy();
  }

  if (this.tileCoordinatesX) {
    this.tileCoordinatesX.destroy();
  }
  if (this.tileCoordinatesY) {
    this.tileCoordinatesY.destroy();
  }

  this.pixelCoordinatesX = createGridCoordinates.call(
    this,
    this.map.tileHeight,
    "x",
  );
  this.pixelCoordinatesY = createGridCoordinates.call(
    this,
    this.map.tileHeight,
    "y",
  );
  this.pixelCoordinatesX.z = this.pixelCoordinatesY.z = Z_PIXEL_GRID;
  this.coordinatesGroup.add(this.pixelCoordinatesX);
  this.coordinatesGroup.add(this.pixelCoordinatesY);

  this.tileCoordinatesX = createGridCoordinates.call(this, undefined, "x");
  this.tileCoordinatesY = createGridCoordinates.call(this, undefined, "y");
  this.tileCoordinatesX.z = this.tileCoordinatesY.z = Z_TILE_GRID;
  this.coordinatesGroup.add(this.tileCoordinatesX);
  this.coordinatesGroup.add(this.tileCoordinatesY);
}

/** creates the background image */
function createBackgroundImage() {
  if (this.backgroundImage) {
    this.backgroundImage.destroy();
  }

  this.backgroundImage = this.add.sprite(0, 0, "UI:transparent-32");
  this.backgroundImage.z = Z_BACKGROUND_IMAGE;
  this.stageGroup.add(this.backgroundImage);
  setBackgroundImage.call(this);
}

/** creates the background gradient */
function createBackgroundGradient() {
  if (this.backgroundGradient) {
    this.backgroundGradient.destroy();
  }

  // this is the bitmap object onto which we'll generate gradients
  // NOTE: this bitmap is not directly added to the stage

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    this.backgroundGradientBitmap = this.make.bitmapData(
      this.model.get("width") * this.model.get("tile-width"),
      this.model.get("height") * this.model.get("tile-height"),
    );
  } else {
    this.backgroundGradientBitmap = this.make.bitmapData(
      this.world.width,
      this.world.height,
    );
  }

  // this is the actual sprite that will hold the generated gradient
  this.backgroundGradient = this.add.sprite(0, 0, "UI:transparent-32");
  this.backgroundGradient.z = Z_BACKGROUND_GRADIENT;
  this.stageGroup.add(this.backgroundGradient);

  setBackgroundGradient.call(this);
}

function setBackgroundColor() {
  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    this.stage.backgroundColor = "#363f4c";
  } else {
    this.stage.backgroundColor =
      this.model.get("background-color") || "#0071BC";
  }
}

function setBackgroundImage() {
  this.backgroundImage.loadTexture(
    this.model.get("background-image") || "UI:transparent-32",
  );

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    // TODO this needs to crop the image
    this.backgroundImage.width =
      this.model.get("width") * this.model.get("tile-width");
    this.backgroundImage.height =
      this.model.get("height") * this.model.get("tile-height");
  } else {
    this.backgroundImage.width = this.world.width;
    this.backgroundImage.height = this.world.height;
  }
}

function setBackgroundGradient() {
  setGradient(
    this.backgroundGradientBitmap,
    this.backgroundGradient,
    this.model.get("background-gradient"),
  );
}

/** Creates a click event-handler for the background */
function createBackgroundClickLayer() {
  if (this.backgroundClickLayer) {
    this.backgroundClickLayer.destroy();
  }

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    const gameWidth = this.model.get("width") * this.model.get("tile-width");
    const gameHeight = this.model.get("height") * this.model.get("tile-height");
    const PADDING = 5000;

    this.backgroundClickLayer = this.add.sprite(
      -PADDING,
      -PADDING,
      "UI:transparent-32",
    );
    this.backgroundClickLayer.width = gameWidth + PADDING * 2;
    this.backgroundClickLayer.height = gameHeight + PADDING * 2;
  } else {
    this.backgroundClickLayer = this.add.sprite(0, 0, "UI:transparent-32");
    this.backgroundClickLayer.width = this.world.width;
    this.backgroundClickLayer.height = this.world.height;
  }

  this.backgroundClickLayer.z = Z_BACKGROUND_CLICK;
  this.stageGroup.add(this.backgroundClickLayer);

  this.backgroundClickLayer.inputEnabled = true;
  this.backgroundClickLayer.events.onInputDown.add(
    (...args) => gameChannel.trigger("background:inputDown", ...args),
    this,
  );
}

/**
 * set or changes the current tilemap
 * Uses the game's Backbone Model as config
 */
function setTileMap() {
  if (this.map) {
    this.map.destroy();
  }

  if (this.backgroundTilesLayer) {
    this.backgroundTilesLayer.destroy();
  }

  const config = this.model.get("tileset-config") || {};
  const tileSize = config.tileSize || 32;
  const width = this.model.get("width");
  const height = this.model.get("height");
  const tiles = this.model.get("tiles").map(tile => Number(tile));

  // create tilemap
  const map = this.add.tilemap(null, tileSize, tileSize, width, height);
  this.map = map;

  // check whether our tileset starts at index 1 or 0
  // if it starts at 0 then the first tile will be considered the `empty` tile and will be used to fill the stage
  // if it starts at 1 we'll use a transparent tile for index 0
  const startIndex = config["add-empty"] ? 1 : 0;

  // add said transparent tile at index 0 if needed
  if (startIndex !== 0) {
    const blankKey = `UI:transparent-${tileSize}`;
    map.addTilesetImage(blankKey, null, tileSize, tileSize, 0, 0, 0);
  }

  // set the graphic of the tileset
  // TODO: allow multiple tile sets at the same time
  const tileSetKey = this.model.get("tile-set");
  if (tileSetKey) {
    map.addTilesetImage(tileSetKey, null, tileSize, tileSize, 0, 0, startIndex);
  }

  // place all visible tiles
  this.backgroundTilesLayer = map.create(
    "backgroundTiles",
    width,
    height,
    tileSize,
    tileSize,
  );
  this.backgroundTilesLayer.z = Z_BACKGROUND_TILES;
  this.stageGroup.add(this.backgroundTilesLayer);
  putTiles(tiles, map, "backgroundTiles");

  // update the model
  this.model.set("tile-width", tileSize);
  this.model.set("tile-height", tileSize);
}

/**
 * Updates the visibility of the grid or coordinates
 * Uses the game's Backbone Model as config
 */
function setHelpersVisibility() {
  const visible = this.mode === "edit" || !this.model.get("hide-grid-run");
  this.grid.visible = visible;

  this.pixelCoordinatesX.visible = this.pixelCoordinatesY.visible =
    visible && this.model.get("show-pixel-coordinates");
  this.tileCoordinatesX.visible = this.tileCoordinatesY.visible =
    visible && this.model.get("show-tile-coordinates");
}

/**
 * ensures both the tileset and background have been loaded
 */
async function ensureAssets() {
  const tileset = this.model.get("tile-set");
  const background = this.model.get("background-image");
  if (background) {
    await load(background);
  }
  await load(tileset);
}

/**
 * creates the stage
 * listens for changes on the backbone model and will update the stage accordingly
 */
export function createStage() {
  //create a phaser group that will contain all our layers
  this.stageGroup = this.add.group(undefined, "stage");
  this.objectGroup = this.add.group(); //create phaser group so we can target all objects simultaneously
  this.coordinatesGroup = this.add.group();

  buildStage.call(this);

  this.listener.listenTo(this.model, "change:background-color", () => {
    setBackgroundColor.call(this);
    interfaceChannel.trigger("design-change");
  });

  this.listener.listenTo(this.model, "change:background-image", async () => {
    await ensureAssets.call(this);
    setBackgroundImage.call(this);
    interfaceChannel.trigger("design-change");
  });

  this.listener.listenTo(this.model, "change:background-gradient", () => {
    setBackgroundGradient.call(this);
    interfaceChannel.trigger("design-change");
  });

  this.listener.listenTo(
    this.model,
    "change:width change:height change:tile-set",
    _.debounce(async () => {
      await ensureAssets.call(this);
      buildStage.call(this);
      interfaceChannel.trigger("design-change");
    }, 250),
  );

  this.listener.listenTo(
    this.model,
    "change:hide-grid-run change:hide-coordinates change:show-pixel-coordinates change:show-tile-coordinates",
    setHelpersVisibility.bind(this),
  );
}

// creates collision layers
// must be called after creating the stage for objects to be able to interact with tiles (pre-run state)
export function createCollisionLayers() {
  const map = this.map;
  const tiles = this.model.get("tiles").map(tile => Number(tile));
  const collisionIndexes = _.without(_.unique(tiles), 0);

  if (this.collisionLayersIndexed) {
    this.collisionLayersIndexed.forEach(layer => layer.destroy());
  }

  // a separate collision layer will be created per tile index to circumvent Phaser's internal edge-collision optimizations
  // this allows users to write code that detects collisions phaser would normally not trigger
  this.collisionLayersIndexed = collisionIndexes.map(
    createCollisionLayer.bind(this, tiles, map),
  );
}
