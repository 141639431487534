import Backbone from "custom/backbone-bundle";
import CodeBlock from "./../code-block";
import game from "../../game";
import _ from "underscore";

//this model represents a changing variable block
//it returns the newly assigned value of a variable based on the inputs
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    {
      type: Backbone.HasOne,
      key: "args",
      parse: true,
      runTests: true,
      relatedModel: require("./../components/arguments").default,
      reverseRelation: {
        key: "parent",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
  }),

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
  },

  blueprint: {
    fn: "changeVariable",
    label: "change {arg1} by {arg2}",
    description: "Change variable",
    category: "variable",
    args: {
      arguments: [
        {
          argumentSuggestions: [{ type: "variable" }],
        },
        {
          argumentSuggestions: [{ type: "number" }],
        },
      ],
    },
  },

  async execute(scope) {
    const args = await this.get("args").run(scope);
    return game.exec(this.get("fn"), ...args);
  },

  getGlossaryTaxonomy() {
    return [
      `glossary-type.code-block.variable.changeVariable`,
      `glossary-type.code-block.variable`,
      "glossary-type.code-block",
    ];
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    return list;
  },

  _placementMessage: require("./../errors").default.PLACEMENT_ASSIGN_VAR,
  _validatePlacement(parent) {
    return !parent || (!parent.get("free-form") && !parent.get("object-scope"));
  },
});
