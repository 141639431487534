import Backbone from "custom/backbone-bundle";
import interfaceChannel from "views/block/channels/interface-channel";
import gameChannel from "./block/phaser-middleware/channels/game-channel";
import { task } from "globals/task";
import { checkFlag } from "utils/flags";
import { generateUUID } from "utils/generate-uuid";
import { activeLayout } from "globals/active-layout";
import { combinedActionHistory } from "globals/action-history";

const Action = Backbone.RelationalModel.extend({
  defaults: {},

  apply(action = this.toJSON()) {
    const GAME = require("models/block/game").default;
    GAME.edit();
    switch (action.type) {
      case "move":
        this._moveBlock(action);
        break;
      case "set":
        this._setValue(action);
        break;
      case "design-change":
        this._changeDesign(action);
        break;
      case "design-order":
        this._orderDesign(action);
        break;
      case "design-add":
        this._addDesign(action);
        break;
      case "design-remove":
        this._removeDesign(action);
    }
  },

  reverse() {
    const action = this.toJSON();
    const actionTo = action.to;
    const actionFrom = action.from;

    /* eslint-disable no-case-declarations */
    switch (action.type) {
      case "move":
        action.to = actionFrom;
        action.from = actionTo;
        action.index = { new: action.index.old, old: action.index.new };
        action.position = {
          new: action.position.old,
          old: action.position.new,
        };

        // if block's current target is back to empty or to the wall,
        // we want to move it to recycle bin instead.
        if (!action.to || (action.to && action.to.get("isWall"))) {
          const { blockRecycleBin } = require("globals/block-recycle-bin");
          action.to = blockRecycleBin;
        }
        break;

      case "set":
        if (action.value) {
          action.value = {
            new: action.value.old,
            old: action.value.new,
          };
        }
        break;
      case "design-change":
        const keysFrom = Object.keys(actionFrom);
        Object.keys(actionTo).forEach(key => {
          if (keysFrom.indexOf(key) < 0) {
            actionFrom[key] = undefined;
          }
        });
        action.to = actionFrom;
        action.from = actionTo;
        break;
      case "design-order":
        action.to = actionFrom;
        action.from = actionTo;
        break;
      case "design-add":
        action.type = "design-remove";
        break;
      case "design-remove":
        action.type = "design-add";
        delete action.id;
        delete action.stageID;
        break;
    }
    return action;
  },

  obverse() {
    // nothing to do now.
    // use this function if we need 'redo' specific
  },

  setLayout() {
    let isLayoutChanged = false;
    const actionLayout = this.get("activeLayout");
    if (checkFlag("UNDO_REDO_COMBINED") && actionLayout) {
      const currentLayout = activeLayout.get("layout");
      if (actionLayout.layout) {
        if (actionLayout.layout.name !== (currentLayout?.name || "")) {
          interfaceChannel.trigger(
            `panel-manager:layout:show:${actionLayout.layout.name}`,
          );
          isLayoutChanged = true;
        }
        if (actionLayout.panels?.length) {
          actionLayout.panels.forEach(panel => {
            interfaceChannel.trigger(
              `panel-manager:panel:open:${panel.config.name}`,
              { from: "action-history", panel },
            );
          });
        }
      }
    }
    return isLayoutChanged;
  },

  _moveBlock(action) {
    const CodeBlock = require("./block/blocks/code-block").default;
    let actionModel =
      CodeBlock.findModel(action.block.get("id")) || action.block;
    if (action.blockLink) {
      actionModel.link(action.blockLink);
    }
    actionModel.move(
      action.to,
      action.index.new,
      action.position.new,
      "system",
    );
    if (action.blockLink) {
      action.blockLink._determineUsed();
    }
  },

  _setValue(action) {
    const CodeBlock = require("./block/blocks/code-block").default;
    const actionModel =
      CodeBlock.findModel(action.block.get("id")) || action.block;
    actionModel.changeValue(action.value.new, "system");
  },

  _changeDesign(action) {
    action.designModel.set({ ...action.to }, { isSystemChange: true });
    if (action.trigger) {
      interfaceChannel.trigger(action.trigger, action);
    }
  },

  _orderDesign(action) {
    action.designModel._setOrder(action.to);
  },

  _addDesign(action) {
    const data = action.designModel.toJSON();
    const object = task
      .getComponent("models/block")
      .get("objects")
      .add(data, { parse: true, at: 0 });

    const blockCoder = object.get("block");
    if (
      blockCoder &&
      blockCoder.get("settings") &&
      blockCoder.get("settings").get("auto-add-object")
    ) {
      interfaceChannel.trigger("add:to:wall", object);
    }

    gameChannel.trigger("object-selected", object);
    this.set("designModel", object);
  },

  _removeDesign(action) {
    action.designModel.trigger("deleted");
    task.getComponent("models/block").get("objects").remove(action.designModel);

    interfaceChannel.trigger("remove:from:wall", action.designModel.model);
    gameChannel.trigger("object-selected", null);
  },
});
export const ActionHistory = Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "actions",
      relatedModel: Action,
    },
  ],
  defaults: {
    currentIndex: 0,
    actions: [],
    category: "",
  },

  initialize(category = "block") {
    this.set("category", category);
    this.listenTo(
      interfaceChannel,
      `actionHistory:undo:${category}`,
      this.undo,
    );
    this.listenTo(
      interfaceChannel,
      `actionHistory:redo:${category}`,
      this.redo,
    );
    this.listenTo(interfaceChannel, "design-change", this.onDesignChange);
  },

  clear() {
    this.set({ currentIndex: 0, actions: [] });
    const { blockRecycleBin } = require("globals/block-recycle-bin");
    blockRecycleBin.delete();
    interfaceChannel.trigger("actionHistory:clear");
  },

  addItem(action) {
    const category = this.get("category");
    if (checkFlag("UNDO_REDO_COMBINED") && category !== "combined") {
      action.category = category;
      combinedActionHistory.addItem(action);
      return;
    }
    if (this.validate(action)) {
      task.onUserAction();
      if (this.isEnabled()) {
        const actions = this.get("actions");
        const currentIndex = this.get("currentIndex");
        if (actions.length > currentIndex) {
          actions.set(actions.slice(0, currentIndex));
        }
        if (category === "design" || action.category === "design") {
          this.removeRelations(action);
        }

        this.addGroupInfo(action);

        action.addedTime = Date.now();
        if (checkFlag("UNDO_REDO_COMBINED")) {
          action.activeLayout = this.getActiveLayout();
        }
        actions.add(action);
        this.set("currentIndex", currentIndex + 1);
      }
    }
  },

  validate(action) {
    const category = action.category || this.get("category");
    switch (category) {
      case "block":
        return this.validateBlock(action);
      case "design":
        return this.validateDesign(action);
      default:
        break;
    }
  },

  validateBlock(action) {
    switch (action.type) {
      case "move":
        if (!action.from || !action.to) {
          return true;
        }
        const from = action.from.toJSON();
        const to = action.to.toJSON();
        const index = action.index;
        if (from["free-form"] && to["free-form"]) {
          return true;
        }
        if (from.id === to.id && index.old === index.new) {
          return false;
        } else {
          return true;
        }
      case "set":
        return (
          JSON.stringify(action.value.old) !== JSON.stringify(action.value.new)
        );
    }
  },

  validateDesign(action) {
    switch (action.type) {
      case "design-change":
      case "design-order":
        return JSON.stringify(action.from) !== JSON.stringify(action.to);

      default:
        return action;
    }
  },

  isEnabled() {
    const category = this.get("category");
    return (
      (category === "block" && checkFlag("UNDO_REDO")) ||
      (category === "design" && checkFlag("UNDO_REDO_DESIGN")) ||
      (category === "combined" && checkFlag("UNDO_REDO_COMBINED"))
    );
  },

  removeRelations(action) {
    const relations = (action.designModel || action.block)
      .getRelations()
      .map(r => r.key);
    if (relations && action.from && action.to) {
      relations.forEach(key => {
        delete action.from[key];
        delete action.to[key];
      });
    }
  },

  addGroupInfo(action) {
    const GROUP_THRESHOLD = 800;
    const index = this.get("currentIndex") - 1;
    const currentTime = Date.now();
    if (index > -1) {
      const latestAction = this.get("actions").at(index).toJSON();
      if (currentTime - latestAction.addedTime < GROUP_THRESHOLD) {
        action.group = latestAction.group;
      } else {
        action.group = generateUUID();
      }
    } else {
      action.group = generateUUID();
    }
  },

  undo(byGroup = "") {
    const index = this.get("currentIndex") - 1;
    if (index > -1) {
      const action = this.get("actions").at(index);
      const group = action.get("group");
      if (byGroup && byGroup !== group) {
        return;
      }
      // skips action if there is a layout change
      if (action.setLayout()) {
        return;
      }
      action.apply(action.reverse());
      this.set("currentIndex", index);
      this.undo(group);
    }
  },

  redo(byGroup = "") {
    const action = this.get("actions").at(this.get("currentIndex"));
    if (action) {
      const group = action.get("group");
      if (byGroup && byGroup !== group) {
        return;
      }
      // skips action if there is a layout change
      if (action.setLayout()) {
        return;
      }
      action.apply(action.obverse());
      this.set("currentIndex", this.get("currentIndex") + 1);
      this.redo(group);
    }
  },

  onDesignChange() {
    // this may be a starting point when we want undo/redo to cover changes for design,
    // but for now we can notify task with an action made,
    // knowing the fact that design changes are user made changes
    task.onUserAction();
  },

  getButtonConfig(config) {
    const actionHistory = this.toJSON();
    config.subButtons.map(button => {
      switch (button.name) {
        case "undo":
          if (actionHistory.currentIndex - 1 < 0) {
            button.isDisabled = true;
          } else {
            button.isDisabled = false;
          }
          break;

        case "redo":
          if (actionHistory.currentIndex < actionHistory.actions.length) {
            button.isDisabled = false;
          } else {
            button.isDisabled = true;
          }
      }
    });

    return config;
  },

  getActiveLayout() {
    const layoutJson = activeLayout.toJSON();
    layoutJson.panels = [].concat(layoutJson.panels || []);
    return layoutJson;
  },
});
