import Backbone from "custom/backbone-bundle";
import CodeBlock from "./../code-block";
import PrototypeModel from "models/prototype-model";
import TestablePrototype from "models/prototypes/testable";

/**
 * this model represents a block scope
 * when run, it will execute all the code contained within and return the last value
 */
export default PrototypeModel.extend(TestablePrototype).extend({
  relations: TestablePrototype.relations.concat([
    {
      type: Backbone.HasMany,
      key: "code",
      parse: true,
      runTests: true,
      relatedModel: CodeBlock,
      reverseRelation: {
        key: "parent-scope",
        includeInJSON: false,
      },
    },
  ]),

  defaults: {
    code: [],
    active: false,
    "free-form": false, //free-from scopes are top-level scopes that are not contained within code blocks (foreground code, background code, code wall)
    scale: 1, //only used by free-form scopes, allows them to be zoomed in/out
  },

  getPseudoCode(indent = "") {
    let code = this.get("code")
      .map(item => item.getPseudoCode(indent + "  "))
      .join("\n");

    return `\n${code}`;
  },

  getChildBlocks(list = []) {
    this.get("code").forEach(block => block.getChildBlocks(list));
    return list;
  },

  /** returns true if the given model can be placed inside of this scope */
  accepts(model) {
    const parentBlock = this.get("parent");
    const isTopLevel = Boolean(!parentBlock);

    if (isTopLevel) {
      return true; //anything goes in top-level scopes
    }

    if (model.get("top-level-only")) {
      return false; // top-level-only blocks cannot be placed inside anything but top level scopes
    }

    //object scope accepts only command blocks
    if (this.get("object-scope")) {
      return model.get("type") === "command";

      //regular scopes only accepts certain blocks
    } else {
      return (
        model.get("type") === "assign-variable" ||
        model.get("type") === "change-variable" ||
        model.get("type") === "object" ||
        model.get("type") === "this" ||
        model.get("type") === "object-named" ||
        model.get("type") === "cloned" ||
        model.get("control-block")
      );
    }
  },

  /** delete this scope and any code inside of it */
  delete() {
    const blocks = this.get("code").models;
    for (let i = blocks.length - 1; i >= 0; i--) {
      const block = blocks[i];
      block.delete();
    }
    Backbone.Relational.store.unregister(this);
  },

  /** Runs all the code inside this scope and promise a result */
  async run(scope, object) {
    const code = this.get("code").slice();
    let out = object;

    while (code.length) {
      const expression = code.shift();
      out = await expression.run(scope, out);
    }

    return out;
  },

  getParentBlock() {
    return this.get("parent");
  },

  getHorizontalCodeChain(chain = []) {
    if (this.get("object-scope")) {
      this.get("code").forEach(block => block.getHorizontalCodeChain(chain));
    }

    return chain;
  },

  getVerticalCodeChain(chain = []) {
    if (!this.get("object-scope")) {
      this.get("code").forEach(block => block.getVerticalCodeChain(chain));
    }
  },

  getHorizontalCodeChainParent() {
    if (this.get("parent")) {
      return this.get("parent").getHorizontalCodeChainParent();
    }
  },

  getVerticalCodeChainParent() {
    if (this.get("parent")) {
      return this.get("parent").getVerticalCodeChainParent();
    }
  },
});
