import BLANK_BLOCK from "fixtures/task-types/block-blank";
import Task from "models/task";

const NULL_TASK = new Task();

export async function createNewApp(stageSize) {
  await NULL_TASK._ensureComponents(BLANK_BLOCK);
  let newTask = new Task(BLANK_BLOCK);
  newTask.setGrade("all");
  const block = newTask.getComponent("models/block");

  const [width, height] = stageSize.split("x");

  block.set("width", width);
  block.set("height", height);

  return newTask;
}
