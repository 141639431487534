import SET from "./set";
import GET from "./get";

import * as validate from "./../utils/validate-inputs";
import { number } from "./../types/number";
import { string } from "./../types/string";

/**
 * Changes a value by a certain amount
 * Value and prop must both be a of a numeric type
 *
 * @param {Object} object - The object whose property to change
 * @param {String} prop - The key of the property to change
 * @param {Number} value - The value by which to change the property by
 */
export default function changeBy(object, prop, value) {
  prop = string(prop);
  value = number(value);
  const oldValue = number(GET(object, prop));

  if (!validate.object(object)) {
    throw Error(validate.error.command);
  }

  return SET(object, prop, oldValue + value);
}
