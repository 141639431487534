import Modal from "../modal";
import { task } from "../../../../../globals/task";
import { user } from "../../../../../globals/user";
import { isAuthor } from "../../../../../utils/is-author";
import simplemde from "simplemde";
import simplemdeCss from "simplemde-css";

export default Modal.extend({
  className: [Modal.prototype.className, "app-readme"].join(" "),

  template: require("./app-readme.hbs"),
  style: require("./app-readme.styl"),

  async render() {
    const readmeText = task.get("readme") || "";

    this.$el.html(
      this.template({
        readmeText,
      }),
    );

    if (isAuthor(user, task)) {
      // Only way to trigger simplemde showing the initial value on a modal is to wait for a while
      await new Promise(r => setTimeout(r, 200));
      await this.loadMarkDownEditor();
      this.markDownEditor.codemirror.setCursor(
        this.markDownEditor.codemirror.lineCount(),
        0,
      );
    }
  },

  /**
   * lazyload simplemde as markdown editor
   */
  async loadMarkDownEditor() {
    const mdTextArea = this.$(".text.editable")[0];
    this.markDownEditor = new simplemde({
      element: mdTextArea,
      forceSync: true,
      autofocus: true,
      codeSyntaxHighlighting: false,
      toolbar: [
        "bold",
        "italic",
        "strikethrough",
        "heading",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "|",
        "preview",
      ],
    });
    this.markDownEditor.codemirror.save = () => {
      const editorValue = this.markDownEditor.codemirror.getValue();
      task.set("readme", editorValue);
    };

    simplemdeCss.use();
  },
});
