export default function removeKeys(obj, keys) {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  for (const key of keys) {
    for (const prop in obj) {
      if (prop === key) delete obj[prop];
      else if (typeof obj[prop] === "object") removeKeys(obj[prop], key);
    }
  }
}
