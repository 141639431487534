var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Choose an app to open",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":37}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<iframe title=\""
    + alias1(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Choose an app to open",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":15},"end":{"line":6,"column":52}}}))
    + "\" src=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\">\n</iframe>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":8,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});