var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create a new app?",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":33}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"image-text-container\">\n  <img class=\"img-warning\" src=\"dist/images/img-warning.png\" alt=\"\" />\n  <p>"
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Make sure you have saved your work before leaving the app, any unsaved work will be lost.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":5},"end":{"line":8,"column":110}}}))
    + "</p>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":56},"end":{"line":13,"column":78}}}))
    + "\" autofocus>"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":90},"end":{"line":13,"column":112}}}))
    + "</button>\n<button class=\"confirm new-app-confirmed\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":80}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":82},"end":{"line":14,"column":108}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":10,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":15,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});