import collisionFilter from "./../creation/collision-filter";
import * as validate from "./../utils/validate-inputs";
import SET from "./set";

/**
 * _Steps_ an object into a specific direction
 *
 * Unlike the standard way of moving objects, this will use a Phaser tween to
 * move the object one tile over a specific amount of time
 *
 * @async
 * @param {Object} object - The object to move a step
 * @param {Vector} direction - The driection in which to move a step
 */
export default async function stepMove(object, direction) {
  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  if (!validate.vector(direction)) {
    throw Error(validate.error.vector);
  }

  // set the heading to match the tween
  SET.call(this, object, "heading", direction);

  if (!object.activeTweenPromise) {
    object.activeTweenPromise = Promise.resolve();
  }

  object.activeTweenPromise = object.activeTweenPromise.then(() =>
    tweenQueue.call(object, direction),
  );

  await object.activeTweenPromise;
}

function promiseTween(direction) {
  const game = this.game;
  const x = direction.x || 0;
  const y = direction.y || 0;
  const math = this.game.math;

  const tweenConfig = {
    x: this.x + x * game.model.get("tile-width"),
    y: this.y + y * game.model.get("tile-height"),
  };

  //follow motion turning in the shortest possible direction
  if (this.model && this["is-topdown"]) {
    const target = math.radToDeg(
      math.angleBetween(this.x - x, this.y - y, this.x, this.y),
    );
    tweenConfig.angle = this.angle + math.getShortestAngle(this.angle, target);
  }

  //check for tile collisions and bounce
  if (
    collisionFilter(this, game.map.getTileWorldXY(tweenConfig.x, tweenConfig.y))
  ) {
    return bounce.call(this, tweenConfig);
  }

  return applyTween.call(
    this,
    tweenConfig,
    game.model.get("tween-speed"),
    Phaser.Easing.Quartic.InOut,
  );
}

const BOUNCE_SIZE = 1 / 4;
function bounce(config) {
  config.x = this.x + (config.x - this.x) * BOUNCE_SIZE;
  config.y = this.y + (config.y - this.y) * BOUNCE_SIZE;
  return applyTween.call(
    this,
    config,
    this.game.model.get("tween-speed") / 2,
    Phaser.Easing.Bounce.InOut,
    true,
  );
}

function applyTween(config, speed, easing, yoyo) {
  this.activeTween = this.game.add
    .tween(this)
    .to(config, speed, easing, false, 0, 0, yoyo);

  this.activeTween.start();

  return new Promise(resolve =>
    this.activeTween.onComplete.add(() => {
      delete this.activeTween;
      resolve();
    }),
  );
}

function tweenQueue(direction) {
  if (this.game.model.get("stage-wrap")) {
    this.game.world.wrap(this, 0, false);
  }

  this.isMoving = true;
  this.onBeforeStep.dispatch();

  return promiseTween.call(this, direction).then(() => {
    this.onAfterStep.dispatch();
    this.isMoving = false;
    return this;
  });
}

export function stopCurrentTween() {
  if (this.activeTween) {
    this.activeTween.stop(true);
    delete this.activeTween;
  }
}
