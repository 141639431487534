import Modal from "../modal";
import { GlossaryTermDetails } from "../../glossary-terms/details/glossary-term-details";

export default Modal.extend({
  className: [Modal.prototype.className, "glossary-term-modal"].join(" "),
  template: require("./glossary-term.hbs"),
  style: require("./glossary-term.styl"),

  /**
   * Open with a particular glossary term
   * @param {String} renderData.id The id of the glossary term to show
   * @param {String[]} [renderData.list] Optional, a list of glossary term id's for pagination
   */
  open(renderData) {
    renderData.list = renderData.list || [renderData.id];

    this.glossaryTermDetails = new GlossaryTermDetails();
    this.glossaryTermDetails.view(
      renderData.list.indexOf(renderData.id),
      renderData.list,
    );

    Modal.prototype.open.call(this);

    this.$el.one("hidden.bs.modal", () => this.onClosed());
  },

  onClosed() {
    if (this.glossaryTermDetails) {
      // delete details view when closed, this stops the video and readspeaker
      this.glossaryTermDetails.remove();
      delete this.glossaryTermDetails;
    }
  },

  render() {
    const hasMultipleItems = this.glossaryTermDetails.__list.length > 1;
    this.$el.html(this.template());
    this.$(".body .main").append(this.glossaryTermDetails.el);
    this.$(".body").toggleClass("multiple-items", hasMultipleItems);
    this.$(".footer").toggleClass("multiple-items", hasMultipleItems);
    this.delegateEvents();
  },
});
