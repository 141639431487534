/**
 * Handles tab navigation through code blocks
 */

export class TabbableBlocks {
  constructor(parent) {
    this.parent = parent;
  }

  async onFocus() {
    await new Promise(r => setTimeout(r, 0));
    const focus = document.activeElement;
    const previous = document.querySelector(".focus");
    if (previous) {
      previous.classList.remove("focus");
    }

    if (
      focus &&
      focus.classList.contains("interactive") &&
      (focus.classList.contains("argument") ||
        focus.classList.contains("super-block"))
    ) {
      focus.classList.add("focus");
    }

    if (focus?.model?.get("jigsaw-fill")?.view) {
      focus.model.get("jigsaw-fill").view.el.focus();
    }
  }
}
