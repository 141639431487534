import { glossary } from "globals/glossary";
import { translate } from "utils/localisation";

export default function (options) {
  const terms = glossary.getItems("glossary-type.definition");
  let html = options.fn(this);

  terms.forEach(term => {
    term.set("name", translate(term.get("name")));
    term.set("description", translate(term.get("description")));
    const regex = createRegexp(term);

    html = html.replace(regex, function (match, before, word, after) {
      if (before === "<") {
        return match; // don't touch HTML tags
      }

      if (/\w/.test(before) || /\w/.test(after)) {
        return match; // don't touch partial words
      }

      if (/-/.test(before) || /-/.test(after)) {
        return match; // don't touch hyphenated words
      }

      const id = term.get("id");
      return `${before}<span class="glossary-term" tabindex="0" role="button" data-glossary-term-id="${id}">${word}</span>${after}`;
    });
  });

  return html;
}

// create a glossary term matching regexp
// note: it is important that this regex creates three matching groups used by the `replace` above
// TODO: support for synonyms?
function createRegexp(term) {
  return new RegExp(`(.?)(${term.get("name")})(.?)`, "i");
}
