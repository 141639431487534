import Backone from "backbone";

let ACTIVE_MODALS = [];

/**
 * This is an agnostic reusable modal
 * Refer to `/example` for how to extend this with bespoke functionality
 */
export default Backone.View.extend({
  className: "fade coding-modal",
  template: require("./modal.hbs"),

  events: {
    keydown: "onKeyDown",
  },
  initialize() {
    if (this.style) {
      this.style.use();
    }

    ACTIVE_MODALS.push(this);
  },

  render() {
    this.$el.html(this.template({}));
    this.delegateEvents();
  },

  async open() {
    this.render();

    // only one modal can be open at a time
    await Promise.all(
      ACTIVE_MODALS.filter(modal => modal !== this).map(modal => modal.close()),
    );

    this.$el.modal("show");

    // there needs to be a small delay here because bootstrap modals are async
    await new Promise(r => setTimeout(r, 250));

    document.activeElement.blur();
    this.$("[autofocus]:not([disabled])").first().trigger("focus");
  },

  async close() {
    if (this.$el.hasClass("in")) {
      this.$el.modal("hide");
      await new Promise(r => this.$el.one("hidden.bs.modal", r));
    }
  },

  remove() {
    ACTIVE_MODALS = ACTIVE_MODALS.filter(item => item !== this);

    if (this.style) {
      this.style.unuse();
    }

    Backone.View.prototype.remove.call(this);
  },
  onKeyDown(e) {
    const focusableElements = "button:not(:disabled), input, textarea, a";
    const modal = this.el.querySelector(".dialog");
    const focusableContent = modal.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement =
      focusableContent?.length > 0
        ? focusableContent[focusableContent?.length - 1]
        : focusableContent[0];
    const isTabPressed = e.code === "Tab" || e.keyCode === 9;
    if (!isTabPressed) {
      return;
    }
    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement?.focus();
        e.preventDefault();
      }
    } else if (isTabPressed) {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement?.focus();
        e.preventDefault();
      }
    }
  },
});
