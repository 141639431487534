import * as validate from "./../utils/validate-inputs";

/**
 * Completes this task
 *
 * Used by content creators to validate tasks via code blocks
 *
 * @param {Object:App} object - The app object
 */
export default function complete(object) {
  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  object.model.pass();
}
