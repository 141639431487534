import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";

/**
 * Plays an animation on an object ONCE (assuming the object can)
 *
 * @param {Object} object - The object to animate
 * @param {String} label - The frame label to play
 *
 * @description https://phaser.io/docs/2.6.2/Phaser.AnimationManager.html#play
 */
export default function animateOnce(object, label) {
  label = string(label);

  if (!validate.animated(object)) {
    throw Error(validate.error.command);
  }

  //prevents play once from looping the current animation if it's already playing
  if (
    !(
      label === object.animations.currentAnim.name &&
      object.animations.currentAnim.isPlaying
    )
  ) {
    object.animations.play(label, null, false, false);
  }
}
