import { checkFlag } from "utils/flags";
import { settings } from "globals/settings";
import { task } from "globals/task";
import { lesson } from "globals/lesson";

import BlockView from "views/block/blocks/code-block";
import CodeBlock from "models/block/blocks/code-block";

import { getDynamicBlocks } from "./block-sources/dynamic-blocks";
import { STATIC_BLOCKS } from "./block-sources/static-blocks";
import { TOODAL_BLOCKS } from "./block-sources/toodal-blocks";
import { CODE_CHEST_PRIMITIVES } from "./block-sources/code-chest-primitives";
import { ADD_OBJECT_BUTTONS } from "./block-sources/add-object-buttons";
import { AddObjectButton } from "views/block/ui-elements/code/add-object-button/add-object-button";
import { ADD_OBJECT_BUTTONS_WALL } from "./block-sources/add-object-buttons-wall";

export class BlockCache {
  constructor() {
    this.loading = false;

    this.isDirty = true;

    this.loadQueue = [];

    this.blockPool = [];

    // controls how many blocks will be initialized per cycle
    // more blocks means faster, but too fast and the browser can become choppy
    this.blocksPerCycle = 3;
  }

  /**
   * removes all blocks from the cache
   */
  clearBlocks() {
    this.blockPool.forEach(block => {
      block.remove();
      block.model.set("critical", false);
      block.model.delete();
    });
    this.blockPool.length = 0;
  }

  /**
   * re-populates all dynamic blocks
   */
  async populateDynamic() {
    if (this.isDirty) {
      this.clearBlocks();
      const dynamicBlocks = await getDynamicBlocks();
      await this.loadBlocks(dynamicBlocks);
    }

    this.isDirty = false;
  }

  /**
   * Loads a list of blocks asynchronously
   * @async
   * @param  {Array}   list - A list of block data
   */
  async loadBlocks(list = []) {
    this.loading = true;

    list = list.map(block => ({ block }));

    this.loadQueue = this.loadQueue.concat(list);
    await this.blockLoader();
  }

  /**
   * loads all blocks in the loadQueue
   * runs asynchronously to prevent the browser from hanging
   * keeps calling itself while the loadQueue has blocks in it
   * resolves once all blocks have been loaded
   */
  async blockLoader() {
    // wait until the end of the call stack with a little breathing room
    // this will prevent the browser from hanging as the code chest loads a lot of blocks
    await new Promise(resolve => setTimeout(() => resolve(), 10));

    const targets = this.loadQueue.splice(0, this.blocksPerCycle);

    while (targets.length) {
      const target = targets.shift();

      const block = this.blockFactory(target.block);

      this.blockPool.push(block);
    }

    // continue loading if needed
    if (this.loadQueue.length) {
      return this.blockLoader();
    } else {
      this.loading = false;
    }
  }

  /**
   * Instantiates a CodeBlock with View given blueprint data
   * @return {Backbone.View}     Returns instantiated View of the code block
   */
  blockFactory(input) {
    input = JSON.parse(JSON.stringify(input));

    // mark the code block as being critical
    // content creators can turn this off via the code config
    input.critical = true;

    const model = CodeBlock.build(input, { parse: true });

    if (model.get("isAddObjectButton")) {
      return new AddObjectButton({ model });
    } else {
      return new BlockView(model);
    }
  }

  // get all blocks relevant to the current grade
  get blocks() {
    return this.blockPool
      .filter(BlockCache.filter)
      .filter(b => BlockCache.filterGradeBlocks(b));
  }

  // get all blocks (without filtering by current grade)
  get allBlocks() {
    return this.blockPool.filter(BlockCache.filter);
  }

  static filter(block) {
    if (block.model) {
      block = block.model;
    }

    return (
      BlockCache.filterHiddenBlocks(block) &&
      BlockCache.filterFeatureFlaggedBlocks(block) &&
      BlockCache.filterEditorialBlocks(block) &&
      BlockCache.filterMissingTextures(block)
    );
  }

  /** Filters blocks to only those relevant to the current grade */
  static filterGradeBlocks(
    block,
    grade = task.getGrade() || lesson.getGrade(),
  ) {
    if (grade === "all") {
      return true;
    }

    if (block.model) {
      block = block.model;
    }

    return (
      block.get("groups").includes("dynamic") ||
      block.get("groups").includes(`year ${grade}`)
    );
  }

  /** Filters out hidden blocks */
  static filterHiddenBlocks(block) {
    return (
      !block.get("deprecated") &&
      !block.get("secret") &&
      !block.get("incomplete")
    );
  }

  /** Filters out blocks based on feature flags */
  static filterFeatureFlaggedBlocks(block) {
    return block
      .get("feature-flags")
      .map(flag => {
        if (flag.startsWith("!")) {
          // allow inverted flag check by adding a `!` in front
          // e.g: `!APP_NEW_CODE_CHEST`
          return !checkFlag(flag.substring(1));
        } else {
          return checkFlag(flag);
        }
      })
      .reduce((acc, val) => acc && val, true);
  }

  /** Filters out editorial-only blocks */
  static filterEditorialBlocks(block) {
    return !block.get("editorial-only") || settings.get("editable");
  }

  /** Filters out missing textures */
  static filterMissingTextures(block) {
    return (
      block.get("type") !== "texture" ||
      !block.view.$(".phaser-tile").hasClass("missing")
    );
  }
}

// singleton cache for dynamic blocks
export const BLOCK_CACHE_DYNAMIC = new BlockCache();

// singleton cache for static blocks
export const BLOCK_CACHE_STATIC = new BlockCache();
BLOCK_CACHE_STATIC.loadBlocks(STATIC_BLOCKS);

// singleton cache for static primitives
export const BLOCK_CACHE_CHEST_PRIMITIVES = new BlockCache();
BLOCK_CACHE_CHEST_PRIMITIVES.loadBlocks(CODE_CHEST_PRIMITIVES);

// singleton cache for contextual blocks in the toodal
export const BLOCK_CACHE_TOODAL = new BlockCache();
BLOCK_CACHE_TOODAL.loadBlocks(TOODAL_BLOCKS);

// singleton cache for add object buttons
export const BLOCK_CACHE_ADD_OBJECT_BUTTONS = new BlockCache();
BLOCK_CACHE_ADD_OBJECT_BUTTONS.loadBlocks(ADD_OBJECT_BUTTONS);

// singleton cache for add object buttons from the wall
export const BLOCK_CACHE_ADD_OBJECT_BUTTONS_WALL = new BlockCache();
BLOCK_CACHE_ADD_OBJECT_BUTTONS_WALL.loadBlocks(ADD_OBJECT_BUTTONS_WALL);
