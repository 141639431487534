import Modal from "../modal";
import { getAbsoluteUrl } from "utils/get-absolute-url";
import translate from "utils/localisation";

export const OpenAppModal = Modal.extend({
  className: [Modal.prototype.className, "open-app"].join(" "),
  style: require("./open-app.styl"),
  template: require("./open-app.hbs"),

  render() {
    this.$el.html(
      this.template({
        url: getAbsoluteUrl(
          `${
            window.process.env.WEBSITE_URL
          }/embedded/apps/my-apps?locale=${translate("locale")}`,
        ),
      }),
    );
  },
});
