import Backbone from "custom/backbone-bundle";
import style from "./code-search.styl";
style.use();

export const CodeSearch = Backbone.View.extend({
  template: require("./code-search.hbs"),
  className: "multi-toodal__code-search",

  events: {
    "input .search-code": "onInput",
  },

  initialize() {
    this.render();

    this.listenTo(this.model, "change:filter change:suggestions", this.render);
    this.listenTo(this.model, "change:search", this.updateVisibility);
  },

  render() {
    this.$el.html(this.template({}));
    this.updateVisibility();
  },

  isVisible() {
    return typeof this.model.get("search") === "string";
  },

  updateVisibility() {
    this.$el.toggleClass("visible", this.isVisible());
    this.$("input").prop("disabled", !this.isVisible());
  },

  onInput(e) {
    this.model.set("search", e.target.value);
  },
});
