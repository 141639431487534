import { addYearGroups } from "./code-chest-primitives";

export const TOODAL_BLOCKS = [
  // input fields
  {
    type: "string",
    category: "string",
    placeholder: "Type a string",
    inputType: "text",
    isInput: true,
  },
  {
    type: "number",
    category: "numbers",
    step: 0.01,
    placeholder: "Type a number",
    inputType: "number",
    isInput: true,
  },
  {
    type: "angle",
    category: "angles",
    step: 1,
    placeholder: "Type an angle",
    inputType: "number",
    isInput: true,
  },

  // booleans
  { type: "boolean", value: true, category: "boolean" },
  { type: "boolean", value: false, category: "boolean" },
  {
    type: "boolean",
    value: null,
    category: "boolean",
    "editorial-only": true,
  },

  // angles
  { type: "angle", category: "angles", value: -135 },
  { type: "angle", category: "angles", value: -90 },
  { type: "angle", category: "angles", value: -45 },
  { type: "angle", category: "angles", value: -180 },
  { type: "angle", category: "angles", value: 0 },
  { type: "angle", category: "angles", value: 135 },
  { type: "angle", category: "angles", value: 90 },
  { type: "angle", category: "angles", value: 45 },
  { type: "angle", category: "angles", value: null, "editorial-only": true },

  // vectors
  { type: "vector", x: 0, y: -1, category: "vectors" },
  { type: "vector", x: 1, y: -1, category: "vectors" },
  { type: "vector", x: 1, y: 0, category: "vectors" },
  { type: "vector", x: 1, y: 1, category: "vectors" },
  { type: "vector", x: 0, y: 1, category: "vectors" },
  { type: "vector", x: -1, y: 1, category: "vectors" },
  { type: "vector", x: -1, y: 0, category: "vectors" },
  { type: "vector", x: -1, y: -1, category: "vectors" },
  {
    type: "vector",
    x: 0,
    y: 0,
    category: "vectors",
    "editorial-only": true,
  },

  // turns
  { type: "turn", category: "turn", value: 10 },
  { type: "turn", category: "turn", value: -10 },
  {
    type: "turn",
    value: null,
    category: "turn",
    "editorial-only": true,
  },

  // strings
  {
    type: "string",
    contextual: true,
    category: "get-time-options",
    value: "All",
  },
  {
    type: "string",
    contextual: true,
    category: "get-time-options",
    value: "Hours: 12 hrs",
  },
  {
    type: "string",
    contextual: true,
    category: "get-time-options",
    value: "Hours: 24 hrs",
  },
  {
    type: "string",
    contextual: true,
    category: "get-time-options",
    value: "Minutes",
  },
  {
    type: "string",
    contextual: true,
    category: "get-time-options",
    value: "Seconds",
  },

  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "frame",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "text",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "alignment",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "x",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "y",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "cameraX",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "cameraY",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "width",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "height",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "angle",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "rotation",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "heading",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "speed",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "friction",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "visible",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "objectName",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "angularVelocity",
  },
  {
    type: "string",
    contextual: true,
    category: "object-properties",
    value: "gravity",
  },

  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "center",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "top",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "bottom",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "left",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "right",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "top-left",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "top-right",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "bottom-left",
  },
  {
    type: "string",
    contextual: true,
    category: "text-alignment-options",
    value: "bottom-right",
  },

  {
    // deprecated
    type: "string",
    contextual: true,
    category: "decimal-point-options",
    value: ".0",
  },
  {
    // deprecated
    type: "string",
    contextual: true,
    category: "decimal-point-options",
    value: ".00",
  },
  {
    // deprecated
    type: "string",
    contextual: true,
    category: "decimal-point-options",
    value: ".000",
  },

  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 100,
  },
  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 10,
  },
  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 1,
  },
  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 0.1,
  },
  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 0.01,
  },
  {
    type: "number",
    contextual: true,
    category: "decimal-point-options",
    value: 0.001,
  },

  {
    type: "string",
    contextual: true,
    category: "rotation-style-options",
    value: "no-rotate",
  },
  {
    type: "string",
    contextual: true,
    category: "rotation-style-options",
    value: "left-right",
  },
  {
    type: "string",
    contextual: true,
    category: "rotation-style-options",
    value: "direction",
  },
  {
    type: "string",
    value: null,
    category: "string",
    "editorial-only": true,
  },
];

// give them all the correct year group
TOODAL_BLOCKS.forEach(addYearGroups);

// make sure they are all editable
TOODAL_BLOCKS.forEach(prim => (prim.editable = true));
