import * as validate from "./../utils/validate-inputs";

export default function onHit(callback, object, target) {
  // if more than one object is passed, bind events on each of them
  if (Array.isArray(object)) {
    return Promise.all(object.map(object => onHit(callback, object, target)));
  }

  // if more than one target is passed, bind events on each of them
  if (Array.isArray(target)) {
    return Promise.all(target.map(target => onHit(callback, object, target)));
  }

  // bind events
  if (
    objectToObject(object, target, callback) ||
    objectToTile(object, target, callback) ||
    inputToObject(object, target, callback) ||
    inputToTile(object, target, callback)
  ) {
    return this;
  } else {
    throw Error(validate.error.collider);
  }
}

/**
 * Binds an Object to Object collision handler
 * @param  {Phaser.Sprite} objA - Sprite object A
 * @param  {Phaser.Sprite} objB - Sprite object B
 * @param  {function} callback   - function that handles the collision
 * @return {Boolean}            Returns true if an event handler was bound
 */
function objectToObject(objA, objB, callback) {
  if (validate.physical(objA) && validate.physical(objB)) {
    objA.collisionSignal.add((a, b) => {
      if (b === objB) {
        callback([a, b]);
      }
    });
    return true;
  } else {
    return false;
  }
}

/**
 * Binds an Object to Tile collision handler
 * @param  {Phaser.Sprite} object - Sprite object
 * @param  {Number} tile          - Tile index
 * @param  {function} callback     - function that handles the collision
 * @return {Boolean}              Returns true if an event handler was bound
 */
function objectToTile(object, tile, callback) {
  //swap variables if they are in the wrong order
  if (validate.texture(object) && validate.physical(tile)) {
    [object, tile] = [tile, object];
  }

  if (validate.physical(object) && validate.texture(tile)) {
    object.collisionSignal.add((a, b) => {
      if (b === tile) {
        callback([object]);
      }
    }, this);
    return true;
  } else {
    return false;
  }
}

/**
 * Binds an Input to Object collision handler
 * @param  {Phaser.Input} input   - Input object
 * @param  {Phaser.Sprite} object - Sprite object
 * @param  {function} callback     - function that handles the collision
 * @return {Boolean}              Returns true if an event handler was bound
 */
function inputToObject(input, object, callback) {
  //swap variables if they are in the wrong order
  if (validate.physical(input) && validate.pointer(object)) {
    [input, object] = [object, input];
  }

  if (validate.pointer(input) && validate.physical(object)) {
    object.inputEnabled = true;

    //This will only trigger if the cursor has moved, so if an object moves against the cursor no event will be triggered
    //https://github.com/photonstorm/phaser/issues/1508
    object.events.onInputOver.add(() => callback([object]));

    return true;
  } else {
    return false;
  }
}

/**
 * Binds an Input to Tile collision handler
 * @param  {Phaser.Input} input   - Input object
 * @param  {Number} tile          - Tile index
 * @param  {function} callback     - function that handles the collision
 * @return {Boolean}              Returns true if an event handler was bound
 */
function inputToTile(input, tile, callback) {
  //swap variables if they are in the wrong order
  if (validate.texture(input) && validate.pointer(tile)) {
    [input, tile] = [tile, input];
  }

  if (validate.pointer(input) && validate.texture(tile)) {
    const game = input.game;
    let lastIndex;

    game.input.addMoveCallback((pointer, x, y) => {
      const cursorTile = game.map.getTileWorldXY(x, y);
      const index = cursorTile && cursorTile.index;

      if (index === tile) {
        //make sure to only trigger an event when the tile index changes
        if (index !== lastIndex) {
          callback();
        }
      }

      lastIndex = index;
    });

    return true;
  } else {
    return false;
  }
}
