import * as validate from "./../utils/validate-inputs";
import moment from "moment";
import { string } from "../types/string";

/**
 * Get the current system time
 *
 * @param {Object:App} object -The app object
 * @param {String} format - The format of the time
 */
export default function time(object, format) {
  let time;

  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  format = string(format);

  if (format === "All") {
    time = moment().format("HH:mm:ss");
  } else if (format.includes("Hours")) {
    time =
      format === "Hours: 12 hrs"
        ? moment().format("hh")
        : moment().format("HH");
  } else if (format === "Minutes") {
    time = moment().format("mm");
  } else {
    time = moment().format("ss");
  }

  return time;
}
