import gameChannel from "./../channels/game-channel";
import interfaceChannel from "views/block/channels/interface-channel";
import save from "utils/save";
import settings from "globals/settings";
import { task } from "globals/task";
import setProp from "../utils/object-properties";
import { designActionHistory } from "globals/action-history";

const LAST_ADD_POSITION = { cameraX: 0, cameraY: 0, x: 0, y: 0 };
/**
 * checks whether objects can be selected
 * @param  {Phaser.Game} game The Phaser game
 * @return {Boolean}     true if objects can be selected by the user
 */
export function canSelectObjects(game) {
  if (!game) {
    return false;
  }

  // can't select objects once the task is locked
  if (task.get("locked")) {
    return false;
  }

  //objects are always selectable to content creators
  if (settings.get("editable")) {
    return true;
  }

  return (
    game.model.get("settings").get("edit-design") &&
    game.model.get("settings").get("stage-objects")
  );
}

/**
 * checks whether objects are deletable / clonable
 * @param  {Phaser.Game} game The Phaser game
 * @param  {Model} model The object model
 * @return {Boolean}     true if objects can be deleted / cloned by the user
 */
export function canDeleteObjects(game, model) {
  //objects are always deletable to content creators
  if (settings.get("editable")) {
    return true;
  }
  return (
    canSelectObjects(game) &&
    game.model.get("settings").get("stage-objects-delete") &&
    game.model.get("settings").get(`stage-objects-${model.get("type")}`)
  );
}

/**
 * checks whether objects are moveable
 * @param  {Phaser.Game} game The Phaser game
 * @return {Boolean}     true if objects can be moved by the user
 */
export function canMoveObjects(game) {
  //objects are always draggable to content creators
  if (settings.get("editable")) {
    return true;
  }

  return (
    canSelectObjects(game) &&
    game.model.get("settings").get("stage-objects-move")
  );
}

/**
 * Updates the position of an object Model according to the Sprite's position
 * snaps the x/y position of this model to the grid if needed
 * @this  {Phaser.Sprite}
 */
function position() {
  const snap = this.model.get("snap") || 0;
  const snapX = snap * this.game.map.tileWidth;
  const snapY = snap * this.game.map.tileHeight;

  //snap to grid
  if (snap) {
    const bounds = this.getBounds();
    const x = bounds.x + this.game.camera.x;
    const y = bounds.y + this.game.camera.y;
    this.x = Math.round(x / snapX) * snapX + bounds.width / 2;
    this.y = Math.round(y / snapY) * snapY + bounds.height / 2;
  }

  this.model.set({ x: this.x, y: this.y });
}

/**
 * Sets the next position of an object Model according to the last added position
 * @param  {Model} model The object model
 * @this  {Phaser.Sprite}
 */
async function setNextPosition(model) {
  const game = this.game;
  const camera = game.camera;
  const tileWidth = this.game.model.get("tile-width");
  const tileHeight = this.game.model.get("tile-height");
  const objectWidth = model.getPhaserSpriteSync().width;
  const objectHeight = model.getPhaserSpriteSync().height;

  // reset stacking order when the camera moves
  if (
    camera.x !== LAST_ADD_POSITION.cameraX ||
    camera.y !== LAST_ADD_POSITION.cameraY
  ) {
    LAST_ADD_POSITION.cameraX = camera.x;
    LAST_ADD_POSITION.cameraY = camera.y;
    LAST_ADD_POSITION.x = 0;
    LAST_ADD_POSITION.y = 0;
  }

  let x = objectWidth / 2 + camera.x + LAST_ADD_POSITION.x;
  let y = objectHeight / 2 + camera.y + LAST_ADD_POSITION.y;
  x = Math.ceil(x / tileWidth) * tileWidth;
  y = Math.ceil(y / tileHeight) * tileHeight;

  if (["variable", "text"].includes(model.get("type"))) {
    y += tileHeight / 2;
  }

  model.set({ x, y }, { isSystemChange: true });

  switch (model.get("type")) {
    case "key":
      await createKey(model, this.game);
      break;
  }

  LAST_ADD_POSITION.x += tileWidth;

  if (LAST_ADD_POSITION.x + objectWidth > camera.width) {
    LAST_ADD_POSITION.x = 0;
    LAST_ADD_POSITION.y += tileHeight;
  }

  if (LAST_ADD_POSITION.y + objectHeight > camera.height) {
    LAST_ADD_POSITION.y = 0;
  }

  if (
    this.game.model.get("settings") &&
    this.game.model.get("settings").get("auto-add-object")
  ) {
    interfaceChannel.trigger("add:to:wall", model);
  }
}

async function createKey(object, game) {
  if (!game) {
    return;
  }

  let height = game.model.get("tile-height");
  let width = game.model.get("tile-width");

  let y = object.get("y") - height / 2;
  let x = object.get("x") - width / 2;

  // SPACE-BAR
  if (object.get("keyCode") === 32) {
    x = object.get("x") + width;
    LAST_ADD_POSITION.x += 3 * width;
    width *= 4;
  }

  object.set({ x, y, width, height }, { isSystemChange: true });
}

/**
 * Toggles whether this sprite has input enabled
 * @this  {Phaser.Sprite}
 * @param {Boolean} on - Toggle input on/off
 */
function toggleInput(on) {
  if (on === this.inputEnabled) {
    return;
  }

  this.inputEnabled = Boolean(on);

  if (this.input) {
    this.input.useHandCursor = Boolean(on);
  }
}

/**
 * Toggles whether this sprite can be dragged
 * @this  {Phaser.Sprite}
 * @param {Boolean} on - Toggle draggable on/off
 */
function toggleDraggable(on) {
  if (on === this.draggable) {
    return;
  }

  if (this.input) {
    try {
      if (on) {
        this.input.enableDrag();
      } else {
        this.input.disableDrag();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Could not toggle draggable for ", this);
    }
  }
}

/**
 * Updates the editable state of this Sprite
 * @this  {Phaser.Sprite}
 */
function updateEditableState() {
  const input = canSelectObjects(this.game);
  const move = canMoveObjects(this.game);

  toggleInput.call(this, input);
  toggleDraggable.call(this, input && move);
}

export function drawCamera(bmd) {
  if (!bmd) {
    return;
  }

  const game = bmd.game;
  const width = game.model.get("camera-width");
  const height = game.model.get("camera-height");
  const x = game.model.get("camera-x") - width / 2;
  const y = game.model.get("camera-y") - height / 2;

  bmd.clear();

  if (!game.model.get("camera-enabled")) {
    return;
  }

  // negative fill
  bmd.context.fillStyle = "rgba(0,0,0,0.33)";
  bmd.context.fillRect(0, 0, bmd.width, bmd.height);
  bmd.context.globalCompositeOperation = "destination-out";
  bmd.context.fillStyle = "#fff";
  bmd.context.fillRect(x, y, width, height);
  bmd.context.globalCompositeOperation = "source-over";

  // drop-shadow
  bmd.context.strokeStyle = "#00000055";
  bmd.context.strokeRect(x - 1, y - 1, width + 2, height + 2);
  bmd.context.strokeRect(x + 1, y + 1, width - 2, height - 2);
  bmd.context.strokeRect(
    x + Math.floor(width / 2) - 1,
    y + Math.floor(height / 2) - 17,
    3,
    34,
  );
  bmd.context.strokeRect(
    x + Math.floor(width / 2) - 17,
    y + Math.floor(height / 2) - 1,
    34,
    3,
  );

  // fill
  bmd.context.strokeStyle = "#00F0FF";
  bmd.context.strokeRect(x, y, width, height);
  bmd.context.strokeRect(
    x + Math.floor(width / 2),
    y + Math.floor(height / 2) - 16,
    1,
    32,
  );
  bmd.context.strokeRect(
    x + Math.floor(width / 2) - 16,
    y + Math.floor(height / 2),
    32,
    1,
  );
}

function edit(game) {
  // note: we must do this BEFORE binding any event listeners
  maintainRelativeCameraPosition(this, game);

  const listener = game.listener;
  let originalObject = { ...this.model.toJSON() };

  //set editable state
  updateEditableState.call(this);

  const addToHistory = item => {
    designActionHistory.addItem(item);
    originalObject = this.model.toJSON();
  };

  //listen for a multitude of events and update editable state accordingly
  listener.listenTo(settings, "change:editable", () =>
    updateEditableState.call(this),
  );
  listener.listenTo(
    game.model.get("settings"),
    "change:edit-design change:can-objects change:stage-objects-move",
    () => updateEditableState.call(this),
  );
  listener.listenTo(game.model.get("task"), "change:completed", () =>
    updateEditableState.call(this),
  );

  listener.listenTo(this.model, "set-initial-user-position", () => {
    setNextPosition.call(this, this.model);
  });

  //update model data when the sprite is dragged
  this.events.onDragStop.add(() => {
    position.call(this);
    interfaceChannel.trigger("design-change");
    save({ silent: true });
  }, this);

  this.events.onDragStart.add(() => {
    this.dragStartPosition = this.position.clone();
    interfaceChannel.trigger("design-change");
  }, this);

  this.events.onDragUpdate.add(() => {
    if (
      this.dragStartPosition &&
      Math.abs(this.x - this.dragStartPosition.x) +
        Math.abs(this.y - this.dragStartPosition.y) >
        5
    ) {
      // if we drag at least 5px, clear the holdTimeout
      clearTimeout(this.onHoldTimeout);
    }
  }, this);

  // prevent contextMenu
  this.game.canvas.oncontextmenu = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  //select sprite on click
  this.events.onInputDown.add(async (canvasObject, pointer) => {
    if (!pointer.isMouse) {
      selectObject(this);
      // touch - hold down for 600ms to open menu
      this.onHoldTimeout = setTimeout(async () => {
        openContextMenu(game, pointer, this.model);
        clearTimeout(this.onHoldTimeout);
      }, 600);
    } else if (pointer.rightButton.isDown) {
      // right click - open menu immediately
      openContextMenu(game, pointer, this.model);
    } else {
      selectObject(this);
    }
  });

  this.events.onInputUp.add(() => {
    clearTimeout(this.onHoldTimeout);
  });

  // listen for changes on the model and reflect these on the sprite
  listener.listenTo(
    this.model,
    "change:width change:height change:x change:y change:visible change:gravity change:angle change:objectName change:name",
    (e, i, options) => {
      Object.entries(e.changed).forEach(pair =>
        setProp.call(this, pair[0], pair[1]),
      );
      if (options?.isSystemChange) {
        originalObject = this.model.toJSON();
      } else {
        addToHistory(getActionItem(this.model, originalObject));
      }
      interfaceChannel.trigger("design-change");
    },
  );

  listener.listenTo(
    this.model,
    "change:text change:bold change:italic change:underlined change:color change:text-shadow change:text-glow",
    (e, i, options) => {
      if (options?.isSystemChange) {
        originalObject = this.model.toJSON();
      } else {
        addToHistory(getActionItem(this.model, originalObject));
      }
      interfaceChannel.trigger("design-change");
    },
  );

  // if snap is toggled on - immediately snap to the grid
  listener.listenTo(this.model, "change:snap", val => {
    if (val) {
      position.call(this);
    }
  });
}

function maintainRelativeCameraPosition(sprite, game) {
  sprite.model.set({
    relativeCameraXPosition:
      sprite.model.get("x") -
      game.model.get("camera-x") +
      game.model.get("camera-width") / 2,
    relativeCameraYPosition:
      sprite.model.get("y") -
      game.model.get("camera-y") +
      game.model.get("camera-height") / 2,
  });

  game.listener.listenTo(
    sprite.model,
    "change:relativeCameraXPosition",
    (e, i, options = {}) => {
      if (options.isSystemChange) {
        return;
      }
      sprite.model.set({
        x:
          sprite.model.get("relativeCameraXPosition") +
          game.model.get("camera-x") -
          game.model.get("camera-width") / 2,
      });
    },
  );

  game.listener.listenTo(
    sprite.model,
    "change:relativeCameraYPosition",
    (e, i, options = {}) => {
      if (options.isSystemChange) {
        return;
      }
      sprite.model.set({
        y:
          sprite.model.get("relativeCameraYPosition") +
          game.model.get("camera-y") -
          game.model.get("camera-height") / 2,
      });
    },
  );

  game.listener.listenTo(sprite.model, "change:x", async () => {
    // a delay is needed here to let the normal event handler happen before we update the relative position
    await new Promise(r => requestAnimationFrame(r));
    sprite.model.set(
      {
        relativeCameraXPosition:
          sprite.model.get("x") -
          game.model.get("camera-x") +
          game.model.get("camera-width") / 2,
      },
      { isSystemChange: true },
    );
  });

  game.listener.listenTo(sprite.model, "change:y", async () => {
    // a delay is needed here to let the normal event handler happen before we update the relative position
    await new Promise(r => requestAnimationFrame(r));
    sprite.model.set(
      {
        relativeCameraYPosition:
          sprite.model.get("y") -
          game.model.get("camera-y") +
          game.model.get("camera-height") / 2,
      },
      { isSystemChange: true },
    );
  });
}

async function openContextMenu(game, pointer, model) {
  if (!game) {
    return;
  }
  await new Promise(r => setTimeout(r, 0));
  interfaceChannel.trigger("context-menu:open", pointer, model);
}

// select an object if it isn't being dragged
function selectObject(obj) {
  gameChannel.trigger("object-selected", obj.model);
}

function getActionItem(model, originalObject) {
  const actionItem = {
    designModel: model,
    type: "design-change",
    from: originalObject,
    to: model.toJSON(),
  };
  return actionItem;
}

export default function (objects) {
  objects.forEach(object => edit.call(object, this));
}
