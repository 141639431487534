import CodeBlock from "./block-prototype";
import BlockContentsView from "./components/block-contents";
import BlockScopeView from "./components/scope";

export default CodeBlock.extend({
  tagName: "event-block",
  className: CodeBlock.prototype.className + " control-block",
  template: require("templates/block/block-types/event.hbs"),
  initialize: function () {
    CodeBlock.prototype.initialize.call(this);
    this.$el.attr("type", this.model.get("fn"));
    this.defineChildView(
      function () {
        return new BlockContentsView({
          model: this.model,
        });
      }.bind(this),
      "args",
      "> control-block-head>control-section-body",
    );

    this.defineChildView(
      function () {
        return new BlockScopeView({
          model: this.model.get("code"),
          className: BlockScopeView.prototype.className + " vertical code",
        });
      }.bind(this),
      "code",
      "> control-block-scope>control-section-scope",
    );
  },
});
