import Backbone from "custom/backbone-bundle";
import { generateUUID } from "utils/generate-uuid";
import { ingestPayloadTranslations } from "utils/localisation";

export default Backbone.RelationalModel.extend({
  initialize: function (options) {
    Backbone.RelationalModel.prototype.initialize.apply(this, [options]);

    if (!this.id) {
      this.set("id", generateUUID());
    }
  },

  async fetch() {
    // this model has already been fetched
    if (this.fetched) {
      return;
    }

    const fetch = Backbone.RelationalModel.prototype.fetch;

    await new Promise((resolve, reject) =>
      fetch.call(this, { success: resolve, error: reject }),
    );
    this.fetched = true;
  },

  parse(payload) {
    if (payload._id) {
      payload.id = payload._id;
      delete payload._id;
    }

    ingestPayloadTranslations(payload);
    return payload;
  },
});
