var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"button-group\">\n  <button\n    class=\"zoom-out comet-button\"\n    aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom out",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":40}}}))
    + "\"\n    title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom out",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":35}}}))
    + "\"\n    tabindex=\"0\"\n  >\n    <svg alt=\"\">\n      <use xlink:href=\"#comet_minus\"></use>\n    </svg>\n  </button>\n\n  <div class=\"comet-button zoom-level\" role=\"text\" tabindex=\"0\"></div>\n\n  <button\n    class=\"zoom-in comet-button\"\n    title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom in",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":11},"end":{"line":17,"column":34}}}))
    + "\"\n    aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom in",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":39}}}))
    + "\"\n    tabindex=\"0\"\n  >\n    <svg alt=\"\">\n      <use xlink:href=\"#comet_plus\"></use>\n    </svg>\n  </button>\n</div>\n\n<button\n  class=\"zoom-fit comet-button\"\n  title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom to fit",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":9},"end":{"line":29,"column":36}}}))
    + "\"\n  aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Zoom to fit",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":14},"end":{"line":30,"column":41}}}))
    + "\"\n  tabindex=\"0\"\n>\n  <svg alt=\"\">\n    <use xlink:href=\"#zoom-to-fit\"></use>\n  </svg>\n</button>";
},"useData":true});