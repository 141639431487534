import Modal from "../modal";
import { AppNamePlugin } from "./../shared/app-name";
import { task } from "globals/task";
import { clearHistory } from "globals/action-history";

import Task from "models/task";
import MetaData from "models/metadata";
import BLANK_BLOCK from "fixtures/task-types/block-blank";

import { Preloader } from "views/layouts/preloader/preloader";
import style from "./create-app-inline.styl";
import interfaceChannel from "views/block/channels/interface-channel";

style.use();

export const CreateAppInlineModal = Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "create-app-inline with-banner"].join(
    " ",
  ),

  template: require("./create-app-inline.hbs"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "click .create-app": "createApp",
        "submit .create-app-form": "createApp",
        "click .go-to-freecoder": "goToFreeCoder",
      },
      Modal.prototype.events,
    ),
  ),

  initialize() {
    Modal.prototype.initialize.call(this);
    this.__preloader = new Preloader(document.createElement("div"));
  },

  async open() {
    this.isSaved = false;
    this.isSaving = false;
    await Modal.prototype.open.call(this);
  },

  render() {
    const meta = task.get("metadata");
    meta.generateThumbnailData();

    this.$el.html(
      this.template({
        banner:
          meta?.toJSON()?.thumbnail?.raw_data ||
          meta?.toJSON()?.thumbnail?.src ||
          null,
        loading: this.isSaving,
        saved: this.isSaved,
        isCreateApp: true,
      }),
    );

    this.$(".loader-container").append(this.__preloader.el);
    this.delegateEvents();

    if (!this.isSaved) {
      this.checkName();
    }
  },

  goToFreeCoder() {
    clearHistory();
    interfaceChannel.trigger("proceed-to-free-coder");
  },

  /**
   * Creates a new user app or exports to free code depending on SAVE flag
   */
  async createApp(e) {
    e.preventDefault();
    const name = this.getName();

    this.__preloader.define(
      "generate thumb",
      "copy",
      "new instance",
      "ensure components",
      "set blank",
      "set grade",
      "paste",
      "fork",
    );
    this.__preloader.reset(
      "generate thumb",
      "copy",
      "new instance",
      "ensure components",
      "set blank",
      "set grade",
      "paste",
      "fork",
    );
    this.isSaving = true;
    this.render();

    const backup = task.toJSON();
    const metadata = new MetaData();
    await this.__preloader.complete("generate thumb");
    metadata.prepFork(task);

    task
      .getComponent("models/block")
      .getBlocks()
      .forEach(block => {
        block.set({ locked: false, editable: true, critical: false });
      });

    task.copy({
      coder: true,
      wall: false,
      metadata: metadata.toJSON(),
      "background-code": false,
      grade: true,
    });
    await this.__preloader.complete("copy");
    const userApp = new Task();
    await this.__preloader.complete("new instance");
    await userApp._ensureComponents(BLANK_BLOCK);
    await this.__preloader.complete("ensure components");
    userApp.set(BLANK_BLOCK);
    await this.__preloader.complete("set blank");
    userApp.setGrade("all");
    await this.__preloader.complete("set grade");
    userApp.paste({});
    await this.__preloader.complete("paste");
    userApp.clearClipboard();
    await userApp.fork(name);
    await this.__preloader.complete("fork");

    // because the process of forking has destructive effects on the original,
    // we need to restore it back to its original state
    // note: the destructive effects comes from how backbone relational handles
    // items with unique ID's, by removing them from their original parents
    // instead of creating new copies
    task.set(backup);

    this.isSaving = false;
    this.isSaved = true;

    clearHistory();

    this.render();
  },
});
