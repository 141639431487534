import Modal from "../modal";
import { CodeCanvasView } from "../../code/canvas";
import { task } from "globals/task";

import style from "./block-view-solution.styl";
style.use();

export default Modal.extend({
  className: [Modal.prototype.className, "block-view-solution"].join(" "),

  template: require("./block-view-solution.hbs"),

  render() {
    if (this.canvasView) {
      this.canvasView.$el.detach();
    }

    const model = task.getComponent("models/block")?.get("solution-code");
    this.canvasView = new CodeCanvasView({
      model,
      className: [CodeCanvasView.prototype.className, "input"].join(" "),
    });
    this.canvasView.render();

    this.$el.html(this.template({}));

    this.$(".main").append(this.canvasView.el);
  },

  async open() {
    await Modal.prototype.open.call(this);
    if (this.canvasView) {
      await this.canvasView.zoomToFit();
    }
  },

  remove() {
    if (this.canvasView) {
      this.canvasView.remove();
    }
    Modal.prototype.remove.call(this);
  },
});
