import PrototypeModel from "models/prototype-model";
import { hasUrlParam } from "utils/get-url-param";
import { getApiUrl } from "utils/get-api-url";
import { checkFlag } from "utils/flags";
import checkStorage from "utils/check-storage";

export default PrototypeModel.extend({
  url() {
    const newUser = document.referrer.indexOf(location.origin) !== 0;
    return getApiUrl(newUser ? "user?new=1" : "user");
  },

  isContentCreator: function () {
    // grab query param and move it to sessionstorage
    if (checkStorage()) {
      if (hasUrlParam("readonly")) {
        sessionStorage.setItem("coding-readonly-session", "readonly");
      }

      // if we're in a readonly session always return false
      // impersonating a user
      if (sessionStorage.getItem("coding-readonly-session") === "readonly") {
        return false;
      }
    }
    // otherwise use user role
    return Boolean(this.has("role") && this.get("role").editorial);
  },

  listRoles() {
    const roles = this.get("role") || {};
    const list = [];
    for (const role in roles) {
      if (roles[role]) {
        list.push(role);
      }
    }
    list.sort();
    return list;
  },

  /**
   * Checks whether user is in a shared account
   * Public users should behave as they are in shared account
   */
  isSharedAccount() {
    // Public users don't have user object
    if (!this.get("user")) {
      return true;
    } else {
      return this.get("user").isSharedAccount;
    }
  },

  /**
   * Checks whether user is impersonating or not
   */
  isImpersonating() {
    return this.get("user")?.isImpersonating;
  },

  /**
   * Checks whether user progress can be loaded
   */
  canLoadUserProgress() {
    return (
      checkFlag("SAVE_LESSON_STATE") &&
      !this.isSharedAccount() &&
      !this.isContentCreator()
    );
  },

  /**
   * Checks whether user progress can be saved
   *
   * NOTE: When impersonating, save state can be loaded (so the teacher can see
   * what the user has done), but should NOT update
   */
  canSaveUserProgress() {
    return this.canLoadUserProgress() && !this.isImpersonating();
  },

  /**
   * Returns the url of a user link
   * E.g. `product`, `platform` ...
   */
  getLink(name) {
    if (!this.has("links")) {
      return null;
    }

    const link = this.get("links")[name];

    if (!(link && link.url)) {
      return null;
    }

    const url = link.url || "/";

    if (/^https?:\/\//.test(url)) {
      return url;
    } else {
      return window.process.env.WEBSITE_URL + url;
    }
  },
});
