import _ from "underscore";
import collisionFilter from "./../creation/collision-filter";
import collisionHandler from "./../creation/collision-handler";
import flip from "./../methods/flip";
import onGround from "./../operators/on-ground";

export function enablePhysicsAll(game) {
  game.physics.startSystem(Phaser.Physics.ARCADE);
  game.onCloned = new Phaser.Signal();
  game.objectGroup.forEach(object => {
    initializePhysics(game, object);
    enablePhysics(game, object);
  });
}

export function initializePhysics(game, sprite) {
  if (!sprite.model.isPhysical()) {
    return; // non-physical sprite (for instance: sound)
  }

  // enable arcade physics - this will allow us to set velocity and trigger collisions
  game.physics.enable(sprite, Phaser.Physics.ARCADE);

  sprite.body.collideWorldBounds = game.model.get("collide-bounds");

  // create signals
  sprite.collisionSignal = new Phaser.Signal();
  sprite.onBeforeStep = new Phaser.Signal();
  sprite.onAfterStep = new Phaser.Signal();

  sprite.speed = 0;
  sprite.heading = 0;
  sprite.friction = 0;
  sprite.acceleration = 0;
  sprite.gravity = 0;
  sprite.jumpFrame = 0;
  sprite.alignment = sprite.previousAlignment = "center";
  sprite.__hasBeenOnStage = false;

  sprite.maxSpeed = game.model.get("max-speed");

  sprite.currentOverlaps = [];
  sprite.previousOverlaps = [];
  sprite["collides-with"] = [];
}

export async function enablePhysics(game, sprite) {
  if (!sprite.model.isPhysical()) {
    return; // non-physical sprite (for instance: sound)
  }

  // we need to wait until the next game cycle before actually enabling the
  // physics of this object. We do this so that the game has the time to
  // correctly calculate the bounds of this new object
  await new Promise(r => requestAnimationFrame(r));

  // override update function
  sprite.update = update;
}

const overlapHandler = function (a, b) {
  b = b instanceof Phaser.Tile ? b.index : b;

  if (!this.currentOverlaps.includes(b)) {
    this.currentOverlaps.push(b);
  }
};
const overlapFilter = function () {
  return true;
};

// updates the heading and speed of an object based on a vector
function applyHeadingSpeed(object, vector) {
  object.speed = vector.getMagnitude();

  // adjust heading according to speed, but only if we have a significantly large speed
  const speed = Math.abs(Math.round(object.speed * 100) / 100);
  if (speed > 0) {
    object.heading = vector.angle(new Phaser.Point(0, 0), true) - 180;
  }
}

function update() {
  const game = this.game;
  const body = this.body;

  this.currentOverlaps = [];
  this.previousOverlaps = this.previousOverlaps || [];

  if (game.model.get("stage-wrap")) {
    game.world.wrap(this, 0, false);
  }

  // object - object collisions
  game.physics.arcade.collide(
    this,
    game.objectGroup,
    collisionHandler,
    collisionFilter,
    game,
  );
  // object - tile collisions
  game.collisionLayersIndexed.forEach(layer =>
    game.physics.arcade.collide(
      this,
      layer,
      collisionHandler,
      collisionFilter,
      game,
    ),
  );

  // overlaps - checks for objects overlapping but does not separate them
  game.physics.arcade.overlap(
    this,
    game.objectGroup,
    overlapHandler,
    overlapFilter,
    this,
  );
  game.collisionLayersIndexed.forEach(layer =>
    game.physics.arcade.overlap(
      this,
      layer,
      overlapHandler,
      overlapFilter,
      this,
    ),
  );

  // only fire collision events for new overlaps
  _.difference(_.unique(this.currentOverlaps), this.previousOverlaps).forEach(
    collisionHandler.bind(this, this),
  );

  // store overlaps so we can compare them in the next frame
  this.previousOverlaps = this.currentOverlaps;

  // apply object physics
  if (isTopdown(this)) {
    applyTopDownPhysics.call(this);
  } else {
    applyPlatformPhysics.call(this);
  }

  // update sprite orientation
  // orient or flip according to motion
  if (this["align-to-direction"]) {
    if (this["is-topdown"]) {
      this.angle = this.heading;
    } else {
      // We don't care about velocities smaller than 0.01
      const velocity = Math.round(body.velocity.x * 100) / 100;
      if (velocity !== 0) {
        flip(this, {
          x: velocity,
          y: 1,
          type: "vector",
        });
      }
    }
  }

  // update the anchor based on sprite alignment
  // we also adjust the sprite position according to its bounding box so that its position is maintained despite the new anchor
  if (this.previousAlignment !== this.alignment) {
    const bounds = this.getBounds();
    switch (this.alignment) {
      case "top":
        this.x = bounds.centerX;
        this.y = bounds.top;
        this.anchor.setTo(0.5, 0);
        break;
      case "bottom":
        this.x = bounds.centerX;
        this.y = bounds.bottom;
        this.anchor.setTo(0.5, 1);
        break;
      case "left":
        this.x = bounds.left;
        this.y = bounds.centerY;
        this.anchor.setTo(0, 0.5);
        break;
      case "right":
        this.x = bounds.right;
        this.y = bounds.centerY;
        this.anchor.setTo(1, 0.5);
        break;
      case "top-left":
        this.x = bounds.left;
        this.y = bounds.top;
        this.anchor.setTo(0, 0);
        break;
      case "top-right":
        this.x = bounds.right;
        this.y = bounds.top;
        this.anchor.setTo(1, 0);
        break;
      case "bottom-left":
        this.x = bounds.left;
        this.y = bounds.bottom;
        this.anchor.setTo(0, 1);
        break;
      case "bottom-right":
        this.x = bounds.right;
        this.y = bounds.bottom;
        this.anchor.setTo(1, 1);
        break;
      default:
        this.x = bounds.centerX;
        this.y = bounds.centerY;
        this.anchor.setTo(0.5, 0.5);
    }
  }

  this.previousAlignment = this.alignment;
  this.previousPosition = this.position.clone();

  if (!this.__hasBeenOnStage && this.inWorld) {
    this.__hasBeenOnStage = true;
  }
}

// applies top-down physics to an object
// these are objects not affected by gravity that use heading/speed for their movement
function applyTopDownPhysics() {
  const game = this.game;
  const body = this.body;
  const delta = game.time.delta / 1000;
  const scale = game.model.get("physics-scale");

  // change speed to a positive value but keep track of whether it was negative
  // this simplifies acceleration and friction calculations
  const negative = this.speed < 0;
  this.speed = Math.abs(this.speed);

  // apply acceleration
  if (this.acceleration > 0) {
    this.speed += this.acceleration * delta;
    // otherwise apply friction
  } else if (this.friction > 0) {
    this.speed -= this.friction * delta;
  }

  // round speed to nearest two decimals
  this.speed = Math.round(this.speed * 100) / 100;

  // prevent speed from going below 0
  if (this.speed < 0) {
    this.speed = 0;
  }

  // change speed back to a negative value if needed
  if (negative) {
    this.speed = -this.speed;
  }

  // calculate direction
  const direction = new Phaser.Point(1, 0);
  direction.setMagnitude(Number(this.speed));
  direction.rotate(0, 0, Number(this.heading), true);

  // clamp direction magnitude
  if (direction.getMagnitude() > this.maxSpeed) {
    direction.setMagnitude(this.maxSpeed);
  }

  applyHeadingSpeed(this, direction);

  // respect negative speeds
  if (negative) {
    this.speed = -this.speed;
    this.heading = Phaser.Math.wrapAngle(this.heading + 180);
  }

  // scale magnitude
  direction.setMagnitude(direction.getMagnitude() * scale);

  // apply direction to velocity
  body.velocity.setTo(direction.x, direction.y);

  // apply maximum speed
  body.maxVelocity.setTo(this.maxSpeed * scale, this.maxSpeed * scale);
}

// applies platformer physics to an object
// platformer objects are affected by gravity and can move sideways or jump
// they ignore heading/speed
function applyPlatformPhysics() {
  const game = this.game;
  const body = this.body;
  const scale = game.model.get("physics-scale");
  const gravity = this.gravity * scale;
  const jumpStrength = game.model.get("default-jump-speed") * scale;

  // apply gravity & drag
  body.gravity.setTo(0, gravity);
  body.drag.setTo(this.friction * scale, 0);

  // for platformer physics we allow a much larger y velocity
  body.maxVelocity.setTo(this.maxSpeed * scale, this.maxSpeed * scale * 10);

  // jump
  if (this.initiateJump && onGround(this) && !this.jumpFrame) {
    body.velocity.y = -(jumpStrength || gravity / 2);

    this.jumpFrame = 10;
  }

  // decay jump frames
  if (this.jumpFrame > 0) {
    this.jumpFrame -= 1;
  }

  this.initiateJump = false;

  // keep track of heading and speed
  // even though they aren't used by this object, user code may want to access the values
  applyHeadingSpeed(this, body.velocity);
}

// determines whether an object is top-down or side-view
// different physics will be applied depending on this
export function isTopdown(object) {
  return !object.gravity;
}

// determines whether this object is a platformer object
// (inverse of isTopdown)
export function isPlatformer(object) {
  return !isTopdown(object);
}
