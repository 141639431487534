import $ from "jquery";

/**
 * This utility functions pulls all of the code blocks out of the code chest onto a white background for easy printing
 *
 * WARNING!! This is non-reversible and only intended for internal use
 */
export async function printBlocks() {
  if (
    !window.confirm(
      `
      ✔️ Make sure you have added all dynamic objects/keys/variables to the stage first.
      ✔️ Make sure the code-chest is open.

      Continue?`,
    )
  ) {
    return;
  }

  $(".add-code.panel-toggle-button").trigger("click");
  await new Promise(r => setTimeout(r, 100));

  const $el = $(".code-chest .categories");
  $el.addClass("orphaned-code-blocks");
  $el.detach().appendTo(document.body);

  const style = document.createElement("style");
  document.body.appendChild(style);

  style.innerHTML = `
        .orphaned-code-blocks {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            background: #fff;
            padding: 16px;
        }

        .orphaned-code-blocks .category {
            margin: 16px;
            break-inside: avoid;
        }
        .orphaned-code-blocks .block-bucket > .hidden {
            display: none;
        }
        .orphaned-code-blocks .block-bucket > .super-block {
            margin: 16px;
        }
        .block-bucket {
          display: flex;
          max-width: 800px;
          flex-wrap: wrap;
        }
    `;
}
