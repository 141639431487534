var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<control-block-footer class=\"block-section drag-handle\">\r\n  <control-section-head class=\"block-partial\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-bottom-left.hbs"),depth0,{"name":"partial-shapes/c-block-bottom-left","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-section-head>\r\n  <control-section-body class=\"block-partial\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-bottom-middle.hbs"),depth0,{"name":"partial-shapes/c-block-bottom-middle","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <control-section-content>\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </control-section-content>\r\n  </control-section-body>\r\n  <control-section-tail class=\"block-partial\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-bottom-right.hbs"),depth0,{"name":"partial-shapes/c-block-bottom-right","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-section-tail>\r\n</control-block-footer>\r\n";
},"usePartial":true,"useData":true});