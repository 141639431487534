import { checkFlag } from "utils/flags";
import { task } from "globals/task";

export default function save(options = {}) {
  //don't save unless auto-save is turned on
  if (!checkFlag("AUTO_SAVE")) {
    return;
  }

  options.silent = true; //since the server is doing no validation, we don't need to notify anyone when a save happens

  task.save(null, options);
}
