import PrimitiveBlock from "./../primitive";

// this model represents the turn primitive
export default PrimitiveBlock.extend({
  getPseudoCode() {
    return `${this.get("value")}°`;
  },

  getPrimitiveValue() {
    return Number(this.get("value"));
  },

  getToodalOptions() {
    return {
      title: "Turn",
      style: "turn",
      cacheKey: "presets:turn",
      blocks: [
        { value: 10 },
        { value: -10 },
        { value: null, "editorial-only": true },
      ].map(option => {
        return Object.assign(option, { type: "turn" });
      }),
    };
  },
});
