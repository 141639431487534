import Backbone from "custom/backbone-bundle";
import translate from "utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";
import style from "./add-object-button.styl";
style.use();

export const AddObjectButton = Backbone.View.extend({
  tagName: "button",
  className: "add-object-button",
  template: require("./add-object-button.hbs"),

  attributes() {
    const category = translate(this.model.get("category"));
    const label = translate("Add {item}").replace("{item}", category);

    return {
      title: label,
      "aria-label": label,
      tabindex: 0,
    };
  },

  events: {
    click: "onClick",
  },

  initialize() {
    this.model.view = this;
    this.render();
  },

  onClick() {
    interfaceChannel.trigger(
      "panel-manager:panel:open:block-design-add-object-panel",
    );

    interfaceChannel.trigger("add-object-panel:go-to", this.model.get("goTo"));
  },

  async render() {
    this.$el.html(this.template({}));
  },
});
