import * as validate from "./../utils/validate-inputs";
import { positiveNumber } from "./../types/number";

/**
 * This block will _sleep_ for a specific number of seconds, causing subsequent
 * blocks to wait until this one wakes back up.
 *
 * @async
 * @param {Object} object - The target object
 * @param {Number} delay - The time to wait in seconds
 */
export default async function wait(object, delay) {
  try {
    delay = positiveNumber(delay);
  } catch (e) {
    throw Error(validate.error.time);
  }

  await new Promise(resolve => {
    this.time.events.add(Phaser.Timer.SECOND * delay, () => resolve());
  });
}
