import { unpack } from "./variable";

/**
 * Cast a value to a boolean
 *
 * @param {any} val - Input value
 * @return {Boolean}
 */
export function boolean(val) {
  val = unpack(val);

  if (val === "true") {
    return true;
  } else if (val === "false") {
    return false;
  } else {
    return Boolean(val);
  }
}
