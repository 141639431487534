import Backbone from "custom/backbone-bundle";
import "bootstrap-3.3.7";

import AppInfo from "./app-info/app-info";
import AppReadme from "./app-readme/app-readme";
import CreateApp from "./create-app/create-app";
import CustomPythonInput from "./custom-python-input/custom-python-input";
import ForkApp from "./fork-app/fork-app";
import GoToMyApps from "./goto-my-apps/goto-my-apps";
import GoToEmbeddedApps from "./goto-embedded-apps/goto-embedded-apps";
import LessonHelpVideo from "./lesson-help-video/lesson-help-video";
import LoadFromDeviceFailedModal from "./load-from-device-failed/load-from-device-failed";
import NewApp from "./new-app/new-app";
import SaveAs from "./save-as/save-as";
import StageSize from "./stage-size/stage-size";
import RenameApp from "./rename-app/rename-app";
import SaveFailure from "./save-failure/save-failure";
import SavedAs from "./saved-as/saved-as";
import UploadConfirm from "./upload-confirm/upload-confirm";
import ShareUrl from "./share-url/share-url";
import Reset from "./reset/reset";
import GlossaryTerm from "./glossary-term/glossary-term";
import EditorialTools from "./editorial-tools/editorial-tools";
import SetTaskType from "./set-task-type/set-task-type";
import DownloadAs from "./download-as/download-as";
import ExternalLink from "./external-link/external-link";
import OpenExternalLink from "./open-external-link/open-external-link";
import { OpenAppModal } from "./open-app/open-app";
import { CreateAppInlineModal } from "./create-app-inline/create-app-inline";
import { StepAnswerTextModal } from "./step-answer-text/step-answer-text";
import BlockViewSolution from "./block-view-solution/block-view-solution";
import NoSolution from "./no-solution/no-solution";
import { ChooseLevelModal } from "./choose-level/choose-level";
import { LoadFromDeviceModal } from "./load-from-device/load-from-device";

import interfaceChannel from "views/block/channels/interface-channel";
import translate from "utils/localisation";

const ModalManager = Backbone.View.extend({
  className: "coding-modals",
  style: require("./modal.styl"),

  initialize() {
    this.style.use();
    this.$el.attr("dir", translate("dir"));
    this.modals = {};
    this.listenTo(interfaceChannel, "request:modal", (...args) =>
      this.openModal(...args),
    );
    this.listenTo(interfaceChannel, "close:modal", (...args) =>
      this.closeModal(...args),
    );
  },

  _getModalConstructor(name) {
    switch (name) {
      case "app-info":
        return AppInfo;
      case "app-readme":
        return AppReadme;
      case "block-view-solution":
        return BlockViewSolution;
      case "create-app":
        return CreateApp;
      case "create-app-inline":
        return CreateAppInlineModal;
      case "custom-python-input":
        return CustomPythonInput;
      case "fork-app":
        return ForkApp;
      case "goto-my-apps":
        return GoToMyApps;
      case "goto-embedded-apps":
        return GoToEmbeddedApps;
      case "load-from-device":
        return LoadFromDeviceModal;
      case "load-from-device-failed":
        return LoadFromDeviceFailedModal;
      case "lesson-help-video":
        return LessonHelpVideo;
      case "new-app":
        return NewApp;
      case "open-app":
        return OpenAppModal;
      case "open-external-link":
        return OpenExternalLink;
      case "save-as":
        return SaveAs;
      case "stage-size":
        return StageSize;
      case "rename-app":
        return RenameApp;
      case "save-failure":
        return SaveFailure;
      case "saved-as":
        return SavedAs;
      case "upload-confirm":
        return UploadConfirm;
      case "share-url":
        return ShareUrl;
      case "reset":
        return Reset;
      case "glossary-term":
        return GlossaryTerm;
      case "editorial-tools":
        return EditorialTools;
      case "set-task-type":
        return SetTaskType;
      case "step-answer-text":
        return StepAnswerTextModal;
      case "download-as":
        return DownloadAs;
      case "external-link":
        return ExternalLink;
      case "choose-level":
        return ChooseLevelModal;
      case "no-solution":
        return NoSolution;
    }
  },

  _construct(name) {
    const Constructor = this._getModalConstructor(name);

    if (!Constructor) {
      throw new Error(`Modal ${name} does not exist.`);
    }

    const modal = new Constructor();
    this.$el.append(modal.el);
    this.modals[name] = modal;

    return modal;
  },

  // returns an existing modal or creates a new one
  _getModal(name) {
    if (this.modals[name]) {
      return this.modals[name];
    } else {
      return this._construct(name);
    }
  },

  async closeModal(name, ...args) {
    const modal = this._getModal(name);
    await modal.close(...args);
  },

  async openModal(name, ...args) {
    interfaceChannel.trigger("modal-open");
    const modal = this._getModal(name);
    await modal.open(...args);
  },
});

// singleton view
export const modalManager = new ModalManager();
