import Backbone from "custom/backbone-bundle";

export const ActiveLayout = Backbone.Model.extend({
  defaults: {
    layout: null,
    panels: [],
  },
  initialize() {
    // no-op
    // We don't want a default ID to be set when this model initializes
  },
  addPanel(config) {
    const layout = this.get("layout");
    const panels = this.get("panels");

    // we are interested in side panels only
    if (layout?.side?.indexOf(config.name) > -1) {
      panels.push({
        config: config,
      });
      this.set("panels", panels);
    }
  },
  removePanel(config) {
    this.set(
      "panels",
      this.get("panels").filter(p => p.config.name !== config.name),
    );
  },
  clear() {
    this.set({
      layout: null,
      panels: [],
    });
  },
  updatePanel(name, data) {
    const panels = this.get("panels");
    this.set(
      "panels",
      panels.map(panel =>
        panel.config.name === name ? { ...panel, ...data } : panel,
      ),
    );
  },
});
