var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <button class=\"type-button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":61}}})) != null ? stack1 : "")
    + " data-index=\""
    + alias3(alias2((data && lookupProperty(data,"index")), depth0))
    + "\" tabindex=\"0\" autofocus\n    aria-label=\""
    + alias3(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":35}}}))
    + "\" aria-disabled=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":92}}})) != null ? stack1 : "")
    + "\">\n    <svg alt=\"\">\n      <use xlink:href=\"#"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></use>\n    </svg>\n    <span>"
    + alias3(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":29}}}))
    + "</span>\n  </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"4":function(container,depth0,helpers,partials,data) {
    return "true";
},"6":function(container,depth0,helpers,partials,data) {
    return "false";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"type-filters\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"buttons") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + "</section>\n";
},"useData":true});