import ERRORS from "./../../blocks/errors";

import { number, positiveNumber } from "./../types/number";
import { angle } from "./../types/angle";
import { string } from "./../types/string";
import { boolean } from "./../types/boolean";

import { unTranslate } from "utils/localisation";

import { validateObjectName } from "./object-name";
import { getPointer } from "./get-pointer";
import { stopCurrentTween } from "../methods/step-move";

/**
 * Get or Set a property
 *
 * @param {String} key - the property to set or get
 * @param {*} [val] - (optional) the value to set the property to
 *
 * Note: val CAN be an array of values instead of a single value.
 * The validation functions need to be able to decide what to do with this.
 * In most cases, just the first value in the list is used
 */
export default function (key, val) {
  // if the property doesn't exist but it's untranslated version does, use that instead
  if (!(key in OBJECT_PROPERTIES) && unTranslate(key) in OBJECT_PROPERTIES) {
    key = unTranslate(key);
  }

  // value is protected and cannot be changed/accessed
  if (isProtected(key)) {
    throw new Error(ERRORS.RUNTIME_PROTECTED_PROPERTY);
  }

  if (isCustom(key)) {
    // if the key is not recognized, use a simple getter/setter into a safe area (__custom)
    if (arguments.length === 1) {
      return this.__custom[key] || 0;
    } else {
      return (this.__custom[key] = val);
    }
  } else {
    // otherwise use advanced validation
    if (arguments.length === 1) {
      return get.call(this, key);
    } else {
      return set.call(this, key, val);
    }
  }
}

/**
 * Get a property
 *
 * @param {String} key - the property to get
 */
function get(key) {
  const validator = OBJECT_PROPERTIES[key];

  if (!validator) {
    return this[key];
  } else if (validator.get) {
    return validator.get.call(this);
  } else {
    return validator.call(this, this[key]);
  }
}

/**
 * Set a property
 *
 * @param {String} key - the property to set
 * @param {*} val - the value to set the property to
 */
function set(key, val) {
  const validator = OBJECT_PROPERTIES[key];

  if (!validator) {
    return (this[key] = val);
  } else if (validator.set) {
    return validator.set.call(this, val);
  } else {
    return (this[key] = validator.call(this, val));
  }
}

/**
 * Check whether a key is protected
 * @param {String} key - the property to check
 */
export function isProtected(key) {
  return OBJECT_PROPERTIES[key] === false;
}

/**
 * Check whether this is a custom key
 * @param {String} key - the property to check
 */
export function isCustom(key) {
  return !Object.prototype.hasOwnProperty.call(OBJECT_PROPERTIES, key);
}

/**
 * Object properties
 * This list defines all properties of our stage objects
 *
 * false:     protected, attempting to change this will result in an error
 * true:      value can be changed without validation
 * function:  value can be changed and will use function to validate prior to setting
 * object:    object with a set&get property to serve as custom setter and getter
 * undefined: if the value is not listed, then it will be stored into the `__custom` sub-object to prevent it possibly interfering with Phaser
 */
export const OBJECT_PROPERTIES = {
  __custom: false,

  acceleration: positiveNumber,

  "align-to-direction": boolean,

  alignment: string,

  alpha: number,

  angle: angle,

  angularVelocity: {
    get: function () {
      return (
        number(this.body.angularVelocity) / this.game.model.get("physics-scale")
      );
    },
    set: function (val) {
      this.body.angularVelocity =
        number(val) * this.game.model.get("physics-scale");
    },
  },

  frame: {
    get: function () {
      return Number(this.frame) || 0;
    },
    set: function (val) {
      val = number(val);
      const frames = this.animations.frameTotal;

      this.frame = Math.floor(((val % frames) + frames) % frames, 0);
    },
  },

  friction: positiveNumber,

  gravity: number,

  heading: {
    get: function () {
      return angle(this.heading);
    },
    set: function (val) {
      // setting the heading of an object disables gravity
      this.gravity = 0;
      this.heading = angle(val);
    },
  },

  height: function (val) {
    const type = this.model ? this.model.get("type") : this.type;

    // height cannot be changed for these types
    if (["variable", "button", "text"].includes(type)) {
      return number(this.height);
    }

    return number(val);
  },

  "is-topdown": boolean,

  objectName: {
    get: function () {
      return string(this.objectName);
    },
    set: function (val) {
      const retVal = validateObjectName(this, string(val));
      this.objectName = retVal;
    },
  },

  rotation: number,

  speed: {
    get: function () {
      return number(this.speed);
    },
    set: function (val) {
      // setting the speed of an object disables gravity
      this.gravity = 0;
      this.speed = number(val);
    },
  },

  text: string,

  visible: {
    get: function () {
      return this.alpha > 0;
    },
    set: function (val) {
      this.alpha = val ? 1 : 0;
    },
  },

  width: function (val) {
    const type = this.model ? this.model.get("type") : this.type;
    // width cannot be changed for these types
    if (["variable", "button", "text"].includes(type)) {
      return number(this.width);
    }

    return number(val);
  },

  // x- position relative to the camera
  cameraX: {
    get: function () {
      if (getPointer.call(this.game)[0] === this) {
        return this.x;
      }

      if (this.fixedToCamera) {
        return this.cameraOffset.x;
      }

      return this.x - this.game.camera.x;
    },
    set: function (val) {
      if (getPointer.call(this.game)[0] === this) {
        return; // pointer position cannot be changed by user
      }

      stopCurrentTween.call(this);

      if (this.fixedToCamera) {
        this.cameraOffset.x = number(val);
      } else {
        this.x = number(val) + this.game.camera.x;
      }
    },
  },

  // y- position relative to the camera
  cameraY: {
    get: function () {
      if (getPointer.call(this.game)[0] === this) {
        return this.y;
      }

      if (this.fixedToCamera) {
        return this.cameraOffset.y;
      }

      return this.y - this.game.camera.y;
    },
    set: function (val) {
      if (getPointer.call(this.game)[0] === this) {
        return; // pointer position cannot be changed by user
      }

      stopCurrentTween.call(this);

      if (this.fixedToCamera) {
        this.cameraOffset.y = number(val);
      } else {
        this.y = number(val) + this.game.camera.y;
      }
    },
  },

  x: {
    get: function () {
      if (getPointer.call(this.game)[0] === this) {
        return this.x + this.game.camera.x;
      }

      if (this.fixedToCamera) {
        return this.game.camera.x + this.cameraOffset.x;
      }

      return this.x;
    },
    set: function (val) {
      if (getPointer.call(this.game)[0] === this) {
        return; // pointer position cannot be changed by user
      }

      stopCurrentTween.call(this);

      val = number(val);

      if (this.fixedToCamera) {
        this.cameraOffset.x = val - this.game.camera.x;
      } else {
        this.x = val;
      }
    },
  },

  y: {
    get: function () {
      if (getPointer.call(this.game)[0] === this) {
        return this.y + this.game.camera.y;
      }

      if (this.fixedToCamera) {
        return this.game.camera.y + this.cameraOffset.y;
      }

      return this.y;
    },
    set: function (val) {
      if (getPointer.call(this.game)[0] === this) {
        return; // pointer position cannot be changed by user
      }

      stopCurrentTween.call(this);

      val = number(val);

      if (this.fixedToCamera) {
        this.cameraOffset.y = val - this.game.camera.y;
      } else {
        this.y = val;
      }
    },
  },
};
