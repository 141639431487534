var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partial-shapes/plus-icon.hbs"),depth0,{"name":"partial-shapes/plus-icon","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<drop-helper>\n  <helper-block class=\"code-block\">\n"
    + ((stack1 = container.invokePartial(require("./code-block.hbs"),depth0,{"name":"code-block","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </helper-block>\n</drop-helper>";
},"usePartial":true,"useData":true});