import PrimitiveBlock from "./../primitive";
import GAME from "models/block/game";

/**
 * this model represents a texture primitive
 * These are simply a number that refers to a PhaserJs tile index
 */
export default PrimitiveBlock.extend({
  getPrimitiveValue() {
    return Number(this.get("value"));
  },

  async getToodalOptions() {
    const game = await GAME.promiseGame();
    const tileSetId = game.model.get("tile-set");
    const tileSet = game.map.tilesets.find(
      tileset => tileset.name === tileSetId,
    );

    const tiles = [];
    for (let i = tileSet.firstgid; i <= tileSet.total; i++) {
      tiles.push(i);
    }
    tiles.unshift(0);
    tiles.unshift(null);

    return {
      title: "Texture",
      style: "texture",
      cacheKey: `presets:texture:${tileSetId}`,
      input: null,
      blocks: tiles.map(tile => ({
        "editorial-only": tile === null,
        value: tile,
        type: "texture",
      })),
    };
  },
});
