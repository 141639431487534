import translate from "utils/localisation";
import "../../../phaser-middleware/phaser-bundle";

/* global Phaser */
export const KEYS = [
  { label: "1", keyCode: Phaser.Keyboard.ONE },
  { label: "2", keyCode: Phaser.Keyboard.TWO },
  { label: "3", keyCode: Phaser.Keyboard.THREE },
  { label: "4", keyCode: Phaser.Keyboard.FOUR },
  { label: "5", keyCode: Phaser.Keyboard.FIVE },
  { label: "6", keyCode: Phaser.Keyboard.SIX },
  { label: "7", keyCode: Phaser.Keyboard.SEVEN },
  { label: "8", keyCode: Phaser.Keyboard.EIGHT },
  { label: "9", keyCode: Phaser.Keyboard.NINE },
  { label: "0", keyCode: Phaser.Keyboard.ZERO },

  { label: "Q", keyCode: Phaser.Keyboard.Q },
  { label: "W", keyCode: Phaser.Keyboard.W },
  { label: "E", keyCode: Phaser.Keyboard.E },
  { label: "R", keyCode: Phaser.Keyboard.R },
  { label: "T", keyCode: Phaser.Keyboard.T },
  { label: "Y", keyCode: Phaser.Keyboard.Y },
  { label: "U", keyCode: Phaser.Keyboard.U },
  { label: "I", keyCode: Phaser.Keyboard.I },
  { label: "O", keyCode: Phaser.Keyboard.O },
  { label: "P", keyCode: Phaser.Keyboard.P },

  { label: "A", keyCode: Phaser.Keyboard.A },
  { label: "S", keyCode: Phaser.Keyboard.S },
  { label: "D", keyCode: Phaser.Keyboard.D },
  { label: "F", keyCode: Phaser.Keyboard.F },
  { label: "G", keyCode: Phaser.Keyboard.G },
  { label: "H", keyCode: Phaser.Keyboard.H },
  { label: "J", keyCode: Phaser.Keyboard.J },
  { label: "K", keyCode: Phaser.Keyboard.K },
  { label: "L", keyCode: Phaser.Keyboard.L },

  { label: "Z", keyCode: Phaser.Keyboard.Z },
  { label: "X", keyCode: Phaser.Keyboard.X },
  { label: "C", keyCode: Phaser.Keyboard.C },
  { label: "V", keyCode: Phaser.Keyboard.V },
  { label: "B", keyCode: Phaser.Keyboard.B },
  { label: "N", keyCode: Phaser.Keyboard.N },
  { label: "M", keyCode: Phaser.Keyboard.M },

  { label: " ", keyCode: Phaser.Keyboard.SPACEBAR },
  { label: "↑", keyCode: Phaser.Keyboard.UP },
  { label: "→", keyCode: Phaser.Keyboard.RIGHT },
  { label: "↓", keyCode: Phaser.Keyboard.DOWN },
  { label: "←", keyCode: Phaser.Keyboard.LEFT },
];

// define keys that need to be mirrored
export const KEY_MIRRORS = {
  [Phaser.Keyboard.ONE]: [Phaser.Keyboard.NUMPAD_1],
  [Phaser.Keyboard.TWO]: [Phaser.Keyboard.NUMPAD_2],
  [Phaser.Keyboard.THREE]: [Phaser.Keyboard.NUMPAD_3],
  [Phaser.Keyboard.FOUR]: [Phaser.Keyboard.NUMPAD_4],
  [Phaser.Keyboard.FIVE]: [Phaser.Keyboard.NUMPAD_5],
  [Phaser.Keyboard.SIX]: [Phaser.Keyboard.NUMPAD_6],
  [Phaser.Keyboard.SEVEN]: [Phaser.Keyboard.NUMPAD_7],
  [Phaser.Keyboard.EIGHT]: [Phaser.Keyboard.NUMPAD_8],
  [Phaser.Keyboard.NINE]: [Phaser.Keyboard.NUMPAD_9],
  [Phaser.Keyboard.ZERO]: [Phaser.Keyboard.NUMPAD_0],
};

export function getKeyMirrors(keyCode) {
  return KEY_MIRRORS[keyCode] || [];
}

// add common properties to each KEY object
KEYS.forEach(key => {
  key.label = translate(`KEY_CODE_${key.keyCode}`);
  key.type = "key";
  key.folders = ["keys"];
});
