import BlockScope from "./../block-environment";
import { checkFlag } from "utils/flags";

export const BlockRecycleBin = BlockScope.extend({
  constructor(attr, options) {
    attr = attr || {};
    attr["isRecycleBin"] = true;
    return BlockScope.prototype.constructor.call(this, attr, options);
  },

  initialize(attr, options) {
    BlockScope.prototype.initialize.call(this, attr, options);
    this.listenTo(this.get("code"), "add", this._codeAdded);
  },

  _codeAdded(model) {
    if (!checkFlag("UNDO_REDO")) {
      model.delete();
    }
  },

  delete() {
    const blocks = this.get("code").models;
    for (let i = blocks.length - 1; i >= 0; i--) {
      const block = blocks[i];
      block.delete();
    }
  },
});
