import Modal from "../modal";

import style from "./no-solution.styl";
style.use();

export default Modal.extend({
  className: [Modal.prototype.className, "no-solution"].join(" "),

  template: require("./no-solution.hbs"),
});
