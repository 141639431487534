import translate from "utils/localisation";

export default {
  name: "HTML free editor",
  model: "models/html",
  views: ["views/free-editor", "views/html-output"],
  autoTest: true,
  language: "html",
  lines: [
    {
      text: "<!DOCTYPE html>",
    },
    {
      text: "<html>",
    },
    {
      text: "\t<body>",
    },
    {
      text: `\t\t<p>${translate("hello world")}</p>`,
    },
    {
      text: "\t</body>",
    },
    {
      text: "</html>",
    },
  ],
  tests: [
    {
      type: "code-valid",
      test: true,
      message: "CODE ERROR",
    },
  ],
};
