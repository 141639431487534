const sanitizer = require("string-sanitizer");

export default function sanitizeString(value) {
  return removeDuplicateDashes(
    sanitizer.sanitize.addDash(
      sanitizer.sanitize.keepUnicode((value || "").toLowerCase()),
    ),
  );
}

function removeDuplicateDashes(value) {
  return value.replace(/--+/g, "-");
}
