import Modal from "../modal";
import embedAction from "utils/embed-action";
import { getAbsoluteUrl } from "./../../../../../utils/get-absolute-url";

export default Modal.extend({
  className: [Modal.prototype.className, "external-link"].join(" "),

  template: require("./external-link.hbs"),

  events: Object.assign(
    { "click .external-link-confirmed": "externalLink" },
    Modal.prototype.events,
  ),

  open(url) {
    this.url = url;
    Modal.prototype.open.call(this);
  },

  externalLink() {
    if (
      !embedAction({
        type: "navigate",
        destination: "link",
        url: this.url,
      })
    ) {
      window.location.href = getAbsoluteUrl(this.url);
    }
  },
});
