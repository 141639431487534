import BlockArgument from "./../components/argument";
import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";
import settings from "globals/settings";
import { task } from "globals/task";
import { hasTaxonomy } from "../../../../utils/taxonomy";

/**
 * OBJECT block
 * There are different types of objects but most of them are PhaserJs sprites that are placed on the stage
 * objects can have a list of commands attached to them which will be executed whenever the object block is reached
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("scope", "commands"),
  ]),

  blueprint: require("./object.json"),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    target: null,
    input: null,
    output: "object",
    placeholder: null,
    commands: {},
  }),

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
    this.get("commands").set("object-scope", true);

    // TODO: check if anything still uses this
    // convert legacy targets that were strings to target objects
    if (typeof this.get("target") === "string") {
      this.set("target", {
        key: this.get("target"),
        type: "image",
      });
    }

    this.set(
      "commandSuggestions",
      this.get("commandSuggestions")[this.get("target")?.type] || null,
    );
  },

  changeValue(value, source) {
    const prev = { target: this.get("target") };

    if (value instanceof CodeBlock) {
      if (value.get("type") !== this.get("type")) {
        throw Error("Can't update the value of this block to a different type");
      }

      value = { target: value.get("target") };
    }

    this.set("target", value.target);
    this.addToActionHistory(
      {
        block: this,
        type: "set",
        value: {
          new: value,
          old: prev,
        },
      },
      source,
    );
  },

  getPseudoCode(indent = "") {
    const commands = this.get("commands")
      .get("code")
      .map(block => block.getPseudoCode());

    return `${indent}${this.get("target").type}${commands}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("commands").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    // find all the objects matching this block
    const objects = await this.findObject();

    return Promise.all(
      objects.map(object => this.get("commands").run(scope, object)),
    );
  },

  isSame(other) {
    const same = CodeBlock.prototype.isSame.call(this, other);

    if (!same) {
      return false;
    }

    const ownTarget = this.get("target");
    const otherTarget = other.get("target");

    return (
      ownTarget &&
      otherTarget &&
      ownTarget.key === otherTarget.key &&
      ownTarget.type === otherTarget.type
    );
  },
  /**
   * Check whether this object is sound
   * @return {Boolean}  true if this object is sound
   */
  isSound() {
    if (this.get("target")?.type === "sound" && this.get("target")?.key) {
      return true;
    }
  },

  /**
   * Promises to find the stage object that this code block targets
   * This can return an array containing multiple objects
   * @return {Promise}           Returns a Promise that resolves with the Phaser.Sprite
   */
  async findObject() {
    if (!game.isState("Run")) {
      await game.promiseSetup();
    }

    const target = this.get("target");
    const key = target.key;
    let fn;

    switch (target.type) {
      case "task": //DEPRECATED
      case "stage": //DEPRECATED
      case "speaker":
      case "app":
        fn = "getApp";
        break;
      case "camera":
        fn = "getCamera";
        break;
      case "pointer":
        fn = "getPointer";
        break;
      case "button":
        fn = "getButton";
        break;
      case "text":
        fn = "getText";
        break;
      case "sound":
        fn = "getSound";
        break;
      default:
        fn = "getObject";
        break;
    }

    let objects = await game.exec(fn, key);

    // remove empty values from list
    objects = objects.filter(obj => Boolean(obj));

    return objects;
  },

  getGlossaryTaxonomy() {
    return [
      `glossary-type.code-block.object.${this.get("target").type}`,
      "glossary-type.code-block.object",
      "glossary-type.code-block",
    ];
  },

  async playSound() {
    const target = this.get("target");

    if (!target) {
      return;
    }

    if (target.type === "sound") {
      await game.promiseSetup();
      game.game.sound.play(target.key);
    }
  },

  _placementMessage: ERRORS.PLACEMENT_OBJECT,
  _validatePlacement(parent) {
    return (
      (parent instanceof BlockScope && !parent.get("free-form")) ||
      parent instanceof BlockArgument
    );
  },
  /**
   * Check whether this primitive is editable
   * @return {Boolean}  true if this primitive is editable
   */
  isEditable() {
    const topScope = this.getTopLevelScope();
    const blockCoder = topScope && topScope.get("block");
    // object is missing context that allows it to be edited
    if (!topScope || !blockCoder || !task) {
      return false;
    }

    // NOTE: if we ever remove this code (because we want all objects to be clickable)
    // then we need to make sure we address hard-locked block combinations,
    // These may no longer exist in new apps, but they will in existing user apps
    // example: app.print(),
    //          if we allow users to swap the object, then you will end up with
    //          a locked camera.print() block, which is invalid
    if (
      ["image", "button", "sound", "sprite", "text"].indexOf(
        this.get("target").type,
      ) < 0
    ) {
      return false;
    }

    // objects cannot be edited unless they are inside a free-form scope (code canvas)
    if (!topScope.get("free-form")) {
      return false;
    }

    // objects are always editable while in CC edit mode
    if (settings.get("editable")) {
      return true;
    }

    // object not editable because the code can't be edited at the top level
    if (!blockCoder.get("settings").get("edit-code")) {
      return false;
    }

    // object not editable because the task is locked
    if (task.get("locked")) {
      return false;
    }

    // object not editable because of jigsaw mode
    if (blockCoder.get("interaction-mode") === "jigsaw") {
      return false;
    }

    // we are in a free coding area or at the build step, the user can edit anything they want
    // unless we are in free coding area or at the build step, object is not editable
    if (
      hasTaxonomy(task, "use-type.free-code") ||
      hasTaxonomy(task, "use-type.user-generated") ||
      hasTaxonomy(task, "task-type.build")
    ) {
      return true;
    } else {
      return false;
    }
  },

  async getToodalOptions() {
    const topScope = this.getTopLevelScope();
    const blockCoder = topScope && topScope.get("block");
    const target = this.get("target");
    const objects = blockCoder
      .get("objects")
      .filter((object, i, list) => {
        // filter different types
        if (object.get("type") !== target.type) {
          return false;
        }
        // filter duplicates of the same class
        if (object.getClass() !== null) {
          return (
            list.findIndex(other => other.getClass() === object.getClass()) ===
            i
          );
        } else {
          return true;
        }
      })
      .map(object => object.getBlockData());

    const keys = objects.map(object => object.target?.key);
    return {
      title: target.type.charAt(0).toUpperCase() + target.type.slice(1),
      style: `object ${target.type}`,
      cacheKey: `presets:${target.type}:${target.key}:${keys.join("-")}`,
      input: null,
      message: !objects.length
        ? `There are no ${target.type}s to swap. Please add more ${target.type}s from the Design panel.`
        : null,
      blocks: objects,
    };
  },

  /**
   * Maps commandSuggestions to block blueprints
   * @returns {CommandSuggestion[]}
   */
  getCommandSuggestions() {
    return (this.get("commandSuggestions") || []).map(
      suggestion => new CommandSuggestion(suggestion),
    );
  },
});

// given a code block, find its targets on the stage
// always returns an array, which can be empty if there are no targets
export async function findTargets(block) {
  let targets;

  switch (block.get("type")) {
    case "object":
      targets = await block.findObject();
      break;
    case "variable":
      targets = await block.findVariable();
      break;
    case "key":
      targets = await block.findKeyObject();
      break;
  }

  // no targets
  if (!targets) {
    return [];
  }

  // ensure array
  if (Array.isArray(targets)) {
    return targets.filter(val => Boolean(val));
  } else {
    return [targets].filter(val => Boolean(val));
  }
}

class CommandSuggestion {
  constructor(blueprint) {
    this.blueprint = blueprint;
  }

  /**
   * Checks whether a code block matches this suggestion
   * @param {CodeBlock} block the code block to check
   * @returns
   */
  match(block) {
    if (
      ["get", "set"].includes(this.blueprint) &&
      block.getArgumentBlocks().filter(argument => argument).length !== 0
    ) {
      return false;
    }
    return block.get("fn") === this.blueprint;
  }
}
