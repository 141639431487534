import { app, object, vector } from "../utils/validate-inputs";
import { unpack } from "./variable";

/**
 * Cast a value to a string
 *
 * @param  {any}     val - The thing to transform to a string
 * @return {String}        The stringified message
 */
export function string(val) {
  val = unpack(val);

  if (app(val)) {
    return `[Object: app]`;
  } else if (object(val)) {
    return val.toString();
  } else if (vector(val)) {
    return `v(${val.x}, ${val.y})`;
  } else if (typeof val === "undefined") {
    return "";
  } else {
    return String(val);
  }
}
