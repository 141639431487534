import * as validate from "./../utils/validate-inputs";
import { unpack } from "../types/variable";
import { canCollide } from "../creation/can-collide";

export default function isTouching(target1, target2) {
  target1 = unpack(target1);
  target2 = unpack(target2);

  // checking an object with itself
  if (target1 === target2) {
    return false;
  }

  // if our first target isn't an object flip our variables around
  if (!validate.physical(target1)) {
    [target1, target2] = [target2, target1];
  }

  if (!canCollide(target1) || !canCollide(target2)) {
    return false;
  }

  // object to collider (object or tile)
  if (validate.physical(target1) && validate.collider(target2)) {
    return target1.currentOverlaps.includes(target2);
  }

  // object to camera
  if (validate.physical(target1) && validate.camera(target2)) {
    return target1.inCamera;
  }

  // object to pointer
  if (validate.physical(target1) && validate.pointer(target2)) {
    return target1.getBounds().contains(target2.x, target2.y);
  }

  // if our first target isn't a pointer flip our variables around
  if (!validate.pointer(target1)) {
    [target1, target2] = [target2, target1];
  }

  // pointer to tile
  if (validate.pointer(target1) && validate.texture(target2)) {
    const tile = target1.game.map.getTileWorldXY(target1.x, target1.y);

    return Boolean(tile && tile.index === target2);
  }

  // pointer to camera
  if (validate.pointer(target1) && validate.camera(target2)) {
    return target1.withinGame;
  }

  // if we get all the way down here, then the combination of objects we are comparing must not be valid
  throw Error(validate.error.collider);
}
