import Backbone from "custom/backbone-bundle";
import _ from "underscore";
import { getApiUrl } from "utils/get-api-url";
import PrototypeModel from "models/prototype-model";
import { hasTaxonomy } from "utils/taxonomy";
import { task } from "globals/task";
import Task from "models/task";
import { user } from "globals/user";

export default PrototypeModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "tasks",
      relatedModel: Task,
      parse: true,
    },
  ],

  url() {
    if (user.canLoadUserProgress()) {
      return getApiUrl(`state/lesson/${this.id}`);
    } else {
      return getApiUrl(`app/lesson/${this.id}`);
    }
  },

  defaults: {
    tasks: [],
    score: 0,
    answerCorrectNextStep: false, // If true, answering correctly automatically moves on to the next step.
    answerAttemptFree: 0, // Number of answer attempts with no score adjustment.
    answerAttemptAdjustment: 0, // Score adjustment per subsequent answer attempts.
    answerTimeFree: 0, // Number of seconds to answer a question with no score adjustment.
    answerTimeAdjustment: 0, // Score adjustment per second.
    questionCorrectAdjustment: 1, // Score adjustment per correct answer.
    questionAdjustmentIsRelative: false, // If true, question adjustment is divided by the number of questions.
    questionSkipAdjustment: 0, // Score adjustment for skipping a question.
    questionSkipIncludesTimeAdjustment: false, // If true, the time adjustment apply to skipped questions.
    hasInstructionVideo: false,
    "glossary-terms": [],
  },

  initialize() {
    this.calculateScore = _(this.calculateScore).debounce(0);

    this.listenTo(this.get("tasks"), "change:completed change:skipped", () =>
      this.calculateScore(),
    );

    // we intentionally DON'T extend from PrototypeModel here as we don't want a default ID to be generated
  },

  /** calculates and stores the global score on this lesson */
  calculateScore() {
    let score = 0;

    this.get("tasks").forEach(task => (score += this.scoreTask(task)));

    this.set("score", score);
  },

  /** calculates the score of an individual task */
  scoreTask(task) {
    var scoreAdjustment = 0,
      taskCount = this.get("tasks").length,
      totalLoss = 0;

    // Lose points if they've made too many attempts at answering.
    if (task.get("answerAttempts") > this.get("answerAttemptFree")) {
      var extraAttempts =
        task.get("answerAttempts") - this.get("answerAttemptFree");
      scoreAdjustment += extraAttempts * this.get("answerAttemptAdjustment");
    }

    // Lose points if they've taken too long (either not skipped, or skipping includes the time penalty as well).
    if (
      task.has("startTime") &&
      task.has("finishTime") &&
      (!task.get("skipped") || this.get("questionSkipIncludesTimeAdjustment"))
    ) {
      var totalDuration =
        (task.get("finishTime") - task.get("startTime")) / 1000;
      var relativeDuration = Math.max(
        0,
        totalDuration - this.get("answerTimeFree"),
      );
      scoreAdjustment += relativeDuration * this.get("answerTimeAdjustment");
    }

    // Lose points if they've skipped.
    if (task.get("skipped")) {
      totalLoss = this.get("questionSkipAdjustment");
    } else if (task.get("completed")) {
      totalLoss = this.get("questionCorrectAdjustment");
    }
    scoreAdjustment += this.get("questionAdjustmentIsRelative")
      ? totalLoss / taskCount
      : totalLoss;

    return scoreAdjustment;
  },

  /**
   * Find the next step in this lesson
   */
  getNextStep() {
    const steps = this.get("tasks");
    const index = steps.indexOf(task);

    if (index !== -1) {
      return steps.at(index + 1);
    }
  },

  /**
   * Find the previous step in this lesson
   */
  getPreviousStep() {
    const steps = this.get("tasks");
    const index = steps.indexOf(task);

    if (index > 0) {
      return steps.at(index - 1);
    }
  },

  hasHelpVideo() {
    return Boolean(
      this.get("media") &&
        this.get("media").tutorialVideo &&
        this.get("media").tutorialVideo.src,
    );
  },

  // get the grade of this unit based on taxonomy
  getGrade() {
    const grade = [1, 2, 3, 4, 5, 6].find(grade =>
      hasTaxonomy(this, `grade.grade-${grade}`),
    );

    return grade;
  },
});
