import Backbone from "custom/backbone-bundle";
import style from "./choose-where.styl";
import BlockView from "views/block/blocks/code-block";
style.use();
import whereTemplate from "./where-target.hbs";
import translate from "templates/helpers/translate";

export const ChooseWhereView = Backbone.View.extend({
  template: require("./choose-where.hbs"),
  tagName: "section",
  className: "choose-where",

  events: {
    "click .where-target": "onWhereTargetClicked",
  },

  initialize() {
    this.listenTo(this.model, "change:target", this.render);
  },

  async render() {
    if (this._blockCopy) {
      this._blockCopy.remove();
    }

    this.$el.html(this.template({}));

    const copy = this.model.get("target")?._shallowCopy?.();
    if (copy) {
      [copy, ...copy.getChildBlocks()].forEach(block =>
        block.set({ interactive: false, "in-toodal": true }),
      );

      this._blockCopy = new BlockView(copy);
      this.$(".block-holder").append(this._blockCopy.el);

      await new Promise(r => requestAnimationFrame(r));
      this.addWhereTargets();
    }

    this.$el.toggleClass("no-targets", this.$(".where-target").length === 0);
  },

  addWhereTargets() {
    this.addAroundTargets();
    this.addInsideTargets();
  },

  addAroundTargets() {
    const target = this.model.get("target");
    const parent = target.getParent();
    const bounds = target.view.el.getBoundingClientRect();
    const scale = 1 / parent.get("scale");
    let positionBefore,
      positionAfter = null;
    let hasAfter = true;

    if (target.isInArgumentSlot()) {
      return; // can't add around a block that is used as an argument
    }

    if (parent.get("locked")) {
      return; // can't add to the parent if it's locked
    }

    const index = parent.get("code")?.indexOf(target) || 0;

    this.$(".code-block-container").toggleClass(
      "horizontal",
      Boolean(parent.get("object-scope")),
    );

    if (parent.get("free-form")) {
      positionBefore = {
        x: target.get("position").get("x"),
        y: target.get("position").get("y"),
      };
      positionBefore.y -= 100;
    }

    if (target.get("type") === "command" && !target.get("chainable")) {
      hasAfter = false;
    }

    if (parent.get("free-form")) {
      positionAfter = {
        x: target.get("position").get("x"),
        y: target.get("position").get("y"),
      };
      positionAfter.y += bounds.height * scale;
    }

    this.$(".before-holder").append(
      whereTemplate({
        index,
        name: "Add code before",
        position: positionBefore,
        autoFocus: !hasAfter,
      }),
    );

    this.$(".after-holder").append(
      whereTemplate({
        index: index + 1,
        name: "Add code after",
        position: positionAfter,
        autoFocus: hasAfter,
        isHidden: !hasAfter,
      }),
    );
  },

  addInsideTargets() {
    const target = this.model.get("target");

    ["code", "commands", "then", "else"].forEach(childScope => {
      if (target.has(childScope) && !target.get(childScope).get("locked")) {
        let name = "Add code inside";
        if (childScope === "then" || childScope === "else") {
          name = translate("Add code inside {name}").replace(
            "{name}",
            childScope,
          );
        }

        this._blockCopy.$(`.${childScope} > scope-code`).append(
          whereTemplate({
            childScope,
            name,
            autoFocus: true,
          }),
        );
      }
    });
  },

  onWhereTargetClicked(e) {
    const el = e.target;
    const { index, childScope } = el.dataset;
    const target = this.model.get("target");

    const position = el.dataset.position
      ? JSON.parse(el.dataset.position)
      : null;

    if (childScope) {
      this.model.set("where", {
        parent: target.get(childScope),
        index: -1,
        position,
      });
    } else {
      this.model.set("where", {
        parent: target.getParent(),
        index: Number(index),
        position,
      });
    }
  },

  //NOTE: this is a rudimentary placeholder method to be replaced with full functionality later
  onPlaceholderClicked(e) {
    const where = e.target.dataset.where;
    const target = this.model.get("target");
    let parent;
    let index = -1;

    switch (where) {
      case "before":
        parent = target.getParent();
        index = target.collection.indexOf(target);
        break;
      case "after":
        parent = target.getParent();
        index = target.collection.indexOf(target) + 1;
        break;
      case "inside":
        // note: this lacks support for if, if-else, ... blocks
        parent = target.get("code") || target.get("commands");
    }

    this.model.set("where", { parent, index });
  },
});
