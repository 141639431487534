import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import _ from "underscore";
import { number } from "../../phaser-middleware/types/number";

/**
 * REPEAT block
 * repeats code a number of times
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "code"),
  ]),

  blueprint: require("./blueprints/repeat.json"),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
    code: {},
    "control-block": true,
  }),

  async execute(scope) {
    this.activate();

    // compute arguments
    const args = await this.get("args").compute(scope);

    const times = number(args);

    // asynchronously iterate and execute the code
    for (let i = 0; i < times; i++) {
      await this.get("code").run(scope);
    }

    this.deactivate();
  },

  getPseudoCode(indent = "") {
    const args = this.get("args").getPseudoCode();
    const code = this.get("code").getPseudoCode(indent);

    return `${indent}REPEAT${args} ${code}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    this.get("code").getChildBlocks(list);
    return list;
  },

  getGlossaryTaxonomy() {
    return [
      "glossary-type.code-block.control.repeat",
      "glossary-type.code-block.control",
      "glossary-type.code-block",
    ];
  },

  _placementMessage: ERRORS.PLACEMENT_CONTROL,
  _validatePlacement(parent) {
    return parent instanceof BlockScope && !parent.get("free-form");
  },
});
