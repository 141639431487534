import { string } from "./../types/string";
import * as validate from "./../utils/validate-inputs";

/**
 * Triggers an Error
 *
 * @param {Object:App} object - The app object
 * @param {String} message - The Error message to be logged in the console
 */
export default function feedback(object, message) {
  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  message = string(message);
  throw Error(message);
}
