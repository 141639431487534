import "core-js/stable";
import "regenerator-runtime/runtime";

// TODO: remove once IE11 support dropped
import "element-closest-polyfill";

import "no-support";

// Global embed code must be before anything that might be protected: e.g. flags and localisation.
import "globals/embed";

import { preloader } from "views/layouts/preloader/preloader";
preloader.define(
  "env",
  "app",
  "layout",
  "route",
  "task fetch",
  "task components",
  "task manifest",
  "task parse",
);

import { flags } from "./globals/flags";
import { load } from "./utils/localisation";
import { user } from "./globals/user";
import "./utils/boomerang";

(async () => {
  await Promise.all([load(), flags.fetch(), user.fetch()]);
  await preloader.complete("env");
  const app = (await import(/* webpackChunkName: "main" */ "./app")).app;
  await preloader.complete("app");
  app.boot();
  app.provide();
})();
