import interfaceChannel from "views/block/channels/interface-channel";

export class DroppableBin {
  constructor(parent) {
    this.parent = parent;
  }

  onDragEnter(e) {
    e.target.classList.add("active");
  }
  onDragLeave(e) {
    e.target.classList.remove("active");
  }
  async onDrop(e) {
    if (e.target.classList.contains("disabled")) {
      return;
    }

    const block = this.parent.getBlockEl(e.relatedTarget).model;

    if (e.target.classList.contains("delete")) {
      const { blockRecycleBin } = require("globals/block-recycle-bin");
      block.move(blockRecycleBin);
    } else if (e.target.classList.contains("copy")) {
      block.copy();
    } else if (e.target.classList.contains("switch")) {
      block.move(block.inactiveCanvas());
    } else if (e.target.classList.contains("help")) {
      await block.openHelp();
    }

    interfaceChannel.trigger("block-interaction-zone:code-dropped");
  }
}
