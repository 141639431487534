import Backbone from "custom/backbone-bundle";
import Modal from "../modal";
import { lesson } from "globals/lesson";
import { VideoView } from "views/block/utils/video/video";

export default Modal.extend({
  className: [Modal.prototype.className, "lesson-video"].join(" "),
  template: require("./lesson-help-video.hbs"),
  style: require("./lesson-help-video.styl"),

  events: Object.assign({}, Modal.prototype.events),

  render() {
    this.$el.html(this.template({}));

    if (lesson && lesson.get("media") && lesson.get("media").tutorialVideo) {
      const model = new Backbone.Model(lesson.get("media").tutorialVideo);

      // use lesson cover image as fallback for missing video poster
      if (!model.get("posterImageSrc") && lesson.get("media").coverImage) {
        model.set("posterImageSrc", lesson.get("media").coverImage.src);
      }

      this.video = new VideoView({ model });
      this.$(".main").append(this.video.$el);
      this.video.render();
    }

    this.$el.one("hidden.bs.modal", () => this._closed());
  },

  _closed() {
    if (this.video) {
      this.video.remove();
    }
  },

  remove() {
    if (this.video) {
      this.video.remove();
    }
    Modal.prototype.remove.call(this);
  },
});
