import * as validate from "./../utils/validate-inputs";

/**
 * Plays a sound
 * @param {string} key - The phaserKey of the sound to play
 */
export default function play(object) {
  if (object.type !== "sound") {
    throw Error(validate.error.sound_command);
  }

  this.sound.play(object.key);
}
