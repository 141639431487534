var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Info",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":20}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<section class=\"app-details\" dir=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"dir",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":34},"end":{"line":7,"column":53}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"app-name-container\">\n    <h2>"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"App filename",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":36}}}))
    + "</h2>\n    <p class=\"app-name\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":42}}}))
    + "</p>\n  </div>\n</section>\n\n<section class=\"app-dates\">\n  <div class=\"date\">\n    <h2 class=\"date-name\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Created",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":49}}}))
    + "</h2>\n    <span class=\"date-value\">"
    + alias2(__default(require("../../../../../templates/helpers/localisedDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"creation_date") : depth0),{"name":"localisedDate","hash":{},"data":data,"loc":{"start":{"line":24,"column":29},"end":{"line":24,"column":60}}}))
    + "</span>\n  </div>\n  <div class=\"date\">\n    <h2 class=\"date-name\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Updated",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":49}}}))
    + "</h2>\n    <span class=\"date-value\">"
    + alias2(__default(require("../../../../../templates/helpers/localisedDate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"modified_date") : depth0),{"name":"localisedDate","hash":{},"data":data,"loc":{"start":{"line":28,"column":29},"end":{"line":28,"column":60}}}))
    + "</span>\n  </div>\n</section>\n\n"
    + ((stack1 = container.invokePartial(require("../shared/share-url.hbs"),depth0,{"name":"../shared/share-url","hash":{"title":"Share","url":(depth0 != null ? lookupProperty(depth0,"share_url") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"app-info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous_versions") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":2},"end":{"line":66,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"galleries") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":2},"end":{"line":98,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"thumbnail-container\">\n    <div class=\"thumbnail-container-inner\" style=\"background-image: url('"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + "')\">\n      <img class=\"thumbnail-print-only\" src="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0), depth0))
    + " alt=\"\" />\n    </div>\n  </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <table class=\"previous-versions\">\n    <colgroup>\n      <col class=\"col-index\">\n      <col class=\"col-name\">\n      <col class=\"col-date\">\n    </colgroup>\n    <thead class=\"headings\">\n      <tr scope=\"columns\">\n        <th class=\"col-name\" colspan=\"2\" scope=\"col\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Versions",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":53},"end":{"line":44,"column":77}}}))
    + "</th>\n        <th class=\"col-date\" scope=\"col\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Last updated",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":41},"end":{"line":45,"column":69}}}))
    + "</th>\n      </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"previous_versions") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":6},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr scope=\"rows\">\n        <td class=\"row-index\">\n          <span class=\"index\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "</span>\n        </td>\n        <td class=\"row-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":55,"column":10},"end":{"line":59,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n        <td class=\"row-date\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"modified_date") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":29},"end":{"line":61,"column":88}}})) != null ? stack1 : "")
    + "</td>\n      </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a class=\"name\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-url=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" tabindex=\"0\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"name no-link\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../../../templates/helpers/localisedDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"modified_date") : depth0),{"name":"localisedDate","hash":{},"data":data,"loc":{"start":{"line":61,"column":50},"end":{"line":61,"column":81}}}));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <table class=\"app-galleries\">\n    <colgroup>\n      <col class=\"col-name\">\n      <col class=\"col-date\">\n    </colgroup>\n    <thead class=\"headings\">\n      <tr scope=\"columns\">\n        <th scope=\"col\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Galleries",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":76,"column":24},"end":{"line":76,"column":49}}}))
    + "</th>\n        <th scope=\"col\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Added on",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":77,"column":24},"end":{"line":77,"column":48}}}))
    + "</th>\n      </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"galleries") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":6},"end":{"line":95,"column":15}}})) != null ? stack1 : "")
    + "    </tbody>\n  </table>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr scope=\"rows\">\n\n        <td class=\"row-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"gallery") : depth0)) != null ? lookupProperty(stack1,"galleryUrl") : stack1)) != null ? lookupProperty(stack1,"public") : stack1)) != null ? lookupProperty(stack1,"path") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":85,"column":10},"end":{"line":90,"column":17}}})) != null ? stack1 : "")
    + "        </td>\n\n        <td class=\"row-date\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"creation_date") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":29},"end":{"line":93,"column":88}}})) != null ? stack1 : "")
    + "</td>\n      </tr>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a class=\"row-name\" href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"gallery") : depth0)) != null ? lookupProperty(stack1,"galleryUrl") : stack1)) != null ? lookupProperty(stack1,"public") : stack1)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" target=\"_blank\"\n            no-confirmation-modal>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"gallery") : depth0)) != null ? lookupProperty(stack1,"heading") : stack1), depth0))
    + "</a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"row-name\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"gallery") : depth0)) != null ? lookupProperty(stack1,"heading") : stack1), depth0))
    + "</span>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../../../templates/helpers/localisedDate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"creation_date") : depth0),{"name":"localisedDate","hash":{},"data":data,"loc":{"start":{"line":93,"column":50},"end":{"line":93,"column":81}}}));
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"confirm ghost\" data-dismiss=\"modal\" autofocus aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":104,"column":73},"end":{"line":104,"column":94}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":104,"column":96},"end":{"line":104,"column":117}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":100,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":103,"column":0},"end":{"line":105,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});