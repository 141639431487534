import BlockScope from "./../components/block-scope";
import settings from "globals/settings";

export default BlockScope.extend({
  constructor(attr, options) {
    attr = attr || {};
    attr.isWall = true;
    attr.enabled = typeof attr.enabled === "boolean" ? attr.enabled : true;
    return BlockScope.prototype.constructor.call(this, attr, options);
  },

  initialize(attr, options) {
    BlockScope.prototype.initialize.call(this, attr, options);

    this.listenTo(this.get("code"), "add", this._codeAdded);
  },

  _codeAdded(model) {
    model.get("position").set({ x: 0, y: 0 });
    this._emptyScopes(model);
    this._lockNested(model);
  },

  /**
   * Empties child scopes of C-blocks
   * Note: this places all the removed blocks on the active canvas
   */
  _emptyScopes(model) {
    if (model.isCBlock()) {
      const canvas = model.activeCanvas();
      const blocks = model
        .get("childScopeKeys")
        .map(key => model.get(key).get("code").models);
      blocks.flat(1).forEach(block => block.move(canvas));
    }
  },

  /** locks the descendants of a block */
  _lockNested(model) {
    // lock child scopes
    model.get("childScopeKeys").forEach(key => {
      model
        .get(key)
        .get("code")
        .forEach(block => this._lockBlock(block));
    });

    // lock arguments
    if (model.has("args")) {
      model
        .get("args")
        .get("arguments")
        .forEach(arg => {
          const block = arg.get("code");
          if (block) {
            this._lockBlock(block);
          }
        });
    }
  },

  /** locks a block and its descendants */
  _lockBlock(block) {
    block.set("locked", true);

    this._lockNested(block);
  },

  //the code wall only accepts code blocks in edit mode
  accepts(model) {
    if (!settings.get("editable")) {
      return false;
    }

    // in jigsaw mode, the wall is only sortable. This means we'll only accept
    // blocks that are already on it
    if (model.isInJigsawMode()) {
      return model.getParent() === this;
    }

    return BlockScope.prototype.accepts.call(this, model);
  },
});
