import { hasTaxonomy } from "./taxonomy";

export function isAuthor(user, app) {
  if (hasTaxonomy(app, "use-type.free-code")) {
    return true;
  }

  if (app.get("isClone") || app.get("reference")) {
    return false;
  }

  const author =
    app && app.get("metadata") && app.get("metadata").get("author");
  const isExample = hasTaxonomy(app, "task-type.example-app");
  const isOwner =
    author && user.get("user") && author.id === user.get("user").id;
  const isEditor = user.get("role") && user.get("role").editor;

  return (isExample && isEditor) || isOwner;
}
