"use strict";

import $ from "jquery";
import { getApiUrl } from "utils/get-api-url";

export async function getGlobalPlatformFreeCoder(codingLanguage) {
  const globalOrigin =
    location.origin.indexOf("localhost") > -1 ||
    location.origin.indexOf("bs-local") > -1
      ? "https://app.dev.discoveryeducation.com"
      : location.origin.replace("//coding-app.", "//app.");
  const url = getApiUrl("app", "global-asset-routes");

  const globalAssetRoutes = await $.get(url);

  const globalRouteKey = Object.keys(globalAssetRoutes).find(
    key => key.indexOf(codingLanguage) > -1,
  );
  const globalRouteId = globalRouteKey
    ? globalAssetRoutes[globalRouteKey]
    : null;

  return `${globalOrigin}/learn/player/${globalRouteId}`;
}
