import Backbone from "custom/backbone-bundle";
import { getApiUrl } from "utils/get-api-url";
import PrototypeModel from "models/prototype-model";
import Lesson from "models/lesson";
import { hasTaxonomy } from "utils/taxonomy";

export default PrototypeModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "lessons",
      relatedModel: Lesson,
      parse: true,
    },

    // DEPRECATED - this is only used for the CODING 1 website
    {
      type: Backbone.HasOne,
      key: "parent",
      relatedModel: PrototypeModel,
    },
  ],

  defaults: {
    lessons: [],
  },

  initialize() {
    // no-op
    // We don't want a default ID to be set when this model initializes
  },

  url() {
    return getApiUrl(`app/unit/${this.get("id")}`);
  },

  // get the grade of this unit based on taxonomy
  getGrade() {
    const grade = [1, 2, 3, 4, 5, 6].find(grade =>
      hasTaxonomy(this, `grade.grade-${grade}`),
    );

    return grade;
  },

  toJSON(options) {
    const data = PrototypeModel.prototype.toJSON.call(this, options);

    delete data._id;

    return data;
  },
});
