import ERRORS from "./../../blocks/errors";
import GET from "./../utils/object-properties";
import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";

/**
 * Get the value of a property
 *
 * @param {Object} object - The object whose property to get
 * @param {String} prop - The key of the property
 */
export default function get(object, prop) {
  if (!object) return;

  prop = string(prop);

  if (validate.object(object)) {
    return GET.call(object, prop);
  } else if (prop in object) {
    return object[prop];
  } else {
    throw Error(ERRORS.RUNTIME_UNKNOWN_PROPERTY);
  }
}
