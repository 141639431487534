import interfaceChannel from "../../channels/interface-channel";
/**
 * Handles hold interaction of canvas
 */

export class HoldableCanvas {
  constructor() {
    this.parent = parent;
  }

  onHold(e) {
    if (e.pointerType === "mouse") {
      e.preventDefault();
      e.stopPropagation();
      return; // ignore 'hold' events if they were done with the mouse
    }
    document.body.style.webkitUserSelect = "none";
    interfaceChannel.trigger("block-interaction-zone:canvas-onhold", e);
    setTimeout(() => (document.body.style.webkitUserSelect = "initial"), 1000);
  }
}
