import settings from "globals/settings";
import {
  createCollisionLayers,
  matchPseudoCameraToActualCamera,
  resizeCamera,
} from "./../creation/create-stage";
import { enablePhysicsAll } from "./../creation/enable-physics";
import { debug } from "globals/debug";

const run = function () {};

run.prototype.init = function (callback) {
  this.game.callback = callback; //we set the callback here, but it will only be resolved once the next state is ready
};

run.prototype.create = function () {
  const game = this.game;

  // update camera and pseudoCamera
  resizeCamera.call(game);
  matchPseudoCameraToActualCamera.call(this);

  createCollisionLayers.call(game);
  enablePhysicsAll(game);

  game.continuous = [];

  // add FPS problem handling
  game.time.advancedTiming = true;
  settings.set("code-highlighting-enabled", true);

  game.stage.disableVisibilityChange = false;
  this._cursorHistory = [];
  game.input.activePointer.heading = 0;
  game.input.activePointer.speed = 0;

  const callback = game.callback;
  delete game.callback;
  if (typeof callback === "function") {
    callback();
  }

  // run user-generated code
  game.model.get("background-code").run();
  game.model.get("input").run();
};

// tracks the cursor for a number of frames so that its heading/speed can be calculated
const MAX_TRACK_DURATION = 12; // in frames
function trackCursor() {
  const game = this.game;
  const pointer = game.input.activePointer;

  // track current position and add it to the history
  this._cursorHistory.unshift(pointer.position.clone());

  // grab the first and last frame of the history
  const last = this._cursorHistory.slice(-1)[0];
  const first = this._cursorHistory[0];

  // calculate heading and speed of cursor based on difference between last and first frames
  if (last && first && last !== first) {
    const angle = last.angle(first, true);
    const distance = last.distance(first);

    pointer.speed =
      (distance * Phaser.Timer.SECOND) /
      (MAX_TRACK_DURATION * game.time.elapsed) /
      game.model.get("physics-scale");

    if (Math.abs(pointer.speed) > 1) {
      pointer.heading = angle;
    }
  }

  // discard entries from history past determined point
  const discard = MAX_TRACK_DURATION;
  if (this._cursorHistory.length > discard) {
    this._cursorHistory.length = discard;
  }
}

run.prototype.render = function () {
  if (debug["phaser-bodies"]) {
    this.game.objectGroup.forEach(object => this.game.debug.body(object));
  }
};

// main game-loop
run.prototype.update = function () {
  const game = this.game;

  // update camera
  game.camera.setPosition(
    game.pseudoCamera.x - game.camera.width / 2,
    game.pseudoCamera.y - game.camera.height / 2,
  );
  matchPseudoCameraToActualCamera.call(this);

  // execute every continuous function
  game.continuous.forEach(function (fn) {
    if (typeof fn === "function") {
      fn();
    }
  });

  trackCursor.call(this);

  // detect bad performance and disable code highlighting
  if (
    game.time.fps < 45 &&
    game.time.fps > 0 &&
    settings.get("code-highlighting-enabled")
  ) {
    settings.set("code-highlighting-enabled", false);
    console.warn("slow performance detected - disabling code highlighting"); // eslint-disable-line
  }
};

run.prototype.shutdown = function () {
  this.game.sound.stopAll();
};

export default run;
