import Modal from "../modal";
import { ShareUrlPlugin } from "./../shared/share-url";
import { getAbsoluteUrl } from "utils/get-absolute-url";
import { task } from "globals/task";

export default Modal.extend(ShareUrlPlugin).extend({
  className: [Modal.prototype.className, "share-url"].join(" "),

  template: require("./share-url.hbs"),
  style: require("./share-url.styl"),

  events: ShareUrlPlugin.events.extend(Modal.prototype.events),

  render() {
    this.$el.html(
      this.template({
        isShared: !task.get("private"),
        isClone: task.get("isClone"),
        url: `${getAbsoluteUrl(task.get("appUrl").share.path)}`,
      }),
    );
    this.delegateEvents();
  },
});
