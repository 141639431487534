export default Object.assign(
  JSON.parse(
    JSON.stringify(
      require("models/block/blocks/command/blueprints/journey-move.json"),
    ),
  ), //make sure all the values are deeply cloned
  {
    groups: ["year 3", "year 4"],
    args: {
      arguments: [
        {
          code: {
            type: "vector",
            x: 0,
            y: 1,
            "hard-lock": true,
            editable: true,
          },
        },
      ],
    },
  },
);
