import Backbone from "custom/backbone-bundle";
import _ from "underscore";

export default Backbone.View.extend({
  initialize: function(options) {
    options = options || {};

    if (options.childFactory) {
      this.childFactory = options.childFactory;
    }

    this.childViews = [];

    this.listenTo(this.model, "reset", this.render);
    this.listenTo(this.model, "remove", this._removeChild);
    this.listenTo(this.model, "add", this._addChild);
    this.listenTo(this.model, "sort", this._sort);
  },

  render: function() {
    this._resetChildren();
  },

  _createChild: function(model) {
    if (typeof this.childFactory !== "function") {
      // eslint-disable-next-line no-console
      console.warn("Unable to create child view - no factory method specified");
      return;
    }

    try {
      return this.childFactory(model);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },

  //add a child model
  _addChild: function(model) {
    var child = this._createChild(model);
    var index = this.model.indexOf(model);

    if (child) {
      this._attachAtIndex(child.$el, index);
      this.childViews.splice(index, 0, child);
    }
  },

  _attachAtIndex: function(el, i) {
    i = i || 0;

    el.detach();

    if (i === 0) {
      this.$el.prepend(el);
    } else {
      this.$el
        .children()
        .eq(i - 1)
        .after(el);
    }
  },

  _sort: function() {
    this.childViews = _.sortBy(
      this.childViews,
      function(view) {
        return this.model.indexOf(view.model);
      }.bind(this),
    );

    this.childViews.forEach(
      function(view, i) {
        if (view.$el.index() !== i) {
          this._attachAtIndex(view.$el, i);
        }
      }.bind(this),
    );
  },

  //remove a child model
  _removeChild: function(model) {
    this.childViews = this.childViews.filter(function(view) {
      if (view.model === model) {
        view.remove();
      }

      return view.model !== model;
    });
  },

  //handles collection reset event
  //attempts to limit DOM manipulation to only the necessary elements
  _resetChildren: function() {
    //find current items
    var current = this.childViews.map(function(view) {
      return view.model;
    });

    //find expected
    var expected = this.model.slice();

    //remove models
    _.difference(current, expected).forEach(this._removeChild.bind(this));

    //add models
    _.difference(expected, current).forEach(this._addChild.bind(this));
  },

  remove: function() {
    this.childViews.forEach(function(child) {
      child.remove();
    });

    Backbone.View.prototype.remove.call(this);
  },
});
