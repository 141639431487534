import { contextMenu } from "views/block/ui-elements/context-menu/context-menu";
import { toodal } from "views/block/ui-elements/toodal/toodal";
import { multiToodal } from "views/block/ui-elements/multi-toodal/multi-toodal";
import _ from "underscore";
import interfaceChannel from "views/block/channels/interface-channel";

/**
 * Closes all menus and todals*
 */
function closeMenus(e) {
  if (!contextMenu.el.contains(e.target)) {
    contextMenu.close();
  }
  if (!toodal.el.contains(e.target)) {
    interfaceChannel.trigger("toodal:close");
  }
  if (!multiToodal.el.contains(e.target)) {
    interfaceChannel.trigger("multi-toodal:close");
  }
}

// throttle this function so that device that support both mouse and touch only do this once
export const closeOpenedMenus = _.throttle(closeMenus, 100, {
  trailing: false,
});
