import Modal from "../modal";
import translate from "utils/localisation";
import { task } from "globals/task";
import { hasTaxonomy } from "utils/taxonomy";
import interfaceChannel from "views/block/channels/interface-channel";
import { AppNamePlugin } from "./../shared/app-name";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "save-as"].join(" "),

  template: require("./save-as.hbs"),
  style: require("./save-as.styl"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "submit .save-app-form": "saveAs",
        "click .save-app-confirm": "saveAs",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    this.getTask().get("metadata").generateThumbnailData();
    const isExampleApp = hasTaxonomy(task, "task-type.example-app");
    const banner =
      this.getTask().get("metadata")?.toJSON()?.thumbnail?.raw_data ||
      this.getTask().get("metadata")?.toJSON()?.thumbnail?.src ||
      null;
    let newAppInfo;
    let name = this.getTask().get("name");
    if (name === "Free code") {
      // when saving for the first time, the app name will default to 'Free code'
      // in this case blank it out to force the user to pick a name
      name = "";
      newAppInfo = this.getNewAppInfo(this.stageSize);
    } else {
      // otherwise default to `Copy of {APP_NAME}`
      name = translate("Copy of {APP_NAME}").replace("{APP_NAME}", name);
    }

    this.$el.html(
      this.template({
        name,
        newAppInfo,
        banner,
      }),
    );
    this.$el.toggleClass(
      "with-banner",
      Boolean(!isExampleApp && banner && !newAppInfo),
    );
    this.$el.toggleClass(
      "with-new-app-info",
      Boolean(!isExampleApp && !banner && newAppInfo),
    );
    this.delegateEvents();
    this.checkName();
  },

  async open(newTask, stageSize) {
    this.task = newTask;
    this.stageSize = stageSize;
    Modal.prototype.open.call(this, this.getTask());
  },

  async saveAs(e) {
    e.preventDefault();
    let name = this.$("input.app-name").val();
    let currentTask = this.getTask();

    try {
      currentTask.get("metadata").prepFork(currentTask);
      const result = await currentTask.fork(name);
      interfaceChannel.trigger("request:modal", "saved-as", result);
    } catch (e) {
      interfaceChannel.trigger("request:modal", "save-failure", e);
    }
  },

  getTask() {
    return this.task || task;
  },

  getNewAppInfo(stageSize) {
    if (!stageSize) {
      return null;
    }

    const [width, height] = stageSize.split("x").map(s => Number(s));
    if (width === height) {
      return {
        svg: "nebula_square",
        name: "Square",
        size: stageSize,
        class: "square",
      };
    } else if (width > height) {
      return {
        svg: "nebula_landscape",
        name: "Landscape",
        size: stageSize,
        class: "landscape",
      };
    } else {
      return {
        svg: "nebula_portrait",
        name: "Portrait",
        size: stageSize,
        class: "portrait",
      };
    }
  },
});
