import { closeOpenedMenus } from "utils/close-opened-menus";
/**
 * Handles interaction of a zoomable canvas
 * Plugs into BlockInteractionZone
 */
export class ZoomableCanvas {
  constructor(parent) {
    this.parent = parent;
    this.__scale = 1;
  }

  onGestureStart(e) {
    const model = e.target.model;
    this.__scale = model.get("scale");
  }
  onGestureEnd() {}
  onGesture(e) {
    closeOpenedMenus(e);

    const model = e.target.model;
    model.trigger("zoom", this.__scale * e.scale);
  }
}
