import { unpack } from "./variable";

/**
 * Cast a value to a sound
 *
 * Sounds are just strings behind the scenes
 *
 * @param  {any}     val - The thing to transform to a sound
 * @return {String}        The sound
 */
export function sound(val) {
  val = unpack(val);

  return String(val);
}
