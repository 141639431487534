import { string } from "./../types/string";
import * as validate from "./../utils/validate-inputs";
import { console as blockConsole } from "globals/console";

/**
 * Logs a message to the console
 *
 * @param {Object:App} object - The app object
 * @param {String} message - The message to be logged in the console
 */
export default function feedback(object, message) {
  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  message = string(message);
  blockConsole.log(message);
}
