import { unpack } from "../types/variable";
import * as validate from "../utils/validate-inputs";
import MOVE from "./move";

export function moveTowards(object, target) {
  target = unpack(target);

  if (!validate.object(target)) {
    throw Error(validate.error.object);
  }

  let angle = 0;

  // OBJECT -> OBJECT
  if (validate.physical(target)) {
    angle = object.position.angle(target, true);
  }

  // OBJECT -> POINTER
  if (validate.pointer(target)) {
    const point = target.position.clone();
    // we need to add camera offset when dealing with the cursor
    point.x += this.camera.x;
    point.y += this.camera.y;
    angle = object.position.angle(point, true);
  }

  // OBJECT -> APP
  if (validate.app(target)) {
    const world = object.game.world;
    angle = object.position.angle(
      new Phaser.Point(world.centerX, world.centerY),
      true,
    );
  }

  let point = new Phaser.Point(1, 0);
  point.rotate(0, 0, angle, true);

  MOVE(object, {
    x: point.x,
    y: point.y,
    angle,
    type: "vector",
  });
}
