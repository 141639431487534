var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partial-shapes/chevron-left.hbs"),depth0,{"name":"partial-shapes/chevron-left","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partial-shapes/circle-left.hbs"),depth0,{"name":"partial-shapes/circle-left","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <jigsaw-plus>\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/plus-icon.hbs"),depth0,{"name":"partial-shapes/plus-icon","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </jigsaw-plus>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partial-shapes/chevron-right.hbs"),depth0,{"name":"partial-shapes/chevron-right","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./partial-shapes/circle-right.hbs"),depth0,{"name":"partial-shapes/circle-right","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<block-head class=\"block-partial drag-handle\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"connectLeft") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "</block-head>\r\n<block-body class=\"block-partial drag-handle\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-horizontal-body.hbs"),depth0,{"name":"partial-shapes/block-horizontal-body","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../helpers/ifFeatureFlag.js")).call(alias1,"BLOCK_CONTEXT_MENU_ADD_CODE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":15,"column":20}}})) != null ? stack1 : "")
    + "</block-body>\r\n<block-tail class=\"block-partial drag-handle\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"connectRight") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + "</block-tail>\r\n\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-error-label.hbs"),depth0,{"name":"partial-shapes/block-error-label","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n<jigsaw-fill-container>\r\n</jigsaw-fill-container>\r\n\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-search-words.hbs"),depth0,{"name":"partial-shapes/block-search-words","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});