import Modal from "../modal";
import { task } from "globals/task";
import { app } from "app";
import translate from "utils/localisation";

const LEVELS = [
  {
    grade: "all",
    label: "Everything",
    img: "dist/images/grade_thumbs/everything.png",
  },
  {
    grade: 1,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/1.png",
  },
  {
    grade: 2,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/2.png",
  },
  {
    grade: 3,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/3.png",
  },
  {
    grade: 4,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/4.png",
  },
  {
    grade: 5,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/5.png",
  },
  {
    grade: 6,
    label: "Level {grade}",
    img: "dist/images/grade_thumbs/6.png",
  },
];

export const ChooseLevelModal = Modal.extend({
  className: [Modal.prototype.className, "choose-level"].join(" "),

  template: require("./choose-level.hbs"),
  style: require("./choose-level.styl"),

  events: Object.assign(
    {
      "click button.card": "onButtonClicked",
    },
    Modal.prototype.events,
  ),

  getBlocksForLevel(grade) {
    // inline require needed due to circular dependencies
    const module = require("utils/block-cache/block-cache");

    const blocks = [
      ...module.BLOCK_CACHE_STATIC.allBlocks,
      ...module.BLOCK_CACHE_CHEST_PRIMITIVES.allBlocks,
    ];

    return blocks.filter(block =>
      module.BlockCache.filterGradeBlocks(block, grade),
    ).length;
  },

  async render() {
    const data = JSON.parse(JSON.stringify(LEVELS));
    const current = task.getGrade();

    // inline require needed due to circular dependencies
    const module = require("utils/block-cache/block-cache");
    await module.BLOCK_CACHE_STATIC.loadBlocks();
    await module.BLOCK_CACHE_CHEST_PRIMITIVES.loadBlocks();

    data.forEach(level => {
      level.label = translate(level.label).replace("{grade}", level.grade);
      level.active = level.grade == current;
      level.count = this.getBlocksForLevel(level.grade);
      level.countLabel = translate("{count} blocks").replace(
        "{count}",
        level.count,
      );
    });

    this.$el.html(this.template(data));
  },

  onButtonClicked(e) {
    this.changeGrade(e.currentTarget.dataset.grade);
  },

  async changeGrade(grade = "all") {
    grade = grade === "all" ? "all" : Number(grade);

    await this.close();

    if (grade == task.getGrade()) {
      return;
    }

    app.destroyLayout();
    task.setGrade(grade);
    await app.setLayout();
  },
});
