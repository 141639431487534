import { number } from "./number";
import { unpack } from "./variable";
import { error } from "../utils/validate-inputs";

/**
 * Cast a value to an angle
 *
 * @param {any} val - Input value
 * @return {Number|NaN|null}
 */
export function angle(val) {
  val = unpack(val);

  // value is an object with an angle
  if (typeof val === "object" && "angle" in val) {
    try {
      return number(val.angle);
    } catch (e) {
      throw Error(error.angle);
    }
  }

  // value is an object with an x and y property
  // calculate angle
  if (typeof val === "object" && "x" in val && "y" in val) {
    const x = val.x;
    const y = val.y;

    // null vector - has no angle
    if (x === 0 && y === 0) {
      return null;
    }

    // pythagoras!
    return Math.atan2(y, x) * (180 / Math.PI);
  }

  try {
    return number(val);
  } catch (e) {
    throw Error(error.angle);
  }
}
