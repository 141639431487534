import * as validate from "./../utils/validate-inputs";
import { getKeyMirrors } from "../../blocks/primitive/types/key-mapping";

// onKeyPress - triggers whenever a key is pressed
export default function onKeyPress(callback, keyCode) {
  if (!validate.key(keyCode)) {
    throw Error(validate.error.key);
  }

  const key = this.input.keyboard.addKey(keyCode);
  key.onDown.add(() => callback());

  // also bind events to all mirrored keys
  getKeyMirrors(keyCode).forEach(keyCode =>
    onKeyPress.call(this, callback, keyCode),
  );
}
