var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"search-result\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"blocksFoundLabel") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"block-section-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"input") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasBlocksSection") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":41,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <h3>\n    "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n  </h3>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <form class=\"choose-code-form\" data-block-type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n    <input type=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"inputType") : stack1), depth0))
    + "\" value=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"value") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":70}}}))
    + "\" placeholder=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":85},"end":{"line":16,"column":116}}}))
    + "\"\n      aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"placeholder") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":49}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"step") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":51},"end":{"line":17,"column":98}}})) != null ? stack1 : "")
    + " tabindex=\"0\"\n      autofocus>\n\n    <button type=\"submit\" tabindex=\"0\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,"Add",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":70}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,"Add",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":72},"end":{"line":20,"column":91}}}))
    + "</button>\n  </form>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "step=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"input") : depth0)) != null ? lookupProperty(stack1,"step") : stack1), depth0))
    + "\" ";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <section class=\"choose-code-group "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"category") : depth0), depth0))
    + "\" aria-label=\""
    + alias1(__default(require("../../../../../templates/helpers/translate.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"category") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":62},"end":{"line":25,"column":84}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"blocks") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":30,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"addObjectButton") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + "  </section>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"block-container\" data-category=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "\" data-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" tabindex=\"0\" autofocus>\n      <div class=\"click-interceptor\" data-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" data-category=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"category") : depths[1]), depth0))
    + "\"></div>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class=\"add-object-button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"addObjectButton") : depth0)) != null ? lookupProperty(stack1,"goTo") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":38},"end":{"line":33,"column":111}}})) != null ? stack1 : "")
    + "\n      title=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"addObjectButton") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"addObjectButton") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "\" tabindex=\"0\" autofocus>\n      <svg>\n        <use xlink:href=\"#add-object\"></use>\n      </svg>\n    </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-goto=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"addObjectButton") : depth0)) != null ? lookupProperty(stack1,"goTo") : stack1), depth0))
    + "\" ";
},"15":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This method for controlling sounds is no longer supported. To edit this code you will need to recreate it.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":2},"end":{"line":48,"column":124}}}))
    + "\n  ";
},"17":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"There are no code blocks left to add here.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":10},"end":{"line":49,"column":68}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"blocksFoundLabel") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":43,"column":9}}})) != null ? stack1 : "")
    + "\n\n<section class=\"error-message\" aria-label=\""
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Error message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":43},"end":{"line":46,"column":72}}}))
    + "\" role=\"alert\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeprecatedSound") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":47,"column":2},"end":{"line":49,"column":75}}})) != null ? stack1 : "")
    + "\n</section>\n";
},"useData":true,"useDepths":true});