import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

//load all blueprints from folder
const BLUEPRINTS = {};
const context = require.context("./blueprints/", true, /\.json$/);
context.keys().forEach(key => {
  const blueprint = context(key);

  BLUEPRINTS[blueprint.fn] = blueprint;
});

/**
 * EVENT block
 * Runs code when a particular event happens
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "code"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    fn: null,
    /**
     * Global events are events that are bound to the game itself rather than
     * specific object instances. These types of events do NOT need to be
     * re-bound when an object is created at runtime
     */
    global: true,
    args: {},
    code: {},
    "top-level-only": true,
  }),

  initialize(options) {
    this.blueprint = BLUEPRINTS[this.get("fn")];

    CodeBlock.prototype.initialize.call(this, options);
  },

  getPseudoCode(indent = "") {
    const args = this.get("args").getPseudoCode();
    const code = this.get("code").getPseudoCode(indent);

    return `${indent}${this.get("fn")}${args} ${code}\n`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("code").getChildBlocks(list);
    this.get("args").getChildBlocks(list);
    return list;
  },

  // activates this block and runs all scoped code
  // to be called when the event triggers
  async _triggerEvent(scope) {
    this.activate();

    const result = await this.get("code").run(scope);

    this.deactivate();

    return result;
  },

  async execute() {
    // prepare callback function
    const code = scope => this._triggerEvent(scope);

    // compute arguments
    const args = await this.get("args").compute();

    return game.exec(this.get("fn"), code, ...args);
  },

  // executes this event for an object that was created at runtime
  async runtimeExecute(object) {
    // prepare callback function
    const code = scope => this._triggerEvent(scope);

    // compute arguments
    let args = await this.get("args").compute();
    let applicable = false;

    // massage arguments so that they only apply to the specified object
    args = args.map(arg => {
      if (arg === object || (Array.isArray(arg) && arg.includes(object))) {
        applicable = true;
        return [object];
      } else {
        return arg;
      }
    });

    if (applicable) {
      return game.exec(this.get("fn"), code, ...args);
    }
  },

  _placementMessage: ERRORS.PLACEMENT_EVENT,
  _validatePlacement(parent) {
    const blockCoder = this.getBlockCoder();
    const isJigsawMode = blockCoder.get("interaction-mode") === "jigsaw";
    // while in jigsaw mode this block is not allowed on stage
    if (
      isJigsawMode &&
      parent.get("free-form") &&
      (this.get("jigsaw-clone") || this.get("distractor-clone"))
    ) {
      return false;
    }
    return parent instanceof BlockScope && parent.get("free-form");
  },
});
