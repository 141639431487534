import * as validate from "./../utils/validate-inputs";

export default function isCloned(object) {
  // if we are checking more than one object, return true if any of them are clones
  if (Array.isArray(object)) {
    return object
      .map(item => isCloned(item))
      .reduce((acc, val) => acc || val, false);
  }

  if (validate.physical(object)) {
    return object["is-cloned"] || false;
  } else {
    throw Error(validate.error.object);
  }
}
