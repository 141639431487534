import interact from "interactjs";
import interfaceChannel from "../../channels/interface-channel";

import "../../ui-elements/toodal/toodal";
import "../../ui-elements/multi-toodal/multi-toodal";
import "../../ui-elements/context-menu/context-menu";

import { DraggableCanvas } from "./draggable-canvas";
import { DraggableBlock } from "./draggable-block";
import { DroppableScope } from "./droppable-scope";
import { DroppableBin } from "./droppable-bin";
import { ClickableBlock } from "./clickable-block";
import { ClickableBody } from "./clickable-body";
import { HoldableCanvas } from "./holdable-canvas";
import { HighlightBlock } from "./highlight-block";
import { ZoomableCanvas } from "./zoomable-canvas";
import { TabbableBlocks } from "./tabbable-blocks";
import { KeyboardControls } from "./keyboard-controls";
import style from "./interaction.styl";
style.use();

// apply minimum move distance for drag & drop
// this prevents imprecise touch / drag events from interfering with one another
interact.pointerMoveTolerance(16);

export class BlockInteractionZone {
  constructor(el) {
    this.el = el;
    this.el.classList.add("block-interaction-zone");

    this.draggableCanvas = new DraggableCanvas(this);
    this.draggableBlock = new DraggableBlock(this);
    this.droppableScope = new DroppableScope(this);
    this.droppableBin = new DroppableBin(this);
    this.clickableBlock = new ClickableBlock(this);
    this.clickableBody = new ClickableBody(this);
    this.highlightBlock = new HighlightBlock(this);
    this.zoomableCanvas = new ZoomableCanvas(this);
    this.tabbableBlocks = new TabbableBlocks(this);
    this.keyboardControls = new KeyboardControls(this);
    this.holdableCanvas = new HoldableCanvas(this);

    /**
     * This event allows external files to notify the BlockInteractionZone of
     * changes to the DOM.
     *
     * This is necessary because interactJS does not re-calculate bounding boxes
     * unless specifically told so. We also don't want to do this unless
     * absolutely necessary as this has a negative impact on performance.
     */
    this.dynamicDrop = true;
    interfaceChannel.on("block-interaction-zone:dynamic-drop", () => {
      this.dynamicDrop = true;
    });
  }

  initialize() {
    interact(".gesturable")
      .gesturable({
        onstart: e => this.zoomableCanvas.onGestureStart(e),
        onmove: e => this.zoomableCanvas.onGesture(e),
        onend: e => this.zoomableCanvas.onGestureEnd(e),
      })
      .draggable({
        onstart: e => this.draggableCanvas.onDragStart(e),
        onend: e => this.draggableCanvas.onDragEnd(e),
        onmove: e => this.draggableCanvas.onMove(e),
        max: 1,
        cursorChecker: BlockInteractionZone.cursorChecker,
      })
      .actionChecker(BlockInteractionZone.actionChecker);

    interact(".draggable.interactive")
      .draggable({
        onstart: e => this.draggableBlock.onDragStart(e),
        onend: e => this.draggableBlock.onDragEnd(e),
        onmove: this._onMove.bind(this),
        max: 1,
        cursorChecker: BlockInteractionZone.cursorChecker,
      })
      .actionChecker(BlockInteractionZone.actionChecker);

    interact(".droppable").dropzone({
      accept: ".draggable",
      checker: this._dropCheck.bind(this),
      ondragenter: this._onDragEnter.bind(this),
      ondragleave: this._onDragLeave.bind(this),
      ondrop: this._onDrop.bind(this),
    });

    interact(".block-partial").on("hold", e => this.clickableBlock.onHold(e));
    interact(".code-canvas").on("hold", e => this.holdableCanvas.onHold(e));
    interact(".clickable.interactive").on("tap", e =>
      this.clickableBlock.onClick(e),
    );

    this.el.addEventListener("contextmenu", e =>
      this.clickableBlock.onContextMenu(e),
    );
    this.el.addEventListener("touchstart", e =>
      this.clickableBody.onTouchStart(e),
    );
    this.el.addEventListener("mousedown", e =>
      this.clickableBody.onMouseDown(e),
    );
    this.el.addEventListener("focusin", e => this.tabbableBlocks.onFocus(e));
    this.el.addEventListener("keydown", e =>
      this.keyboardControls.onKeydown(e),
    );

    this.el.addEventListener("mousemove", e => this.highlightBlock.onMove(e));
  }

  // check whether an event is done via left click (or touch)
  static isLeftClick(e) {
    return e.type === "touchstart" || e.button === 0;
  }

  static actionChecker(pointer, event, action) {
    if (BlockInteractionZone.isLeftClick(event)) {
      return action;
    } else {
      return null;
    }
  }

  static cursorChecker(action, interactable, element, interacting) {
    return interacting ? "grabbing" : "grab";
  }

  /**
   * Finds the DOM element of the code block that is being dragged
   *
   * Because blocks have weird non-rectangle shapes, and we want to allow the
   * user to click through their empty space, we only allow dragging to be
   * initiated from inside their solid parts. As such, we need to be able to
   * find the parent code block element based on one of their parts
   *
   * @param {Element} el the `.draggable` part of the block that initiated the drag
   * @returns {Element} The closest parent `.super-block` element
   */
  getBlockEl(el) {
    if (!el.classList.contains("block-partial")) {
      return el;
    }

    return el.closest(".super-block");
  }

  _onMove(e) {
    // let interactJS re-calculate bounding boxes when necessary
    if (this.dynamicDrop) {
      interact.dynamicDrop(true);
      this.dynamicDrop = false;
    } else {
      interact.dynamicDrop(false);
    }

    this.draggableBlock.onMove(e);
  }

  /**
   * Check whether the dragged element can be dropped in this dropzone
   *
   * Note: when a draggable is picked up, **every** droppable will immediately
   * run this check. Keep this function as light as possible as there can easily
   * be hundreds of droppables on the page at one time.
   */
  _dropCheck(
    dragEvent,
    event,
    dropped,
    dropzone,
    dropElement,
    draggable,
    draggableElement,
  ) {
    const dropModel = dropElement.model;
    const dragModel =
      draggableElement.model ||
      (this.draggableBlock.dragging && this.draggableBlock.dragging.model);

    if (!dragModel) {
      return false;
    }

    if (dragModel.has("free-form")) {
      // a free-form canvas cannot be 'dropped' anywhere
      return false;
    }

    if (dropElement.classList.contains("bin")) {
      // dropping a block into a bin
      return dropped && dragModel;
    }

    // dropping a block into another block / scope
    return (
      dropped &&
      dropModel &&
      dragModel &&
      dropModel.accepts(dragModel) &&
      dropElement.closest(".dragging") === null // prevent a draggable from being dropped inside of itself
    );
  }

  _onDragEnter(e) {
    if (e.target.classList.contains("bin")) {
      this.droppableBin.onDragEnter(e);
    } else {
      this.droppableScope.onDragEnter(e);
    }
  }

  _onDragLeave(e) {
    if (e.target.classList.contains("bin")) {
      this.droppableBin.onDragLeave(e);
    } else {
      this.droppableScope.onDragLeave(e);
    }
  }

  _onDrop(e) {
    if (e.target.classList.contains("bin")) {
      this.droppableBin.onDrop(e);
    } else {
      this.droppableScope.onDrop(e);
    }
  }
}
