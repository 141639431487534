import * as validate from "./../utils/validate-inputs";

export default function onOutOfBounds(callback, object) {
  if (Array.isArray(object)) {
    return Promise.all(object.map(object => onOutOfBounds(callback, object)));
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.physical);
  }

  object.checkWorldBounds = true;
  object.events.onOutOfBounds.add(() => {
    if (object.__hasBeenOnStage) {
      callback([object]);
    }
  });
}
