var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Name and create your app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":40}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save as",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":26}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"save-app-form\">\n"
    + ((stack1 = container.invokePartial(require("../shared/app-name.hbs"),depth0,{"name":"../shared/app-name","hash":{"name":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</form>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"cancel\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":56},"end":{"line":16,"column":78}}}))
    + "\" autofocus>"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":90},"end":{"line":16,"column":112}}}))
    + "</button>\n<button class=\"confirm save-app-confirm form-submit\" aria-label=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":17,"column":65},"end":{"line":21,"column":9}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":22,"column":2},"end":{"line":26,"column":9}}})) != null ? stack1 : "")
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "\n  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":18,"column":28}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":20,"column":25}}}))
    + "\n  ";
},"13":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":23,"column":28}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":25,"column":25}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":13,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":28,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});