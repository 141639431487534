import makeThumbnail from "./../utils/make-thumbnail";

export default function Sprite(game, model) {
  const phaserKey = model.get("phaserKey");
  const frames = model.get("frames") || 1;

  // transform the cached image into a spritesheet
  // note: spritesheets are preloaded as images, because we need to know what
  // width they are before we can divide them into frames
  const img = game.cache.getImage(phaserKey);
  game.cache.addSpriteSheet(
    phaserKey,
    img.src,
    img,
    img.height,
    img.width / frames,
  );

  Phaser.Sprite.call(this, game, 0, 0, `${phaserKey}`);
  makeThumbnail.call(this, model);
}

Sprite.prototype = Object.create(Phaser.Sprite.prototype);
Sprite.prototype.constructor = Sprite;
