import { canCollide } from "./can-collide";

export default function collisionFilter(a, b) {
  const collisions = a["collides-with"];
  let match;

  if (!canCollide(a) || !canCollide(b)) {
    return false;
  }

  if (b instanceof Phaser.Tile) {
    match = collisions.find(c => c.type === "tile" && c.index === b.index);
  }
  if (b instanceof Phaser.Sprite) {
    match = collisions.find(c => c.type === "object" && c.target === b);
  }
  return Boolean(match);
}
