import { string } from "./../types/string";
import { console as blockConsole } from "globals/console";
import { translate } from "utils/localisation";
import WARNINGS from "./../../blocks/warnings";

// this method checks if the name given to the object is unique
export function validateObjectName(object, value) {
  value = string(value);

  if (!value) {
    return null;
  }

  const game = object.game;
  if (game.mode === "run" && checkForDuplicateObjectName(object, value)) {
    blockConsole.warn(
      translate(WARNINGS.RUNTIME_INVALID_NAME).replace("{name}", value),
      `duplicate-name-${value}`,
    );
  }

  return value;
}

/**
 * Check for duplicate objectName
 * @param {Phaser.Sprite} object
 * @param {String} [name] - The objectName to check, object.objectName is used when omitted
 * @returns true if the objectName is already in use
 */
export function checkForDuplicateObjectName(object, name = object.objectName) {
  return Boolean(
    object.game.objectGroup.children.find(
      sprite => sprite !== object && sprite.objectName === name,
    ),
  );
}

export function getObjectByName(objectName) {
  objectName = string(objectName);

  if (!objectName) {
    blockConsole.warn(
      translate(WARNINGS.RUNTIME_EMPTY_NAME),
      "get-empty-object-name",
    );
    return null;
  }

  const targetObject = this.objectGroup.children.find(
    childObject => childObject.objectName === objectName,
  );

  return targetObject;
}
