import CodeBlock from "./block-prototype";
import BlockContentsView from "./components/block-contents";

export default CodeBlock.extend({
  tagName: "operator-block",
  className: CodeBlock.prototype.className + " code-block",
  template: require("templates/block/block-types/operator.hbs"),
  initialize: function () {
    CodeBlock.prototype.initialize.call(this);

    this.$el.attr("type", this.model.get("fn"));

    this.defineChildView(
      function () {
        return new BlockContentsView({
          model: this.model,
        });
      }.bind(this),
      "args",
      "> block-body",
    );

    this.$el.toggleClass(
      "multi-arg",
      this.model.get("args").get("arguments").length > 1,
    );
  },
});
