import Backbone from "custom/backbone-bundle";
import CodeBlock from "./../code-block";
import PrototypeModel from "models/prototype-model";
import TestablePrototype from "models/prototypes/testable";

/**
 * Argument
 * this model represents a single argument
 * when run, the argument is computed and its value is returned
 */
export default PrototypeModel.extend(TestablePrototype).extend({
  relations: TestablePrototype.relations.concat([
    {
      type: Backbone.HasOne,
      key: "code",
      parse: true,
      runTests: true,
      relatedModel: CodeBlock,
      reverseRelation: {
        key: "parent-argument",
        includeInJSON: false,
        type: Backbone.HasOne,
      },
    },
  ]),

  defaults: {
    code: null,
    name: "argument",
    "has-brackets": false,
    argumentSuggestions: [],
  },

  initialize(options) {
    PrototypeModel.prototype.initialize.call(this, options);
    this.on("change:code", this.updateBrackets);
    this.updateBrackets();
  },

  updateBrackets() {
    const code = this.get("code");
    let brackets = false;
    const types = ["object", "this", "object-named", "cloned", "operator"];

    if (code && types.includes(code.get("type"))) {
      brackets = true;
    }

    this.set("has-brackets", brackets);
  },

  getPseudoCode() {
    return this.get("code").getPseudoCode();
  },

  getChildBlocks(list = []) {
    if (this.get("code")) {
      this.get("code").getChildBlocks(list);
    }

    return list;
  },

  /** delete this argument and any code inside of it */
  delete() {
    if (this.get("code")) {
      this.get("code").delete();
    }
    return this;
  },

  /** given some input, test whether it is functionally the same as this */
  isSame(other) {
    if (!other || other.constructor !== other.constructor) {
      return false;
    }

    //both argument slots are empty
    if (!this.has("code") && !other.has("code")) {
      return true;
    }

    return this.get("code")?.isSame(other.get("code"));
  },

  /** returns true if the given model can be placed inside of an argument slot */
  accepts(model) {
    const parentBlock = this.get("parent")?.get("parent");

    // only allow object blocks inside these types
    if (
      parentBlock.get("type") === "this" ||
      parentBlock.get("type") === "cloned"
    ) {
      return model.get("type") === "object";
    }

    // control blocks can't be placed inside of arguments
    if (model.get("control-block")) {
      return false;
    }

    // don't allow these inside arguments
    return (
      model.get("type") !== "event" &&
      model.get("type") !== "assign-variable" &&
      model.get("type") !== "change-variable" &&
      model.get("type") !== "command" &&
      model.get("type") !== "placeholder"
    );
  },

  /** run the code inside of this argument and resolves with its value */
  // eslint-disable-next-line require-await
  async run(...args) {
    const code = this.get("code");

    if (!this.has("code")) {
      return null;
    } else if (typeof code.run === "function") {
      return code.run(...args);
    } else {
      return code;
    }
  },

  getParentBlock() {
    return this.get("parent").get("parent");
  },

  getTopLevelScope() {
    return this.getParentBlock().getTopLevelScope();
  },

  hasCustomKeyboardControls() {
    return true;
  },

  canHaveFocus() {
    if (this.getParentBlock().get("jigsaw")) {
      return false;
    }

    return !this.has("code");
  },

  getHorizontalCodeChain(chain = []) {
    chain.push(this);
    if (this.has("code")) {
      this.get("code").getHorizontalCodeChain(chain);
    }
    return chain;
  },

  getHorizontalCodeChainParent() {
    return this.get("parent").getHorizontalCodeChainParent();
  },

  getVerticalCodeChainParent() {
    return this.get("parent").getVerticalCodeChainParent();
  },

  /**
   * Filters argument suggestions defined in block blueprint
   * @returns {ArgumentSuggestion[]}
   */
  getArgumentSuggestions() {
    let firstArgValue = this.getPreviousArgument();

    let suggestions = (this.get("argumentSuggestions") || []).map(
      suggestion => new ArgumentSuggestion(suggestion),
    );
    // filter suggestions based on first argument
    if (firstArgValue) {
      suggestions = suggestions.filter(
        s => !s.blueprint.firstArg || s.blueprint.firstArg === firstArgValue,
      );
    }
    return suggestions;
  },
  //  If a block with two arguments, get the value of the first argument
  getPreviousArgument() {
    const index = this.get("parent").get("arguments").indexOf(this);
    if (index === 0) {
      return null;
    } else {
      return this.get("parent")
        .get("arguments")
        .at(0)
        ?.get("code")
        ?.get("value");
    }
  },

  /**
   * Filters contextual arguments based on block context
   * @returns {ArgumentSuggestion[]}
   */
  getContextualArguments() {
    const fn = this.get("parent").get("parent")?.get("fn");
    const parentObjectType = this.__getParentObjectBlock()?.get("target")?.type;
    const index = this.get("parent").get("arguments").indexOf(this);

    // time options
    if (index === 0 && fn === "time") {
      return TIMES;
    }

    // round operator
    if (index === 1 && fn === "round") {
      if (this.get("code")?.get("type") === "string") {
        return DECIMAL_PRECISION_DEPRECATED;
      }

      return DECIMAL_PRECISION;
    }

    // rotation styles
    if (index === 0 && fn === "rotationStyle") {
      return ROTATION_STYLES;
    }

    // text alignments
    if (
      index === 1 &&
      this.get("parent").get("arguments").at(0)?.get("code")?.get("value") ===
        "alignment" &&
      (fn === "get" || fn === "set" || fn === "changeBy" || fn === "matchTo")
    ) {
      return ALIGNMENTS;
    }

    // object properties
    if (
      index === 0 &&
      (fn === "get" || fn === "set" || fn === "changeBy" || fn === "matchTo")
    ) {
      // cursor
      if (parentObjectType === "pointer") {
        return CURSOR_PROPERTIES;
      }

      // text object
      if (parentObjectType === "text") {
        return TEXT_PROPERTIES;
      }

      // object named
      if (parentObjectType === "object-named") {
        return OBJECT_NAMED_PROPERTIES;
      }

      // sprite object
      if (parentObjectType === "sprite") {
        return SPRITE_PROPERTIES;
      }

      // app object
      if (parentObjectType === "app") {
        return APP_PROPERTIES;
      }

      // camera object
      if (parentObjectType === "camera") {
        return CAMERA_PROPERTIES;
      }

      // not attached to object
      if (parentObjectType === null) {
        return;
      }

      // default properties
      return PROPERTIES;
    }
  },

  __getParentObjectBlock() {
    try {
      let block = this.get("parent").get("parent").getParentBlock();
      if (block.get("type") === "this" || block.get("type") === "cloned") {
        // if the parent is a `this`-block, grab the object that is inside of it
        block = block.get("args").get("arguments").at(0).get("code");
      }
      return block;
    } catch (e) {
      return null;
    }
  },
});

/**
 * Argument suggestions
 */
class ArgumentSuggestion {
  constructor(blueprint) {
    this.blueprint = blueprint;
  }

  /**
   * Checks whether a code block matches this suggestion
   * @param {CodeBlock} block the code block to check
   * @returns
   */
  match(block) {
    if (block.has("commands") && block.get("commands").get("code").length > 0) {
      // suggestions can never contain object + command combinations
      return false;
    }
    for (const property in this.blueprint) {
      if (property === "firstArg") {
        continue;
      }
      if (block.get(property) != this.blueprint[property]) {
        return false;
      }
    }

    return true;
  }
}

const TIMES = [
  new ArgumentSuggestion({ value: "All", type: "string" }),
  new ArgumentSuggestion({ value: "Hours: 12 hrs", type: "string" }),
  new ArgumentSuggestion({ value: "Hours: 24 hrs", type: "string" }),
  new ArgumentSuggestion({ value: "Minutes", type: "string" }),
  new ArgumentSuggestion({ value: "Seconds", type: "string" }),
];
const PROPERTIES = [
  new ArgumentSuggestion({ isInput: true, type: "string" }),
  new ArgumentSuggestion({ value: "angle", type: "string" }),
  new ArgumentSuggestion({ value: "rotation", type: "string" }),
  new ArgumentSuggestion({ value: "heading", type: "string" }),
  new ArgumentSuggestion({ value: "speed", type: "string" }),
  new ArgumentSuggestion({ value: "friction", type: "string" }),
  new ArgumentSuggestion({ value: "height", type: "string" }),
  new ArgumentSuggestion({ value: "width", type: "string" }),
  new ArgumentSuggestion({ value: "visible", type: "string" }),
  new ArgumentSuggestion({ value: "x", type: "string" }),
  new ArgumentSuggestion({ value: "y", type: "string" }),
  new ArgumentSuggestion({ value: "cameraX", type: "string" }),
  new ArgumentSuggestion({ value: "cameraY", type: "string" }),
  new ArgumentSuggestion({ value: "angularVelocity", type: "string" }),
  new ArgumentSuggestion({ value: "gravity", type: "string" }),
  new ArgumentSuggestion({ value: "objectName", type: "string" }),
];
const SPRITE_PROPERTIES = [...PROPERTIES].concat(
  new ArgumentSuggestion({
    value: "frame",
    type: "string",
  }),
);
const TEXT_PROPERTIES = [...PROPERTIES].concat(
  new ArgumentSuggestion({ value: "text", type: "string" }),
  new ArgumentSuggestion({ value: "alignment", type: "string" }),
);
const CURSOR_PROPERTIES = [
  new ArgumentSuggestion({ value: "heading", type: "string" }),
  new ArgumentSuggestion({ value: "speed", type: "string" }),
  new ArgumentSuggestion({ value: "x", type: "string" }),
  new ArgumentSuggestion({ value: "y", type: "string" }),
  new ArgumentSuggestion({ value: "cameraX", type: "string" }),
  new ArgumentSuggestion({ value: "cameraY", type: "string" }),
];
const APP_PROPERTIES = [
  new ArgumentSuggestion({ value: "width", type: "string" }),
  new ArgumentSuggestion({ value: "height", type: "string" }),
];
const OBJECT_NAMED_PROPERTIES = [...PROPERTIES];
const ALIGNMENTS = [
  new ArgumentSuggestion({ value: "center", type: "string" }),
  new ArgumentSuggestion({ value: "top", type: "string" }),
  new ArgumentSuggestion({ value: "bottom", type: "string" }),
  new ArgumentSuggestion({ value: "left", type: "string" }),
  new ArgumentSuggestion({ value: "right", type: "string" }),
  new ArgumentSuggestion({ value: "top-left", type: "string" }),
  new ArgumentSuggestion({ value: "top-right", type: "string" }),
  new ArgumentSuggestion({ value: "bottom-left", type: "string" }),
  new ArgumentSuggestion({ value: "bottom-right", type: "string" }),
];
const DECIMAL_PRECISION_DEPRECATED = [
  // deprecated
  new ArgumentSuggestion({ value: ".0", type: "string" }),
  new ArgumentSuggestion({ value: ".00", type: "string" }),
  new ArgumentSuggestion({ value: ".000", type: "string" }),
];
const DECIMAL_PRECISION = [
  new ArgumentSuggestion({ value: 100, type: "number" }),
  new ArgumentSuggestion({ value: 10, type: "number" }),
  new ArgumentSuggestion({ value: 1, type: "number" }),
  new ArgumentSuggestion({ value: 0.1, type: "number" }),
  new ArgumentSuggestion({ value: 0.01, type: "number" }),
  new ArgumentSuggestion({ value: 0.001, type: "number" }),
];
const ROTATION_STYLES = [
  new ArgumentSuggestion({ value: "no-rotate", type: "string" }),
  new ArgumentSuggestion({ value: "left-right", type: "string" }),
  new ArgumentSuggestion({ value: "direction", type: "string" }),
];
const CAMERA_PROPERTIES = [
  new ArgumentSuggestion({ value: "x", type: "string" }),
  new ArgumentSuggestion({ value: "y", type: "string" }),
  new ArgumentSuggestion({ value: "width", type: "string" }),
  new ArgumentSuggestion({ value: "height", type: "string" }),
];
