import * as validate from "./../utils/validate-inputs";

/**
 * Disables collisions between object and target
 * Has no effect if `collide` was not called prior to this
 * (as the default is for no collisions to happen, but overlaps instead)
 *
 * @param {Object} object - the object that will have a collision removed
 * @param {Object|Tile} target - the target that the object should not collide with anynore
 */
export default function moveThrough(object, target) {
  if (Array.isArray(target)) {
    return Promise.all(target.map(target => moveThrough(target)));
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  const list = object["collides-with"];
  let match;

  if (validate.texture(target)) {
    match = list.findIndex(c => c.type === "tile" && c.index === target);
  } else if (validate.physical(target)) {
    match = list.findIndex(c => c.type === "object" && c.target === target);
  } else {
    throw Error(validate.error.collider);
  }

  if (match !== -1) {
    list.splice(match, 1);
  }
}
