import Backbone from "custom/backbone-bundle";
import { glossary } from "globals/glossary";
import interfaceChannel from "views/block/channels/interface-channel";
import { MediaAssetView } from "views/block/utils/media-asset/media-asset";
import ReadSpeaker from "views/readspeaker";
import style from "./glossary-term-details.styl";
import { translate } from "utils/localisation";

import _ from "underscore";
style.use();

export const GlossaryTermDetails = Backbone.View.extend({
  className: "glossary-term-details",
  template: require("./glossary-term-details.hbs"),

  events: {
    "click .next": "onNextClicked",
    "click .prev": "onPreviousClicked",
    "click .readable": "onReadableTextClicked",
    "click .example-apps .app": "onExampleAppClicked",
  },

  initialize() {
    this.__list = [];
    this.__index = 0;

    this.listenTo(interfaceChannel, "glossary-term-details:change", source =>
      this.onOtherGlossaryTermDetailsChanged(source),
    );
    this.$el.attr("dir", translate("dir"));
  },

  render() {
    const item = glossary
      .get("glossary-terms")
      .find(term => term.get("id") === this.__list[this.__index]);

    let exampleApps;
    if (item?.get("example-apps")?.[0]) {
      exampleApps = JSON.parse(JSON.stringify(item.get("example-apps")));
      exampleApps.forEach(app => {
        app.name = translate("See {EXAMPLE_APP_NAME}").replace(
          "{EXAMPLE_APP_NAME}",
          app.name,
        );
      });
    }
    if (this.media) {
      this.media.remove();
      delete this.media;
    }

    if (this.reader) {
      ReadSpeaker.remove();
      delete this.reader;
    }

    if (!item) {
      return;
    }

    const hasNav = this.__list.length > 1;

    this.$el.html(
      this.template({
        term: item.toJSON(),
        index: this.__index + 1,
        total: this.__list.length,
        hasNav,
        hasPrev: this.__index > 0,
        hasNext: this.__index < this.__list.length - 1,
        exampleApps,
      }),
    );

    this.media = new MediaAssetView({ model: item.get("media")?.asset });
    this.$(".media-placeholder").append(this.media.el);
    this.media.render();

    if (ReadSpeaker.available) {
      this.reader = new ReadSpeaker(
        this.$(".readable")[0],
        this.$(".readspeaker-placeholder")[0],
      );
      ReadSpeaker.ignore([...this.$(".audio__btn")]);
    }

    this.$el.toggleClass("hasNav", hasNav);
  },

  /**
   * View a specific item from a list of items
   * @param {Number} index The index to view
   * @param {String[]} [list] An semi-optional array of glossary term ID's used to populate the navigation
   *                          NOTE: This list is only optional on subsequent calls to this function, the initial call to the function must pass a list
   *                          This list should only be omitted when changing the index without changing the list
   */
  view(index = 0, list = this.__list) {
    this.__list = list;
    this.__index = index;

    this.render();
  },

  onExampleAppClicked(e) {
    let id = e.target.dataset.id;
    interfaceChannel.trigger("proceed-to-user-app", id, null, "_blank");
  },

  onNextClicked() {
    this.view(this.__index + 1);
    this.$(".next").trigger("focus");
    interfaceChannel.trigger("glossary-term-details:change", this);
  },

  onPreviousClicked() {
    this.view(this.__index - 1);
    this.$(".prev").trigger("focus");
    interfaceChannel.trigger("glossary-term-details:change", this);
  },

  onOtherGlossaryTermDetailsChanged(other) {
    if (other === this) {
      return; // ignore events sent by same instance
    }

    // if the other view contains the same list of items
    if (_.isEqual(this.__list, other.__list)) {
      this.view(other.__index); // match the index on this one
    }
  },

  onReadableTextClicked() {
    if (this.reader) {
      this.reader.read();
    }
  },

  stopMedia() {
    this.media?.video?.player?.pause();

    if (this.reader) {
      ReadSpeaker.remove();
      delete this.reader;
    }
  },

  remove() {
    if (this.media) {
      this.media.remove();
    }

    if (this.reader) {
      ReadSpeaker.remove();
      delete this.reader;
    }

    Backbone.View.prototype.remove.call(this);
  },
});
