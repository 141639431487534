import * as validate from "../utils/validate-inputs";

/**
 * Destroys an object, removing it from the stage
 *
 * @param {Object} object - The object to destroy
 */
export default function clone(object) {
  if (!validate.physical(object)) {
    throw Error(validate.error.physical);
  }

  object.destroy();
}
