import SET from "./set";

/**
 * Changes the position of an object to match the position of the pointer
 *
 * @param {Object} object - The object whose position to adjust
 */
export default async function matchPointerPosition(object) {
  SET(object, "x", this.input.x + this.camera.x);
  SET(object, "y", this.input.y + this.camera.y);
}
