import * as validate from "./../utils/validate-inputs";

export default function onCloned(callback, object) {
  // apply event to all objects if more than one is passed to this function
  if (Array.isArray(object)) {
    return Promise.all(object.map(object => onCloned(callback, object)));
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.physical);
  }

  object.game.onCloned.add(clone => {
    if (clone.phaserKey === object.phaserKey) {
      callback([clone]);
    }
  });
}
