import Backbone from "custom/backbone-bundle";

function getParent(child) {
  return child.appendTo ? this.$(child.appendTo || "") : this.$el;
}

//A parent view has one or more children and will update these as required
export default Backbone.View.extend({
  initialize: function() {
    this.childViews = [];
  },

  render: function() {
    this.detachChildren();
    this.attachChildren();
  },

  //define a child view
  defineChildView: function(factory, property, appendTo, prepend) {
    var child = {
      view: null,
      appendTo: appendTo,
      factory: factory,
      property: property,
      prepend: prepend || false,
    };
    this.childViews.push(child);

    if (property) {
      this.listenTo(
        this.model,
        "change:" + property,
        this.updateChildView.bind(this, child),
      );
    }

    this.updateChildView(child);

    return child;
  },

  //appends a single child
  _attachChild: function(child) {
    var el = child.view.$el;
    var parentEl = getParent.call(this, child);
    el.detach();
    parentEl[child.prepend ? "prepend" : "append"](el);
  },

  //updates a child view for a property, creating/removing it as necessary
  updateChildView: function(options) {
    if (options.view) {
      options.view.remove();
    }

    if (!options.property || this.model.has(options.property)) {
      options.view = options.factory();
      options.view.render();

      //we need to detach and reattach all children to ensure they maintain order
      this.detachChildren();
      this.attachChildren();
    } else {
      options.view = null;
    }
  },

  detachChildren: function() {
    this.childViews.forEach(function(child) {
      if (child.view) {
        child.view.$el.detach();
      }
    });
  },

  attachChildren: function() {
    this.childViews.forEach(
      function(child) {
        if (child.view) {
          this._attachChild(child);
          child.view.delegateEvents();
        }
      }.bind(this),
    );
  },

  remove: function() {
    this.childViews.forEach(function(child) {
      if (child.view) {
        child.view.remove();
      }
    });
  },
});
