import { v4 as uuid, validate } from "uuid";

// cache that stores generated UUID's
const UUID_CACHE = {};

/** generates a new UUID */
export function generateUUID() {
  return uuid();
}

/** returns if string is valid uid */
export function isValidUUID(uid) {
  return validate(uid);
}

/**
 * generates a new UUID but produces the same UUID for similar id's:
 * generateConsistentUUID('a') === generateConsistentUUID('a')
 * generateConsistentUUID('a') !== generateConsistentUUID('b')
 *
 * This function's main use case if for fixing old data structures without breaking the connection between items that may be linked to one another
 */
export function generateConsistentUUID(id) {
  // no id given to store against, just produce a random ID
  if (!id) {
    return generateUUID();
  }

  // generate if not in cache
  if (!UUID_CACHE[id]) {
    UUID_CACHE[id] = generateUUID(); // map old -> new
    UUID_CACHE[UUID_CACHE[id]] = UUID_CACHE[id]; // also keep track of new, so that we don't re-generate these
  }

  return UUID_CACHE[id];
}

/**
 * Clears the UUID cache
 */
export function clearConsistentUUIDCache() {
  for (let key in UUID_CACHE) {
    delete UUID_CACHE[key];
  }
}
