import PrototypeModel from "models/prototype-model";
import { generateUUID, generateConsistentUUID } from "utils/generate-uuid";
import CodeBlock from "models/block/blocks/code-block";
import { task } from "globals/task";
import { designActionHistory } from "globals/action-history";
import gameChannel from "./phaser-middleware/channels/game-channel";
import interfaceChannel from "views/block/channels/interface-channel";
import GAME from "models/block/game";
import {
  canDeleteObjects,
  canMoveObjects,
  canSelectObjects,
} from "./phaser-middleware/creation/enable-edit";
import { checkFlag } from "utils/flags";

//This model represents an object on the stage
export default PrototypeModel.extend({
  defaults: {
    /**
     * The major version of this stage object
     * used to determine when backwards-compatible code needs to run
     *
     * Increment this whenever changes are made to the data model that would
     * not be backwards-compatible
     *
     * Note: this property may be missing on older data, in those cases, the
     * version is inferred from the presence of other properties.
     * see `_parseV2toV3` and `_parseV1toV2`
     */
    __v: 3,

    // used to reference items on the stage
    // this is what links code blocks to stage objects
    stageID: null,

    // used to map to values from the MANIFEST
    phaserKey: null,

    // object name, mainly used for variables
    name: null,

    // unique object name, used to target named object via code blocks
    objectName: "",

    // image/animated/button/variable/sound/...
    type: null,

    width: 64,
    height: 64,
    angle: 0,

    "align-to-direction": false, // determine whether a sprite will flip/rotate when moving
    "is-topdown": false, // determines whether an object will rotate or flip when moving

    // Edit mode config
    snap: 1, //determines the scale at which this object will snap to when in edit mode (0 = no snapping, 1 = snap to tile size)

    // determines whether this object is affected by gravity
    gravity: 0,

    // allow objects to be hidden
    visible: true,
  },

  parse(data) {
    this._parseV1toV2(data);
    this._parseV2toV3(data);

    this._parseCloned(data);

    this._validateParseData(data);

    return PrototypeModel.prototype.parse.call(this, data);
  },

  _validateParseData(data) {
    // generate stageID for new objects
    data.stageID = data.stageID || generateUUID();
    delete data._id;

    // delete name for objects that cannot be named
    if (["button", "image", "key", "sprite", "text"].includes(data.type)) {
      delete data.name;
    }

    // delete objectName for objects that cannot be objectNamed
    if (["button", "key", "sound", "text", "variable"].includes(data.type)) {
      delete data.objectName;
    }
  },

  _parseCloned(data = {}) {
    if (!task.get("isClone")) {
      return;
    }

    if (data.stageID) {
      data.stageID = generateConsistentUUID(data.stageID);
    }
  },

  _parseV1toV2(data = {}) {
    if (data.__v || data.phaserKey) {
      return;
    }

    // prior to `phaserKey` property, objects used `key` or `id`
    data.phaserKey = data.key || data.id;

    delete data.key;
    delete data.id;
  },

  _parseV2toV3(data = {}) {
    if (data.__v) {
      return;
    }

    // use id as stageID if it's missing
    data.stageID = data.stageID || data.id;

    delete data.id;

    // gravity was invented later :) ~ add it
    data.gravity = Number(data.gravity) || 0;

    data.__v = 3;
  },

  /**
   * Get the class of this block
   * Multiple objects of the same class are represented by a single code block
   * Some objects are not class-based, in this case we will return null
   *
   * @returns {null|string} The class of this block
   */
  getClass() {
    switch (this.get("type")) {
      case "image":
      case "sprite":
        return this.get("phaserKey");
      case "key":
        return this.get("keyCode");
      default:
        return null;
    }
  },

  /**
   * Determines whether this object is 'physical'
   * Physical objects can move, rotate, collide etc...
   *
   */
  isPhysical() {
    switch (this.get("type")) {
      case "image":
      case "sprite":
      case "button":
      case "text":
        return true;
      default:
        return false;
    }
  },

  /**
   * Get the JSON data necessary to create a block targetting this object
   */
  getBlockData() {
    switch (this.get("type")) {
      case "image":
      case "sprite":
        return {
          type: "object",
          category: this.get("type"),
          target: {
            type: this.get("type"),
            key: this.get("phaserKey"),
          },
        };
      case "button":
      case "text":
        return {
          type: "object",
          category: this.get("type"),
          target: {
            type: this.get("type"),
            key: this.get("stageID"),
          },
        };

      case "variable":
        return {
          type: "variable",
          target: this.get("stageID"),
          category: "variables",
        };
      case "key":
        return {
          type: "key",
          groups: ["year 2", "year 3", "year 4", "year 5", "year 6"],
          value: this.get("keyCode"),
          category: "keys",
        };
      case "sound":
        if (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE")) {
          return {
            type: "object",
            name: this.get("name"),
            category: "sounds",
            value: this.get("phaserKey"),
            target: {
              type: this.get("type"),
              key: this.get("phaserKey"),
            },
          };
        } else {
          // note: even though sounds can be represnted by primitives of type sound
          // these primitives should not be accessible on their own, use SPEAKER.play(sound) instead
          return {
            type: "sound",
            groups: [],
            value: this.get("phaserKey"),
            category: "sounds",
            secret: true, // secret to prevent direct access in the code-chest
          };
        }
      default:
        return null;
    }
  },

  /**
   * Returns the Phaser sprite associated with this object
   * @async
   */
  async getPhaserSprite() {
    const game = await GAME.promiseSetup();
    return game.objectGroup.getAll("stageID", this.get("stageID"))[0];
  },

  /**
   * Returns the Phaser sprite associated with this object synchronously
   * note: this method can fail and should not be relied upon for critical code
   */
  getPhaserSpriteSync() {
    try {
      return GAME.game.objectGroup.getAll("stageID", this.get("stageID"))[0];
    } catch (e) {
      return null;
    }
  },

  /**
   * Creates a code block Model targetting this object
   */
  createBlock() {
    let data = this.getBlockData();

    if (data) {
      return CodeBlock.build(data, { parse: true });
    }
  },

  // ensures the data of this object conforms to blueprint data - blueprint data should come from the manifest
  // this allows changes in the manifest to be automatically reflected down onto the stage objects
  fixData(blueprint) {
    var type = this.get("type");

    // these object types have no blueprint data and can't be fixed
    if (
      type === "text" ||
      type === "variable" ||
      type === "button" ||
      type === "key"
    ) {
      return;
    }

    if (blueprint) {
      blueprint = JSON.parse(JSON.stringify(blueprint));

      // ignore these values from the manifest
      delete blueprint._id;
      delete blueprint.id;
      delete blueprint.key;

      // prevent the following values from being overriden by the manifest
      // normally the manifest should not have these values set, but in some rare cases it does.
      delete blueprint.width;
      delete blueprint.height;
      delete blueprint.x;
      delete blueprint.y;
      delete blueprint.visible;
      delete blueprint["align-to-direction"];
      delete blueprint.gravity;

      this.set(blueprint);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        "Object is missing from the manifest:",
        this.get("phaserKey"),
        this.toJSON(),
        this,
      );
    }
  },

  _edit() {
    gameChannel.trigger("object-selected", this);
    gameChannel.trigger("edit-object", this);
  },

  _delete() {
    const collection = this.get("block").get("objects");

    const actionItem = {
      designModel: this,
      type: "design-remove",
    };
    designActionHistory.addItem(actionItem);

    this.trigger("deleted");
    collection.remove(this);

    interfaceChannel.trigger("remove:from:wall", this.model);
    gameChannel.trigger("object-selected", null);
  },

  _copy() {
    const data = Object.assign({}, this.toJSON());
    delete data.objectName;
    delete data.id;
    delete data.stageID;
    data.x = 0;
    data.y = 0;

    const object = this.get("block")
      .get("objects")
      .add(data, { parse: true, at: 0 });

    const actionItem = {
      designModel: object,
      type: "design-add",
    };
    designActionHistory.addItem(actionItem);

    object.trigger("set-initial-user-position");
    gameChannel.trigger("object-selected", object);
  },

  _setLayering(action) {
    const collection = this.get("block").get("objects");
    const modelIndex = collection.indexOf(this);
    collection.remove(this);
    switch (action) {
      case "bring-to-front":
        collection.unshift(this);
        break;
      case "send-to-back":
        collection.push(this);
        break;
      case "bring-forward":
        collection.add(this, { at: Math.max(0, modelIndex - 1) });
        break;
      case "send-backward":
        collection.add(this, {
          at: Math.min(collection.length, modelIndex + 1),
        });
        break;
    }
    gameChannel.trigger("object-selected", this);

    const actionItem = {
      designModel: this,
      type: "design-order",
      from: modelIndex,
      to: collection.indexOf(this),
    };
    designActionHistory.addItem(actionItem);
  },

  _setOrder(index) {
    const collection = this.get("block").get("objects");
    collection.remove(this);
    collection.add(this, {
      at: Math.min(collection.length, index),
    });
    gameChannel.trigger("object-selected", this);
  },

  // generates context menu items for current object
  async getContextMenuItems() {
    const game = await GAME.promiseGame();
    const canSelect = canSelectObjects(game);
    const canMove = canMoveObjects(game);
    const canDelete = canDeleteObjects(game, this);

    return [
      {
        name: "edit",
        text: "Edit",
        icon: "comet_edit",
        isAvailable: canSelect,
        action: () => this._edit(),
      },
      {
        name: "duplicate",
        text: "Duplicate",
        icon: "comet_copy",
        isDisabled: ["key", "sound"].indexOf(this.get("type")) > -1,
        isAvailable: canDelete,
        action: () => this._copy(),
      },
      {
        name: "delete",
        text: "Delete",
        icon: "comet_delete",
        isAvailable: canDelete,
        action: () => this._delete(),
      },
      {
        name: "separator",
        isAvailable: true,
        isSeparator: true,
      },
      {
        name: "bringToFront",
        text: "Bring to front",
        image: "dist/images/icon_bring_to_front.svg",
        isAvailable: canMove,
        action: () => this._setLayering("bring-to-front"),
      },
      {
        name: "sendToBack",
        text: "Send to back",
        image: "dist/images/icon_send_to_back.svg",
        isAvailable: canMove,
        action: () => this._setLayering("send-to-back"),
      },
      {
        name: "bringForward",
        text: "Bring forward",
        image: "dist/images/icon_bring_forwards.svg",
        isAvailable: canMove,
        action: () => this._setLayering("bring-forward"),
      },
      {
        name: "sendBackward",
        text: "Send backward",
        image: "dist/images/icon_send_backwards.svg",
        isAvailable: canMove,
        action: () => this._setLayering("send-backward"),
      },
    ].filter(item => item.isAvailable);
  },
});
