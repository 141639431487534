import BlockContentsView from "./components/block-contents";
import BlockScopeView from "./components/scope";
import CodeBlock from "./block-prototype";

export default CodeBlock.extend({
  tagName: "for-each-block",
  className: CodeBlock.prototype.className + " control-block",
  template: require("templates/block/block-types/for-each.hbs"),
  initialize() {
    CodeBlock.prototype.initialize.call(this);

    this.defineChildView(
      () =>
        new BlockContentsView({
          model: this.model,
        }),
      "args",
      "> control-block-head>control-section-body",
    );

    this.defineChildView(
      () =>
        new BlockScopeView({
          model: this.model.get("code"),
          className: BlockScopeView.prototype.className + " vertical code",
        }),
      "code",
      "> control-block-scope>control-section-scope",
    );
  },
});
