import BlockArgument from "./../components/argument";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import settings from "globals/settings";
import { task } from "globals/task";
import { hasTaxonomy } from "../../../../utils/taxonomy";

/**
 * PRIMITIVE superclass
 * This class cannot be instantiated directly, only its sub-classes can
 * A primitive is a block that simply holds a user-defined value
 */
export default CodeBlock.extend({
  defaults: Object.assign({}, CodeBlock.prototype.defaults, {
    value: null,
    editable: false,

    /** @type {String|null} title shown in toodal - defaults to primitive type when omitted */
    title: null,
  }),

  blueprint: {
    primitive: true,
  },

  getPseudoCode() {
    return this.get("value");
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    return list;
  },

  /**
   * Returns the value of this primitive
   * A layer of validation can be added here to ensure a value of the correct type returned
   */
  getPrimitiveValue() {
    return this.get("value");
  },

  changeValue(value, source) {
    const prev = { value: this.get("value") };

    if (value instanceof CodeBlock) {
      if (value.get("type") !== this.get("type")) {
        throw Error("Can't update the value of this block to a different type");
      }

      value = { value: value.get("value") };
    }

    this.set("value", value.value);
    this.addToActionHistory(
      {
        block: this,
        type: "set",
        value: {
          new: value,
          old: prev,
        },
      },
      source,
    );
  },

  // eslint-disable-next-line require-await
  async execute() {
    return this.getPrimitiveValue();
  },

  isSame(other) {
    const same = CodeBlock.prototype.isSame.call(this, other);

    return same && this.getPrimitiveValue() === other.getPrimitiveValue();
  },

  /**
   * Check whether this primitive is editable
   * @return {Boolean}  true if this primitive is editable
   */
  isEditable() {
    const topScope = this.getTopLevelScope();
    const blockCoder = topScope && topScope.get("block");

    // primitive is missing context that allows it to be edited
    if (!topScope || !blockCoder || !task) {
      return false;
    }

    // primitives cannot be edited unless they are inside a free-form scope (code canvas)
    if (!topScope.get("free-form")) {
      return false;
    }

    // primitives are always editable while in CC edit mode
    if (settings.get("editable")) {
      return true;
    }

    // primitive not editable because the code can't be edited at the top level
    if (!blockCoder.get("settings").get("edit-code")) {
      return false;
    }

    // we are in a free coding area, the user can edit anything they want
    if (
      hasTaxonomy(task, "use-type.free-code") ||
      hasTaxonomy(task, "use-type.user-generated")
    ) {
      return true;
    }

    // primitive not editable because the task is locked
    if (task.get("locked")) {
      return false;
    }

    // primitive not editable because of jigsaw mode
    if (blockCoder.get("interaction-mode") === "jigsaw") {
      return false;
    }

    return Boolean(this.get("editable"));
  },

  /**
   * @typedef {ToodalConfig}
   * @property {string} title - The title of the toodal
   * @property {string} style - An arbitrary class that will be used to apply custom layout to the toodal
   * @property {string} cacheKey - An arbitrary cache key that will let the toodal know to re-use presets
   *                               When extending this, make sure the key is calculated in such a way that
   *                               any changes to the options would generate a unique key
   * @property {(null|"text"|"number")} input - Whether the toodal has an input field and what type it is
   * @property {Block[]} blocks - A list of block data that the user will be able to select from
   * @property {*[]} custom - A list of custom values that the user will be able to select from
   *
   */
  /**
   * Let this primitive define what's available in the toodal
   * @returns {ToodalConfig} The toodal configuration
   */
  getToodalOptions() {
    return {
      title: "Primitive",
      style: "primitive",
      cacheKey: "primitive",
      input: "text",
      blocks: [],
      custom: [],
    };
  },

  __getParentObjectBlock() {
    try {
      let block = this.getParentBlock().getParentBlock();
      if (block.get("type") === "this" || block.get("type") === "cloned") {
        // if the parent is a `this`-block, grab the object that is inside of it
        block = block.get("args").get("arguments").at(0).get("code");
      }
      return block;
    } catch (e) {
      return null;
    }
  },

  async __getParentObjects() {
    try {
      return await this.__getParentObjectBlock().findObject();
    } catch (e) {
      return null;
    }
  },

  __getParentObjectType() {
    try {
      return (
        (this.__getParentObjectBlock().get("target") &&
          this.__getParentObjectBlock().get("target").type) ||
        this.__getParentObjectBlock().get("type")
      );
    } catch (e) {
      return null;
    }
  },

  __getParentBlockFn() {
    try {
      return this.getParentBlock().get("fn");
    } catch (e) {
      return null;
    }
  },

  __getParentBlockType() {
    try {
      return this.getParentBlock().get("type");
    } catch (e) {
      return null;
    }
  },

  __isFirstArg() {
    try {
      return (
        this ===
        this.getParentBlock().get("args").get("arguments").at(0).get("code")
      );
    } catch (e) {
      return false;
    }
  },

  __isSecondArg() {
    try {
      return (
        this ===
        this.getParentBlock().get("args").get("arguments").at(1).get("code")
      );
    } catch (e) {
      return false;
    }
  },

  __getParentFirstArgValue() {
    try {
      return this.getParentBlock()
        .get("args")
        .get("arguments")
        .at(0)
        .get("code")
        .get("value");
    } catch (e) {
      return null;
    }
  },

  getGlossaryTaxonomy() {
    const blockType = this.get("type");
    const blockValue = this.get("value");
    const parentBlockType = this.__getParentBlockType();
    const parentBlockFn = this.__getParentBlockFn();
    const glossaryTaxonomies = [];

    if (
      blockType === "string" &&
      parentBlockType === "command" &&
      ["changeBy", "get", "set"].indexOf(parentBlockFn) > -1
    ) {
      glossaryTaxonomies.push(
        `glossary-type.object-property.${blockValue}`,
        "glossary-type.object-property",
      );
    }
    glossaryTaxonomies.push(
      `glossary-type.code-block.primitive.${blockType}`,
      `glossary-type.code-block.primitive`,
      "glossary-type.code-block",
    );

    return glossaryTaxonomies;
  },

  canHaveCodeAdded() {
    return false;
  },

  _placementMessage: ERRORS.PLACEMENT_PRIMITIVE,
  _validatePlacement(parent) {
    return parent instanceof BlockArgument;
  },
});
