import Backbone from "custom/backbone-bundle";
import sanitize from "utils/sanitize-string";

import { setCurrentTopic } from "globals/topic";
import { setCurrentUnit } from "globals/unit";
import { lesson, setCurrentLesson } from "globals/lesson";
import { setCurrentTask, setFreeCoderTask, task } from "globals/task";
import { glossary } from "globals/glossary";
import { user } from "globals/user";
import { isAuthor } from "utils/is-author";
import { translate } from "utils/localisation";
import checkStorage from "utils/check-storage";

import { validateStepParents } from "utils/validate-step-parents";
import { preloader } from "views/layouts/preloader/preloader";
import { AppURL } from "utils/url";

import LEGACY_IDS from "fixtures/legacy-to-master-product-mapping.json";
import { checkFlag } from "utils/flags";
import { GlobalPlatformAsset } from "models/global-platform-asset";
import { stableRequests } from "./tracking/otel";

// Storage key for clipboard functionality
const CLIPBOARD_STORAGE_KEY = "coding-task-clipboard";

function errorHandler(e) {
  // TODO tempt fix to figure out what's actually wrong on dev
  throw e;

  // if (window.process.env.NODE_ENV === "development") {
  //   throw e;
  // } else {
  //   // eslint-disable-next-line no-console
  //   console.error(e);
  // }
}

const Router = Backbone.Router.extend({
  initialize() {
    // routes defined in reverse order
    this.route(/(.*)/, "error");

    if (window.process.env.SHARED_APPS_ONLY === "true") {
      this.route("u/:taskID(/)", "sharedApp");
    } else {
      this.route(":title/:taskID(/:lessonID)(/:unitID)(/:topicID)(/)", "task");
      this.route("lesson/:lessonID(/:unitID)(/:topicID)(/)", "lesson");
      this.route("u/:taskID(/)", "userApp");
      this.route("fork/:taskID(/)", "forkApp");
      this.route("certificate/:lessonID(/)", "certificate");
      this.route("new(/)", "new");
      this.route("new/blank(/:type)(/)", "blank");
      this.route("new/intro(/:type)(/)", "intro");
      this.route("open/:type(/)", "open");
      this.route("guid/:guid(/:appID)(/)", "guid");
    }

    this.history = [];
    this.NO_HISTORY = ["guid"]; // define routes that do NOT create history entries
    this.listenTo(this, "route", this.onNavigation);
  },

  // store navigation history
  onNavigation(name, args) {
    stableRequests.routeChangeStart();
    setTimeout(() => stableRequests.routeChangeEnd(), 0);

    if (this.NO_HISTORY.includes(name)) {
      return;
    }

    this.history.unshift({
      name: name,
      args: args,
      fragment: Backbone.history.fragment,
    });
  },

  // redirect to previous route when app is embedded
  previous() {
    if (!this.hasPrevious()) {
      return;
    }

    this.history.shift(); // remove the current route
    this.navigate(this.history[0].fragment, { trigger: true }); // go back to the previous route
    this.history.shift(); // prevent previous route having two entries
  },

  // check whether the router has a previous location
  hasPrevious() {
    return this.history.length > 1;
  },

  /**
   * Opens a GlobalPlatformAsset and redirects to the appropriate coding route
   * after doing a lookup on the API
   * @param {string} guid - the GUID of the global platform player page
   * @param {string} [appID] - optional, the ID of a user app. if passed, and `guid` points to new/intro/block, then a user app will be opened instead of going to said route
   */
  async guid(guid, appID = null) {
    // Global Platform IDs are in a variety of cases in different places; ensure upper-case consistency within Coding
    guid = guid.toUpperCase();

    const asset = new GlobalPlatformAsset({ global_id: guid });

    try {
      await asset.fetch();
    } catch (e) {
      e.code = 5073228897;
      e.additional_info = {
        route: "guid",
        params: {
          guid,
          appID,
        },
      };
      this.trigger("goto:error", e);
      return;
    }

    let route;
    if (asset.get("type") === "route") {
      route = asset.get("coding_route");

      if (route === "new/intro/block" && appID) {
        route = `u/${appID}`;
      }
    } else if (asset.get("coding_collection_type") === "Lesson") {
      route = `lesson/${asset.get("coding_item")}`;
    }

    const locale = asset.get("locale");
    if (locale === translate("locale")) {
      this.navigate(new AppURL(route).href, {
        trigger: true,
        replace: true,
      });
    } else {
      // if the locale has changed, make sure we do a full page load
      const url = new AppURL(route);
      url.addQuery("locale", locale);
      location.replace(url.href);
    }
  },

  // redirect legacy content to the master product when possible
  legacyCheck(lessonID, unitID = null, topicID = null) {
    if (checkFlag("USE_MASTER_PRODUCT") && LEGACY_IDS[lessonID]) {
      let url = `lesson/${LEGACY_IDS[lessonID]}`;

      if (unitID && LEGACY_IDS[unitID]) {
        url += `/${LEGACY_IDS[unitID]}`;

        if (topicID && LEGACY_IDS[topicID]) {
          url += `/${LEGACY_IDS[topicID]}`;
        }
      }

      this.navigate(new AppURL(url).href, { trigger: true, replace: true });
      return true;
    }
  },

  /**
   * navigate to a specific step
   * @param  {string}   title    - Human-readable title, ignored for all intents and purposes
   * @param  {string}   taskID   - The ID of the task
   * @param  {[string]} lessonID - (optional) The ID of the lesson
   * @param  {[string]} unitID   - (optional) The ID of the unit
   * @param  {[string]} topicID  - (optional) The ID of the topic
   */
  async task(title, taskID, lessonID, unitID, topicID) {
    if (this.legacyCheck(lessonID, unitID, topicID)) {
      return;
    }

    try {
      this.trigger("request:destroy-layout");

      await preloader.reset(
        "route",
        "task fetch",
        "task components",
        "task parse",
        "task manifest",
      );

      await Promise.all([
        setCurrentTask(taskID),
        setCurrentLesson(lessonID),
        setCurrentUnit(unitID),
        setCurrentTopic(topicID),
      ]).catch(e => {
        e.code = 1267422289;
        e.additional_info = {
          route: "task",
          params: {
            taskID,
            lessonID,
            unitID,
            topicID,
          },
        };
        this.trigger("goto:error", e);
        errorHandler(e);
      });

      try {
        await glossary.fetch();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      await preloader.complete("route");

      if (validateStepParents()) {
        this.trigger("preserve-idp");
        this.trigger("goto:step");
      } else {
        const e = new Error("Cannot validate parent steps");
        e.code = 7040526877;
        e.additional_info = {
          route: "task",
          params: {
            taskID,
            lessonID,
            unitID,
            topicID,
          },
        };
        this.trigger("goto:error", e);
      }
    } catch (e) {
      e.code = 1856143917;
      e.additional_info = {
        route: "task",
        params: {
          taskID,
          lessonID,
          unitID,
          topicID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * Navigate to the start of a lesson
   * @param  {[string]} lessonID - The ID of the lesson
   * @param  {[string]} unitID   - (optional) The ID of the unit
   * @param  {[string]} topicID  - (optional) The ID of the topic
   */
  async lesson(lessonID, unitID, topicID) {
    if (this.legacyCheck(lessonID, unitID, topicID)) {
      return;
    }

    try {
      this.trigger("request:destroy-layout");

      await preloader.reset(
        "route",
        "task fetch",
        "task components",
        "task parse",
        "task manifest",
      );

      await Promise.all([
        setCurrentLesson(lessonID),
        setCurrentUnit(unitID),
        setCurrentTopic(topicID),
      ]).catch(e => {
        e.code = 8657960471;
        e.additional_info = {
          route: "lesson",
          params: {
            lessonID,
            unitID,
            topicID,
          },
        };
        this.trigger("goto:error", e);
        errorHandler(e);
      });

      await preloader.complete("route");

      this.trigger("preserve-idp");
      this.trigger("lesson-redirect");

      const taskName = sanitize(translate(lesson.get("name")));
      const taskID = lesson.get("tasks").at(0).get("id");

      // redirect to first task of the lesson
      this.navigate(
        new AppURL(
          [taskName, taskID, lessonID, unitID, topicID]
            .filter(x => Boolean(x))
            .join("/"),
        ).href,
        { trigger: true, replace: true },
      );
    } catch (e) {
      e.code = 7497408039;
      e.additional_info = {
        route: "lesson",
        params: {
          lessonID,
          unitID,
          topicID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * Navigate to a new blank app
   */
  async new() {
    this.trigger("request:destroy-layout");

    await preloader.reset(
      "route",
      "task fetch",
      "task components",
      "task parse",
      "task manifest",
    );

    await Promise.all([
      setCurrentTask(null),
      setCurrentLesson(null),
      setCurrentUnit(null),
      setCurrentTopic(null),
    ]);

    await preloader.complete("route");

    this.trigger("goto:new");
    this.trigger("preserve-idp");
  },

  /**
   * Navigate to a free coder blank app of a specific type
   * @param  {string} type - block, python or html
   */
  async blank(type) {
    this.trigger("request:destroy-layout");

    await preloader.reset(
      "route",
      "task fetch",
      "task components",
      "task parse",
      "task manifest",
    );

    await Promise.all([
      setFreeCoderTask(type),
      setCurrentLesson(null),
      setCurrentUnit(null),
      setCurrentTopic(null),
    ]);

    try {
      await glossary.fetch();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    await preloader.complete("route");

    this.trigger("preserve-idp");
    this.trigger("goto:step");
  },

  /**
   * Navigate to free code intro
   * @param  {string} type - block, python or html
   */
  async intro(type) {
    if (type !== "block" || this.hasClipboardData()) {
      this.navigate(new AppURL(`new/blank/${type}`).href, {
        trigger: true,
        replace: true,
      });
      return;
    }

    this.trigger("request:destroy-layout");

    await preloader.reset("route");

    await Promise.all([
      setCurrentTask(null),
      setCurrentLesson(null),
      setCurrentUnit(null),
      setCurrentTopic(null),
    ]);

    await preloader.complete("route");

    this.trigger("preserve-idp");
    this.trigger("goto:intro", type);
  },

  /**
   * Navigate to open-app
   * @param  {string} type - block, python or html
   */
  async open(type) {
    this.trigger("request:destroy-layout");

    await preloader.reset("route");

    await Promise.all([
      setCurrentTask(null),
      setCurrentLesson(null),
      setCurrentUnit(null),
      setCurrentTopic(null),
    ]);

    await preloader.complete("route");

    this.trigger("preserve-idp");
    this.trigger("goto:open", type);
  },

  /**
   * navigate to a user generated app
   * @param  {string}   taskID   - The ID of the task
   */
  async userApp(taskID) {
    try {
      this.trigger("request:destroy-layout");

      await preloader.reset(
        "route",
        "task fetch",
        "task components",
        "task parse",
        "task manifest",
      );

      await Promise.all([
        setCurrentTask(taskID, "user-app"),
        setCurrentLesson(null),
        setCurrentUnit(null),
        setCurrentTopic(null),
      ]);

      try {
        await glossary.fetch();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      await preloader.complete("route");

      this.trigger("preserve-idp");

      if (isAuthor(user, task)) {
        this.trigger("goto:step");
      } else {
        this.trigger("goto:shared");
      }
    } catch (e) {
      e.code = 3596028885;
      e.additional_info = {
        route: "userApp",
        params: {
          taskID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * navigate to a shared app
   * @param  {string}   taskID   - The ID of the task
   */
  async sharedApp(taskID) {
    try {
      this.trigger("request:destroy-layout");

      await preloader.reset(
        "route",
        "task fetch",
        "task components",
        "task parse",
        "task manifest",
      );

      await Promise.all([
        setCurrentTask(taskID, "user-app"),
        setCurrentLesson(null),
        setCurrentUnit(null),
        setCurrentTopic(null),
      ]);

      await preloader.complete("route");

      this.trigger("preserve-idp");
      this.trigger("goto:shared");
    } catch (e) {
      e.code = 4113488747;
      e.additional_info = {
        route: "sharedApp",
        params: {
          taskID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * fork an app
   * @param  {string}   taskID   - The ID of the app to fork
   */
  async forkApp(taskID) {
    try {
      this.trigger("request:destroy-layout");

      await preloader.reset(
        "route",
        "task fetch",
        "task components",
        "task parse",
        "task manifest",
      );

      await Promise.all([
        setCurrentTask(taskID, "user-app"),
        setCurrentLesson(null),
        setCurrentUnit(null),
        setCurrentTopic(null),
      ]);

      await preloader.complete("route");

      this.trigger("fork");
    } catch (e) {
      e.code = 3844378131;
      e.additional_info = {
        route: "forkApp",
        params: {
          taskID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * Navigate to the certificate
   * @param  {[string]} lessonID - The ID of the lesson
   */
  async certificate(lessonID) {
    try {
      this.trigger("request:destroy-layout");

      await setCurrentLesson(lessonID);

      this.trigger("preserve-idp");
      this.trigger("goto:certificate");
    } catch (e) {
      e.code = 6327057915;
      e.additional_info = {
        route: "certificate",
        params: {
          lessonID,
        },
      };
      this.trigger("goto:error", e);
      errorHandler(e);
    }
  },

  /**
   * Navigate to the error page
   */
  error() {
    this.trigger("goto:error");
  },

  // check whether data is on the clipboard
  hasClipboardData() {
    return Boolean(checkStorage() && localStorage[CLIPBOARD_STORAGE_KEY]);
  },
});

export const router = new Router();
