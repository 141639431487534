import { unit } from "globals/unit";
import { lesson } from "globals/lesson";
import { task } from "globals/task";

// checks whether the current unit-lesson-task combination is valid
export function validateStepParents() {
  const lessons = unit && unit.toJSON().lessons.map(x => x.id);
  const tasks = lesson && lesson.toJSON().tasks.map(x => x.id);

  if (unit.get("id") && !lessons.includes(lesson.get("id"))) {
    return false;
  }

  if (lesson.get("id") && !tasks.includes(task.get("id"))) {
    return false;
  }

  return true;
}
