import Backbone from "custom/backbone-bundle";
import CollectionView from "views/prototypes/collection-view";
import Component from "views/prototypes/component";
import ParentView from "views/prototypes/parent-view";
import { task } from "globals/task";
import settings from "globals/settings";

export default ParentView.extend(Component.prototype).extend({
  tagName: "block-scope",
  className: "block-scope",

  initialize() {
    if (this.style) {
      this.style.use();
    }

    this.el.model = this.model;
    this.model.view = this;

    ParentView.prototype.initialize.call(this);

    this.scopeCode = this.defineChildView(
      () =>
        new CollectionView({
          tagName: "scope-code",
          model: this.model.get("code"),
          childFactory: require("./../code-block").default,
        }),
      "code",
      "",
    );

    this.reflectSetting(this.model, "free-form", "free-form");

    this._updateDroppableState();
    this.listenTo(this.model, "change:locked", this._updateDroppableState);
    this.listenTo(settings, "change:editable", this._updateDroppableState);
  },

  async _updateDroppableState() {
    await new Promise(r => requestAnimationFrame(r));
    this.$el.toggleClass("droppable", this._isDroppable());
  },

  _isDroppable() {
    if (this.model.get("isWall") || this.model.get("free-form")) {
      return true;
    }

    const blockCoder = task.getComponent("models/block");

    if (!blockCoder) {
      return false;
    }

    if (
      blockCoder.get("interaction-mode") === "jigsaw" &&
      !settings.get("editable")
    ) {
      return false;
    }

    const parent = this.model.get("parent");
    return parent && parent.isInCanvas() && !this.model.get("locked");
  },

  remove() {
    delete this.el.model;
    ParentView.prototype.remove.call(this);
    Component.prototype.remove.call(this);
    Backbone.View.prototype.remove.call(this);
  },

  render() {
    // no-op
  },
});
