"use strict";

import translate from "./localisation";

const API = window.process.env.API_URL;

export function getApiUrl() {
  const bits = Array.prototype.slice.call(arguments);
  const locale = translate("locale");

  bits.unshift(API);

  const base = bits.join("/");
  if (locale) {
    return `${base}${base.indexOf("?") !== -1 ? "&" : "?"}locale=${locale}`;
  }
  return base;
}
