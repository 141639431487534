import CodeBlock from "./block-prototype";
import BlockScopeView from "./components/scope";
import { Sprite } from "../utils/sprite/sprite";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";

export default CodeBlock.extend({
  tagName: "object-block",
  className:
    CodeBlock.prototype.className +
    " code-block object-block object-like-block",
  template: require("templates/block/block-types/object.hbs"),

  initialize() {
    CodeBlock.prototype.initialize.call(this);
    this.defineChildView(
      () =>
        new BlockScopeView({
          model: this.model.get("commands"),
          className:
            BlockScopeView.prototype.className + " horizontal commands",
        }),
      "commands",
      "> object-scope",
    );

    this.defineChildView(
      () =>
        new Sprite({
          model: this.model,
          interactive: this.model.get("interactive"),
        }),
      "target",
      "> block-body > block-content > object-graphic",
    );
  },

  onClick() {
    if (!this.model.isInWall()) {
      if (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE")) {
        if (this._isEmptyJigsaw()) {
          interfaceChannel.trigger("insert-code:open", this.model);
          return;
        }
        if (!this.model.isEditable()) {
          return;
        }
        interfaceChannel.trigger("swap-code:open", this.model);
        return;
      } else {
        if (this.model.isEditable()) {
          interfaceChannel.trigger(
            "toodal:open",
            this.childViews.find(
              cv =>
                cv.appendTo === "> block-body > block-content > object-graphic",
            ).view,
          );
        } else {
          return;
        }
      }
    }
  },
});
