import SET from "./set";
import * as validate from "./../utils/validate-inputs";

/**
 * Randomizes the X position of an object (whithin stage bounds)
 * Takes the object's width into account so that it will be entirely within the stage
 *
 * @param {Object} object - The object to randomize
 */
export default function randomXPosition(object) {
  if (!(validate.physical(object) || validate.camera(object))) {
    throw Error(validate.error.command);
  }

  const stageWidth = this.world.width;
  const objectWidth = object.width;

  let x = 0;

  if (objectWidth > stageWidth) {
    x = Math.random() * stageWidth;
  } else {
    x = Math.random() * (stageWidth - objectWidth) + objectWidth / 2;
  }

  x = Math.floor(x);

  SET(object, "x", x);
}
