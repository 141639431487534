export default Object.assign(
  JSON.parse(
    JSON.stringify(
      require("models/block/blocks/command/blueprints/change-by.json"),
    ),
  ), //make sure all the values are deeply cloned
  {
    groups: ["year 5", "year 6"],
    category: "position",
    args: {
      arguments: [
        {
          code: {
            type: "string",
            value: "x",
            title: "property",
            "hard-lock": true,
            editable: false,
          },
        },
        {
          name: "value",
        },
      ],
    },
  },
);
