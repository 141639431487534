var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<control-block-scope class=\"block-section\">\r\n  <control-block-spine class=\"block-partial drag-handle\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-middle.hbs"),depth0,{"name":"partial-shapes/c-block-middle","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-block-spine>\r\n  <control-section-scope>\r\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-section-scope>\r\n  <control-block-corner-top class=\"block-partial\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-inner-corner-top.hbs"),depth0,{"name":"partial-shapes/c-block-inner-corner-top","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-block-corner-top>\r\n  <control-block-corner-bottom class=\"block-partial\">\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/c-block-inner-corner-bottom.hbs"),depth0,{"name":"partial-shapes/c-block-inner-corner-bottom","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </control-block-corner-bottom>\r\n</control-block-scope>";
},"usePartial":true,"useData":true});