export function setGradient(bitmap, sprite, data) {
  if (!data) {
    return sprite.loadTexture("UI:transparent-32");
  }

  switch (data.type) {
    case "linear":
      setLinearGradient(bitmap, sprite, data);
      break;
    case "radial":
      setRadialGradient(bitmap, sprite, data);
      break;
  }
}

function setRadialGradient(bitmap, sprite, data) {
  const ctx = bitmap.ctx;
  ctx.clearRect(0, 0, bitmap.width, bitmap.height);

  const sizeX = bitmap.width * (Math.abs(data.x - 0.5) + 0.5);
  const sizeY = bitmap.height * (Math.abs(data.y - 0.5) + 0.5);

  const gradient = ctx.createRadialGradient(
    data.x * bitmap.width,
    data.y * bitmap.height,
    0,
    data.x * bitmap.width,
    data.y * bitmap.height,
    Math.sqrt(Math.pow(sizeX, 2) + Math.pow(sizeY, 2)), // by the power of pythagoras!
  );

  // add the color stops
  data.stops.forEach(stop => gradient.addColorStop(stop[0], stop[1]));

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, bitmap.width, bitmap.height);

  sprite.loadTexture(bitmap);
}

function setLinearGradient(bitmap, sprite, data) {
  const ctx = bitmap.ctx;
  ctx.clearRect(0, 0, bitmap.width, bitmap.height);

  // calculate the line of the gradient and apply rotation
  const a = new Phaser.Point(bitmap.width / 2, 0);
  const b = new Phaser.Point(bitmap.width / 2, bitmap.height);
  Phaser.Point.rotate(a, bitmap.width / 2, bitmap.height / 2, data.angle, true);
  Phaser.Point.rotate(b, bitmap.width / 2, bitmap.height / 2, data.angle, true);

  const gradient = ctx.createLinearGradient(a.x, a.y, b.x, b.y);

  // add the color stops
  data.stops.forEach(stop => gradient.addColorStop(stop[0], stop[1]));

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, bitmap.width, bitmap.height);

  sprite.loadTexture(bitmap);
}
