import Backbone from "custom/backbone-bundle";
import videojs from "video.js";
import videoJSStyle from "video.js/dist/video-js.min.css";
videoJSStyle.use();
import style from "./video.styl";
import { checkFlag } from "utils/flags";

style.use();

export const VideoView = Backbone.View.extend({
  className: "video-container",
  template: require("./video.hbs"),

  initialize() {},

  render() {
    if (this.player) {
      this.player.dispose();
    }

    const url = this.model.get("src");
    const coverImg = this.model.get("posterImageSrc");
    const type = this.defineFormat(url);
    const isEmbedded = this.model.get("host") === "de-guid";
    this.$el.html(this.template({ url, type, coverImg, isEmbedded }));

    if (!isEmbedded) {
      this.player = videojs(this.$("video")[0], {
        controlBar: {
          fullscreenToggle: checkFlag("FULLSCREEN_TOGGLE"),
          pictureInPictureToggle: false,
        },
      });
      this.$(".vjs-big-play-button").attr("autofocus", "autofocus");
    }
  },

  defineFormat(url) {
    const type = url.split("/").pop().split(".").pop();

    switch (type) {
      case "mp4":
        return "video/mp4";
      case "webm":
        return "video/webm";
      case "ogv":
      case "ogm":
      case "ogg":
        return "video/ogg";
    }
  },

  remove() {
    if (this.player) {
      this.player.dispose();
    }
    Backbone.View.prototype.remove.call(this);
  },
});
