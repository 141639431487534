import ErrorBlockView from "./error";
import PlaceholderBlockView from "./placeholder";
import SeparatorBlockView from "./separator";
import ObjectBlockView from "./object";
import ThisBlockView from "./this";
import ObjectNamedBlockView from "./object-named";
import ClonedBlockView from "./cloned";
import CommandBlockView from "./command";
import OperatorBlockView from "./operator";
import VariableBlockView from "./variable";
import EventblockView from "./event";
import IfElseBlockView from "./if-else";
import IfBlockView from "./if";
import RepeatBlockView from "./repeat";
import ForEachBlockView from "./for-each";
import PrimitiveBlockView from "./primitive";

import style from "stylesheets/block/block-base.styl";
style.use();

import { BlockInteractionZone } from "./interaction/block-interaction-zone";
const interactionZone = new BlockInteractionZone(document.body);
interactionZone.initialize();

function getConstructor(type) {
  switch (type) {
    case "placeholder":
      return PlaceholderBlockView;
    case "separator":
      return SeparatorBlockView;
    case "object":
      return ObjectBlockView;
    case "this":
      return ThisBlockView;
    case "object-named":
      return ObjectNamedBlockView;
    case "cloned":
      return ClonedBlockView;
    case "command":
      return CommandBlockView;
    case "operator":
    case "assign-variable":
    case "change-variable":
      return OperatorBlockView;
    case "variable":
      return VariableBlockView;
    case "event":
      return EventblockView;
    case "if-else":
      return IfElseBlockView;
    case "if":
      return IfBlockView;
    case "repeat":
      return RepeatBlockView;
    case "for-each":
      return ForEachBlockView;
    case "vector":
    case "angle":
    case "number":
    case "null":
    case "texture":
    case "key":
    case "string":
    case "boolean":
    case "turn":
    case "sound":
      return PrimitiveBlockView;
  }

  throw Error(`BlockViewError: Unable to create view for object type: ${type}`);
}

function create(constructor, model) {
  return new constructor({ model: model });
}

//this factory creates a new View of the correct type based on model type
export default function (model) {
  var type = model.get("type");

  try {
    return create(getConstructor(type), model);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return create(ErrorBlockView, model);
  }
}
