import { translate } from "utils/localisation";
import { focusZoom } from "utils/focus-zoom";
import { checkFlag } from "utils/flags";
import SelectorEngine from "bootstrap/js/src/dom/selector-engine";

export class KeyboardControls {
  constructor(parent) {
    this.parent = parent;
  }

  onKeydown(e) {
    if (!checkFlag("BLOCK_KEYBOARD_NAVIGATION")) {
      return;
    }

    const model = document.activeElement?.model;
    const rtl = "rtl" === translate("dir");

    if (!model) {
      return;
    }

    if (!model.hasCustomKeyboardControls?.()) {
      return;
    }

    switch (e.code) {
      case "Tab":
        if (e.shiftKey) {
          return this.onShiftTab(e, model);
        } else {
          return this.onTab(e, model);
        }

      case "ArrowDown":
        return this.onDown(e, model);

      case "ArrowUp":
        return this.onUp(e, model);

      case "ArrowRight":
        if (rtl) {
          return this.onLeft(e, model);
        } else {
          return this.onRight(e, model);
        }

      case "ArrowLeft":
        if (rtl) {
          return this.onRight(e, model);
        } else {
          return this.onLeft(e, model);
        }

      case "Enter":
        if (
          checkFlag("BLOCK_ENTER_KEY_CONTEXT_MENU") &&
          typeof model.view.onContextMenu === "function" &&
          !model.get("jigsaw") // note: pressing enter on jigsaw blocks opens the toodal instead of the context menu
        ) {
          return this.onContextMenu(e, model);
        } else {
          return this.onEnter(e, model);
        }
    }
  }

  async onUp(e, model) {
    const parent = model.getVerticalCodeChainParent();
    const chain = parent.getVerticalCodeChain();
    const block = this._moveFocus(
      model.getHorizontalCodeChainParent(),
      chain,
      -1,
    );

    if (block) {
      e.preventDefault();
      await focusZoom(block);
      block.view.el.focus();
    }
  }

  async onDown(e, model) {
    const parent = model.getVerticalCodeChainParent();
    const chain = parent.getVerticalCodeChain();
    const block = this._moveFocus(
      model.getHorizontalCodeChainParent(),
      chain,
      +1,
    );

    if (block) {
      e.preventDefault();
      await focusZoom(block);
      block.view.el.focus();
    }
  }

  async onLeft(e, model) {
    const parent = model.getHorizontalCodeChainParent();
    const chain = parent.getHorizontalCodeChain();
    const block = this._moveFocus(model, chain, -1);

    if (block) {
      e.preventDefault();
      await focusZoom(block);
      block.view.el.focus();
    }
  }

  async onRight(e, model) {
    const parent = model.getHorizontalCodeChainParent();
    const chain = parent.getHorizontalCodeChain();
    const block = this._moveFocus(model, chain, +1);

    if (block) {
      e.preventDefault();
      await focusZoom(block);
      block.view.el.focus();
    }
  }

  async onTab(e, model) {
    const parent = model.getVerticalCodeChainParent();
    const tababbles = SelectorEngine.focusableChildren(document.body);
    let i = tababbles.indexOf(model.view.el);
    let next = tababbles[i];

    // keep moving focus down the chain until we are no longer nested in the current top-level-block
    while (parent.view.el.contains(next)) {
      i += 1;
      next = tababbles[i];
    }

    if (next) {
      e.preventDefault();
      await focusZoom(next.model);
      next.focus();
    }
  }

  async onShiftTab(e, model) {
    const parent = model.getVerticalCodeChainParent();
    let next = parent.view.el;

    // if the current focus is a top level block (or a filled top-level jigsaw
    // block), then move focus one step backwards
    if (parent === model || parent === model.get("parent-jigsaw")) {
      const tababbles = SelectorEngine.focusableChildren(document.body);
      next = tababbles[tababbles.indexOf(next) - 1];
    }

    // if the focus is a nested code block, move focus to the top-level-block
    if (next.model?.getVerticalCodeChainParent?.()) {
      next = next.model.getVerticalCodeChainParent().view.el;
    }

    if (next) {
      e.preventDefault();
      await focusZoom(next.model);
      next.focus();
    }
  }

  onEnter(e, model) {
    model.view.onClick?.(e);
    e.preventDefault();
  }

  onContextMenu(e, model) {
    model.view.onContextMenu?.(e);
    e.preventDefault();
  }

  /**
   * Get the next/previous block down the chain that can have focus
   * @param {Backbone.Model} current The current model that has focus
   * @param {[Backbone.Model]} chain The chain of focusable models
   * @param {Number} increment The increment of each step (ideally 1 or -1)
   * @returns {Backbone.Model} The model to move focus to
   */
  _moveFocus(current, chain, increment = 1) {
    if (current.get("jigsaw-fill")) {
      current = current.get("jigsaw-fill");
    }

    let index = chain.indexOf(current);

    index += increment;
    let next = chain[index];

    while (next) {
      if (next.canHaveFocus()) {
        return next;
      } else {
        index += increment;
        next = chain[index];
      }
    }
  }
}
