export default {
  type: "object",
  category: "sound",
  target: { type: "speaker", key: "speaker" },
  groups: ["year 4", "year 5", "year 6"],
  "feature-flags": ["OLD_SOUND_BLOCKS"],
  commands: {
    locked: true,
    code: [
      {
        "hard-lock": true,
        type: "command",
        fn: "playSound",
        args: {
          arguments: [
            {
              code: {
                type: "sound",
                value: "",
                "hard-lock": true,
                locked: true,
              },
            },
          ],
        },
      },
    ],
  },
};
