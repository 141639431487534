import _ from "underscore";

export default {
  type: "splash",
  layout: "layouts/techbook",
  components: [
    _.extend({}, require("fixtures/components/splash-screen.json"), {
      background: "image",
      title: "title",
      description: "description",
    }),
  ],
};
