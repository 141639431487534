/**
 * On window resize, add breakpoints as class name
 **/
export default function() {
  let breakpointName = checkBreakpoint(window.innerWidth);
  document.body.classList.add(breakpointName);
  $(window).on("resize", () => {
    document.body.classList.remove(breakpointName);
    breakpointName = checkBreakpoint(window.innerWidth);
    document.body.classList.add(breakpointName);
  });
}

/**
 * Check window width and return Comet breakpoint
 **/
function checkBreakpoint(n) {
  let breakpoint;
  if (n >= 0 && n <= 320) {
    breakpoint = "comet-breakpoints-handset-portrait";
  } else if (n >= 321 && n <= 480) {
    breakpoint = "comet-breakpoints-handset-landscape";
  } else if (n >= 481 && n <= 568) {
    breakpoint = "comet-breakpoints-handset-wide-landscape";
  } else if (n >= 569 && n <= 600) {
    breakpoint = "comet-breakpoints-tablet-narrow-portrait";
  } else if (n >= 601 && n <= 768) {
    breakpoint = "comet-breakpoints-tablet-portrait";
  } else if (n >= 769 && n <= 1024) {
    breakpoint = "comet-breakpoints-tablet-landscape";
  } else if (n >= 1025 && n <= 1280) {
    breakpoint = "comet-breakpoints-tablet-wide-landscape";
  } else {
    breakpoint = "comet-breakpoints-desktop-wide";
  }
  return breakpoint;
}
