/**
 * Check taxonomy of an item
 * @param {Backbone.Model|object} model The model or JSON object to check
 * @param {string} taxonomy `.`-separated string of taxonomy, example `language.block`
 * @returns {boolean} true if the model has taxonomy set to true
 */
export function hasTaxonomy(model = {}, taxonomy = "") {
  const node = accessNode(model, taxonomy);
  return Boolean(node && node.__value);
}

/**
 * Check whether an item has taxonomy below a certain point
 * @param {string} taxonomy `.`-separated string of taxonomy, example `language.block`
 * @returns {boolean} true if the model has more taxonomy below the given taxonomy
 */
export function hasChildTaxonomy(model = {}, taxonomy = "") {
  const node = accessNode(model, taxonomy);
  for (let key in node) {
    if (!/^__/.test(key) && node[key].__value) {
      return true;
    }
  }

  return false;
}

/**
 * Digs into the taxonomy tree and returns the node we are interested in
 */
function accessNode(model = {}, taxonomy = "") {
  let pointer =
    typeof model.get === "function" ? model.get("taxonomy") : model.taxonomy;

  if (!pointer) {
    return false;
  }

  const version = pointer.__version || 1;
  const path = taxonomy.split(".");

  while (path[0] && pointer) {
    pointer = pointer[path[0]];
    path.shift();
  }

  switch (version) {
    case 1:
      // convert v1 to v2 so that the rest of the code doesn't have to worry
      return { __value: pointer };
    case 2:
      return pointer;
  }
}
