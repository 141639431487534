import { isAuthor } from "utils/is-author";
import { user } from "globals/user";
import { task } from "globals/task";

export default function(options) {
  if (isAuthor(user, task)) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
}
