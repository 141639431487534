import { string } from "../types/string";
import { number } from "../types/number";
import { translateContent } from "utils/localisation";
/**
 * Asks the user for input
 *
 * Will cast output to a number when possible
 *
 * @param {text} text       - The text to be displayed in the prompt
 * @return {Number|String}  Returns a number if possible, otherwise a string
 */
export default function(text) {
  text = translateContent(string(text));

  let result = window.prompt(`${text}:`, "");
  try {
    result = number(result);
  } catch (e) {
    /* failed to convert result to number - assume it's a string instead */
  }

  return result;
}
