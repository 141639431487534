var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label \n    aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":35}}}))
    + "\" \n    for=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"> \n    "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":23}}}))
    + "</label>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"micro\">"
    + container.escapeExpression(__default(require("../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"micro") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":39}}}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"class") : depth0), depth0))
    + "\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max") : depth0), depth0))
    + "\"\n        min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min") : depth0), depth0))
    + "\"\n        step=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"step") : depth0), depth0))
    + "\"\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"coding-form__input-warning\">\n        <svg>\n            <use xlink:href=\"#icon-error-warning\"></use>\n        </svg>\n        <span>\n            "
    + container.escapeExpression(__default(require("../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"warningText") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":34,"column":37}}}))
    + "\n        </span>\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"micro") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":7}}})) != null ? stack1 : "")
    + "\n<input\n    type=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "    placeholder=\""
    + alias3(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":17},"end":{"line":18,"column":42}}}))
    + "\"\n    value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNumber") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":35}}})) != null ? stack1 : "")
    + "\n>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"warningText") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":37,"column":7}}})) != null ? stack1 : "")
    + "\n\n";
},"useData":true});