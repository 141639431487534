import { translate } from "../../../../utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";

/**
 * Handles interaction of droppable scopes
 *
 * Scopes are:
 * - the free-form canvas
 * - the code wall
 * - scope of a C-block
 * - commands of an object block
 * - argument slots
 * - jigsaw blocks
 */
export class DroppableScope {
  constructor(parent) {
    this.parent = parent;
  }

  get draggableBlock() {
    return this.parent.draggableBlock;
  }

  onDragEnter(e) {
    e.target.classList.add("dragged-over");
    this.dropZone = e.target;
  }

  onDragLeave(e) {
    e.target.classList.remove("dragged-over");
    this.dropZone = null;
  }

  onDrop(e) {
    const parent = e.target.model;
    const child = this.parent.getBlockEl(e.relatedTarget).model;

    if (!child) {
      return;
    }

    const scale = child.activeCanvas().get("scale");
    let position = null;

    if (parent.get("free-form")) {
      position = this._calculateDropPosition(e, scale);
    } else {
      position = { x: 0, y: 0 };
    }

    e.target.classList.remove("dragged-over");

    const index = Math.max(this.draggableBlock.dropIndex, 0);

    child.move(parent, index, position);

    interfaceChannel.trigger("block-interaction-zone:code-dropped");
  }

  /**
   * Calculates the drop position of a code block
   *
   * @param {Event} e the interact event
   * @param {number} scale the current scale of the canvas
   */
  _calculateDropPosition(e, scale = 1) {
    const scopeEl = e.target.childNodes[0];
    const dropRect = scopeEl.getBoundingClientRect();
    const helperBounds = this.draggableBlock.helperBounds;

    let x;
    let y = helperBounds.top - dropRect.top;

    if (translate("dir") === "rtl") {
      x = dropRect.left - (helperBounds.left + helperBounds.width);
    } else {
      x = helperBounds.left - dropRect.left;
    }

    x /= scale;
    y /= scale;

    return { x, y };
  }
}
