import * as validate from "./../utils/validate-inputs";

export default function onBeforeStep(callback, object) {
  if (Array.isArray(object)) {
    return Promise.all(object.map(object => onBeforeStep(callback, object)));
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.physical);
  }

  object.onBeforeStep.add(() => callback([object]));
}
