import Modal from "../modal";
import { task } from "globals/task";
import interfaceChannel from "views/block/channels/interface-channel";
import { AppNamePlugin } from "../shared/app-name";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "download-as"].join(" "),

  template: require("./download-as.hbs"),
  style: require("./download-as.styl"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "submit .download-app-form": "downloadAs",
        "click .download-app-confirm": "downloadAs",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    this.$el.html(this.template());
    this.delegateEvents();
    this.checkName();
  },

  async downloadAs(e) {
    e.preventDefault();

    try {
      await task.download(this.getName());
      this.close();
    } catch (e) {
      const messageText = "An error occurred and your file has not been saved.";
      interfaceChannel.trigger("request:modal", "save-failure", {
        messageText,
      });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  },
});
