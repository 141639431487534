import PrototypeModel from "models/prototype-model";
import Backbone from "custom/backbone-bundle";
import { getApiUrl } from "utils/get-api-url";

const Asset = Backbone.RelationalModel.extend({
  parse(data) {
    // because the same asset can be in multiple asset packs at the same time
    // we can't actually use their MongoID's within BackboneRelational
    // instead, we use the derived `phaserKey` property that will not conflict
    data.phaserKey = data.phaserKey || data.id;

    delete data._id;
    delete data.id;

    return Backbone.RelationalModel.prototype.parse.call(this, data);
  },
});

export default PrototypeModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "assets",
      parse: true,
      relatedModel: Asset,
    },
  ],

  defaults: {
    assets: [],
  },

  initialize() {
    PrototypeModel.prototype.initialize.apply(this, arguments);
  },

  url() {
    return getApiUrl(`app/asset-pack/${this.id}`);
  },
});
