import { app } from "app";
import Task from "models/task";
import { checkFlag } from "utils/flags";
import { saveState } from "utils/state";
let task = new Task();

const NULL_TASK = new Task();

export default task;
export { task };

import BLANK_BLOCK from "fixtures/task-types/block-blank";
import BLANK_PYTHON from "fixtures/task-types/python-blank";
import BLANK_HTML from "fixtures/task-types/html-blank";
import { preloader } from "views/layouts/preloader/preloader";

const FREE_CODERS = {};
async function findOrCreate(name, fixture) {
  if (!FREE_CODERS[name]) {
    await NULL_TASK._ensureComponents(fixture);
    FREE_CODERS[name] = new Task(fixture);
  }

  return FREE_CODERS[name];
}

export function resetFreeCoder(name) {
  delete FREE_CODERS[name];
}

export async function setFreeCoderTask(type) {
  switch (type) {
    case "block":
      task = await findOrCreate("block", BLANK_BLOCK);
      task.setGrade("all");
      setStageSize();
      break;
    case "python":
      task = await findOrCreate("python", BLANK_PYTHON);
      break;
    case "html":
      task = await findOrCreate("html", BLANK_HTML);
      break;
  }
  preloader.complete("task fetch");
  preloader.complete("task components");
  preloader.complete("task parse");

  // load manifest
  await task.fetchManifest({ hard: true });
  preloader.complete("task manifest");
  app.expose("task", task);
}

export async function setCurrentTask(id, type = "task") {
  if (!id) {
    preloader.complete("task fetch");
    preloader.complete("task components");
    preloader.complete("task parse");
    preloader.complete("task manifest");
    task = NULL_TASK;
    app.expose("task", task);
    return;
  }

  if (id === task.get("id")) {
    return;
  }

  // If task is changing, save the state before that.
  if (checkFlag("SAVE_LESSON_STATE_NAVIGATION")) {
    saveState(task);
  }

  // flag current task as not being active
  task.set("active", false);

  // Use backbone relational to find or create new task
  // Then map this to the exported task variable
  // If a task with said ID has already been created, backbone relational will restore it, otherwise a new task will be instantiated
  task = Task.findOrCreate({ id });

  // flag new task as being active
  task.set("active", true);

  task.type = type;

  // load task
  await task.fetch();

  // load manifest
  await task.fetchManifest({ hard: true });
  preloader.complete("task manifest");

  app.expose("task", task);
}

function setStageSize() {
  const block = task.getComponent("models/block");
  const stageSizeParam = new URLSearchParams(window.location.search).get(
    "stageSize",
  );
  if (block && stageSizeParam) {
    const stageSizePair = stageSizeParam.split("x");
    block.set("width", stageSizePair[0]);
    block.set("height", stageSizePair[1]);
  }
}
