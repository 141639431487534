export default {
  type: "super-block",
  layout: "layouts/techbook",
  name: "Free code",
  components: [
    Object.assign(
      {},
      JSON.parse(
        JSON.stringify(require("fixtures/components/super-block-coder.json")),
      ),
      {
        settings: JSON.parse(
          JSON.stringify(
            require("fixtures/block-grade-defaults/grade-all.json"),
          ),
        ),
        "tile-set": "59d61f8746b9a70b0976e7f4",
      },
    ),
  ],
  taxonomy: {
    language: {
      block: true,
    },
    grade: {
      "grade-all": true,
    },
    "use-type": {
      "free-code": true,
    },
  },
};
