var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"message\">"
    + ((stack1 = __default(require("../../../../templates/helpers/glossary.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"glossary","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":68}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":55}}}));
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form>\n  <input type=\"number\" class=\"preset-input number-input\" value=\"\" placeholder=\"\" step=\"0.01\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Number",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":105},"end":{"line":9,"column":127}}}))
    + "\">\n  <button type=\"submit\" class=\"preset-submit\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":76}}}))
    + "\">"
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":78},"end":{"line":10,"column":96}}}))
    + "</button>\n</form>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<form>\n  <input type=\"text\" class=\"preset-input string-input\" value=\"\" placeholder=\"\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Text",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":91},"end":{"line":16,"column":111}}}))
    + "\">\n  <button type=\"submit\" class=\"preset-submit\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":76}}}))
    + "\">"
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":78},"end":{"line":17,"column":96}}}))
    + "</button>\n</form>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"blocks") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSounds") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":36,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFrames") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":2},"end":{"line":47,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"option\" data-index=\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\"></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"custom") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":35,"column":11}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"option\" data-index=\""
    + alias1(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\">\n    <span class=\"option-icon audio__btn\" role=\"button\" aria-label=\""
    + alias1(__default(require("../../../../templates/helpers/translate.js")).call(alias2,"Audio",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":67},"end":{"line":30,"column":88}}}))
    + "\" title=\""
    + alias1(__default(require("../../../../templates/helpers/translate.js")).call(alias2,"Audio",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":97},"end":{"line":30,"column":118}}}))
    + "\">\n      <img src=\"dist/images/icon_audio.png\">\n    </span>\n    <label>"
    + alias1(__default(require("../../../../templates/helpers/translate.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":33,"column":11},"end":{"line":33,"column":29}}}))
    + "</label>\n  </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"custom") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":2},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"option\" data-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n    <span class=\"option-icon frame__image\">\n      <img src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? lookupProperty(stack1,"texture") : stack1), depth0))
    + "\" alt=\"\">\n    </span>\n    <label>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "</label>\n  </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"This method for controlling sounds is no longer supported. To edit this code you will need to recreate it.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":53,"column":2},"end":{"line":53,"column":124}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"title\" role=\"heading\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":64}}}))
    + "\">"
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":85}}}))
    + "</h1>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"number") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isObjectSound") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":49,"column":11}}})) != null ? stack1 : "")
    + "\n<section class=\"error-message\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Error message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":51,"column":43},"end":{"line":51,"column":72}}}))
    + "\" role=\"alert\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isObjectSound") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":0},"end":{"line":54,"column":7}}})) != null ? stack1 : "")
    + "</section>";
},"useData":true});