import { getApiUrl } from "utils/get-api-url";
import PrototypeModel from "models/prototype-model";

export const GlobalPlatformAsset = PrototypeModel.extend({
  initialize() {
    // no-op
    // We don't want a default ID to be set when this model initializes
  },

  url() {
    return getApiUrl(`app/guid/${this.get("global_id")}`);
  },
});
