import Backbone from "custom/backbone-bundle";

export default Backbone.View.extend({
  tagName: "block-placeholder",
  className: "code-block",

  template: require("templates/block/block-types/placeholder.hbs"),
  initialize: function() {
    this.style.use();
    this.render();
  },

  render: function() {
    this.$el.html(this.template());
  },

  remove: function() {
    Backbone.View.prototype.remove.call(this);
    this.style.unuse();
  },
});
