var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "<label class=\"block-label\">"
    + container.escapeExpression(__default(require("../../helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"else",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":27},"end":{"line":8,"column":47}}}))
    + "</label>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./c-block-head.hbs"),depth0,{"name":"c-block-head","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./c-block-scope.hbs"),depth0,{"name":"c-block-scope","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./c-block-separator.hbs"),depth0,{"name":"c-block-separator","fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./c-block-scope.hbs"),depth0,{"name":"c-block-scope","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = container.invokePartial(require("./c-block-footer.hbs"),depth0,{"name":"c-block-footer","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\r\n<jigsaw-fill-container>\r\n</jigsaw-fill-container>\r\n\r\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-search-words.hbs"),depth0,{"name":"partial-shapes/block-search-words","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});