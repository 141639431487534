var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit scene size",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":31}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stage-size__container\">\n  <div class=\"stage-size__row\">\n    <div class=\"stage-size__presets\">\n      <div class=\"stage-size__preset-cards\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"presets") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":30,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    <div class=\"stage-size__custom\">\n      <div class=\"stage-size__custom-form\">\n        <div class=\"stage-size__custom-form-input\">\n          <div class=\"stage-size__custom-form-input--width\"></div>\n          <span class=\"stage-size__custom-form-micro\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeMessage") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":54},"end":{"line":37,"column":81}}}))
    + "</span>\n        </div>\n        <div class=\"stage-size__custom-form-input\">\n          <div class=\"stage-size__custom-form-input--height\"></div>\n          <span class=\"stage-size__custom-form-micro\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeMessage") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":54},"end":{"line":41,"column":81}}}))
    + "</span>\n        </div>\n      </div>\n    </div>\n  </div>\n  <div class=\"stage-size__row stage-size__row--warning hidden\">\n    <div class=\"stage-size__warning\">\n      <div class=\"stage-size__warning-header\">\n        <svg>\n          <use xlink:href=\"#icon-error-warning\"></use>\n        </svg>\n        <p class=\"stage-size__warning-message\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Paint may be removed and objects outside the new scene will be placed in the best available position.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":52,"column":47},"end":{"line":52,"column":164}}}))
    + "</p>\n      </div>\n  </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"stageSizes") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":29,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button\n              class=\"stage-size__preset-card stage-size__preset-card--"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n              title=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"alt") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":21},"end":{"line":14,"column":44}}}))
    + "\"\n              data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n            >\n              <div class=\"thumbnail\">\n                <svg class=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"class") : depths[1]), depth0))
    + "\" aria-hidden=true focusable=false >\n                  <use xlink:href=\"#"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"svg") : depths[1]), depth0))
    + "\"></use>\n                </svg>\n              </div>\n              <span class=\"title\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":34},"end":{"line":22,"column":62}}}))
    + "</span>\n              <span class=\"comet-button__inner\">\n                <span class=\"comet-button__text\">\n                  "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":18},"end":{"line":25,"column":43}}}))
    + "\n                </span>\n              </span>\n            </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":56},"end":{"line":59,"column":78}}}))
    + "\" autofocus>"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":90},"end":{"line":59,"column":112}}}))
    + "</button>\n<button class=\"confirm stage-size-confirmed\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Make changes",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":57},"end":{"line":60,"column":85}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Make changes",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":87},"end":{"line":60,"column":115}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":56,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":58,"column":0},"end":{"line":61,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});