import Modal from "../modal";
import CodeMirror from "codemirror";
import "codemirror/mode/python/python";
import "codemirror/mode/htmlmixed/htmlmixed";
import getCodeMirrorLanguage from "utils/get-code-mirror-language";
import { translateInline } from "utils/localisation";
import { task } from "globals/task";
import settings from "globals/settings";

import style from "./step-answer-text.less";
style.use();

export const StepAnswerTextModal = Modal.extend({
  className: [Modal.prototype.className, "step-answer-text"].join(" "),

  template: require("./step-answer-text.hbs"),

  async render() {
    this.$el.html(this.template({}));

    const codeModel = this.getCodeModel();

    if (!codeModel) {
      return;
    }

    this.editor = CodeMirror(element => this.$(".main").append(element), {
      lineNumbers: true,
      value: translateInline(codeModel.get("solution")),
      mode: getCodeMirrorLanguage(codeModel),
      theme: "monokai",
      indentUnit: 4,
      indentWithTabs: true,
      readOnly: settings.get("editable") ? false : "nocursor",
    });

    this.editor.on("changes", () => this.onChanges());

    this.delegateEvents();

    await new Promise(r => setTimeout(r, 250));

    this.editor.refresh();
  },

  getCodeModel() {
    return (
      task.getComponent("models/python") || task.getComponent("models/html")
    );
  },

  onChanges() {
    const code = this.editor.getValue();
    const codeModel = this.getCodeModel();

    codeModel.set("solution", code);
  },
});
