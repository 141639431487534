import PrimitiveBlock from "./../primitive";

/** this model represents a boolean primitive */
export default PrimitiveBlock.extend({
  getPseudoCode() {
    return this.get("value") ? "true" : "false";
  },

  getPrimitiveValue() {
    return Boolean(this.get("value"));
  },

  getToodalOptions() {
    return {
      title: "Boolean",
      style: "boolean",
      cacheKey: "presets:boolean",
      input: null,
      blocks: [
        { value: true },
        { value: false },
        { value: null, "editorial-only": true },
      ].map(option => {
        return Object.assign(option, { type: "boolean" });
      }),
    };
  },
});
