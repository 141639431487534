var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <button class=\"audio__btn\" autofocus tabindex=\"0\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Read out loud",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":70},"end":{"line":8,"column":99}}}))
    + "\" title=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Read out loud",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":108},"end":{"line":8,"column":137}}}))
    + "\">\n          <svg>\n            <use xlink:href=\"#icon-sound_icon\"></use>\n          </svg>\n        </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":21},"end":{"line":15,"column":46}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"term") : depth0)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":19},"end":{"line":17,"column":51}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"app\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" no-confirmation-modal>\n        <svg>\n          <use xlink:href=\"#comet_upper-right-arrow\"></use>\n        </svg>\n        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n      </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"glossary-term-nav\">\n  <button class=\"prev\" title=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Previous",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":54}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrev") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":56},"end":{"line":39,"column":94}}})) != null ? stack1 : "")
    + " autofocus>\n    <svg>\n      <use xlink:href=\"#comet_caret-left\"></use>\n    </svg>\n  </button>\n\n  <span class=\"page\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "/"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"total") : depth0), depth0))
    + "</span>\n\n  <button class=\"next\" title=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Next",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":47,"column":30},"end":{"line":47,"column":50}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNext") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":52},"end":{"line":47,"column":90}}})) != null ? stack1 : "")
    + " autofocus>\n    <svg>\n      <use xlink:href=\"#comet_caret-right\"></use>\n    </svg>\n  </button>\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"glossary-term-contents\">\n  <div class=\"media-placeholder\"></div>\n\n  <section class=\"glossary-term-text\">\n    <section class=\"readable\">\n      <h2>\n"
    + ((stack1 = __default(require("../../../../../templates/helpers/ifReadSpeaker.js")).call(alias1,{"name":"ifReadSpeaker","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":13,"column":26}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = __default(require("../../../../../templates/helpers/markdown.js")).call(alias1,{"name":"markdown","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":59}}})) != null ? stack1 : "")
    + "\n      </h2>\n      "
    + ((stack1 = __default(require("../../../../../templates/helpers/markdown.js")).call(alias1,{"name":"markdown","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":64}}})) != null ? stack1 : "")
    + "\n    </section>\n\n    <section class=\"readspeaker-container\">\n      <div class=\"readspeaker-placeholder rs_skip\"></div>\n    </section>\n\n    <section class=\"example-apps\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"exampleApps") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </section>\n  </section>\n</section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNav") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":0},"end":{"line":53,"column":7}}})) != null ? stack1 : "");
},"useData":true});