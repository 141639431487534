import randomX from "./randomXPosition";
import randomY from "./randomYPosition";

/**
 * Moves an object to a random X & Y position (within the stage bounds)
 *
 * @param {Object} object - The object to move
 */
export default function randomPosition(object) {
  randomX.call(this, object);
  randomY.call(this, object);
}
