/**
 * Allows caching of block method outputs during runtime
 *
 * Useful for storing the results of computationally-heavy functions that don't
 * change during runtime
 */
export default class Cache {
  constructor() {
    this._props = {};
    this._on = false;
  }

  /**
   * turns the cache on or off
   * when turned off, values will not be cached
   */
  get on() {
    return this._on;
  }
  set on(val = false) {
    this._on = Boolean(val);
  }

  /**
   * get/set a key
   * @param  {String} key       - The key identifier
   * @param  {function} setter  - The function to set the key if it hasn't been cached before
   * @return {*}                The cached value
   */
  get(key = null, setter) {
    if (key === null) {
      return undefined;
    }

    if (typeof setter !== "function") {
      return undefined;
    }

    if (!this.on) {
      return setter();
    }

    if (!this._props.hasOwnProperty(key)) {
      this._props[key] = setter();
    }

    return this._props[key];
  }

  /**
   * Clears keys from the cache
   * @param  {String} [key]  - Optional, the key to clear. If omitted, ALL keys will be cleared
   */
  clear(key = null) {
    if (key === null) {
      Object.keys(this._props).forEach(prop => {
        delete this._props[prop];
      });
    } else {
      delete this._props[key];
    }
  }
}
