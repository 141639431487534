/* button object module
 *
 * create a phaser button with text child or a simple sprite
 */
import makeThumbnail from "./../utils/make-thumbnail";
import { makeTextBitmap } from "./../utils/make-text";
import { translateContent } from "utils/localisation";

export default function Button(game, model) {
  Phaser.Sprite.call(this, game, 0, 0, "UI:transparent-64");

  this.game.listener.listenTo(
    model,
    "change:text change:bold change:italic change:underlined change:color change:text-shadow change:text-glow",
    this.renderText.bind(this, game, model),
  );
  this.renderText(game, model);
}

Button.prototype = Object.create(Phaser.Sprite.prototype);
Button.prototype.constructor = Button;

Button.prototype.renderText = function (game, model, flag) {
  let button;

  const str = translateContent((flag && this.text) || model.get("text"));

  const text = game.make.sprite(
    0,
    0,
    makeTextBitmap(game, str, {
      bold: model.get("bold"),
      italic: model.get("italic"),
      color: model.get("color"),
      underlined: model.get("underlined"),
      "text-shadow": model.get("text-shadow"),
      "text-glow": model.get("text-glow"),
    }),
  );

  // if the game is not running replace the button child with a sprite to disable hover effect
  if (game.mode === "run") {
    button = game.make.button(
      0,
      0,
      model.get("phaserKey"),
      function () {},
      this,
      2,
      1,
      0,
    );
  } else {
    button = game.make.sprite(0, 0, model.get("phaserKey"));
  }

  this.height = this.game.map.tileHeight;
  this.scale.x = this.scale.y;

  const buttonScale = 64 / button.height;
  button.scale.setTo(buttonScale);
  button.anchor.setTo(0.5, 0.5);

  text.anchor.setTo(0.5, 0.5);

  // Phasers Sprite.removeChildren() has a bug in our current version so doing this for now.
  if (this.children.length != 0) {
    this.children = [];
  }

  this.clickTarget = this.addChild(button);
  this.addChild(text);

  //make resize the parent object so that it's the size of the button
  const reverseScale = (button.width * buttonScale) / this.width;
  this.width *= reverseScale;
  //apply reverse scale to children so they don't appear stretched
  button.scale.x /= reverseScale;
  text.scale.x /= reverseScale;

  makeThumbnail.call(this, model);
};
