import * as validate from "./../utils/validate-inputs";
import { unpack } from "./../types/variable";

/**
 * Assigns a value to a variable oject
 *
 * @param {Object:Variable} variable - The variable object
 * @param {*} val - The value to assign
 */
export default function assignVariable(variable, val) {
  if (Array.isArray(variable)) {
    return variable.forEach(item => assignVariable(item, val));
  }

  if (!validate.variable(variable)) {
    throw Error(validate.error.variable);
  }

  variable._value = unpack(val);

  if (variable.alpha > 0) {
    // only render variable if it's actually visible, this saves performance
    variable.__throttledRender();
  }
}
