import * as validate from "../utils/validate-inputs";

export default function isOnStage(object) {
  // if we are checking multiple objects, true if any of them are on the stage
  if (Array.isArray(object)) {
    return object
      .map(item => isOnStage(item))
      .reduce((acc, val) => acc || val, false);
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  return Boolean(object.inWorld);
}
