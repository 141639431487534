/**
 * This function returns an object with:
 * {
 *    language: String,
 *    type: String,
 *    experimental: Boolean
 * }
 *
 * The actual values are determined by looking at which components the JSON data of a task has and how they are configured
 *
 * @argument task - a task model or the toJSON() of the model
 **/
export default function(task) {
  const data = {
    experimental: false,
  };

  if (!task) {
    return data;
  }

  if (typeof task.toJSON === "function") {
    task = task.toJSON();
  }

  if (!task.components) {
    data.type = "none";
    data.language = "none";
    return data;
  }

  task.components.forEach(component => {
    if (component.language) {
      data.language = component.language;
    }

    if (component["interaction-mode"]) {
      data.type = component["interaction-mode"];

      switch (data.type) {
        case "jigsaw":
          data.type = "Normal";
          break;
        case "default":
          data.type = "Advanced";
          break;
      }
    }

    if (component.model === "models/splash") {
      data.type = "splash";
    }

    if (
      component.views &&
      component.views.indexOf("views/free-editor") !== -1
    ) {
      data.type = "free";
    }

    if (component.views && component.views.indexOf("views/dnd-editor") !== -1) {
      data.type = "drag and drop";
    }

    if (component.experimental) {
      data.experimental = true;
    }
  });

  return data;
}
