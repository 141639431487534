import translate from "utils/localisation";

/**
 * Static Blocks
 * Static blocks are any blocks that are not defined by what is on the stage
 */
export const STATIC_BLOCKS = [];

/**
 * adds all blueprinted blocks to the list of static blocks
 * blueprinted blocks can all be found under `model/blocks/blocks/%block-type%/blueprints/` or `./code-chest-blocks`
 */
(function getBlockDataFromBlueprints() {
  const contexts = [
    require.context("models/block/blocks/command/blueprints/", true, /\.json$/),
    require.context("models/block/blocks/control/blueprints/", true, /\.json$/),
    require.context("models/block/blocks/event/blueprints/", true, /\.json$/),
    require.context(
      "models/block/blocks/operator/blueprints/",
      true,
      /\.json$/,
    ),
    require.context("./code-chest-blocks/", true, /\.js$/),
  ];

  contexts.forEach(context => {
    context.keys().forEach(key => {
      if (key.startsWith("./")) {
        // ignore relative paths generated by context
        // NOTE: this is because of how our `resolve.modules` is configured
        // see: https://github.com/webpack/webpack/issues/12087
        return;
      } else {
        let data = context(key);

        if (data.__esModule) {
          data = data.default;
        }

        if (!data.secret && !data.deprecated) {
          STATIC_BLOCKS.push(data);
        }
      }
    });
  });
})();

// sort blocks by label
STATIC_BLOCKS.sort((a, b) => translate(a.label) < translate(b.label));
