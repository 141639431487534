import { ExportResultCode } from "@opentelemetry/core";
import axios from "axios";

export class RawSpanExporter {
  /** @type {(config: { url: string })} */
  constructor(config) {
    this._url = config.url;
  }

  /** @type {(spans: import("@opentelemetry/tracing").ReadableSpan[], resultCallback: (result: import("@opentelemetry/core").ExportResult) => void) => void} */
  export(spans, resultCallback) {
    // Remove internal properties (_*) and replace getters with plain values (duration, ended)
    const spansToSend = spans.map(span => ({
      ...span,
      duration: span.duration,
      ended: span.ended,
      _duration: undefined,
      _ended: undefined,
      _logger: undefined,
      _spanProcessor: undefined,
      _traceParams: undefined,
    }));
    axios.post(this._url, spansToSend).then(
      () => resultCallback({ code: ExportResultCode.SUCCESS }),
      error => resultCallback({ code: ExportResultCode.FAILED, error }),
    );
  }

  /** @type {() => Promise<void>} */
  shutdown() {
    return Promise.resolve();
  }
}
