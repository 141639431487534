import CodeBlock from "./block-prototype";
import BlockContentsView from "./components/block-contents";

export default CodeBlock.extend({
  tagName: "command-block",
  className: CodeBlock.prototype.className + " code-block",
  template: require("templates/block/block-types/command.hbs"),
  initialize: function () {
    CodeBlock.prototype.initialize.call(this);
    this.defineChildView(
      function () {
        return new BlockContentsView({
          model: this.model,
        });
      }.bind(this),
      "args",
      "> block-body",
    );
  },
});
