var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <argument-plus>\n"
    + ((stack1 = container.invokePartial(require("./plus-icon.hbs"),depth0,{"name":"./plus-icon","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </argument-plus>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<block-shape class=\"block-placeholder-middle\">\n"
    + ((stack1 = __default(require("../../../helpers/ifFeatureFlag.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"BLOCK_CONTEXT_MENU_ADD_CODE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":20}}})) != null ? stack1 : "")
    + "  <shape-solid></shape-solid>\n</block-shape>\n";
},"usePartial":true,"useData":true});