import Backbone from "custom/backbone-bundle";

//add subModelType to backbone relational store
Backbone.Relational.store.addModelScope({
  "Blocks/Object": require("./object/object").default,
  "Blocks/This": require("./object/this").default,
  "Blocks/ObjectNamed": require("./object/object-named").default,
  "Blocks/Cloned": require("./object/cloned").default,

  "Blocks/Command": require("./command/command").default,
  "Blocks/Operator": require("./operator/operator").default,
  "Blocks/IfElse": require("./control/if-else").default,
  "Blocks/If": require("./control/if").default,
  "Blocks/Repeat": require("./control/repeat").default,
  "Blocks/ForEach": require("./control/for-each").default,
  "Blocks/Event": require("./event/event").default,
  "Blocks/Variable": require("./variable/variable").default,
  "Blocks/AssignVariable": require("./variable/assign-variable").default,
  "Blocks/ChangeVariable": require("./variable/change-variable").default,

  "Blocks/Placeholder": require("./components/placeholder").default,
  "Blocks/Separator": require("./separator/separator").default,

  "Blocks/Primitive/Vector": require("./primitive/types/vector").default,
  "Blocks/Primitive/Angle": require("./primitive/types/angle").default,
  "Blocks/Primitive/Number": require("./primitive/types/number").default,
  "Blocks/Primitive/Texture": require("./primitive/types/texture").default,
  "Blocks/Primitive/Null": require("./primitive/types/null").default,
  "Blocks/Primitive/Key": require("./primitive/types/key").default,
  "Blocks/Primitive/String": require("./primitive/types/string").default,
  "Blocks/Primitive/Boolean": require("./primitive/types/boolean").default,
  "Blocks/Primitive/Turn": require("./primitive/types/turn").default,
  "Blocks/Primitive/Sound": require("./primitive/types/sound").default,
});
