var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content\">\n  <div class=\"dialog\" aria-modal=true aria-label=\" "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"modal-title"),depth0,{"name":"modal-title","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\" >\n    <div class=\"header\">\n      <!-- title -->\n      <h3 class=\"title\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"modal-title"),depth0,{"name":"modal-title","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      </h3>\n\n      <!-- close button -->\n      <button class=\"close-button\" data-dismiss=\"modal\" autofocus aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":78},"end":{"line":10,"column":99}}}))
    + "\"\n        title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":15},"end":{"line":11,"column":36}}}))
    + "\">\n        <svg alt=\"\" aria-hidden=true focusable=false >\n          <title>"
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":17},"end":{"line":13,"column":38}}}))
    + "</title>\n          <use xlink:href=\"#icon-x\"></use>\n        </svg>\n      </button>\n    </div>\n    <div class=\"body\">\n      <!-- content -->\n      <div class=\"main\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"modal-content"),depth0,{"name":"modal-content","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      </div>\n\n      <!-- warnings, errors, validators -->\n      <div class=\"warning\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"modal-warning"),depth0,{"name":"modal-warning","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      </div>\n\n    </div>\n\n    <!-- call to actions -->\n    <div class=\"footer\">\n      <div class=\"buttons\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"modal-actions"),depth0,{"name":"modal-actions","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});