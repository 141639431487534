import stepMove from "./step-move";

/**
 * Shorthand for `stepMove` when only moving a single step
 *
 * @async
 * @param {Object} object - The object to `stepMove`
 * @param {Vector} direction - The direction in which to `stepMove`
 */
export default async function journeyMove(object, direction) {
  // no need for validation here - stepMove will do all the validation
  await stepMove.call(this, object, direction, 1);
}
