import { makeTextBitmap } from "./../utils/make-text";
import { translateContent } from "utils/localisation";

export default function Text(game, model) {
  const tileSize = game.map.tileHeight;
  Phaser.Sprite.call(this, game, 0, 0, `UI:transparent-${tileSize}`);

  model.set("height", this.game.map.tileHeight);

  this.game.listener.listenTo(
    model,
    "change:text change:bold change:italic change:underlined change:color change:text-shadow change:text-glow",
    this.renderText.bind(this, game, model, false),
  );
  this.renderText(game, model);
}

Text.prototype = Object.create(Phaser.Sprite.prototype);
Text.prototype.constructor = Text;

Text.prototype.renderText = function (game, model, flag) {
  if (this.children[0]) {
    this.removeChild(this.children[0]);
  }
  const str = translateContent((flag && this.text) || model.get("text"));

  const text = game.make.sprite(
    0,
    0,
    makeTextBitmap(game, str, {
      bold: model.get("bold"),
      italic: model.get("italic"),
      color: model.get("color"),
      underlined: model.get("underlined"),
      "text-shadow": model.get("text-shadow"),
      "text-glow": model.get("text-glow"),
    }),
  );

  this.addChild(text);
  text.anchor.setTo(0.5);

  text.scale.setTo(this.height / text.height);
  this.width = (this.height * text.width) / text.height;
  text.scale.x *= 1 / this.scale.x;

  this.thumbnail = makeTextBitmap(game, str, {
    bold: model.get("bold"),
    italic: model.get("italic"),
    color: model.get("color"),
    underlined: model.get("underlined"),
    "text-shadow": model.get("text-shadow"),
    "text-glow": model.get("text-glow"),
    thumbnail: true,
  });
  model.trigger("new-thumbnail");
};
