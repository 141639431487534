/* eslint-env es6 */
import Backbone from "custom/backbone-bundle";
import BlockCoder from "models/block";
import GAME from "models/block/game";
import ObjectBlock from "models/block/blocks/object/object";
import StageObject from "models/block/stage-object";
import { createSprite } from "models/block/phaser-middleware/creation/create-objects";
import { load } from "models/block/phaser-middleware/utils/runtime-load";
import translate from "utils/localisation";
import { task } from "globals/task";
import "custom/baps";

import styles from "./sprite.styl";

import "custom/baps";
import { checkFlag } from "utils/flags";

styles.use();

/**
 * Depending on the type of the model bound to this sprite it will behave differently
 */
export const Sprite = Backbone.View.extend({
  tagName: "span",
  className: "phaser-sprite loading",

  events: {
    click: "onClick",
  },

  async initialize(options = {}) {
    this.options = options;

    // render from Phaser Sprite
    if (this.model instanceof Phaser.Sprite) {
      return this.renderObject(this.model);
    }

    // render from StageObject
    if (this.model instanceof StageObject) {
      if (this.model.get("type") === "sound") {
        return this.renderSound(
          this.model.get("phaserKey"),
          this.model.get("name"),
        );
      } else {
        return this.renderObject(this.model);
      }
    }

    // render from an object block model
    if (this.model instanceof ObjectBlock) {
      const target = this.model.get("target") && this.model.get("target").type;

      if (target === "app" || target === "stage") {
        return this.createIcon("icon-app");
      } else if (target === "camera") {
        return this.createIcon("icon-camera");
      } else if (target === "speaker") {
        return this.createIcon("icon-sound");
      } else if (target === "task") {
        return this.createText(translate("Task"));
      } else if (target === "pointer") {
        return this.createIcon("icon-pointer");
      } else {
        const object = await this.model.findObject();

        if (!object || object.length === 0) {
          return this.reconstructTarget(this.model.get("target"));
        } else {
          return this.renderObject(object);
        }
      }
    }

    if (this.model.type === "sound") {
      return this.renderSound(this.model.phaserKey, this.model.name);
    }

    // render from JSON data
    this.renderTempObject(this.model);
  },

  // creates a temporary object from JSON data and renders it
  async renderTempObject(data) {
    if (data.phaserKey) {
      await load(data.phaserKey);
    }
    const game = await GAME.promiseSetup();

    if (data.type === "background") {
      this.createBackground(game.cache.getImage(data.phaserKey));
    } else {
      const object = new StageObject(data, { parse: true });
      const sprite = createSprite(game, object);
      return this.createSprite(sprite);
    }
  },

  // renders an object by finding it on the stage
  async renderObject(model) {
    if (Array.isArray(model)) {
      model = model[0];
    }

    if (!model) {
      return;
    }

    if (model instanceof Phaser.Game || model instanceof Phaser.Stage) {
      return this.createIcon("icon-app");
    } else if (model instanceof BlockCoder) {
      return this.createText("Task");
    } else if (model instanceof Phaser.Pointer) {
      return this.createIcon("icon-pointer");
    } else if (model.type === "sound") {
      return this.renderSound(model.key);
    }

    // get model from sprite if needed
    return this.createSprite(model);
  },

  // sometimes an object block may target an object that isn't on the stage, in such cases we need to reconstruct a temporary object to render
  async reconstructTarget(target) {
    const manifest = await task.getManifest();

    const data = manifest.find(
      asset =>
        asset.type === target.type &&
        (asset.phaserKey === target.key || asset._id === target.key),
    );

    if (data) {
      return this.renderTempObject(JSON.parse(JSON.stringify(data)));
    } else {
      return Promise.resolve(); //okay ~ I give up
    }
  },

  // renders a sound sprite
  async renderSound(key, label = "") {
    await load(key);

    this.$el.empty();
    this.$el.removeClass("loading");
    this.$el.addClass("icon sound");

    if (this.options.interactive && checkFlag("SOUND_BLOCK_PREVIEW")) {
      this.$el.addClass("interactive");
    }

    this.el.dataset.key = key;
    this.$el.append(`
        <svg alt="">
          <use xlink:href="#icon-sound"></use>
        </svg>
    `);

    if (label) {
      this.$el.append(`<label>${translate(label)}</label>`);
    }
  },

  // creates an icon sprite
  createIcon(icon) {
    this.$el.empty();
    this.$el.removeClass("loading");
    this.$el.addClass("icon");
    if (icon === "icon-sound") {
      this.$el.addClass("sound");
    }
    this.$el.append(`
        <svg alt="">
          <use xlink:href="#${icon}"></use>
        </svg>
    `);
  },

  // creates a text sprite
  createText(text) {
    this.$el.empty();
    this.$el.removeClass("loading");
    this.$el.append(`<label>${text}</label>`);
  },

  // creates a Phaser sprite
  async createSprite(source) {
    let sprite, model;

    if (source instanceof Phaser.Sprite) {
      model = source.model;
      sprite = source;
    } else {
      model = source;
      sprite = await model.getPhaserSprite();
    }

    if (model) {
      this.listenTo(model, "new-thumbnail", () => this.onNewThumbnail(model));
    }

    this.updateSprite(sprite);
  },

  async onNewThumbnail(model) {
    const sprite = await model.getPhaserSprite();

    if (!GAME.isState("Run")) {
      this.updateSprite(sprite);
    }
  },

  updateSprite(sprite) {
    if (!sprite) {
      return;
    }

    if (sprite.thumbnail) {
      const image = sprite.thumbnail.getImage();
      this.loadImage(image.src, image);
    }
  },

  // create a background sprite
  createBackground(image) {
    return this.loadImage(image.src);
  },

  // loads an image
  loadImage(src, image = new Image()) {
    image.onload = () => {
      this.$el.removeClass("loading");
      this.$el.toggleClass("width-bound", image.width > image.height);
      this.$el.toggleClass("height-bound", !(image.width > image.height));

      // append the image last so that its width and height calculations are not impacted by CSS
      this.$("img").remove();
      this.$el.append(image);
    };
    image.src = src; //trigger load
    return image;
  },

  onClick(e) {
    e.preventDefault();

    if (this.$el.hasClass("sound")) {
      this.playSound(this.el.dataset.key);
    }
  },

  async playSound(key) {
    const game = await GAME.promiseSetup();
    game.sound.play(key);
  },
});
