export default {
  type: "change-variable",
  category: "variables",
  groups: ["year 4", "year 5", "year 6"],
  args: {
    arguments: [
      {
        name: "variable",
      },
    ],
  },
};
