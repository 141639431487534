import GAME from "./../../game";

//returns one or more objects by manifest key
export default async function getByKey(key) {
  return GAME.game.objectGroup.children.filter(item => {
    return (
      item.phaserKey === key ||
      // for backwards-compatibility, we need to also return any objects that match by `key`
      item.key === key
    );
  });
}
