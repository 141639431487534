import { canCollide } from "./can-collide";

export default function collisionHandler(a, b) {
  if (b instanceof Phaser.Tile) {
    b = b.index;
  }
  if (
    a &&
    a.collisionSignal instanceof Phaser.Signal &&
    canCollide(a) &&
    canCollide(b)
  ) {
    a.collisionSignal.dispatch(a, b);
  }
}
