export default {
  MISSING_ARGUMENT: "Something is missing here.",
  JIGSAW_EMPTY: "Something is missing here.",
  JIGSAW_WRONG: "This block doesn't belong here.",
  REQUIRED_USE: "You haven't used this block enough times.",
  DEPRECATED: "This block is not available any more.",
  INVALID_ROTATION_STYLE:
    'Invalid input - only "no-rotate", "left-right" or "direction" are valid input for this command.',

  PLACEMENT_COMMAND: "You need to attach this command to an object.",
  PLACEMENT_CONTROL: "You need to place this block inside an event.",
  PLACEMENT_EVENT: "This is in the wrong place.",
  PLACEMENT_OBJECT: "You need to place this block inside an event.",
  PLACEMENT_OPERATOR: "You need to place this operator inside another block.",
  PLACEMENT_PRIMITIVE: "You need to place this value inside another block.",
  PLACEMENT_VAR: "You need to place this variable inside another block.",
  PLACEMENT_ASSIGN_VAR: "You need to place this block inside an event.",
  PLACEMENT_VAR_IN_EVENT_ARGS: "You can't use a variable here.",

  RUNTIME_NUMBER_EXPECTED: "This value should be a number.",
  RUNTIME_ANGLE_EXPECTED: "This value should be an angle.",
  RUNTIME_TEXTURE_EXPECTED: "This value should be a texture.",
  RUNTIME_KEY_EXPECTED: "This value should be a key.",
  RUNTIME_TIME_EXPECTED: "This value should be a duration.",
  RUNTIME_BOOLEAN_EXPECTED: "This value should be a boolean.",
  RUNTIME_STRING_EXPECTED: "This value should be a string.",
  RUNTIME_VECTOR_EXPECTED: "This value should be a vector.",
  RUNTIME_OBJECT_EXPECTED: "This value should be an object.",
  RUNTIME_SPRITE_EXPECTED: "This value should be a sprite.",
  RUNTIME_COLLIDER_EXPECTED:
    "This value should be an object, pointer or texture.",
  RUNTIME_VARIABLE_EXPECTED: "This value should be a variable.",
  RUNTIME_VARIABLE_CHANGE:
    "This variable cannot be changed because it doesn't contain a number or a string.",
  RUNTIME_UNKNOWN_PROPERTY: "This property doesn't exist.",
  RUNTIME_PROTECTED_PROPERTY: "This property cannot be changed.",
  RUNTIME_SOUND_EXPECTED: "You need to select a sound to play.",
  RUNTIME_SOUND_OBJECT_EXPECTED:
    "You need to use this command with a sound object.",

  RUNTIME_INVALID_THIS: "This object isn't part of the event.",
  RUNTIME_INVALID_COMMAND: "You cannot use this command with that object.",

  RUNTIME_CLASS_EXPECTED: "This object should be an image or sprite.",

  RUNTIME_NO_GRAVITY:
    "Invalid input - oops, this block only works for objects that are affected by gravity.",
};
