import "../plugins/shake";
import { checkFlag } from "utils/flags";
import interfaceChannel from "views/block/channels/interface-channel";

let previousScale = 1;

// manages the scale of the game
function resize(scaleManager) {
  var available = this.game.parent.parentElement.getBoundingClientRect();

  // scale according to width or height, picking whichever is smallest so that the game always fits
  var scale =
    Math.min(
      available.width / this.game.width,
      available.height / this.game.height,
    ) || 1;

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    if (scale !== Infinity && scale !== previousScale) {
      scaleManager.setUserScale(scale, scale);
      previousScale = scale;
    }
  } else {
    if (scale !== Infinity) {
      scaleManager.setUserScale(scale, scale);
    }
  }
}

const boot = function () {};

boot.prototype.init = function (callback) {
  var game = this.game;

  // load shake plugin
  var shake = new Phaser.Plugin.Shake(game);
  game.plugins.add(shake);
  game.shakePlugin = shake;

  this.callback = callback;

  previousScale = 1;
  game.scale.scaleMode = Phaser.ScaleManager.USER_SCALE;
  game.scale.fullScreenScaleMode = Phaser.ScaleManager.USER_SCALE;
  game.scale.setResizeCallback(resize, this);
};

boot.prototype.preload = function () {
  // preload the bare minimum required to display a preloader
  this.load.image(
    "UI:preload-fill",
    "dist/images/block-coding/ui/preload-bar.png",
  );
  this.load.image(
    "UI:preload-background",
    "dist/images/block-coding/ui/preload-bar-background.png",
  );

  if (checkFlag("UI_INTERACTION_SOUNDS")) {
    loadUiInteractionSounds.call(this);
  }

  // UI elements
  // GRID
  this.load.image(
    "UI:grid-32",
    "dist/images/block-coding/ui/grid_white_32.png",
    32,
    32,
  );
  this.load.image(
    "UI:grid-64",
    "dist/images/block-coding/ui/grid_white_64.png",
    64,
    64,
  );
  this.load.image(
    "UI:grid-128",
    "dist/images/block-coding/ui/grid_white_128.png",
    128,
    128,
  );

  // CURSOR
  this.load.spritesheet(
    "UI:cursor",
    "dist/images/block-coding/ui/cursor.png",
    32,
    32,
  );

  // SOUND
  this.load.image(
    "UI:sound",
    "dist/images/block-coding/ui/sound.png",
    256,
    256,
  );

  // TRANSPARENT tiles
  this.load.image(
    "UI:transparent-32",
    "dist/images/block-coding/tilesets/transparent_32px.png",
    32,
    32,
  );
  this.load.image(
    "UI:transparent-64",
    "dist/images/block-coding/tilesets/transparent_64px.png",
    64,
    64,
  );
  this.load.image(
    "UI:transparent-128",
    "dist/images/block-coding/tilesets/transparent_128px.png",
    128,
    128,
  );

  // KEYS
  this.load.image("UI:key", "dist/images/block-coding/ui/key.png", 128, 128);
  this.load.image(
    "UI:spacebar",
    "dist/images/block-coding/ui/spacebar.png",
    512,
    128,
  );

  // VARIABLES
  this.load.image(
    "name-start",
    "dist/images/block-coding/ui/bkgd_name_start.png",
  );
  this.load.image(
    "name-middle",
    "dist/images/block-coding/ui/bkgd_main_middle.png",
  );
  this.load.image(
    "value-start",
    "dist/images/block-coding/ui/bkgd_value_start.png",
  );
  this.load.image(
    "value-middle",
    "dist/images/block-coding/ui/bkgd_value_middle.png",
  );
  this.load.image(
    "value-end",
    "dist/images/block-coding/ui/bkgd_name_value_end.png",
  );
};

// trigger callback when ready
boot.prototype.create = function () {
  var game = this.game;
  game.scale.reflowCanvas();
  game.scale.refresh();

  this.game.load.onFileComplete.add(() =>
    interfaceChannel.trigger("phaser:load-file-complete"),
  );

  var callback = this.callback;
  delete this.callback;
  if (typeof callback === "function") {
    callback();
  }
};

function loadUiInteractionSounds() {
  this.load.audio("puzzle-drop", "dist/sounds/effects/puzzle-drop.mp3");
  this.load.audio("clone-block", "dist/sounds/effects/clone-block.mp3");
  this.load.audio("connect-block", "dist/sounds/effects/connect-block.mp3");
  this.load.audio("delete-block", "dist/sounds/effects/delete-block.mp3");
}

export default boot;
