import copy from "clipboard-copy";
import select from "./select";
import embedAction from "utils/embed-action";

export default ($el, selectText) => {
  if ($el.length === 0)
    throw Error(`No element found with selector: ${$el.selector}.`);

  let copyText;
  if ($el.is("input")) {
    copyText = $el.val();
  } else {
    copyText = $el.text();
  }

  if (
    !embedAction({
      type: "clipboard",
      name: "write",
      text: copyText,
    })
  ) {
    copy(copyText);
  }

  if (selectText) select($el);
};
