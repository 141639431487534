import Backbone from "custom/backbone-bundle";
import ParentView from "views/prototypes/parent-view";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";
import translate from "utils/localisation";

export default ParentView.extend({
  tagName: "block-argument",
  template: require("templates/block/block-types/argument.hbs"),

  className: "argument droppable clickable",

  initialize(options) {
    ParentView.prototype.initialize.call(this, options);
    this.$el.attr("aria-label", translate(this.model.get("type")));

    // attach model to DOM element to allow drag&drop to work
    this.el.model = this.model;
    this.model.view = this;

    this.defineChildView(
      () => {
        const BlockView = require("./../code-block").default;
        return BlockView(this.model.get("code"));
      },
      "code",
      "argument-code",
    );

    this.listenTo(this.model.get("code"), "change", this.render);

    this.listenTo(this.model, "change:error", this._updateErrorState);
    this.listenTo(this.model, "change:code", this._updateDroppableState);

    this.reflectSetting(this.model, "has-brackets", "has-brackets");

    this.listenTo(this.model, "change:code", () =>
      interfaceChannel.trigger("argument-change", this.model.get("code")),
    );

    this.render();
  },

  /**
   * Updates the `error` state of this argument
   */
  _updateErrorState() {
    this.$el.toggleClass("error", this.model.get("error"));
  },

  /**
   * Updates `droppable` state of this argument
   * Arguments are droppable while they are empty and (nested) on the canvas
   */
  async _updateDroppableState() {
    await new Promise(r => requestAnimationFrame(r));
    const hasCode = this.model.has("code");
    const inCanvas = this.model?.get("parent")?.get("parent")?.isInCanvas();
    this.$el.toggleClass("droppable", inCanvas && !hasCode);
    this.$el.toggleClass(
      "clickable",
      inCanvas && !hasCode && checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE"),
    );
  },

  _updateInteractiveState() {
    this.$el.toggleClass(
      "interactive",
      Boolean(this.model.get("parent")?.get("parent")?.get("interactive")),
    );
  },

  render() {
    this.detachChildren();
    this.$el.html(
      this.template({
        inToodal: this.model?.get("parent")?.get("parent")?.get("in-toodal"),
      }),
    );
    this._updateDroppableState();
    this._updateFocusableState();
    this._updateInteractiveState();
    this.attachChildren();
  },

  onClick() {
    if (this.model?.get("parent")?.get("parent")?.isInCanvas()) {
      interfaceChannel.trigger("insert-code:open", this.model);
    }
  },

  _updateFocusableState() {
    if (this.model.get("parent")?.get("parent")?.get("interactive")) {
      this.el.tabIndex = 0;
    } else {
      this.el.tabIndex = -1;
    }
  },

  remove() {
    delete this.el.model;
    ParentView.prototype.remove.call(this);
    Backbone.View.prototype.remove.call(this);
  },
});
