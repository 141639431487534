import PrimitiveBlock from "./../primitive";

/** this model represents a string primitive */
export default PrimitiveBlock.extend({
  getPseudoCode() {
    return `"${this.get("value")}"`;
  },

  getPrimitiveValue() {
    return String(this.get("value"));
  },

  async getToodalOptions() {
    const parentObjectType = this.__getParentObjectType();
    const parentObjectFn = this.__getParentBlockFn();
    const isFirstArg = this.__isFirstArg();
    const isSecondArg = this.__isSecondArg();

    // time options
    if (isFirstArg && parentObjectFn === "time") {
      return {
        title: "String",
        style: "string",
        cacheKey: "presets:string:times",
        input: null,
        blocks: TIMES.map(string => ({
          type: "string",
          value: string,
        })),
      };
    }

    // round operator
    if (isSecondArg && parentObjectFn === "round") {
      return {
        title: "String",
        style: "string",
        cacheKey: "presets:string:decimal-precision",
        input: null,
        blocks: DECIMAL_PRECISION.map(string => ({
          type: "string",
          value: string,
        })),
      };
    }

    // rotation styles operator
    if (isFirstArg && parentObjectFn === "rotationStyle") {
      return {
        title: "String",
        style: "string",
        cacheKey: "presets:string:rotation-styles",
        input: null,
        blocks: ROTATION_STYLES.map(string => ({
          type: "string",
          value: string,
        })),
      };
    }

    // text alignments
    if (
      isSecondArg &&
      this.__getParentFirstArgValue() === "alignment" &&
      (parentObjectFn === "get" ||
        parentObjectFn === "set" ||
        parentObjectFn === "changeBy")
    ) {
      return {
        title: "String",
        style: "string wide",
        cacheKey: "presets:string:alignments",
        input: null,
        blocks: ALIGNMENTS.map(string => ({
          type: "string",
          value: string,
        })),
      };
    }

    // object properties
    if (
      isFirstArg &&
      (parentObjectFn === "get" ||
        parentObjectFn === "set" ||
        parentObjectFn === "changeBy")
    ) {
      // cursor
      if (parentObjectType === "pointer") {
        return {
          title: "String",
          style: "string",
          cacheKey: "presets:string:cursor-props",
          input: null,
          blocks: CURSOR_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // text object
      if (parentObjectType === "text") {
        return {
          title: "String",
          style: "string wide text-properties",
          cacheKey: "presets:string:text-object-props",
          input: "text",
          message: "Custom property",
          blocks: TEXT_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // object named
      if (parentObjectType === "object-named") {
        return {
          title: "String",
          style: "string wide object-named-properties",
          cacheKey: "presets:string:object-named-props",
          input: "text",
          message: "Custom property",
          blocks: OBJECT_NAMED_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // text object
      if (parentObjectType === "sprite") {
        return {
          title: "String",
          style: "string wide sprite-properties",
          cacheKey: "presets:string:sprite-object-props",
          input: "text",
          message: "Custom property",
          blocks: SPRITE_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // app object
      if (parentObjectType === "app") {
        return {
          title: "String",
          style: "string",
          cacheKey: "presets:string:app-object-props",
          input: null,
          blocks: APP_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // camera
      if (parentObjectType === "camera") {
        return {
          title: "String",
          style: "string",
          cacheKey: "presets:string:camera",
          input: null,
          blocks: CAMERA_PROPERTIES.map(string => ({
            type: "string",
            value: string,
          })),
        };
      }

      // not attached to object
      if (parentObjectType === null) {
        return {
          title: "String",
          style: "string wide",
          cacheKey: "presets:string:null-object-props",
          input: null,
          blocks: [],
          message:
            "Attach this command to an object to find out what options are available.",
        };
      }

      // default properties
      return {
        title: "String",
        style: "string wide object-properties",
        cacheKey: "presets:string:object-props",
        input: "text",
        message: "Custom property",
        blocks: PROPERTIES.map(string => ({
          type: "string",
          value: string,
        })),
      };
    }

    // default fallback
    return {
      title: "String",
      style: "string",
      cacheKey: "presets:string",
      input: "text",
      blocks: [],
    };
  },
});

const TIMES = ["All", "Hours: 12 hrs", "Hours: 24 hrs", "Minutes", "Seconds"];
const PROPERTIES = [
  "angle",
  "rotation",
  "heading",
  "speed",
  "friction",
  "height",
  "width",
  "visible",
  "x",
  "y",
  "cameraX",
  "cameraY",
  "angularVelocity",
  "gravity",
  "objectName",
];
const SPRITE_PROPERTIES = [...PROPERTIES].concat("frame");
const TEXT_PROPERTIES = [...PROPERTIES].concat("text", "alignment");
const CURSOR_PROPERTIES = ["heading", "speed", "x", "y", "cameraX", "cameraY"];
const APP_PROPERTIES = ["width", "height"];
const OBJECT_NAMED_PROPERTIES = [...PROPERTIES];
const ALIGNMENTS = [
  "center",
  "top",
  "bottom",
  "left",
  "right",
  "top-left",
  "top-right",
  "bottom-left",
  "bottom-right",
];
const DECIMAL_PRECISION = [".0", ".00", ".000"];
const ROTATION_STYLES = ["no-rotate", "left-right", "direction"];
const CAMERA_PROPERTIES = ["x", "y", "width", "height"];
