import * as validate from "./../utils/validate-inputs";

// checks whether an object exists on the stage
export default function (input) {
  if (Array.isArray(input)) {
    return input.length > 0;
  }

  throw Error(validate.error.object);
}
