import Backbone from "custom/backbone-bundle";
import {
  filterCommandBlocks,
  filterControlBlocks,
  filterEventBlocks,
  filterObjectBlocks,
  filterOperatorBlocks,
  filterPrimitiveBlocks,
  filterVariableBlocks,
} from "../block-filters";
import style from "./choose-type.styl";
style.use();

export const getChooseTypeButtons = function () {
  return [
    {
      label: "Objects",
      icon: "nebula_coding-object",
      filter: filterObjectBlocks,
    },
    {
      label: "Variable",
      icon: "nebula_coding-object-variable",
      filter: filterVariableBlocks,
    },
    {
      label: "Events",
      icon: "nebula_coding-event",
      filter: filterEventBlocks,
    },
    {
      label: "Control",
      icon: "nebula_coding-control",
      filter: filterControlBlocks,
    },
    {
      label: "Commands",
      icon: "nebula_coding-command",
      filter: filterCommandBlocks,
    },
    {
      label: "Operators",
      icon: "nebula_coding-operator",
      filter: filterOperatorBlocks,
    },
    {
      label: "Values",
      icon: "nebula_coding-value",
      filter: filterPrimitiveBlocks,
    },
  ];
};

export const ChooseTypeView = Backbone.View.extend({
  template: require("./choose-type.hbs"),
  tagName: "section",
  className: "choose-type",

  events: {
    "click button.type-button": "onTypeButtonClicked",
  },

  initialize() {
    this.listenTo(
      this.model,
      "change:where change:filter change:suggestions",
      this.render,
    );

    this.buttons = getChooseTypeButtons();
  },

  async render() {
    const where = this.model.get("where");
    if (!where) {
      return;
    }

    const blocks = this.model.getAvailableBlocks();

    this.buttons.forEach(button => {
      button.disabled = blocks.filter(button.filter).length === 0;
    });

    this.$el.html(this.template({ buttons: this.buttons }));
  },

  onTypeButtonClicked(e) {
    const index = e.target.dataset.index;
    const button = this.buttons[index];
    this.model.set({ filter: button.filter, filterName: button.label });
  },
});
