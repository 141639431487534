import { focusZoom } from "utils/focus-zoom";

/**
 * This class separates an individual block, so that it no longer overlaps any
 * other blocks on the canvas
 */
export class BlockSeparator {
  constructor(codeCanvasView) {
    this.canvasView = codeCanvasView;
  }

  get children() {
    try {
      return [...this.canvasView.codeView.el.children[0].children];
    } catch (e) {
      return [];
    }
  }

  get items() {
    return this.children
      .map(el => BlockSeparator.itemize(el))
      .map(item => BlockSeparator.addMarginToItem(item));
  }

  async separate(blockView) {
    let model = blockView.model;
    const offset = this.hitDetection(blockView.el);

    if (offset) {
      const scale = 1 / Number(this.canvasView.model.get("scale"));

      const x = Math.round(model.get("position").get("x") + offset.x * scale);
      const y = Math.round(model.get("position").get("y") + offset.y * scale);

      model = model.move(model.getParent(), null, { x, y });

      await focusZoom(model);
      model.view.el.focus();
    }
  }

  hitDetection(target) {
    let offset = { x: 0, y: 0 };

    target = BlockSeparator.itemize(target);

    const items = this.items.sort((a, b) =>
      BlockSeparator.distanceSort(target.circle, a.circle, b.circle),
    );

    for (let i = 0; i < items.length; i++) {
      const b = items[i];

      if (target.el !== b.el) {
        if (BlockSeparator.overlaps(target.bounds, b.bounds)) {
          const res = BlockSeparator.separationResponse(target, b, offset);

          target.bounds.x += res.x;
          target.bounds.left += res.x;
          target.bounds.right += res.x;
          target.circle.x += res.x;

          target.bounds.y += res.y;
          target.bounds.top += res.y;
          target.bounds.bottom += res.y;
          target.circle.y += res.y;

          offset.x += res.x;
          offset.y += res.y;
        }
      }
    }

    return offset;
  }

  /**
   * Returns an offset by which `focus` needs to be moved so that it doesn't overlap with `other`
   * Once offset is defined, it will continue to grow in the same direction.
   * @param {*} focus
   * @param {*} other
   * @param {*} offset
   * @returns
   */
  static separationResponse(focus, other, offset = { x: 0, y: 0 }) {
    let xOffset = 0;
    let yOffset = 0;

    if (offset.x === 0) {
      if (focus.circle.x > other.circle.x) {
        xOffset = other.bounds.right - focus.bounds.left;
      } else {
        xOffset = other.bounds.left - focus.bounds.right;
      }
    } else if (offset.x > 0) {
      xOffset = other.bounds.right - focus.bounds.left;
    } else {
      xOffset = other.bounds.left - focus.bounds.right;
    }

    if (offset.y === 0) {
      if (focus.circle.y > other.circle.y) {
        yOffset = other.bounds.bottom - focus.bounds.top;
      } else {
        yOffset = other.bounds.top - focus.bounds.bottom;
      }
    } else if (offset.y > 0) {
      yOffset = other.bounds.bottom - focus.bounds.top;
    } else {
      yOffset = other.bounds.top - focus.bounds.bottom;
    }

    if (Math.abs(xOffset) + offset.x < Math.abs(yOffset) + offset.y) {
      return { x: xOffset, y: 0 };
    } else {
      return { x: 0, y: yOffset };
    }
  }

  /**
   * Sorts items based on their distance to a target
   * @param {Item} target
   * @param {Item} a
   * @param {Item} b
   */
  static distanceSort(target, a, b) {
    return (
      BlockSeparator.distance(target, a) - BlockSeparator.distance(target, b)
    );
  }

  /**
   * Transforms an Element into an item with more information
   * @param {Element} el
   * @returns
   */
  static itemize(el) {
    const rect = el.getBoundingClientRect();
    const bounds = {
      x: rect.x,
      y: rect.y,
      left: rect.left,
      top: rect.top,
      bottom: rect.bottom,
      right: rect.right,
      width: rect.width,
      height: rect.height,
    };

    const circle = {
      x: bounds.x + bounds.width / 2,
      y: bounds.y + bounds.height / 2,
      r: BlockSeparator.diagonal(bounds.width, bounds.height) / 2,
    };

    return { el, bounds, circle };
  }

  static addMarginToItem(item) {
    const MARGIN = 8;
    item.bounds.width += MARGIN * 2;
    item.bounds.height += MARGIN * 2;
    item.bounds.x -= MARGIN;
    item.bounds.y -= MARGIN;
    item.bounds.top -= MARGIN;
    item.bounds.left -= MARGIN;
    item.bounds.bottom += MARGIN;
    item.bounds.right += MARGIN;
    item.circle.r += MARGIN;
    return item;
  }

  /**
   * Get the diagonal of a square given a width and height
   * @param {Number} width
   * @param {Number} height
   * @returns
   */
  static diagonal(width, height) {
    return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
  }

  /**
   * Get the distance between two points
   * @param {Point} a
   * @param {Point} b
   */
  static distance(a, b) {
    return BlockSeparator.diagonal(a.x - b.x, a.y - b.y);
  }

  /**
   * Checks if two DOMRect overlap
   * @param {DOMRect} a
   * @param {DOMRect} b
   * @returns {Boolean}
   */
  static overlaps(a, b) {
    return (
      a.x < b.x + b.width &&
      a.x + a.width > b.x &&
      a.y < b.y + b.height &&
      a.y + a.height > b.y
    );
  }
}
