import Modal from "../modal";
import { app } from "app";
import interfaceChannel from "views/block/channels/interface-channel";
import translate from "utils/localisation";
import { getAbsoluteUrl } from "utils/get-absolute-url";
import { AppNamePlugin } from "./../shared/app-name";
import { checkFlag } from "utils/flags";
import MetaData from "models/metadata";
import { task } from "globals/task";
import { AppURL } from "utils/url";
import { hasTaxonomy } from "utils/taxonomy";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "fork"].join(" "),

  template: require("./fork-app.hbs"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "click .fork-app-confirmed": "forkApp",
        "submit .fork-app-form": "forkApp",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    const isExampleApp = hasTaxonomy(task, "task-type.example-app");
    let name = isExampleApp ? translate(task.get("name")) : task.get("name");
    name = translate("Remix of {APP_NAME}").replace("{APP_NAME}", name);
    name = name.substring(0, 50);

    let metadata = task.get("metadata").toJSON();

    this.$el.toggleClass(
      "with-banner",
      Boolean(
        !isExampleApp &&
          metadata &&
          metadata.thumbnail &&
          metadata.thumbnail.src,
      ),
    );

    this.$el.html(
      this.template({
        name,
        metadata,
        isExampleApp,
      }),
    );
    this.delegateEvents();
    this.checkName();

    this.$el.one("hidden.bs.modal", () => this._closed());
  },

  async forkApp(e) {
    e.preventDefault();
    this.forking = true;

    const name = this.getName();

    await this.close();

    try {
      // depending on the SAVE flag~
      // - we need to either go straight to the saved app
      // - OR go to the free coder and past in the copied code
      if (checkFlag("SAVE")) {
        this._exportToNewApp(name);
      } else {
        this._exportToFreeCode();
      }
    } catch (e) {
      // TODO: provide a more meaningful error
      app.setLayout(await app.chooseLayout("error"));
    }
  },

  /**
   * Exports the task to the free coder - WITHOUT SAVING
   * (relies on the free coder to use what's on the clipboard)
   */
  _exportToFreeCode() {
    const metadata = new MetaData();
    metadata.prepFork(task);

    task.copy({
      coder: true,
      wall: false,
      metadata: metadata,
      "background-code": false,
      grade: true,
    });

    interfaceChannel.trigger("proceed-to-free-coder");
  },

  /**
   * Exports and saves to a new user app
   */
  async _exportToNewApp(name) {
    task.get("metadata").prepFork(task);
    const result = await task.fork(name);
    interfaceChannel.trigger("proceed-to-user-app", result.id);
  },

  // when the modal is closed, if we are not forking, we need to go back to the shared app
  async _closed() {
    // introduce a small delay to allow for modal closing animation and making
    // sure we haven't started forking
    await new Promise(r => setTimeout(r, 500));

    if (!this.forking) {
      window.location.href = new AppURL(
        getAbsoluteUrl(
          !task.get("private")
            ? `${task.get("appUrl").share.path}?locale=${translate("locale")}`
            : `${task.get("appUrl").edit.path}?locale=${translate("locale")}`,
        ),
      ).href;
    }
  },
});
