import { number } from "./../types/number";

export default function randomBetween(min, max) {
  const precisionMin = min.toString().includes(".")
    ? min.toString().split(".").pop().length
    : 0;
  const precisionMax = max.toString().includes(".")
    ? max.toString().split(".").pop().length
    : 0;
  const precision = Math.max(precisionMax, precisionMin);
  min = number(min);
  max = number(max);

  // Swap the values around if not in (min,max) order
  if (min > max) {
    [max, min] = [min, max];
  }

  return Number((Math.random() * (max - min) + min).toFixed(precision));
}
