export default function(url) {
  const type = url
    .split("/")
    .pop()
    .split(".")
    .pop();

  switch (type) {
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "ogv":
    case "ogm":
    case "ogg":
      return "video/ogg";
  }
}
