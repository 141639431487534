import Backbone from "custom/backbone-bundle";
import BlockScopeView from "./../../blocks/components/scope";
import { ZoomView } from "../zoom/zoom";
import translate from "utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";
import { CodeSeparator } from "./code-separator/code-separator";
import { BlockSeparator } from "./block-separator/block-separator";
import _ from "underscore";
import { checkFlag } from "utils/flags";
import style from "stylesheets/block/code-canvas.styl";
import { preloader } from "views/layouts/preloader/preloader";

style.use();

export const CodeCanvasView = Backbone.View.extend({
  className: "code-canvas loading",
  template: require("templates/block/code-canvas.hbs"),

  events: {
    mousewheel: "onCanvasScroll",
    contextmenu: "onContextMenu",
    keydown: "onKeydown",
  },

  initialize() {
    this.model.set("free-form", true);
    this.$el.attr("dir", translate("dir"));

    this.codeView = new BlockScopeView({
      tagName: "code-canvas",
      attributes: {
        tabindex: "0",
        "aria-label": translate("Canvas"),
      },
      model: this.model,
    });
    this.codeView.render();
    this.codeView.$el.addClass("gesturable");

    this.codeZoom = new ZoomView({ model: this.model });
    this.codeZoom.render();

    this._showOutOfBoundsIndicators = _.throttle(
      this._showOutOfBoundsIndicators,
      100,
    );

    this.model.set("scale", 1);
  },

  onContextMenu(e) {
    if (!checkFlag("BLOCK_CONTEXT_MENU")) {
      return;
    }

    e.preventDefault();
    interfaceChannel.trigger("context-menu:open", e, this.model);
  },

  onKeydown(e) {
    if (e.target !== this.codeView.el) {
      // only apply keyboard events to this element directly and none of its descendants
      return;
    }

    if (!checkFlag("BLOCK_KEYBOARD_NAVIGATION")) {
      return;
    }

    if (e.code === "Enter") {
      this.onContextMenu(e);
    }
  },

  __startListening() {
    this.listenTo(this.codeZoom, "zoom-in", this.zoomIn);
    this.listenTo(this.codeZoom, "zoom-out", this.zoomOut);
    this.listenTo(this.codeZoom, "zoom-fit", this.zoomToFit);
    this.listenTo(this.model, "pan", pos => this.pan(pos.x, pos.y));
    this.listenTo(this.model, "zoom", scale => this.zoom(scale));
    this.listenTo(this.model, "change:scale", this._applyScale);
    this.listenTo(interfaceChannel, "zoom-to-fit", this.zoomToFit);
    this.listenTo(interfaceChannel, "separate-block", this.separateBlock);
    this.listenTo(
      interfaceChannel,
      "block-interaction-zone:code-dropped",
      this._showOutOfBoundsIndicators,
    );
    this.listenTo(
      interfaceChannel,
      "block-interaction-zone:canvas-onhold",
      this.onClickAddContextMenu,
    );
    this.listenTo(
      interfaceChannel,
      "zoom-to-block",
      this.zoomToBlock.bind(this),
    );
  },

  /**
   * Waits until everything is done loading
   * then optimizes position of the code blocks on the canvas before showing them
   */
  __finishLoading() {
    if (!this.$el.hasClass("loading")) {
      return;
    }
    if (this.$el.hasClass("prepping")) {
      return;
    }

    if (preloader.isCompleted) {
      this.$el.addClass("prepping");

      setTimeout(async () => {
        await this.separateBlocks();
        await this.zoomToFit();
        await new Promise(r => requestAnimationFrame(r));
        this.$el.removeClass("loading");
        this.$el.removeClass("prepping");
        this._applyScale();
        this.__startListening();
        this.$el.addClass("ready");
      }, 100);
    } else {
      setTimeout(() => this.__finishLoading(), 100);
    }
  },

  onCanvasScroll(event) {
    const scroll = (event.originalEvent.wheelDelta / 120) * 0.05;
    this.zoom(this.model.get("scale") + scroll);
    interfaceChannel.trigger("close-menu", scroll);
    event.preventDefault();
  },

  _moveGrid(x, y) {
    if (!this._offset) {
      this._offset = { x: 0, y: 0 };
    }

    // if it shot off to infinity - reset it to 0, this can happen when a canvas has no blocks on it
    if (!isFinite(this._offset.x) || !isFinite(this._offset.y)) {
      this._offset.x = this._offset.y = 0;
    }

    this._offset.x += x / this.model.get("scale");
    this._offset.y += y / this.model.get("scale");

    var el = this.$("> grid-container > freeform-grid")[0];

    el.style.backgroundPositionX = Math.floor(this._offset.x) + "px, center";
    el.style.backgroundPositionY = Math.floor(this._offset.y) + "px, center";
  },

  /**
   * Pans the canvas relative to its current position
   *
   * @param {number} x delta in pixels
   * @param {number} y delta in pixels
   * @param {boolean} skipDir skips direction check for x coordinates
   */
  pan(x, y, skipDir = false) {
    var scale = this.model.get("scale");

    this._moveGrid(x, y);

    if (!skipDir && translate("dir") === "rtl") {
      x = -x;
    }

    this.model.get("code").forEach(code => {
      var position = code.get("position");
      position.set({
        x: position.get("x") + (x * 1) / scale,
        y: position.get("y") + (y * 1) / scale,
      });
    });

    this._showOutOfBoundsIndicators();
  },

  MAX_ZOOM() {
    return 0.25;
  },

  MIN_ZOOM() {
    return 1;
  },

  /**
   * Change the zoom level of the canvas
   *
   * @param {number} scale between 0-1
   * @param {boolean} [relative] When false, the scale will be `set`
   *                             When true, the scale will be `added`
   */
  zoom(scale, relative = false) {
    if (relative) {
      scale += this.model.get("scale");
    }

    scale = Math.round(scale * 100) / 100;
    scale = Math.min(this.MIN_ZOOM(), Math.max(this.MAX_ZOOM(), scale));
    this.model.set("scale", scale);
  },

  zoomIn() {
    this.zoom(0.1, true);
  },

  zoomOut() {
    this.zoom(-0.1, true);
  },

  /**
   * Zooms and pans the canvas so that the elements fit
   *
   * @param {Element} [el] - The element to fit
   * If no element is passed, then zoom to fit will be done for ALL blocks
   */
  async zoomToFit(el = null) {
    await new Promise(r => requestAnimationFrame(r));

    const outerBounds = this.el.getBoundingClientRect();
    let scale = this.model.get("scale");
    let blocks;

    if (el) {
      blocks = [el];
    } else {
      blocks = [
        ...this.el.querySelectorAll(
          "code-canvas > scope-code > *:not(.hidden)",
        ),
      ];
    }

    if (blocks.length === 0) {
      return this.zoom(1);
    }

    const innerBounds = {
      top: Infinity,
      left: Infinity,
      bottom: -Infinity,
      right: -Infinity,
    };

    // get the current DOM bounds
    blocks.forEach(el => {
      const elBounds = el.getBoundingClientRect();
      innerBounds.top = Math.min(innerBounds.top, elBounds.top);
      innerBounds.left = Math.min(innerBounds.left, elBounds.left);
      innerBounds.bottom = Math.max(innerBounds.bottom, elBounds.bottom);
      innerBounds.right = Math.max(innerBounds.right, elBounds.right);
    });

    innerBounds.width = innerBounds.right - innerBounds.left;
    innerBounds.height = innerBounds.bottom - innerBounds.top;

    // pan code to center
    const x =
      outerBounds.left +
      outerBounds.width / 2 -
      (innerBounds.left + innerBounds.width / 2);
    const y =
      outerBounds.top +
      outerBounds.height / 2 -
      (innerBounds.top + innerBounds.height / 2);
    this.pan(x, y);

    // calculate & zoom to ideal scale
    scale =
      Math.min(
        outerBounds.width / (innerBounds.width / scale),
        outerBounds.height / (innerBounds.height / scale),
        1,
      ) || 1;

    // round down to the nearest 5%
    scale = Math.floor(scale * 20) / 20;
    this.zoom(scale);
  },

  zoomToBlock(block) {
    const el = block && block.view && block.view.el;

    if (!el) {
      return;
    }

    this.zoomToFit(el);
  },

  panToBlock(blockView) {
    const bounds = blockView.el.getBoundingClientRect();

    const scale = this.model.get("scale");
    const x = blockView.model.get("position").get("x");
    const y = blockView.model.get("position").get("y");

    this.pan(
      -(bounds.width / 2 + x * scale),
      -(bounds.height / 2 + y * scale),
      true,
    );
  },

  async separateBlocks() {
    if (this.model.get("requires-separation")) {
      if (checkFlag("AUTO_BLOCK_SEPARATION")) {
        const separator = new CodeSeparator();
        await separator.separateAll(this.model.get("code"));
      }
      this.model.set("requires-separation", false);
    }
  },

  /**
   * Shows out-of-bounds indicators on the edges of the canvas
   * This lets the user know that they have more code hidden away on that side
   */
  async _showOutOfBoundsIndicators() {
    if (this.$el.hasClass("loading")) {
      return;
    }

    await new Promise(r => setTimeout(r, 100));
    await new Promise(r => requestAnimationFrame(() => r()));

    const box = this.codeView.el.getBoundingClientRect();
    const blocks = this.codeView
      .$("> scope-code")
      .children(":not(.hidden)")
      .toArray()
      .map(el => el.getBoundingClientRect());

    let top = false;
    let bottom = false;
    let right = false;
    let left = false;

    blocks.forEach(block => {
      if (block.top < box.top) {
        top = true;
      }

      if (block.bottom > box.bottom) {
        bottom = true;
      }

      if (block.left < box.left) {
        left = true;
      }

      if (block.right > box.right) {
        right = true;
      }
    });

    this.$(".more-blocks.top").toggleClass("active", top);
    this.$(".more-blocks.right").toggleClass("active", right);
    this.$(".more-blocks.bottom").toggleClass("active", bottom);
    this.$(".more-blocks.left").toggleClass("active", left);
  },

  /**
   * Applies the current scale of the model visually
   */
  _applyScale() {
    var scale = this.model.get("scale");

    this.$el.data("scale", scale);

    if (scale == 1) {
      scale = "";
    } else {
      scale = "scale(" + scale + ")";
    }

    var el = this.codeView.$("> scope-code")[0];
    if (el) {
      el.style.webkitTransform = el.style.transform = scale;
    }

    el = this.$("> grid-container > freeform-grid")[0];
    if (el) {
      el.style.webkitTransform = el.style.transform = scale;
    }

    this._showOutOfBoundsIndicators();
  },

  async separateBlock(blockModel) {
    const separator = new BlockSeparator(this);
    separator.separate(blockModel.view);

    await new Promise(r => requestAnimationFrame(r));

    this.panToBlock(blockModel.view);
  },

  render() {
    this.codeZoom.$el.detach();
    this.codeView.$el.detach();
    this.$el.html(this.template());
    this.$el.append(this.codeView.el);
    this.$el.append(this.codeZoom.el);

    this.__finishLoading();
  },

  remove() {
    this.codeZoom.remove();
    this.codeView.remove();
    Backbone.View.prototype.remove.call(this);
  },
});
