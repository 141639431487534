import changeBy from "../methods/change-by";
import { number } from "./../types/number";

/**
 * Turn an object by changing it's heading and angle
 *
 * @param {Object} object - The object to turn
 * @param {Number} value - The angle (in degrees) by which to turn
 */
export default function turn(object, value) {
  value = number(value);

  changeBy(object, "angle", value);
  changeBy(object, "heading", value);
}
