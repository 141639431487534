import { unpack } from "./variable";
import { error } from "../utils/validate-inputs";

/**
 * Cast a value to a number
 *
 * @param  {any} val    The value to convert
 * @return {Number|NaN} Returns the value converted to a number or NaN
 */
export function number(val) {
  val = unpack(val);

  let out;

  switch (typeof val) {
    // attempt to convert strings to numbers
    // Number() returns NaN when it fails to convert
    // empty strings should be NaN instead of 0
    case "string":
      out = val ? Number(val) : NaN;
      break;

    // numbers are fine
    case "number":
      out = val;
      break;

    // all other types are NaN
    default:
      out = NaN;
      break;
  }

  if (isNaN(out)) {
    throw Error(error.number);
  } else {
    return out;
  }
}

/**
 * Cast a value to a POSITIVE number
 *
 * @param  {any} val    The value to convert
 * @return {Number|NaN} Returns the value converted to a number or NaN
 */
export function positiveNumber(val) {
  return Math.abs(number(val));
}
