var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  data-position=\""
    + container.escapeExpression(__default(require("../../../../../templates/helpers/stringify.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"position") : depth0),{"name":"stringify","hash":{},"data":data,"loc":{"start":{"line":2,"column":17},"end":{"line":2,"column":39}}}))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "autofocus\n  ";
},"5":function(container,depth0,helpers,partials,data) {
    return "style=\"visibility:hidden\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"where-target\" data-index=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" data-child-scope=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"childScope") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"position") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":86},"end":{"line":2,"column":48}}})) != null ? stack1 : "")
    + " aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":79}}}))
    + "\" tabindex=\"0\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"autoFocus") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":94},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isHidden") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":59}}})) != null ? stack1 : "")
    + ">\n  <label>\n    "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":22}}}))
    + "\n  </label>\n  <svg>\n    <use xlink:href=\"#circle-plus\"></use>\n  </svg>\n</button>\n";
},"useData":true});