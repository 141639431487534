import _ from "underscore";

//uses _.omit to perform a deep omit
export default function deepOmit(object, keys) {
  if (_.isArray(object)) {
    object.forEach(function(el, index, array) {
      array[index] = deepOmit(el, keys);
    });
  } else if (_.isObject(object)) {
    object = _.omit(object, keys);

    for (var key in object) {
      object[key] = deepOmit(object[key], keys);
    }
  }

  return object;
}
