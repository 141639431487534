export const MAP = {};

// object getters
MAP.getObject = require("./utils/get-object-by-key").default;
MAP.getButton = require("./utils/get-object-by-id").default;
MAP.getText = require("./utils/get-object-by-id").default;
MAP.getVariable = require("./utils/get-object-by-id").default;
MAP.getApp = require("./utils/get-app").default;
MAP.getPointer = require("./utils/get-pointer").getPointer;
MAP.getCamera = require("./utils/get-camera").default;
MAP.getSound = require("./utils/get-sound").default;
MAP.this = require("./utils/this").default;
MAP.cloned = require("./utils/cloned").default;
MAP.objectNamed = require("./utils/object-name").getObjectByName;

// events
MAP.onStart = require("./events/on-start").default;
MAP.onHit = require("./events/on-hit").default;
MAP.onUpdate = require("./events/on-update").default;
MAP.onKeyPress = require("./events/on-key-press").default;
MAP.onOutOfBounds = require("./events/on-out-of-bounds").default;
MAP.onClick = require("./events/on-click").default;
MAP.onAfterStep = require("./events/on-after-step").default;
MAP.onBeforeStep = require("./events/on-before-step").default;
MAP.onAfterSeconds = require("./events/on-after-seconds").default;
MAP.onEverySeconds = require("./events/on-every-seconds").default;
MAP.onPointerMove = require("./events/on-pointer-move").default;
MAP.onPointerPress = require("./events/on-pointer-press").default;
MAP.onPointerRelease = require("./events/on-pointer-release").default;
MAP.onCloned = require("./events/on-cloned").default;

// methods
MAP.get = require("./methods/get").default;
MAP.set = require("./methods/set").default;
MAP.flip = require("./methods/flip").default;
MAP.stop = require("./methods/stop").default;
MAP.move = require("./methods/move").default;
MAP.stepMove = require("./methods/step-move").default;
MAP.journeyMove = require("./methods/journey-move").default;
MAP.jump = require("./methods/jump").default;
MAP.shake = require("./methods/shake").default;
MAP.fail = require("./methods/fail").default;
MAP.complete = require("./methods/complete").default;
MAP.feedback = require("./methods/feedback").default;
MAP.error = require("./methods/error").default;
MAP.warning = require("./methods/warning").default;
MAP.alert = require("./methods/alert").default;
MAP.explode = require("./methods/explode").default;
MAP.collide = require("./methods/collide").default;
MAP.moveThrough = require("./methods/move-through").default;
MAP.animate = require("./methods/animate").default;
MAP.animateOnce = require("./methods/animateOnce").default;
MAP.stopAnimation = require("./methods/stopAnimation").default;
MAP.wait = require("./methods/wait").default;
MAP.changeBy = require("./methods/change-by").default;
MAP.play = require("./methods/play").default;
MAP.playSound = require("./methods/playSound").default;
MAP.show = require("./methods/show").default;
MAP.hide = require("./methods/hide").default;
MAP.randomPosition = require("./methods/randomPosition").default;
MAP.randomXPosition = require("./methods/randomXPosition").default;
MAP.randomYPosition = require("./methods/randomYPosition").default;
MAP.rotationStyle = require("./methods/rotationStyle").default;
MAP.matchPointerPosition = require("./methods/matchPointerPosition").default;
MAP.time = require("./methods/time").default;
MAP.turn = require("./methods/turn").default;
MAP.clone = require("./methods/clone").default;
MAP.destroy = require("./methods/destroy").default;
MAP.moveTowards = require("./methods/moveTowards").moveTowards;
MAP.matchTo = require("./methods/match-to").default;

// operators
MAP.isTouching = require("./operators/is-touching").default;
MAP.isMoving = require("./operators/is-moving").default;
MAP.isCloned = require("./operators/is-cloned").default;
MAP.exists = require("./operators/exists").default;
MAP.greaterThan = require("./operators/greater-than").default;
MAP.smallerThan = require("./operators/smaller-than").default;
MAP.equality = require("./operators/equality").default;
MAP.and = require("./operators/and").default;
MAP.or = require("./operators/or").default;
MAP.assignVariable = require("./operators/assign-variable").default;
MAP.changeVariable = require("./operators/change-variable").default;
MAP.add = require("./operators/add").default;
MAP.subtract = require("./operators/subtract").default;
MAP.divide = require("./operators/divide").default;
MAP.multiply = require("./operators/multiply").default;
MAP.absolute = require("./operators/absolute").default;
MAP.randomNumber = require("./operators/random-number").default;
MAP.randomNumberBetween = require("./operators/random-number-between").default;
MAP.askForInput = require("./operators/ask-for-input").default;
MAP.not = require("./operators/not").default;
MAP.isJigsawCorrect = require("./operators/jigsaw-correct").default;
MAP.join = require("./operators/join").default;
MAP.isOnGround = require("./operators/on-ground").default;
MAP.isOnStage = require("./operators/on-stage").default;
MAP.round = require("./operators/round").default;
MAP.keyPressed = require("./operators/key-pressed").default;
MAP.angleBetween = require("./operators/angleBetween").angleBetween;
MAP.distanceBetween = require("./operators/distanceBetween").distanceBetween;
MAP.toLowerCase = require("./operators/to-lower-case").default;
MAP.toUpperCase = require("./operators/to-upper-case").default;
