import { task } from "globals/task";
import { lesson } from "globals/lesson";
import { unit } from "globals/unit";
import { topic } from "globals/topic";
import { hasTaxonomy } from "utils/taxonomy";

// determines the coding language of an item
// if no item passed, will try task -> lesson -> unit -> topic
export function getCodingLanguage(item) {
  if (!item) {
    return (
      getCodingLanguage(task) ||
      getCodingLanguage(lesson) ||
      getCodingLanguage(unit) ||
      getCodingLanguage(topic)
    );
  } else {
    if (hasTaxonomy(item, "language.block")) {
      return "block";
    } else if (hasTaxonomy(item, "language.html")) {
      return "html";
    } else if (hasTaxonomy(item, "language.python")) {
      return "python";
    }
  }

  return null;
}
