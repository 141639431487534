import Backbone from "custom/backbone-bundle";

const ConsoleMessage = Backbone.Model.extend({
  defaults: {
    // the message that will be displayed in the console
    message: null,

    // the severity of the message
    // 0 - log
    // 1 - warning
    // 2 - error
    severity: 0,

    // the origin of the message
    // this is used to prevent the same message from appearing more than once
    origin: null,

    fixed: false,
  },
});

export const Console = Backbone.Collection.extend({
  model: ConsoleMessage,

  /**
   * Clears the console of all messages
   */
  clear() {
    this.reset();
  },

  /**
   * Fixes a console warning/error with a particular source
   *
   * @param {any} source - The source of the warning or error to mark as being fixed
   */
  fix(source) {
    this.where({ source }).forEach(message => message.set("fixed", true));
  },

  /**
   * Logs a message to the console
   *
   * @param {String} message - the message to log
   */
  log(message = "") {
    this.add({ message, severity: 0 });
  },

  /**
   * Logs a warning to the console
   *
   * @param {String} message - the warning message
   * @param {any} source - the source of the warning, can be any truthy value, used to prevent the same source from triggering more than one warning
   */
  warn(message = "", source) {
    if (!source) {
      throw new Error("Cannot warn without a source");
    }

    const severity = 1;

    if (
      this.find(
        item =>
          item.get("source") === source && item.get("severity") >= severity,
      )
    ) {
      return;
    }

    this.add({ message, severity, source });
  },

  /**
   * Logs an error to the console
   *
   * @param {String} message - the error message
   * @param {any} source - the source of the error, can be any truthy value, used to prevent the same source from triggering more than one error
   */
  error(message = "", source) {
    if (!source) {
      throw new Error("Cannot error without a source");
    }

    const severity = 2;

    if (
      this.find(
        item =>
          item.get("source") === source && item.get("severity") >= severity,
      )
    ) {
      return;
    }

    this.add({ message, severity, source });
  },
});
