import Modal from "../modal";
import { getAbsoluteUrl } from "./../../../../../utils/get-absolute-url";
import translate from "utils/localisation";

export default Modal.extend({
  className: [Modal.prototype.className, "open-external-link"].join(" "),

  template: require("./open-external-link.hbs"),

  events: Object.assign(
    { "click .open-external-link-confirm": "openExternalLink" },
    Modal.prototype.events,
  ),

  render() {
    const message = translate(
      "Do you want to proceed to {arg} ? Doing so will open a new tab.",
    ).replace("{arg}", `<b>${this.url}</b>`);
    this.$el.html(this.template({ message }));
    this.delegateEvents();
  },

  open(url) {
    this.url = url;
    Modal.prototype.open.call(this);
  },
  openExternalLink() {
    window.open(getAbsoluteUrl(this.url, "_blank"));
  },
});
