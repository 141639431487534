import PrototypeModel from "models/prototype-model";
import store from "globals/store";
import Backbone from "custom/backbone-bundle";

Backbone.Relational.store.addModelScope(store);

//agnostic component model
//will return a different model of the appropriate type
export default PrototypeModel.extend({
  subModelTypeAttribute: "model",
  subModelTypes: {
    "models/instructions": "models/instructions",
    "models/python": "models/python",
    "models/html": "models/html",
    "models/block": "models/block",
  },
});
