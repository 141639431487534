import Modal from "../modal";
import { router } from "router";

import { resetFreeCoder } from "globals/task";
import { clearHistory } from "globals/action-history";

import embedAction from "utils/embed-action";
import { AppURL } from "utils/url";

export default Modal.extend({
  className: [Modal.prototype.className, "new-app"].join(" "),

  template: require("./new-app.hbs"),

  events: Object.assign(
    { "click .new-app-confirmed": "newApp" },
    Modal.prototype.events,
  ),

  async newApp() {
    await this.close();

    if (
      embedAction({
        type: "navigate",
        destination: "free-coder",
        language: "block",
      })
    ) {
      return; // we're doing an embedAction, abort the rest of the code
    }
    clearHistory();

    // we need to navigate to a non-existing route first to ensure that we can
    // navigate from the free coder to the free coder
    router.navigate(new AppURL("none").href, { replace: true });
    resetFreeCoder("block");
    router.navigate(new AppURL("new/intro/block").href, {
      trigger: true,
      replace: true,
    });
  },
});
