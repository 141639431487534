import GAME from "../../game";
import { task } from "globals/task";
import { loadAsset } from "../states/preload";
import interfaceChannel from "views/block/channels/interface-channel";
import Backbone from "custom/backbone-bundle";

/**
 * Loads an asset from the manifest with a given key
 * Returns a promise that resolves when the asset has been loaded
 */
export async function load(key) {
  const asset = new RunTimeAsset(key);
  await asset.isReady();
}

export class RunTimeAsset {
  constructor(key) {
    if (!key) {
      throw new Error("Cannot load asset without a key.");
    }

    this.key = key;
    this.listener = new Backbone.Model({});
  }

  /**
   * Check whether the asset is ready.ac-highlighted
   * Instantiate the asset, then `await asset.isReady()`
   * @returns {Boolean} true when ready
   * @async
   */
  async isReady() {
    if (this.__isLoaded) {
      return true;
    }

    return new Promise(ready => {
      this.__load();
      this.listener.listenTo(
        interfaceChannel,
        "phaser:load-file-complete",
        () => {
          if (this.__isLoaded) {
            this.listener.stopListening();
            ready(true);
          }
        },
      );
    });
  }

  /**
   * Check whether this asset has already been loaded
   * @returns {boolean} true if the asset has loaded
   */
  get __isLoaded() {
    if (!GAME.game || !GAME.game.cache) {
      return false;
    } else {
      return (
        GAME.game.cache.checkImageKey(this.key) ||
        GAME.game.cache.checkSoundKey(this.key)
      );
    }
  }

  /** starts loading the asset */
  async __load() {
    const item = await task.getManifestItem(this.key);
    const game = await GAME.promiseSetup();
    const load = game.load;

    loadAsset.call(game, item);
    load.start();
  }
}
