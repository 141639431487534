import BlockArgument from "./../components/argument";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

//load all blueprints from folder
const BLUEPRINTS = {};
const context = require.context("./blueprints/", true, /\.json$/);
context.keys().forEach(key => {
  const blueprint = context(key);

  BLUEPRINTS[blueprint.fn] = blueprint;
});

/**
 * OPERATOR block
 * operators transform 0 or more inputs into some output
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    fn: null,
    args: {},
  }),

  initialize(options) {
    this.blueprint = BLUEPRINTS[this.get("fn")];
    CodeBlock.prototype.initialize.call(this, options);
  },

  getPseudoCode() {
    return this.get("label").replace(/{arg(\d?)}/g, (match, i) => {
      i = i ? Number(i) - 1 : 0;
      return this.get("args").get("arguments").at(i).getPseudoCode();
    });
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    const args = await this.get("args").compute(scope);
    return game.exec(this.get("fn"), ...args);
  },

  canHaveCodeAdded() {
    return false;
  },

  _placementMessage: ERRORS.PLACEMENT_OPERATOR,
  _validatePlacement(parent) {
    return parent instanceof BlockArgument;
  },
});
