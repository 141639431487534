import copy from "utils/copy";
import select from "utils/select";
import { getApiUrl } from "../../../../../utils/get-api-url";
import $ from "jquery";
import { task } from "globals/task";

export const ShareUrlPlugin = {
  get events() {
    return {
      extend: (props = {}) =>
        Object.assign(props, {
          "click .share-url .copy:not([aria-disabled=true])": "copyShareUrl",
          "click .share-url .link input": "selectShareUrl",
          "change .share-toggle": "toggleShared",
        }),
    };
  },

  copyShareUrl() {
    copy(this.$(".share-url .link input"), true);
  },

  selectShareUrl() {
    select(this.$(".share-url .link input"));
  },

  toggleShared() {
    const checked = this.$(".share-toggle").is(":checked");
    this.networkSave(!checked);
  },

  updatePrivateStatus(isPrivate) {
    this.$(".share-url .link input, .share-url button.copy").attr(
      "aria-disabled",
      isPrivate,
    );
    task.set("private", isPrivate);
  },

  networkSave(isPrivate) {
    const url = getApiUrl(`shared/app/${task.get("id")}`);
    const payload = { private: isPrivate };

    $.ajax(url, {
      data: JSON.stringify(payload),
      contentType: "application/json",
      type: "PUT",
      success: () => this.updatePrivateStatus(isPrivate),
      error: () => {
        this.$(".share-toggle").prop("checked", !isPrivate);
      },
    });
  },
};
