import style from "./preloader.styl";
import template from "./preloader.hbs";
style.use();

/**
 * This class manages showing of preload progress
 *
 * Note: we intentionally use as few dependencies as possible as this needs to
 * be as lightweight as possible since it needs to load before any of the other
 * app chunks, hence the lack of Backbone, jQuery and external stylus variables
 */
export class Preloader {
  constructor(el) {
    this._steps = {};
    this.el = el;
    this.el.classList.add("coding-preloader");
  }

  hide() {
    this.el.classList.remove("loading");
  }

  show() {
    this.el.classList.add("loading");
  }

  /**
   * Define all steps needed until preloading is complete
   * @param  {...string} steps A list of steps
   */
  define(...steps) {
    this._steps = {};
    steps.forEach(step => {
      this._steps[step] = false;
    });
    this.render();
  }

  /**
   * Discards a preloader step (because it's not actually needed)
   * @param {string} step The step to discard
   * @async
   */
  async discard(step) {
    delete this._steps[step];
    await this.render();
  }

  /**
   * Discards all preloader steps
   * @async
   */
  async discardAll() {
    this._steps = {};
    await this.render();
  }

  /**
   * Add a step to the preloader
   * Note: it is recommended to pass the full list of known steps in via the
   * `define` function instead. But in case where we can't know in advance how
   * many steps are needed, this can be used to add individual steps instead.
   * @param {string} step The step to add
   * @async
   */
  async add(step) {
    this._steps[step] = false;
    await this.render();
  }

  /**
   * Complete a step of the preloader
   * @param {...string} steps The steps that were completed
   * @async
   */
  async complete(...steps) {
    steps.forEach(step => {
      this._steps[step] = true;
    });
    await this.render();
  }

  /**
   * Reset completion of particular steps
   * @param  {...string} steps A list of steps to reset
   * @async
   */
  async reset(...steps) {
    steps.forEach(step => {
      this._steps[step] = false;
    });
    await this.render();
  }

  /**
   * Renders this component
   * @async
   */
  async render() {
    if (!this.isCompleted) {
      this.show();
    }

    await new Promise(r => requestAnimationFrame(r));
    this.el.innerHTML = template({ chunks: this.chunks });
    await new Promise(r => requestAnimationFrame(r));

    if (this.isCompleted) {
      this.hide();
    }
  }

  get chunks() {
    const chunks = [];

    for (let i = 0; i < this.totalStepCount; i++) {
      chunks.push({
        completed: i <= this.completedStepCount,
        current: i === this.completedStepCount,
      });
    }

    return chunks;
  }

  get totalStepCount() {
    return Object.values(this._steps).length;
  }

  get completedStepCount() {
    return Object.values(this._steps).filter(step => !!step).length;
  }

  get isCompleted() {
    return this.completedStepCount === this.totalStepCount;
  }
}

// singleton - This is the main Preloader
export const preloader = new Preloader(
  // note: this element is on the base page template
  document.getElementById("coding-preloader"),
);
