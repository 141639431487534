export class EnhancedSpanProcessor {
  /** @type {(time: import("@opentelemetry/api").HrTime) => number} */
  _milliseconds(time) {
    return Math.round(time[0] * 1e3 + time[1] / 1e6);
  }

  /** @type {(time1: import("@opentelemetry/api").HrTime, time2: import("@opentelemetry/api").HrTime) => number} */
  _subtractTime(time1, time2) {
    return this._milliseconds(time2) - this._milliseconds(time1);
  }

  /** @type {(traceId: string, name: string, amount?: number) => void} */
  _count(traceId, name, amount) {
    this._counters[traceId] = this._counters[traceId] || {};
    this._counters[traceId][name] = this._counters[traceId][name] || 0;
    this._counters[traceId][name] += amount || 1;
  }

  /** @type {(traceId: string, name: string, amount?: number) => void} */
  _max(traceId, name, amount) {
    this._counters[traceId] = this._counters[traceId] || {};
    this._counters[traceId][name] = Math.max(
      this._counters[traceId][name] || 0,
      amount,
    );
  }

  /** @type {(span: import("@opentelemetry/tracing").Span, name: string) => void} */
  _booleanRollup(span, name) {
    if (span.attributes[name]) {
      this._count(span.spanContext.traceId, `totals.${name}.count`);
    }
  }

  /** @type {(span: import("@opentelemetry/tracing").Span, name: string) => void} */
  _integerRollup(span, name) {
    this._count(span.spanContext.traceId, `totals.${name}.count`);
    this._count(
      span.spanContext.traceId,
      `totals.${name}.sum`,
      span.attributes[name],
    );
  }

  /** @type {(span: import("@opentelemetry/tracing").ReadableSpan) => PerformanceNavigationTiming | PerformanceResourceTiming} */
  _getResourceForSpan(span) {
    if (!("http.url" in span.attributes)) {
      return performance.getEntriesByType("navigation")[0];
    }
    const url = String(span.attributes["http.url"]);
    const duration = this._milliseconds(span.duration);
    return performance
      .getEntriesByName(url, "resource")
      .find(resource => Math.round(resource.duration) === duration);
  }

  /** @type {(spanProcessor: import("@opentelemetry/tracing").SpanProcessor)} */
  constructor(spanProcessor) {
    /** @type {{ [name: string]: { [name: string]: number } }} */
    this._counters = {};
    this._spanProcessor = spanProcessor;
  }

  /** @type {() => Promise<void>} */
  forceFlush() {
    return this._spanProcessor.forceFlush();
  }

  /** @type {(span: import("@opentelemetry/tracing").Span, parentContext: import("@opentelemetry/context-base").Context) => void} */
  onStart(span, parentContext) {
    this._spanProcessor.onStart(span, parentContext);
  }

  /** @type {(span: import("@opentelemetry/tracing").ReadableSpan) => void} */
  onEnd(span) {
    // Count up and measure all child spans NOTE: This only works if we can see the root span of the trace
    const traceId = span.spanContext.traceId;
    this._max(traceId, `summary.duration_ms`, this._milliseconds(span.endTime));
    if (span.parentSpanId) {
      const ms = this._milliseconds(span.duration);
      this._count(traceId, `totals.${span.name}.count`);
      this._count(traceId, `totals.${span.name}.duration_ms`, ms);
      this._count(traceId, `totals.count`);
      this._count(traceId, `totals.duration_ms`, ms);
    } else if (traceId in this._counters) {
      for (const name of Object.keys(this._counters[traceId])) {
        span.attributes[name] = this._counters[traceId][name];
      }
      span.attributes["summary.duration_ms"] -= this._milliseconds(
        span.startTime,
      );
    }

    // Add attributes for each span event, containing their time after the overall start time
    for (const event of Object.values(span.events)) {
      span.attributes[`span.event.${event.name}`] = this._subtractTime(
        span.startTime,
        event.time,
      );
    }

    // Add some commonly needed attributes for all spans
    span.attributes["browser.user_agent"] = navigator.userAgent;
    span.attributes[
      "browser.timezone_offset"
    ] = -new Date().getTimezoneOffset();

    // Add some useful attributes for page loads
    if (span.name === "documentLoad" || span.name === "routeChange") {
      span.attributes["document.url"] = location.href;
      span.attributes["document.title"] = document.title;
      span.attributes["window.width"] = window.innerWidth;
      span.attributes["window.height"] = window.innerHeight;
      span.attributes["screen.width"] = screen.width;
      span.attributes["screen.height"] = screen.height;
    }

    // Add some useful attributes for document/resource fetches
    if (span.name === "documentFetch" || span.name === "resourceFetch") {
      // Without this, we can't correctly determine the cache status of the request
      const hasSize = "encodedBodySize" in PerformanceResourceTiming.prototype;
      if (hasSize) {
        const resource = this._getResourceForSpan(span);
        if (resource) {
          // Cross-domain resources have very limited data, which means we cannot work out their cache status
          if (resource.requestStart !== 0) {
            const local = resource.transferSize === 0;
            const validated = resource.transferSize <= resource.encodedBodySize;
            span.attributes["http.cache.cached"] = local;
            span.attributes["http.cache.validated"] = !local && validated;
            span.attributes["http.cache.uncached"] = !local && !validated;
            span.attributes["http.cache.network_bytes"] = resource.transferSize;
            this._booleanRollup(span, "http.cache.cached");
            this._booleanRollup(span, "http.cache.validated");
            this._booleanRollup(span, "http.cache.uncached");
            this._integerRollup(span, "http.cache.network_bytes");
          }
        }
      }
    }

    this._spanProcessor.onEnd(span);
  }

  /** @type {() => Promise<void>} */
  shutdown() {
    return this._spanProcessor.shutdown();
  }
}
