import $ from "jquery";
import { getApiUrl } from "utils/get-api-url";
import { user } from "globals/user";
import "url-search-params-polyfill";

const ALL = {
  /**
   * Allow the translations to be changed via a query param
   * example: ?locale=ar-sa
   */
  locale: new URLSearchParams(window.location.search).get("locale"),
};
const REVERSED = {};

/**
 * Loads the translations
 * @async
 */
export async function load() {
  return new Promise(resolve => {
    $.ajax({
      url: getApiUrl("locale"),
      dataType: "json",
      success: data => {
        Object.entries(data).forEach(pair => addTerm(...pair));
        resolve();
      },
      error: e => {
        // eslint-disable-next-line no-console
        console.error(e);
        resolve();
      },
    });
  });
}

/**
 * Define a translation term
 * @param {string} key      The identifier
 * @param {string} value    The translation
 */
export function addTerm(key, value) {
  ALL[key] = value;
  REVERSED[value] = key;
}

/**
 * Ingests a payload and adds all translations terms to the cache
 * @param {any} payload      Payload
 */
export function ingestPayloadTranslations(payload) {
  if (!payload) {
    return;
  }

  for (const prop in payload) {
    if (prop === "__translations") {
      Object.entries(payload[prop]).forEach(pair => addTerm(...pair));
      delete payload[prop];
    }
  }
}

/**
 * Translates a string to the current locale
 * @param {String} input - The string to return the translation of
 * @return {String}      The translated string
 */
export const translate = (input = "") => ALL[String(input)] || input;
export default translate;

/**
 * Translates content-type strings, string must be of `_KEY:word` format
 * does NOT translate when user is editorial
 * @param {String} input - The string to return the translation of
 */
export function translateContent(input) {
  if (isContentKey(input) && !user.isContentCreator()) {
    return translate(input);
  } else {
    return input;
  }
}

/**
 * Translate inline content unless the user is a content creator
 * content must be surrounded by $$
 * eg: print("$$Monday$$")
 * @param {String} input The text to translate
 */
export function translateInline(input = "") {
  if (user.isContentCreator()) {
    return input;
  } else {
    return _translateInlineAlways(input);
  }
}

/**
 * Search a string for all inline translations
 * @param {String} input The text to search
 */
export function findInlineTranslations(input = "") {
  return [...input.matchAll(/\$\$(.*?)\$\$/gm)].map(match => match[1]);
}

/**
 * Translate inline content regardless of whether the user is a content creator or not
 * NOTE: unless there is a specific reason to use this function, use `translateInline` instead
 * @param {String} input The text to translate
 */
export function _translateInlineAlways(input = "") {
  // inline require so that it doesn't get bundled into main.js package
  const { task } = require("globals/task");

  input = String(input);

  return input.replace(/\$\$(.*?)\$\$/gm, (match, word) => {
    const key = `$TRANSLATION$${task.get("id")}:${word}`;
    if (hasTranslation(key)) {
      return translate(key);
    } else {
      return match;
    }
  });
}

/**
 * Given a translation, return the non-translated text
 * @param  {String} input - The translation to undo
 * @return {String}      The original string
 */
export const unTranslate = (input = "") => REVERSED[String(input)] || input;

/**
 * Test whether a translation exists for a given string
 * @param  {String} input - The string that would be translated
 * @return {Boolean}      True if a translation exists for the given string
 */
export const hasTranslation = input => {
  if (!input) {
    return false;
  }

  return (
    Object.prototype.hasOwnProperty.call(ALL, input) && Boolean(ALL[input])
  );
};

/**
 * Checks whether a string represents a translation key of format `$TRANSLATION$document_id:path`
 * @param {String} input
 */
export const isTranslationKey = input => {
  return Boolean(input && /^\$TRANSLATION\$/.test(input));
};

/**
 * Checks whether a content has a translation key of format  `_KEY:word`
 * @param {String} input
 */
export const isContentKey = input => {
  return Boolean(input && /_KEY:/.test(input));
};
