import BlockArgument from "../components/argument";
import BlockScope from "../components/block-scope";
import CodeBlock from "../code-block";
import ERRORS from "../errors";
import game from "../../game";
import _ from "underscore";

/**
 * Object Name block
 * Targets objects by their object-name properties
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "commands"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
    commands: {},
  }),

  blueprint: {
    type: "object-named",
    fn: "objectNamed",
    label: "object named {arg}",
    description: "targets objects by their object-name properties",
    groups: ["year 6"],
    category: "named objects",
    output: "object",
    args: {
      arguments: [
        {
          argumentSuggestions: [{ type: "string" }],
        },
      ],
    },
  },

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
    this.get("commands").set("object-scope", true);
  },

  getPseudoCode(indent = "") {
    const commands = this.get("commands")
      .get("code")
      .map(block => block.getPseudoCode());

    const target = this.get("args").get("arguments").at(0).getPseudoCode();

    return `${indent}object-named ${target}${commands}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    this.get("commands").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    const args = await this.get("args").compute(scope);
    const object = await game.exec(this.get("fn"), ...args);
    if (object) {
      return [await this.get("commands").run(scope, object)];
    } else {
      return [];
    }
  },
  _placementMessage: ERRORS.PLACEMENT_OBJECT,
  _validatePlacement(parent) {
    return (
      (parent instanceof BlockScope && !parent.get("free-form")) ||
      parent instanceof BlockArgument
    );
  },
});
