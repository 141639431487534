var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Choose a task type:",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":35}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"change-type\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"types") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "</ul>\n<p>\n    "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"This will replace the current task with an empty one of the chosen type.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":92}}}))
    + "\n</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a class=\"type-selection\" index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n            <span class=\"type "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sanitized") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":68}}}))
    + "</span>\n            &mdash;\n            <span class=\"subtype "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"sanitized") : depth0)) != null ? lookupProperty(stack1,"subtype") : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"subtype") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":77}}}))
    + "</span>\n        </a>\n    </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"confirm\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":57},"end":{"line":23,"column":79}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":81},"end":{"line":23,"column":103}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":20,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":24,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});