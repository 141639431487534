var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":8,"column":7}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Saving app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":3,"column":26}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"saved") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":8,"column":0}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Your app is saved!",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":5,"column":34}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create your own app!",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":7,"column":36}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":23,"column":7}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "<p class=\"loader-container\"></p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"saved") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":23,"column":0}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "<p>\n  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Go to Free code to open your app.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":17,"column":51}}}))
    + "\n</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"create-app-form\">\n"
    + ((stack1 = container.invokePartial(require("../shared/app-name.hbs"),depth0,{"name":"../shared/app-name","hash":{"name":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</form>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":29,"column":0},"end":{"line":42,"column":7}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"saved") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":30,"column":0},"end":{"line":42,"column":0}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":56},"end":{"line":31,"column":74}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Ok",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":76},"end":{"line":31,"column":94}}}))
    + "</button>\n"
    + ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(alias1,"EMBEDDED_IN_DE_PLATFORM",{"name":"ifFeatureFlag","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":0},"end":{"line":36,"column":18}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"confirm go-to-freecoder form-submit\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Go to Freecoder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":33,"column":64},"end":{"line":33,"column":95}}}))
    + "\">\n  "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Go to Freecoder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":34,"column":33}}}))
    + "\n</button>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" autofocus aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":66},"end":{"line":38,"column":88}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":90},"end":{"line":38,"column":112}}}))
    + "</button>\n<button class=\"confirm create-app form-submit\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":39,"column":59},"end":{"line":39,"column":85}}}))
    + "\">\n  "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":40,"column":2},"end":{"line":40,"column":28}}}))
    + "\n</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n\n\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":24,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":43,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});