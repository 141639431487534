import settings from "globals/settings";

export default function(action = { type: "" }) {
  const embed = settings.get("embed");
  if (embed) {
    const actionName = [
      action.type,
      action.destination || action.name || undefined,
    ]
      .filter(Boolean)
      .join(".");
    if (embed.supported[actionName] === true) {
      parent.postMessage(action, embed.origin);
      return true;
    }
  }
  return false;
}
