import CodeBlock from "./block-prototype";
import BlockContentsView from "./components/block-contents";
import BlockScopeView from "./components/scope";

export default CodeBlock.extend({
  tagName: "if-block",
  className: CodeBlock.prototype.className + " control-block if-block",
  template: require("templates/block/block-types/if.hbs"),
  initialize: function () {
    CodeBlock.prototype.initialize.call(this);

    this.defineChildView(
      function () {
        return new BlockContentsView({
          model: this.model,
        });
      }.bind(this),
      "args",
      "> control-block-head>control-section-body",
    );

    this.defineChildView(
      function () {
        return new BlockScopeView({
          model: this.model.get("then"),
          className: BlockScopeView.prototype.className + " vertical then",
        });
      }.bind(this),
      "then",
      "> :nth-child(2) >control-section-scope",
    );
  },
});
