import Backbone from "custom/backbone-bundle";
import translate from "utils/localisation";
import CodeBlock from "models/block/blocks/code-block";
import chooseCodeSectionsTemplate from "./choose-code-sections.hbs";
import style from "./choose-code.styl";
import interfaceChannel from "views/block/channels/interface-channel";
import { SOURCE_TYPE } from "../block-source";
style.use();

export const ChooseCodeView = Backbone.View.extend({
  template: require("./choose-code.hbs"),
  tagName: "section",
  className: "choose-code",

  events: {
    "keydown .block-container": "onKeyDownBlockContainer",
    "click .click-interceptor": "onBlockClicked",
    "submit .choose-code-form": "onFormSubmit",
    "click .add-object-button": "onAddObjectButtonClicked",
    "keydown .add-object-button": "onAddObjectButtonKeyDown",
  },

  initialize() {
    this.render();

    this.listenTo(
      this.model,
      "change:target change:where change:filter change:suggestions change:search",
      this.renderBlockSections,
    );
  },

  render() {
    this.$el.html(this.template({}));
  },

  renderBlockSections() {
    if (
      !this.model.get("target") ||
      this.model.requiresWhereSelection() ||
      this.model.requiresFilterSelection()
    ) {
      return;
    }

    const blocks = this.model.getAvailableBlocks();
    const sections = this.getSections(blocks);
    this.sections = sections;

    let blocksFoundLabel;
    if (typeof this.model.get("search") === "string") {
      const count = blocks.filter(block => !block.get("isInput")).length;

      blocksFoundLabel = translate("{n} Code blocks found.").replace(
        "{n}",
        `<strong>${count}</strong>`,
      );
    }
    const isDeprecatedSound =
      blocks.length === 0 &&
      (this.model.get("target")?.get("type") === "sound" ||
        this.model.get("target")?.get("target")?.type === "speaker");
    this.$(".block-sections").empty().append(
      chooseCodeSectionsTemplate({
        sections,
        blocksFoundLabel,
        isDeprecatedSound,
      }),
    );

    this.addBlocks(sections);

    this.$el.toggleClass("no-blocks", blocks.length === 0);
  },

  getSections(blocks) {
    const categories = {};
    const block = this.model.get("target");
    const type = block?.get("type");
    const currentValue = block?.get("value") || "";

    blocks.forEach(block => {
      const category = block.get("category") || "other";

      // create the category
      if (!categories[category]) {
        categories[category] = {
          category,
          title:
            category === "wall" ||
            category === "canvas" ||
            category === "wall-objects"
              ? ""
              : translate(category),
          blocks: [],
        };
      }

      if (block.get("isInput")) {
        categories[category].input = block.toJSON();

        // pre-fill the value
        switch (category) {
          case "numbers":
          case "angles":
            categories[category].input.value =
              type !== "string" ? currentValue : "";
            break;
          case "string":
            categories[category].input.value =
              type === "string" ? currentValue : "";
            break;
          case "sounds":
            categories[category].input.value =
              type === "sound" ? currentValue : "";
            break;
        }
      } else if (block.get("isAddObjectButton")) {
        const data = block.toJSON();
        categories[category].addObjectButton = data;
        categories[category].addObjectButton.label = translate(
          "Add {item}",
        ).replace("{item}", translate(data.category));
        categories[category].hasBlocksSection = true;
      } else {
        categories[category]?.blocks.push(block);
        categories[category].hasBlocksSection = true;
      }
      //fix for Chrome: add timeout to stick select on input text
      this.$el.on("focus", "input", function () {
        if (this) {
          setTimeout(() => {
            this.select();
          }, 100);
        }
      });
    });

    if (this.model.getBlockSource() === SOURCE_TYPE.WALL) {
      return Object.values(categories).sort((a, b) =>
        a.addObjectButton
          ? -1
          : a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
      );
    } else {
      return Object.values(categories).sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
      );
    }
  },

  addBlocks(sections) {
    sections.forEach(section => {
      section.blocks.forEach((model, i) => {
        const clone = model.view.el.cloneNode(true);

        clone.classList.remove("hidden");
        clone
          .querySelectorAll(".phaser-sprite.sound.interactive")
          .forEach(el => el.classList.remove("interactive"));

        clone.style.transform = `rotate(${model.get("invalid-rotation")}deg)`;

        // make this and all nested blocks non-tabable
        [clone, ...clone.querySelectorAll("[tabindex]")].forEach(
          el => (el.tabIndex = -1),
        );

        this.$(
          `.block-container[data-index="${i}"][data-category="${section.category}"]`,
        ).append(clone);
      });
    });
  },

  onAddObjectButtonClicked(e) {
    interfaceChannel.trigger("multi-toodal:close");
    interfaceChannel.trigger(
      "panel-manager:panel:open:block-design-add-object-panel",
    );
    interfaceChannel.trigger("add-object-panel:go-to", e.target.dataset.goto);
  },

  onAddObjectButtonKeyDown(e) {
    if (e.key === "Enter") {
      interfaceChannel.trigger("multi-toodal:close");
      interfaceChannel.trigger(
        "panel-manager:panel:open:block-design-add-object-panel",
      );
      interfaceChannel.trigger("add-object-panel:go-to", e.target.dataset.goto);
    }
  },

  onKeyDownBlockContainer(e) {
    if (e.key === "Enter") {
      this.selectBlock(e.target);
    }
  },

  onBlockClicked(e) {
    this.selectBlock(e.target);
  },

  onFormSubmit(e) {
    const type = e.target.dataset.blockType;
    const value = e.target.querySelector("input").value;
    const block = CodeBlock.build({ type, value }, { parse: true });
    this.model.set("selection", block);
    e.preventDefault();
  },

  selectBlock(el) {
    const index = el.dataset.index;
    const category = el.dataset.category;

    const block = this.sections.find(section => section.category === category)
      .blocks[index];

    this.model.set("selection", block);
  },
});
