import translate from "utils/localisation";

export default {
  name: "Python free editor",
  model: "models/python",
  views: ["views/free-editor", "views/python-console"],
  language: "python",
  experimental: true,
  lines: [
    {
      text: `print("${translate("hello world")}")`,
    },
  ],
  tests: [
    {
      type: "code-valid",
      test: true,
      message: "CODE ERROR",
    },
  ],
};
