import interfaceChannel from "../../channels/interface-channel";
import GAME from "../../../../models/block/game";
import { translate } from "../../../../utils/localisation";
import { closeOpenedMenus } from "utils/close-opened-menus";
/**
 * Handles the interaction of draggable code blocks.
 *
 * Allows code blocks to be moved and inserts helpers to indicate their
 * potential drop positions
 *
 * Note: does *not* handle drop interactions, see the `droppable-` files in this
 * folder for those
 */
export class DraggableBlock {
  constructor(parent) {
    this.parent = parent;

    // create drop helper
    this.dropHelper = document.createElement("div");
    this.dropHelper.classList.add("drop-placeholder");

    // this additional helper is only used when we need a second one to indicate
    this.dropHelperExtra = document.createElement("div");
    this.dropHelperExtra.classList.add("drop-placeholder");
  }

  get dropZone() {
    return this.parent.droppableScope.dropZone;
  }

  set dropZone(val) {
    this.parent.droppableScope.dropZone = val;
  }

  onDragStart(e) {
    const el = this.parent.getBlockEl(e.target);
    if (!(el && el.model)) {
      return;
    }
    const model = el.model;

    this.dragging = el;

    this._createDragHelper(e, el, model);
    this._makeParentSlotDroppable(el);

    el.classList.add("dragging");
    this.parent.el.classList.add("dragging-block");
    interfaceChannel.trigger("block-interaction-zone:drag-start", model);
  }

  onDragEnd(e) {
    const el = this.parent.getBlockEl(e.target);
    if (!(el && el.model)) {
      return;
    }
    const model = el.model;

    this.parent.el.removeChild(this.dragHelper);
    delete this.dragHelper;
    this.dropZone = null;
    this.dragging = null;
    this._showDropHelpers(null);

    el.classList.remove("dragging");
    this.parent.el.classList.remove("dragging-block");
    interfaceChannel.trigger("block-interaction-zone:drag-end", model);

    GAME.resume();
    GAME.edit();
  }

  onMove(e) {
    // we pause the game while dragging for performance
    GAME.pause();

    closeOpenedMenus(e);

    const helper = this.dragHelper;
    if (!helper) {
      return;
    }
    const x = (parseFloat(helper.getAttribute("data-x")) || 0) + e.dx;
    const y = (parseFloat(helper.getAttribute("data-y")) || 0) + e.dy;
    const s = parseFloat(helper.getAttribute("data-s")) || 0;

    // move the helper
    helper.style.transform = `translate(${x}px, ${y}px) scale(${s})`;
    helper.style.transformOrigin = "top left";

    this.helperBounds = helper.getBoundingClientRect();

    // update the position attributes
    helper.setAttribute("data-x", x);
    helper.setAttribute("data-y", y);

    // calculate information about the potential drop position
    const parent = this._getDropScope();
    const siblings = this._getDropSiblings(parent);
    this.dropIndex = this._getDropIndex(siblings, e.clientX, e.clientY);

    // apply visual aid
    this._showDropHelpers(parent, this.dropIndex, siblings);
  }

  /**
   * Create the drag helper
   * This is the element that the user will be moving around while the
   * original element stays in place.
   */
  _createDragHelper(e, el, model) {
    this.dragHelper = el.cloneNode(true);
    this.dragHelper.classList.add("helper");
    this.dragHelper.classList.remove("invalid-position");
    this.dragHelper.classList.remove("focus");
    this.dragHelper.setAttribute("dir", translate("dir"));
    this.dragHelper.style.position = "absolute";
    this.dragHelper.style.top = 0;
    this.dragHelper.style.left = 0;
    this.dragHelper.style.zIndex = 5;

    if (model.has("parent-jigsaw")) {
      this._hideHelperJigsawScopes();
    }

    this.parent.el.appendChild(this.dragHelper);

    this._snapHelperToCursor(e, el);
  }

  /**
   * Hide child scopes when dragging a jigsaw block
   *
   * Under normal circumstances, when dragging a block, the user also drags
   * all of it's children. In `jigsaw mode` however, this is different as the
   * user only ever drags individual blocks.
   */
  _hideHelperJigsawScopes() {
    // find all the child scope elements in our helper
    const childScopes = [
      ...this.dragHelper.querySelectorAll(
        "control-block-scope > control-section-scope > block-scope > scope-code",
      ),
      ...this.dragHelper.querySelectorAll("object-scope"),
    ];

    // empty them
    childScopes.forEach(element => (element.innerHTML = ""));
  }

  /**
   * When a block is dragged out of an `argument` slot or a `jigsaw` slot, we
   * make that slot droppable, so that the block can be dropped back where it
   * came from
   */
  _makeParentSlotDroppable(el) {
    if (el.parentElement.classList.contains("argument")) {
      el.parentElement.classList.add("droppable");
    }

    if (el.parentElement.parentElement.classList.contains("jigsaw")) {
      el.parentElement.parentElement.classList.add("droppable");
    }
  }

  _snapHelperToCursor(e, el) {
    const model = el.model;

    // snap helper to cursor (with some padding)
    const scale = model.activeCanvas().get("scale");
    const padding = 22 * scale;

    let x = -padding;
    let y = -padding;

    if (translate("dir") === "rtl") {
      const isControlBlock = this.dragHelper.classList.contains(
        "control-block",
      );
      if (isControlBlock) {
        const dragHandleBounds = el
          .querySelector(".drag-handle")
          .getBoundingClientRect();
        x = padding - dragHandleBounds.width;
      } else {
        const dragHelperBounds = this.dragHelper.getBoundingClientRect();
        x = padding - dragHelperBounds.width * scale;
      }
    }

    x += e.clientX0;
    y += e.clientY0;

    this.dragHelper.setAttribute("data-x", x);
    this.dragHelper.setAttribute("data-y", y);
    this.dragHelper.setAttribute("data-s", scale);
  }

  /**
   * Adds drop helpers to indicate where a draggable would drop
   */
  async _showDropHelpers(parent = null, index = 0, siblings = []) {
    // remove previous helpers
    if (this.dropHelper.parentElement) {
      this.dropHelper.parentElement.removeChild(this.dropHelper);
    }
    if (this.dropHelperExtra.parentElement) {
      this.dropHelperExtra.parentElement.removeChild(this.dropHelperExtra);
    }

    if (!parent) {
      return;
    }

    // determine helper type
    const isEmpty = !parent.hasChildNodes();
    const isVertical = parent.parentElement.classList.contains("vertical");

    if (isEmpty) {
      this.dropHelper.classList.add("empty-parent");
      this.dropHelperExtra.classList.add("empty-parent");
    } else {
      this.dropHelper.classList.remove("empty-parent");
      this.dropHelperExtra.classList.remove("empty-parent");
    }

    if (isVertical) {
      this.dropHelper.classList.add("vertical");
      this.dropHelperExtra.classList.add("vertical");
      this.dropHelper.classList.remove("horizontal");
      this.dropHelperExtra.classList.remove("horizontal");
    } else {
      this.dropHelper.classList.add("horizontal");
      this.dropHelperExtra.classList.add("horizontal");
      this.dropHelper.classList.remove("vertical");
      this.dropHelperExtra.classList.remove("vertical");
    }

    // append drop helper at the right position
    if (index !== siblings.length) {
      parent.insertBefore(this.dropHelper, siblings[index] || null);
    } else {
      parent.appendChild(this.dropHelper);
    }

    /**
     * Add secondary drop helper (if needed)
     *
     * There are two scenarios where this is needed ~
     *  - When the current drop position is the same as where the element was
     *    picked up: in this scenario we apply a helper before and after the
     *    'shadow' of the element
     *  - when a vertical scope is empty
     */
    if (
      this.dropHelper.previousSibling &&
      this.dropHelper.previousSibling.classList.contains("dragging")
    ) {
      parent.insertBefore(
        this.dropHelperExtra,
        this.dropHelper.previousSibling || null,
      );
    }
    if (isEmpty && isVertical) {
      parent.appendChild(this.dropHelperExtra);
    }
  }

  /**
   * Returns the DOMElement that is the target of the current drop
   */
  _getDropScope() {
    if (!this.dropZone || !this.dropZone.model) {
      return; // no target - some information appears to be missing
    }

    if (this.dropZone.model.get("free-form")) {
      return; // no target - free-form scope
    }

    return [...this.dropZone.children].find(el => el.tagName === "SCOPE-CODE");
  }

  /**
   * Produces the list of siblings of a potential drop
   */
  _getDropSiblings(element) {
    if (!element) {
      return [];
    }

    // then we find all the interesting children inside
    return [...element.children].filter(
      child =>
        !child.classList.contains("helper") &&
        !child.classList.contains("drop-placeholder") &&
        !child.classList.contains("dragging"),
    );
  }

  /**
   * Calculates the index of a draggable
   *
   * When dropping a draggable into a scope, we need to figure out the index at
   * which the draggable will be dropped. This is done based on the x or y
   * position of the draggable element (depending on the axis of the droppable)
   * compared to the other items in the scope.
   *
   * A helper will be added to indicate this position
   *
   * @returns {number} The index at which to insert the element, -1 when not applicable
   */
  _getDropIndex(siblings, x, y) {
    if (!siblings.length) {
      return -1;
    }

    // determine axis used for sorting
    let axis = "y";
    if (this.dropZone.model.get("object-scope")) {
      axis = "x";
    }

    // then we find the center point of all siblings
    const centers = siblings.map(el => {
      const rect = el.getBoundingClientRect();
      return {
        x: Math.floor(rect.left + rect.width / 2),
        y: Math.floor(rect.top + rect.height / 2),
      };
    });

    const position = { x, y };

    // now we compare the list of centers to the position of our helper to find
    // the closest most relevant index
    for (let i = 0; i < centers.length; i++) {
      if (translate("dir") === "rtl" && axis === "x") {
        // when in RTL mode, we need to flip calculations on the the x-axis
        if (centers[i][axis] < position[axis]) {
          return i;
        }
      } else {
        if (centers[i][axis] > position[axis]) {
          return i;
        }
      }
    }

    return centers.length;
  }
}
