import * as validate from "./../utils/validate-inputs";

/**
 * Causes this task to fail, will prevent any subsequent calls to `complete`
 * from succeeding.
 *
 * Used by content creators to validate tasks via code blocks
 *
 * @param {Object:App} object - The app object
 */
export default function fail(object) {
  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  object.model.fail();
}
