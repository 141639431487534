import * as validate from "../utils/validate-inputs";
import { createObject } from "../creation/create-objects";
import { initializePhysics, enablePhysics } from "../creation/enable-physics";
import { console as blockConsole } from "globals/console";
import { translate } from "utils/localisation";
import WARNINGS from "./../../blocks/warnings";
import get from "./get";
import set from "./set";
import { OBJECT_PROPERTIES, isProtected } from "../utils/object-properties";

const MAX_DYNAMIC = 50;

/**
 * Clones an object on the stage
 *
 * @param {Object} object - The object to clone
 */
export default async function clone(object) {
  if (!validate.classBased(object)) {
    throw Error(validate.error.classBased);
  }

  const game = object.game;

  if (
    game.objectGroup.getAll("runtimeDynamicClone", true).length >= MAX_DYNAMIC
  ) {
    blockConsole.warn(
      translate(WARNINGS.RUNTIME_MAX_CLONES).replace("{number}", MAX_DYNAMIC),
      "max-dynamic",
    );
    return;
  }

  const clone = createObject(game, object.model, { isClone: true });
  initializePhysics(game, clone);

  // copy properties from the active sprite to the clone
  Object.keys(OBJECT_PROPERTIES).forEach(key => {
    if (!isProtected(key) && key !== "objectName") {
      set(clone, key, get(object, key));
    }
  });

  // copy custom properties from the active sprite to the clone
  clone.__custom = JSON.parse(JSON.stringify(object.__custom));

  // clone collisions
  clone["collides-with"] = [...object["collides-with"]];

  // mark as cloned
  clone["is-cloned"] = true;

  // bind all non-global events to new clone
  [
    ...object.game.model.get("input").get("code"),
    ...object.game.model.get("background-code").get("code"),
  ]
    .filter(block => block.get("type") === "event")
    .filter(block => !block.get("global"))
    .forEach(event => event.runtimeExecute(clone));

  await enablePhysics(game, clone);

  // dispatch event
  game.onCloned.dispatch(clone);
}
