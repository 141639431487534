var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"File not saved",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":30}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg class=\"error\" aria-hidden=\"true\" focusable=\"false\">\n  <use xlink:href=\"#icon-save-default-warning\"></use>\n</svg>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messageText") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messageText") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":3},"end":{"line":10,"column":28}}}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<p>\n  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Your file has not been saved, please check your Internet connection and try saving again or download the app instead.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":13,"column":138}}}))
    + "\n</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" autofocus\n  aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":20,"column":36}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":38},"end":{"line":20,"column":60}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":16,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":18,"column":0},"end":{"line":21,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});