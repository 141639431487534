import * as validate from "./../utils/validate-inputs";

export default function isMoving(object) {
  // if we are checking more than one object, return true if any of them are moving
  if (Array.isArray(object)) {
    return object
      .map(item => isMoving(item))
      .reduce((acc, val) => acc || val, false);
  }

  if (validate.physical(object)) {
    return isObjectMoving(object);
  } else if (validate.pointer(object)) {
    return isPointerMoving(object);
  } else {
    throw Error(validate.error.object);
  }
}

function isObjectMoving(object) {
  const accuracy = 5;
  //round values so that imperceptible velocities are counted as NOT moving
  const x = Number(object.body.velocity.x.toFixed(accuracy));
  const y = Number(object.body.velocity.y.toFixed(accuracy));

  return object.isMoving || x !== 0 || y !== 0;
}

function isPointerMoving(pointer) {
  return pointer.speed !== 0;
}
