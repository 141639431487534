import interfaceChannel from "views/block/channels/interface-channel";

/**
 * Zooms the canvas that a block is in, so that it is in view for focus
 * note: does nothing if the model is not in a canvas
 * @param {any} model can be a backbone-model, a view model or an element
 * @returns
 * @async
 */
export async function focusZoom(model) {
  if (!model) {
    return;
  }

  const modelView = model.view?.el || model.el || model;
  const canvas = modelView.closest("code-canvas");

  if (!canvas) {
    return;
  }

  const targetRect = modelView.getBoundingClientRect();
  const canvasRect = canvas.getBoundingClientRect();

  const toZoom =
    targetRect.top < canvasRect.top ||
    targetRect.bottom > canvasRect.bottom ||
    targetRect.left < canvasRect.left ||
    targetRect.right > canvasRect.right;

  if (toZoom) {
    interfaceChannel.trigger("zoom-to-fit", modelView);
    await new Promise(r => requestAnimationFrame(r));
  }
}
