import PrimitiveBlock from "./../primitive";

/** this model represents a number primitive */
export default PrimitiveBlock.extend({
  getPrimitiveValue() {
    return Number(this.get("value"));
  },

  async getToodalOptions() {
    // set sprite frame
    if (
      this.__isSecondArg() &&
      this.__getParentFirstArgValue() === "frame" &&
      this.__getParentBlockFn() === "set"
    ) {
      const [sprite] = await this.__getParentObjects();
      const frames = sprite.animations.frameData._frames;
      const defaultFrameIndex = sprite.animations.frame;

      frames.forEach(frame => {
        sprite.animations.frame = frame.index;
        frame.texture = sprite.generateTexture().getBase64();
      });

      sprite.animations.frame = defaultFrameIndex;

      if (frames) {
        return {
          title: "Frame",
          style: "frame",
          cacheKey: `presets:frames:${sprite.phaserKey}`,
          custom: frames.map(frame => ({
            value: {
              type: "number",
              texture: frame.texture,
              value: frame.index,
            },
          })),
        };
      }
    }

    return {
      title: "Number",
      style: "number",
      cacheKey: "presets:number",
      input: "number",
      blocks: [],
    };
  },
});
