import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";
import { translateContent } from "utils/localisation";

/**
 * Alerts text
 *
 * @param {Object:App} object - The app object
 * @param {String} text - The text to alert
 */
export default function alert(object, text) {
  text = translateContent(string(text));

  if (!validate.app(object)) {
    throw Error(validate.error.command);
  }

  window.alert(text);
}
