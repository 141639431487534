import * as validate from "./../utils/validate-inputs";

/**
 * Shakes an object!
 *
 * @param {Object} object - The Object to shake
 */
export default function shake(object) {
  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  this.shakePlugin.shake(Math.max(object.width, object.height) / 10, object);
}
