import * as validate from "./../utils/validate-inputs";
import { number } from "./../types/number";
import assignVariable from "./assign-variable";

export default function changeVariableBy(variable, val) {
  if (Array.isArray(variable)) {
    return variable.forEach(item => changeVariableBy(item, val));
  }

  if (!validate.variable(variable)) {
    throw Error(validate.error.variable);
  }

  let value;
  try {
    value = number(variable);
  } catch (e) {
    throw Error(validate.error.variable_change);
  }

  val = number(val);

  return assignVariable(variable, value + val);
}
