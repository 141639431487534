import makeThumbnail from "./../utils/make-thumbnail";
import { KEYS } from "./../../blocks/primitive/types/key-mapping";

const Key = function(game, model) {
  Phaser.Sprite.call(
    this,
    game,
    model.get("x"),
    model.get("y"),
    "UI:transparent-64",
  );

  this.keyCode = Number(model.get("keyCode"));
  const spacebar = this.keyCode === 32;
  const key = KEYS.find(key => key.keyCode === this.keyCode);
  const label = key ? key.label : "";

  const fontsize = (this.height * 2) / 3;

  const keySprite = game.make.sprite(0, 0, spacebar ? "UI:spacebar" : "UI:key");
  const text = game.make.text(0, 0, label, {
    font: `${fontsize}px Arial`,
    fill: "#000",
  });

  keySprite.width = this.width;
  keySprite.height = this.height;

  keySprite.anchor.setTo(0.5);
  text.anchor.setTo(0.5);

  this.addChild(keySprite);
  this.addChild(text);

  //make sure the 'SPACE' text is scaled correctly
  if (spacebar) {
    text.scale.setTo(keySprite.scale.x, keySprite.scale.y);
    text.scale.x /= text.scale.y;
    text.scale.y /= text.scale.y;
  }

  makeThumbnail.call(this, model);

  //make object clickable while running
  if (game.mode === "run") {
    this.bindSimulatedEvents();
  }
};

Key.prototype = Object.create(Phaser.Sprite.prototype);
Key.prototype.constructor = Key;

//makes the key fire keyboard events when clicked
Key.prototype.bindSimulatedEvents = function() {
  this.inputEnabled = true;
  this.events.onInputDown.add(() => fireEvent("keydown", this.keyCode));
  this.events.onInputUp.add(() => fireEvent("keyup", this.keyCode));
  this.events.onInputUp.add(() => fireEvent("keypress", this.keyCode));
  this.input.useHandCursor = true;
};

export default Key;

//simulates keyboard events for phaser to listen to
function fireEvent(type, key) {
  //due to a bug in webkit we need to do some wizardry for the simulated keypress to actually have a key code
  //https://stackoverflow.com/a/23812767
  const keyboardEvent = new KeyboardEvent(type, { bubbles: true });
  Object.defineProperty(keyboardEvent, "keyCode", {
    get: function() {
      return this.charCodeVal;
    },
  });
  keyboardEvent.charCodeVal = key;
  window.dispatchEvent(keyboardEvent);

  if (type === "keydown") {
    SIMULATED_KEYS[key] = true;
  } else if (type === "keyup") {
    SIMULATED_KEYS[key] = false;
  }
}

export const SIMULATED_KEYS = {};
