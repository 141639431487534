import { app } from "app";
import Topic from "models/topic";
let topic = new Topic();

export default topic;
export { topic };

export async function setCurrentTopic(id) {
  if (!id || id === "-") {
    return topic.clear();
  }

  if (id === topic.get("id")) {
    return;
  }

  // flag current topic as not being active
  topic.set("active", false);

  // Use backbone relational to find or create new topic,
  // Then map this to the exported topic variable
  // If a topic with said ID has already been created, backbone relational will restore it, otherwise a new topic will be instantiated
  topic = Topic.findOrCreate({ id });

  // flag new topic as being active
  topic.set("active", true);

  await topic.fetch();
  app.expose("topic", topic);
}
