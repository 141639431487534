import * as validate from "./../utils/validate-inputs";
import { positiveNumber } from "./../types/number";

//onAfterSeconds - triggers after a given amount of seconds
export default function onAfterSeconds(callback, delay) {
  try {
    delay = positiveNumber(delay);
  } catch (e) {
    throw Error(validate.error.time);
  }

  this.time.events.add(Phaser.Timer.SECOND * delay, () => callback());
}
