import { checkFlag } from "utils/flags";
import interfaceChannel from "views/block/channels/interface-channel";

// loads an individual asset
export function loadAsset(data) {
  if (!data) {
    return;
  }

  const url = data.dynamicSource;
  const key = data.phaserKey;

  switch (data.type) {
    case "image":
    case "tileset":
    case "background":
    case "sprite":
      this.load.image(key, url);
      break;
    case "spritesheet":
    case "button":
      this.load.spritesheet(key, url, data.tileWidth, data.tileHeight);
      break;
    case "sound":
      this.load.audio(key, url);
      break;
  }
}

const preload = function () {};

function initSounds(game) {
  if (!this.__puzzle_drop) {
    this.__puzzle_drop = game.add.audio("puzzle-drop");
    game.listener.listenTo(interfaceChannel, "block-dropped", () =>
      this.__puzzle_drop.play(),
    );
  }

  if (!this.__clone_block) {
    this.__clone_block = game.add.audio("clone-block");
    game.listener.listenTo(interfaceChannel, "block-cloned", () =>
      this.__clone_block.play(),
    );
  }

  if (!this.__connect_block) {
    this.__connect_block = game.add.audio("connect-block");
    game.listener.listenTo(interfaceChannel, "block-connected", () =>
      this.__connect_block.play(),
    );
  }

  if (!this.delete_block) {
    this.delete_block = game.add.audio("delete-block");
    game.listener.listenTo(interfaceChannel, "block-recycled", () =>
      this.delete_block.play(),
    );
  }
}

// configure game
preload.prototype.init = function (assets = [], callback) {
  this.preloadAssets = assets;
  this.callback = callback;
};

// ensure that we never ask for cached external images
function cacheBustforLoadingImage() {
  const { userAgent } = navigator;
  const isSafari = /safari/i.test(userAgent) && !/chrome/i.test(userAgent);

  if (!isSafari || Phaser.Loader.prototype.originalTransformUrl) return;

  // note: As long as assets load from a different domain, we need to ensure
  // each individual asset is cache busted on iPad. This is because the headers
  // containing the cross-domain policies do not get cached and if an image were
  // to load twice, the second attempt would fail to load.
  Phaser.Loader.prototype.originalTransformUrl =
    Phaser.Loader.prototype.transformUrl;
  Phaser.Loader.prototype.transformUrl = function (url, file) {
    if (url && url.match(/^(?:http:\/\/|https:\/\/)/)) {
      url = `${url}?cacheBust=${Date.now()}`;
    }

    return this.originalTransformUrl(url, file);
  };
}

// preload all objects based on object type
preload.prototype.preload = function () {
  const game = this.game;

  game.stage.disableVisibilityChange = true;

  // allow assets to be loaded from AWS
  game.load.crossOrigin = "anonymous";

  cacheBustforLoadingImage();

  // preload assets
  this.preloadAssets.forEach(asset => loadAsset.call(this, asset));
  delete this.preloadAssets;

  if (checkFlag("UI_INTERACTION_SOUNDS")) {
    initSounds.call(this, game);
  }

  loadBlockSounds.call(this);
};

// trigger callback when ready
preload.prototype.create = function () {
  var callback = this.callback;
  delete this.callback;
  if (typeof callback === "function") {
    callback();
  }
};

async function loadBlockSounds() {
  // using this audio to unblock sound on iOS
  if (!checkFlag("UI_INTERACTION_SOUNDS")) {
    this.load.audio("puzzle-drop", "dist/sounds/effects/puzzle-drop.mp3");
  }
}

export default preload;
