import PrimitiveBlock from "./../primitive";
import GAME from "models/block/game";

/** this model represents a sound primitive */
export default PrimitiveBlock.extend({
  getPseudoCode() {
    return `"${this.get("value")}"`;
  },

  getPrimitiveValue() {
    return String(this.get("value"));
  },

  async getToodalOptions() {
    const game = await GAME.promiseGame();
    let models = game.objectGroup.children
      .filter(sprite => sprite.model.get("type") === "sound")
      .map(sprite => sprite.model);
    if (!models.length) {
      return {
        title: "Sound",
        style: "sound",
        cacheKey: `presets:sound:$empty`,
        input: null,
        custom: [],
        message: "When you add a sound to your design it will appear here.",
      };
    }

    return {
      title: "Sound",
      style: "sound",
      cacheKey: `presets:sound:${models
        .map(model => model.get("phaserKey"))
        .join("-")}`,
      input: null,
      custom: models.map(model => ({
        value: {
          type: "sound",
          value: model.get("phaserKey"),
        },
        name: model.get("name"),
      })),
    };
  },
});
