import { View } from "backbone";
import interfaceChannel from "../../channels/interface-channel";
import { ToodalPreset } from "./toodal-preset";
import { translate } from "utils/localisation";
import GAME from "../../../../models/block/game";

import style from "./toodal.styl";
style.use();

/**
 * This view manages opening/closing/positioning of the toodal
 */
const Toodal = View.extend({
  template: require("./toodal.hbs"),
  tagName: "block-toodal",
  className: "toodal",

  initialize() {
    this.$el.attr("dir", translate("dir"));
    this.$el.html(this.template());
    this.presets = {};
    this.listenTo(interfaceChannel, "toodal:open", this.open);
    this.listenTo(interfaceChannel, "toodal:close", this.close);
  },

  /** renders a the appropriate toodal based on a model, re-uses previously cached toodals */
  async render(model) {
    const data = await model.getToodalOptions();
    const key = data.cacheKey;

    if (!this.presets[key]) {
      this.presets[key] = new ToodalPreset(data);
      this.$(".presets").append(this.presets[key].el);
    }

    this.presets[key].show();
    this.presets[key].bind(model);
  },

  /** opens the toodal next to the selected view */
  async open(view) {
    // apply some delay to opening a toodal
    // this prevents it from immediately closing depending on other events happening in the browser
    await new Promise(r => setTimeout(r, 100));

    Object.values(this.presets).forEach(preset => preset.hide());
    this.$el.addClass("active loading");
    this.position(view.el);

    // make sure we're in edit mode
    GAME.edit();

    await this.render(view.model);

    this.$el.removeClass("loading");

    // we wait for the next animation frame and position the toodal
    await new Promise(r => requestAnimationFrame(r));
    this.position(view.el);

    // because some blocks have async rendering, we need to wait for a second animation frame and position again
    await new Promise(r => requestAnimationFrame(r));
    this.position(view.el);
  },

  /** closes the toodal */
  close() {
    this.$el.removeClass("active");
  },

  /** positions the toodal next to a target element */
  position(target) {
    const block = target.getBoundingClientRect();
    const toodal = this.el.getBoundingClientRect();
    const y = Math.floor(block.top + block.height / 2 - toodal.height / 2);

    let x;
    if (translate("dir") === "ltr") {
      //if there is not enough space on the right, open toodal on the left
      if (window.innerWidth - block.right < toodal.width) {
        x = Math.floor(block.x - toodal.width - 15);
        this.$el.addClass("exception-left");
      } else {
        //default - open on the right
        x = Math.floor(block.right);
        this.$el.removeClass("exception-left");
      }
    } else {
      //if there is not enough space on the left, open toodal on the right
      if (block.x > toodal.width) {
        x = Math.floor(block.x - toodal.width - 15);
        this.$el.addClass("exception-right");
      } else {
        //default - open on the left
        x = Math.floor(block.x + 42);
        this.$el.removeClass("exception-right");
      }
    }
    this.el.style.transform = `translate(${x}px, ${y}px)`;
  },
});

export const toodal = new Toodal(); // Singleton View
document.body.appendChild(toodal.el);
