var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span>...</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <argument-head class=\"block-partial\">\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-placeholder-left.hbs"),depth0,{"name":"partial-shapes/block-placeholder-left","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </argument-head>\n    <argument-body class=\"block-partial\">\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-placeholder-middle.hbs"),depth0,{"name":"partial-shapes/block-placeholder-middle","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </argument-body>\n    <argument-code></argument-code>\n    <argument-tail class=\"block-partial\">\n"
    + ((stack1 = container.invokePartial(require("./partial-shapes/block-placeholder-right.hbs"),depth0,{"name":"partial-shapes/block-placeholder-right","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </argument-tail>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"argument-bracket\">(</label>\n<argument-container class=\"block-section\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inToodal") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":16,"column":9}}})) != null ? stack1 : "")
    + "</argument-container>\n<label class=\"argument-bracket\">)</label>\n";
},"usePartial":true,"useData":true});