import translate from "utils/localisation";

const RTL = translate("dir") === "rtl";

/**
 * Separates code blocks after localisation.
 *
 * Tests for overlap on both x and y-axis, but moves blocks only along x-axis.
 *
 * NOTE: this is only intended to be used by editorially prepared tasks that get
 * localised automatically. The logic is very limited and does not handle every
 * possible scenario.
 */
export class CodeSeparator {
  constructor() {}

  /**
   * Separates a collection of code blocks
   * @param {Backbone.Collection} blockCollection
   */
  async separateAll(blockCollection) {
    document.body.classList.add("is-separating");
    await new Promise(r => requestAnimationFrame(r));

    this.done = [];
    this.todo = blockCollection
      .filter(model => model.get("type") === "event") // we only separate event blocks
      .map(model => new Item(model));

    this.todo.sort((a, b) => a.x - b.x);

    if (RTL) {
      this.todo.reverse();
    }

    while (this.todo.length) {
      const item = this.todo.shift();
      this.separate(item);
      this.done.push(item);
    }

    this.done.forEach(item => item.update());
    document.body.classList.remove("is-separating");
  }

  /**
   * Separates an individual item
   * @param {Item} item
   */
  separate(item) {
    for (let i = 0; i < this.done.length; i++) {
      const offset = item.overlaps(this.done[i]);
      item.x += offset;
    }
  }
}

/**
 * Item handled by the CodeSeparator
 */
class Item {
  constructor(model) {
    this.model = model;
    const bounds = this.model.view.el.getBoundingClientRect();

    this.__x = bounds.x;
    this.y = bounds.y;
    this.width = bounds.width;
    this.height = bounds.height;
    this.offset = 0;
  }

  get x() {
    return this.__x + this.offset;
  }

  set x(val) {
    this.offset = val - this.__x;
  }

  /**
   * Checks if this Item overlaps with another
   * Performs AABB collision detection
   * @param {Item} other
   * @return {Number} The distance this Item needs to be moved along the X-axis to prevent overlap
   */
  overlaps(other) {
    if (
      this.x < other.x + other.width &&
      this.x + this.width > other.x &&
      this.y < other.y + other.height &&
      this.y + this.height > other.y
    ) {
      return other.x + other.width - this.x;
    }

    return 0;
  }

  /**
   * Updates the model of this item
   */
  update() {
    if (this.offset) {
      const pos = this.model.get("position");

      if (RTL) {
        pos.set("x", pos.get("x") - this.offset);
      } else {
        pos.set("x", pos.get("x") + this.offset);
      }
    }
  }
}
