/**
 * Sorts code blocks
 * Uses their x*y position on the stage
 *
 * @param {CodeBlock} a
 * @param {CodeBlock} b
 */
export default function comparator(a, b) {
  // models with no position can't be sorted
  if (!a.has("position") || !b.has("position")) {
    return 0;
  }

  // move `onStart` events at the end
  if (a.get("fn") === "onStart" && b.get("fn") !== "onStart") {
    return 1;
  } else if (a.get("fn") !== "onStart" && b.get("fn") === "onStart") {
    return -1;
  }

  // sort by y-position first
  if (a.get("position").get("y") < b.get("position").get("y")) {
    return -1;
  } else if (a.get("position").get("y") > b.get("position").get("y")) {
    return 1;
  }

  // then sort by x-position
  if (a.get("position").get("x") < b.get("position").get("x")) {
    return -1;
  } else if (a.get("position").get("x") > b.get("position").get("x")) {
    return 1;
  }

  return 0;
}
