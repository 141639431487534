var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"new-app-info\">\n    <div class=\"thumbnail\">\n      <svg class=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0)) != null ? lookupProperty(stack1,"class") : stack1), depth0))
    + "\" aria-hidden=true focusable=false >\n        <use xlink:href=\"#"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0)) != null ? lookupProperty(stack1,"svg") : stack1), depth0))
    + "\"></use>\n      </svg>\n    </div>\n    <span class=\"title\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":55}}}))
    + "</span>\n    <span class=\"comet-button__inner\">\n      <span class=\"comet-button__text\" *ngIf=\"stageSize.text\">\n        "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0)) != null ? lookupProperty(stack1,"size") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":39}}}))
    + "\n      </span>\n    </span>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"banner\">\n    <img src=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"banner") : depth0), depth0))
    + "\" alt=\"\" >\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <p class=\"create-app-message\">\n    "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Great work! Now you can take your code and design from this lesson and create your own app.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":111}}}))
    + "\n  </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":27}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Give your app a file name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":34,"column":49}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " \""
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":91},"end":{"line":41,"column":116}}}))
    + "\"\n        ";
},"13":function(container,depth0,helpers,partials,data) {
    return " \""
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add app name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":18},"end":{"line":42,"column":47}}}))
    + "\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"app-name-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"newAppInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":16,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"banner") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCreateApp") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"app-name-form-elements\">\n    <p>\n      <label for=\"app-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "      </label>\n\n    </p>\n\n    <p>\n      <input type=\"text\" class=\"app-name\" name=\"app-name\" placeholder="
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":41,"column":70},"end":{"line":42,"column":56}}})) != null ? stack1 : "")
    + " value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" autofocus required maxlength=\"50\" />\n      <small>"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Must contain 1 – 50 characters",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":13},"end":{"line":43,"column":59}}}))
    + "</small>\n    </p>\n  </div>\n</div>\n";
},"useData":true});