import Modal from "../modal";
import { task } from "globals/task";

export default Modal.extend({
  className: [Modal.prototype.className, "upload-confirm"].join(" "),

  template: require("./upload-confirm.hbs"),

  events: Object.assign(
    { "click .upload-confirmed": "upload" },
    Modal.prototype.events,
  ),

  async upload(e) {
    await task.upload(e);
  },
});
