/* global readpage, rspkr */
import { checkFlag } from "utils/flags";
import translate, { hasTranslation } from "utils/localisation";

//each readspeaker button needs to have a unique ID so we'll just increment a value to distinguish them
let index = 0;

/**
 * Interface class for ReadSpeaker buttons
 * @class
 */
export default class ReadSpeaker {
  /**
   * @constructor
   * @param  {HTMLElement} el       - The element that will be made readable by readspeaker
   * @param  {HTMLElement} controls - The element where the readspeaker controls will be added to - MUST NOT be nested within `el`
   */
  constructor(el, controls) {
    this.el = el;
    this.controls = controls;
    this.index = index;
    index += 1;

    let parent = controls && controls.parentElement;
    while (parent) {
      if (parent === el) {
        throw new Error("ReadSpeaker - Controls cannot be nested inside of el");
      }
      parent = parent.parentElement;
    }
  }

  /**
   * starts the reading process
   */
  read() {
    if (!this.el.id) {
      this.el.id = `READSPEAKER-${this.index}`;
    }

    if (!this.controls.id) {
      this.controls.id = `READSPEAKER-CONTROLS-${this.index}`;
    }

    const config = translate("READSPEAKER-LOCALE");

    readpage(
      `//app-eu.readspeaker.com/cgi-bin/rsent?customerid=5915&${config}&readid=${this.el.id}`,
      this.controls.id,
    );
  }

  /**
   * makes the reader ignore these elements
   * @param  {Array[HTMLElement]} array - the list of elements to ignore
   */
  static ignore(array) {
    if (Array.isArray(array)) {
      array.forEach(el => el.classList.add("rs_skip"));
    }
  }

  /**
   * Removes the active readspeaker
   */
  static remove() {
    if (rspkr && rspkr.ui && rspkr.ui.getActivePlayer()) {
      rspkr.ui.getActivePlayer().close();
    }
  }

  /**
   * Determines whether readspeaker is available
   * @static
   * @return {Boolean} true if readspeaker is available for use
   */
  static get available() {
    return (
      checkFlag("USE_READSPEAKER") &&
      hasTranslation("READSPEAKER-LOCALE") &&
      translate("READSPEAKER-LOCALE") !== "none"
    );
  }
}
