import Backbone from "custom/backbone-bundle";
import style from "./input.styl";
style.use();

export const InputView = Backbone.View.extend({
  template: require("./input.hbs"),
  className: "coding-form__input",

  /**
   * Initializes this input
   * @param {Backbone.Model} options.model The model to track
   * @param {string} options.prop The property on the model to track
   * @param {'text'|'number'} [options.type=text] The input type (defaults to text)
   * @param {string} [options.label] The label
   * @param {string} [options.micro] Extra text to explain purpose
   * @param {string} [options.class] Class name
   * @param {string} [options.placeholder] The placeholder text
   * @param {function} [options.validate] A custom validator function
   * @param {function} [options.translate] A custom translation function
   * @param {function} [options.warning] A custom warning function, when this returns true, `warningText` will be visible
   * @param {function} [options.warningText] The warning text to display when `warning` function returns true
   * @param {Object} [options.actionHistory] optional actionHistory configuration
   * @param {ActionHistory} [options.actionHistory.store] The action history store to use
   * @param {ActionHistory} [options.actionHistory.type] The action history action type
   * @param {ActionHistory} [options.actionHistory.trigger] The action history trigger
   */
  initialize(options = {}) {
    this.options = options;

    if (
      !options.actionHistory ||
      !options.actionHistory.store ||
      !options.actionHistory.type
    ) {
      delete options.actionHistory;
    }

    this.options.type = this.options.type || "text";
    this.options.step = this.options.step || "any";

    this.listenTo(this.model, `change:${options.prop}`, this.onChange);
    this.render();
    this._setWarning();
  },

  events: {
    "input input": "onInput",
    "change input": "onInputChange",
  },

  render() {
    const disabled =
      typeof this.options.disabled === "function"
        ? this.options.disabled()
        : this.options.disabled;

    this.$el.html(
      this.template({
        value: this._getValue() || "",
        label: this.options.label,
        micro: this.options.micro || "",
        class: this.options.class || "",
        placeholder: this.options.placeholder,
        type: this.options.type,
        warningText: this.options.warningText,
        isNumber: this.options.type === "number",
        max: this.options.max,
        min: this.options.min,
        step: this.options.step,
        disabled,
      }),
    );
  },

  _translate(value) {
    // use custom translator
    if (typeof this.options.translate === "function") {
      return this.options.translate(value);
    }
    return value;
  },

  _getValue() {
    return this._validate(this._translate(this.model.get(this.options.prop)));
  },

  _setWarning(value) {
    let warning = false;

    if (typeof this.options.warning === "function") {
      warning = this.options.warning(value);
    }

    this.$el.toggleClass("warning", warning);
  },

  _validate(value) {
    // base validation
    switch (this.options.type) {
      case "text":
        break;
      case "number":
        value = Number(value) || 0;

        if (typeof this.options.min === "number") {
          value = Math.max(this.options.min, value);
        }

        if (typeof this.options.max === "number") {
          value = Math.min(this.options.max, value);
        }

        break;
    }

    // custom validator
    if (typeof this.options.validate === "function") {
      return this.options.validate(value);
    }

    return value;
  },

  /**
   * Triggered by external changes
   * i.e. When the value we are bound to was changed by `.set()`
   */
  onChange() {
    const val = this._getValue();
    this.$("input").val(val);
    this._setWarning();
  },

  /**
   * Triggered by the user when they type in this input field
   * For number fields, we need to listen change event instead
   */
  onInput() {
    if (this.options.type !== "number") {
      const val = this._validate(this.$("input").val());
      this.setValue(val);
    }
  },

  /**
   * Triggered by the user when they focus out from the input number field
   */
  onInputChange() {
    if (this.options.type === "number") {
      const val = this._validate(this.$("input").val());
      if (!(typeof val === "number" && isFinite(val))) {
        const latestValue = this._getValue() || this.options.min || 0;
        this.setValue(latestValue);
      } else {
        this.setValue(val);
      }
    }
  },

  setValue(val) {
    const prop = this.options.prop;
    let actionItem;
    if (this.options.actionHistory) {
      actionItem = {
        designModel: this.model,
        type: this.options.actionHistory.type,
        from: this.getValues(this.options.actionHistory.relatedProps || []),
        trigger: this.options.actionHistory.trigger,
      };
    }

    this.model.set(prop, val, { source: "user" });
    this.$("input").val(this._getValue());
    this._setWarning();
    if (this.options.valueChanged) {
      this.options.valueChanged(prop);
    }

    setTimeout(() => {
      if (actionItem) {
        actionItem.to = this.getValues(
          this.options.actionHistory.relatedProps || [],
        );
        this.options.actionHistory.store.addItem(actionItem);
      }
    }, 40);
  },

  getValues(props) {
    const values = { [this.options.prop]: this.model.get(this.options.prop) };
    props.forEach(prop => {
      values[prop] = this.model.get(prop);
    });

    return values;
  },
});
