import translate from "utils/localisation";

export const filterObjectBlocks = function (block) {
  if (block.get("type") === "object") {
    if (!["speaker"].includes(block.get("target")?.type)) {
      return true;
    }
  }

  if (["cloned", "object-named", "this"].includes(block.get("type"))) {
    return true;
  }

  return false;
};

export const filterEventBlocks = function (block) {
  return block.get("type") === "event";
};

export const filterCommandBlocks = function (block) {
  return block.get("type") === "command";
};

export const filterOperatorBlocks = function (block) {
  return block.get("type") === "operator";
};

export const filterControlBlocks = function (block) {
  return ["if", "if-else", "for-each", "repeat"].includes(block.get("type"));
};

export const filterVariableBlocks = function (block) {
  return ["variable", "assign-variable", "change-variable"].includes(
    block.get("type"),
  );
};

export const filterPrimitiveBlocks = function (block) {
  return [
    "number",
    "texture",
    "vector",
    "angle",
    "null",
    "key",
    "string",
    "boolean",
    "turn",
    "sound",
  ].includes(block.get("type"));
};

export const filterAvailableAddButtons = function (block, settings = {}) {
  if (block.get("isAddObjectButton")) {
    switch (block.get("category")) {
      case "image":
        return settings["stage-objects-image"];
      case "sprite":
        return settings["stage-objects-sprite"];
      case "sounds":
        return settings["stage-objects-sound"];
      case "button":
        return settings["stage-objects-button"];
      case "keys":
        return settings["stage-objects-key"];
      case "text":
        return settings["stage-objects-text"];
      case "variables":
        return settings["stage-objects-variable"];
      case "wall-objects":
        return (
          settings["stage-objects-image"] ||
          settings["stage-objects-sprite"] ||
          settings["stage-objects-sound"] ||
          settings["stage-objects-button"] ||
          settings["stage-objects-key"] ||
          settings["stage-objects-text"] ||
          settings["stage-objects-variable"]
        );
      default:
        return false;
    }
  } else {
    return true;
  }
};

export const filterSameTypeBlocks = function (block, otherBlock) {
  if (block.get("type") === "object") {
    return (
      block.get("type") === otherBlock.get("type") &&
      block?.get("target")?.type === otherBlock?.get("target")?.type
    );
  } else {
    return block.get("type") === otherBlock.get("type");
  }
};

export const filterContextualArguments = function (block, argument) {
  if (!block.get("contextual")) {
    return true;
  }

  const suggestions = argument.getContextualArguments?.() || [];
  return suggestions.find(suggestion => suggestion.match(block));
};

export const filterSuggested = function (block, suggestions = []) {
  return suggestions.find(suggestion => suggestion.match(block));
};

export const filterSearch = function (block, search) {
  const blocks = [block, ...block.getChildBlocks()];

  const words = [
    // search the type and category of only the top block ...
    translate(block.get("type")),
    translate(block.get("category")),
    ...blocks.map(block => [
      // ... but include the labels and values of all the nested ones
      translate(block.get("label")),
      translate(block.get("value")),
    ]),
  ]
    .flat()
    .join(" ");

  return words.toLowerCase().includes(search.toLowerCase());
};
