import SET from "./set";

/**
 * Makes an object visible (assuming it was hidden, has no effect otherwise)
 *
 * @param {Object} object - The object to make visible
 */
export default function show(object) {
  return SET(object, "visible", true);
}
