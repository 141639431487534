export default Object.assign(
  JSON.parse(
    JSON.stringify(require("models/block/blocks/command/blueprints/turn.json")),
  ), //make sure all the values are deeply cloned
  {
    groups: ["year 2", "year 3", "year 4", "year 5", "year 6"],
    args: {
      arguments: [
        {
          code: {
            type: "turn",
            value: -10,
            "hard-lock": true,
            editable: true,
          },
        },
      ],
    },
  },
);
