import Modal from "../modal";
import StageSizePresets from "fixtures/stage-size-presets.json";
import { InputView } from "views/block/form-elements/input/input";
import Backbone from "custom/backbone-bundle";
import { designActionHistory } from "globals/action-history";

const STAGE_MIN_SIZE = 8;
const STAGE_MAX_SIZE = 48;

export default Modal.extend({
  className: [Modal.prototype.className, "stage-size"].join(" "),

  template: require("./stage-size.hbs"),
  style: require("./stage-size.styl"),

  events: Object.assign(
    {
      "click .stage-size__preset-card": "presetCardSelected",
      "click .stage-size-confirmed": "makeChanges",
    },
    Modal.prototype.events,
  ),

  open(model) {
    this.mainModel = model;
    this.stageSizeModel = new Backbone.RelationalModel({
      width: model.get("width"),
      height: model.get("height"),
      showWarning: false,
    });
    this.inputWidth = new InputView({
      label: "Width",
      model: this.stageSizeModel,
      class: "input-width",
      prop: "width",
      type: "number",
      max: STAGE_MAX_SIZE,
      min: STAGE_MIN_SIZE,
      step: 1,
      validate: val => Math.floor(val),
    });

    this.inputHeight = new InputView({
      label: "Height",
      model: this.stageSizeModel,
      class: "input-height",
      prop: "height",
      type: "number",
      max: STAGE_MAX_SIZE,
      min: STAGE_MIN_SIZE,
      validate: val => Math.round(val),
    });

    this.listenTo(
      this.stageSizeModel,
      "change:width change:height",
      this.modelChanged,
    );
    Modal.prototype.open.call(this);
  },

  render() {
    this.inputWidth.$el.detach();
    this.inputHeight.$el.detach();
    this.$el.html(
      this.template({
        presets: StageSizePresets,
        sizeMessage: `Must contain number ${STAGE_MIN_SIZE}-${STAGE_MAX_SIZE}`,
      }),
    );
    this.$(".stage-size__custom-form-input--width").append(this.inputWidth.el);
    this.$(".stage-size__custom-form-input--height").append(
      this.inputHeight.el,
    );
    this.setActivePreset();
    this.delegateEvents();
  },

  modelChanged() {
    const modelDimensions = {
      width: this.mainModel.get("width"),
      height: this.mainModel.get("height"),
    };
    if (
      this.stageSizeModel.get("width") < modelDimensions.width ||
      this.stageSizeModel.get("height") < modelDimensions.height
    ) {
      this.$(".stage-size__row--warning").removeClass("hidden");
    } else {
      this.$(".stage-size__row--warning").addClass("hidden");
    }
    this.setActivePreset();
  },

  setActivePreset() {
    const stageSizeClass = ".stage-size__preset-card";
    const activeSizeClass = `${stageSizeClass}--${this.stageSizeModel.get(
      "width",
    )}x${this.stageSizeModel.get("height")}`;
    this.$(stageSizeClass).removeClass("active");
    this.$(activeSizeClass).addClass("active");
  },

  presetCardSelected(e) {
    const presetName = this.$(e.currentTarget).attr("data-name");
    const width = presetName.split("x")[0];
    const height = presetName.split("x")[1];
    this.stageSizeModel.set({
      height,
      width,
    });
  },

  makeChanges() {
    const currentTiles = this.mainModel.get("tiles");
    const currentHeight = this.mainModel.get("height");
    const currentWidth = this.mainModel.get("width");
    const height = this.stageSizeModel.get("height");
    const width = this.stageSizeModel.get("width");

    const xOffset = width - currentWidth;

    const newTiles = new Array(height * width).fill(null);

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width - xOffset; x++) {
        const index = y * width + x;
        const oldIndex = y * (width - xOffset) + x;
        newTiles[index] = currentTiles[oldIndex] || null;
      }
    }

    const actionItem = {
      designModel: this.mainModel,
      type: "design-change",
      from: {
        height: currentHeight,
        width: currentWidth,
        tiles: currentTiles,
      },
      to: {
        height: height,
        width: width,
        tiles: newTiles,
      },
    };

    this.mainModel.set(
      {
        height: height,
        width: width,
        tiles: newTiles,
      },
      { source: "user" },
    );

    setTimeout(() => {
      designActionHistory.addItem(actionItem);
      this.close();
    }, 40);
  },
});
