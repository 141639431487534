export default {
  type: "command",
  fn: "playSound",
  label: "play {arg}",
  description: "Play a sound",
  chainable: false,
  deprecated: false,
  secret: true,
  category: "sound",
  args: {
    arguments: [{}],
  },
};
