export default function (model) {
  // keep track of initial rotation and alpha and set them to default values while generating thumbnail
  const alpha = this.alpha;
  const angle = this.angle;
  this.alpha = 1;
  this.angle = 0;

  this.thumbnail = this.thumbnail || this.game.make.renderTexture(0, 0);
  this.thumbnail.resize(this.width, this.height);

  let x = 0;
  let y = 0;

  if (this.children.length) {
    x = this.width / 2;
    y = this.height / 2;
  }

  this.thumbnail.renderXY(this, x, y, true);

  //restore initial rotation and alpha
  this.angle = angle;
  this.alpha = alpha;

  model.trigger("new-thumbnail");
}
