import Backbone from "custom/backbone-bundle";
import translate from "utils/localisation";

export const ZoomView = Backbone.View.extend({
  template: require("./zoom.hbs"),
  style: require("./zoom.styl"),
  className: "zoom-tools",

  events: {
    "click .zoom-out": "zoomOut",
    "click .zoom-in": "zoomIn",
    "click .zoom-fit": "fitCode",
    "touchstart .zoom-out": "zoomOut",
    "touchstart .zoom-in": "zoomIn",
    "touchstart .zoom-fit": "fitCode",
    keydown: "onKeydown",
  },

  initialize(options = {}) {
    this.prop = options.prop || "scale";
    this.listenTo(this.model, `change:${this.prop}`, this.updateScale);
    this.$el.attr("dir", translate("dir"));
    this.style.use();
  },

  updateScale() {
    const scale = `${Math.ceil(this.model.get(this.prop) * 100)}%`;
    const label = translate("Current zoom: {scale}%").replace("{scale}", scale);
    this.$(".zoom-level").attr("aria-label", label).text(scale);
  },

  render() {
    this.$el.html(this.template({}));
    this.updateScale();
  },

  zoomIn() {
    this.trigger("zoom-in");
  },

  zoomOut() {
    this.trigger("zoom-out");
  },

  fitCode() {
    this.trigger("zoom-fit");
  },

  show() {
    this.el.classList.remove("hidden");
  },

  hide() {
    this.el.classList.add("hidden");
  },

  remove() {
    this.style.unuse();
  },
});
