import Backbone from "custom/backbone-bundle";

import { hasTaxonomy } from "../utils/taxonomy";
import { translate } from "utils/localisation";
import { user } from "globals/user";

export default Backbone.RelationalModel.extend({
  defaults: {
    // generated thumbnail
    thumbnail: null,

    // user-generated instructions
    user_instructions: null,

    // a list of previous versions of this app
    previous_versions: [],

    // the author of this app
    author: null,

    // the platform this app has been created in
    platform: null,

    // keep track of original lesson
    original_topic_id: null,
    original_unit_id: null,
    original_lesson_id: null,
    original_task_id: null,
  },

  resetAuthor() {
    let author;

    try {
      author = {
        id: user.get("user").id,
      };
    } catch (e) {
      throw new Error(
        "Unable to grab user info - maybe the user is not logged in?",
      );
    }

    const platform = {
      domain: window.location.hostname,
      locale: translate("locale"),
    };

    this.set({ author, platform });
  },

  /**
   * Updates the thumbnail with raw imagedata, this will be uploaded to AWS by the API
   */
  generateThumbnailData() {
    const thumbnail = this.get("thumbnail") || {};
    const block = require("globals/task").task?.getComponent("models/block");

    if (!block) {
      return;
    }

    // update thumbnail if there is a new cached one
    const data = block.getCachedThumbnail();
    if (data) {
      thumbnail.raw_data = data;
    }

    // force a new thumbnail if none exists
    if (!thumbnail.src && !data) {
      thumbnail.raw_data = block.getNewThumbnail();
    }

    this.set({ thumbnail });
  },

  // when uploading code, add an upload to the list of previous versions
  createUploadVersion() {
    const versions = this.get("previous_versions").slice();
    const last = versions[versions.length - 1];

    // no need to add an 'upload' version, there already is one
    if (last && last.upload) {
      return;
    }

    versions.push({ upload: true });
    this.set({ previous_versions: versions });
  },

  /**
   * prepares metadata for a fork
   * @param {Task} from The task we're forking from
   */
  prepFork(from) {
    this.generateThumbnailData();
    this._createPreviousVersion(from);
    this._setOriginalItems(from);
    this._copyThumbnail(from);
    this.resetAuthor();
  },

  /**
   * Adds the previous task to the version history
   * @param {Task} previous The previous task
   */
  _createPreviousVersion(previous) {
    if (hasTaxonomy(previous, "use-type.user-generated")) {
      const versions = this.get("previous_versions").slice();
      versions.push({
        id: previous.get("id"),
        platform: previous.get("platform"),
        author: previous.get("author"),
        fork: true,
      });
      this.set({ previous_versions: versions });
    }
  },

  /**
   * Adds original task/lesson/unit/topic ID if we're forking from a lesson
   * @param {Task} previous The previous task
   */
  _setOriginalItems(previous) {
    if (
      !hasTaxonomy(previous, "use-type.free-code") &&
      !hasTaxonomy(previous, "use-type.user-generated")
    ) {
      const topic = require("globals/topic").topic;
      const unit = require("globals/unit").unit;
      const lesson = require("globals/lesson").lesson;
      const task = require("globals/task").task;

      this.set({
        original_topic_id: topic.get("id"),
        original_unit_id: unit.get("id"),
        original_lesson_id: lesson.get("id"),
        original_task_id: task.get("id"),
      });
    }
  },

  /**
   * Copy thumbnail when forking from a user app
   * @param {Task} previous The previous task
   */
  _copyThumbnail(previous) {
    if (previous.get("metadata")?.get("thumbnail")?.src) {
      // mark thumbnail as needing to be copied from the previous user app
      this.set("thumbnail", { copyFrom: previous.get("id") });
    }
  },

  toJSON(options) {
    return JSON.parse(
      JSON.stringify(
        Backbone.RelationalModel.prototype.toJSON.call(this, options),
      ),
    );
  },
});
