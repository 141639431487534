import Backbone from "custom/backbone-bundle";
export const settings = new Backbone.Model(
  Object.assign(
    {},
    {
      editable: false,
    },
  ),
);

export default settings;
