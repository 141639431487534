import SET from "./set";

/**
 * Hides an object
 * Note: this also prevents the object from causing collisions or overlaps
 *
 * @param {Object} object - The object to hide
 */
export default function hide(object) {
  return SET(object, "visible", false);
}
