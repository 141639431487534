export class SampleSpanProcessor {
  /** @type {(span: import("@opentelemetry/tracing").Span) => boolean} */
  _sampleSpan(span) {
    return !(span.name in this._sampling) || this._sampling[span.name];
  }

  /** @type {(config: { sampleRates: { [name: string]: number } }, spanProcessor: import("@opentelemetry/tracing").SpanProcessor)} */
  constructor(config, spanProcessor) {
    /** @type {{ [name: string]: boolean }} */
    this._sampling = {};
    const random = Math.random();
    for (const name of Object.keys(config.sampleRates)) {
      const rate = config.sampleRates[name];
      this._sampling[name] = rate >= 1 && random < 1 / rate;
    }
    this._spanProcessor = spanProcessor;
  }

  /** @type {() => Promise<void>} */
  forceFlush() {
    return this._spanProcessor.forceFlush();
  }

  /** @type {(span: import("@opentelemetry/tracing").Span, parentContext: import("@opentelemetry/context-base").Context) => void} */
  onStart(span, parentContext) {
    if (this._sampleSpan(span)) {
      this._spanProcessor.onStart(span, parentContext);
    }
  }

  /** @type {(span: import("@opentelemetry/tracing").ReadableSpan) => void} */
  onEnd(span) {
    if (this._sampleSpan(span)) {
      this._spanProcessor.onEnd(span);
    }
  }

  /** @type {() => Promise<void>} */
  shutdown() {
    return this._spanProcessor.shutdown();
  }
}
