import Backbone from "custom/backbone-bundle";
import style from "./multi-toodal.styl";
import { BlockSource, MODES, SCREENS, SOURCE_TYPE } from "./block-source";
import { ChooseWhereView } from "./choose-where/choose-where";
import { ChooseTypeView } from "./choose-type/choose-type";
import { ChooseCodeView } from "./choose-code/choose-code";
import { translate } from "utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";
import { BLOCK_CACHE_DYNAMIC } from "utils/block-cache/block-cache";
import { filterSearch } from "./block-filters";
import GAME from "../../../../models/block/game";
import { checkFlag } from "utils/flags";
import { CodeSearch } from "./code-search/code-search";
import { focusZoom } from "utils/focus-zoom";

style.use();

let MARGIN = 16;

export const MultiToodal = Backbone.View.extend({
  template: require("./multi-toodal.hbs"),
  className: "multi-toodal",

  events: {
    keydown: "onKeyDown",
    "click .multi-toodal__close": "onCloseClicked",
    "click .multi-toodal__search": "onSearchClicked",
    "click .multi-toodal__breadcrumb": "onBreadcrumbClicked",
  },

  initialize() {
    this.$el.attr("dir", translate("dir"));
    this.$el.attr("role", translate("dialog"));
    this.$el.attr("aria-modal", true);

    this.position = new Backbone.Model({ x: 0, y: 0 });
    this.listenTo(this.position, "change:x change:y", this.updateTransform);

    this.blockSource = new BlockSource();
    this.chooseCode = new ChooseCodeView({ model: this.blockSource });
    this.chooseType = new ChooseTypeView({ model: this.blockSource });
    this.chooseWhere = new ChooseWhereView({ model: this.blockSource });
    this.codeSearch = new CodeSearch({ model: this.blockSource });

    this.listenTo(interfaceChannel, "add-code:open", target =>
      this.open(target, MODES.ADD),
    );
    this.listenTo(interfaceChannel, "swap-code:open", target =>
      this.open(target, MODES.SWAP),
    );
    this.listenTo(interfaceChannel, "insert-code:open", (target, position) =>
      this.open(target, MODES.INSERT, position),
    );

    this.listenTo(interfaceChannel, "multi-toodal:close", this.close);

    this.listenTo(
      this.blockSource,
      "change:where change:filter change:suggestions",
      this.render,
    );

    this.listenTo(this.blockSource, "change:selection", this.onSelectionChange);
  },

  getCrumbs() {
    const screen = this.blockSource.getCurrentScreen();

    const crumbs = [];

    if (this.blockSource.canChooseWhere()) {
      crumbs.push({
        active: screen === SCREENS.WHERE,
        label: "Where",
        for: "where",
      });
    }

    if (this.blockSource.canChooseFilter() && this.blockSource.get("where")) {
      crumbs.push({
        active: screen === SCREENS.FILTER,
        label: "Code",
        for: "filter",
      });
    }

    if (screen === SCREENS.CODE) {
      let label = this.blockSource.get("filterName") || "Code";

      if (this.blockSource.get("suggestions")) {
        label = "Suggested";
      }

      crumbs.push({
        active: true,
        label,
        for: "code",
      });
    }

    // add separators
    return crumbs.flatMap((crumb, index, array) =>
      array.length - 1 !== index ? [crumb, { separator: true }] : crumb,
    );
  },

  async render() {
    if (!this.isOpen()) {
      return;
    }

    this.chooseCode.$el.detach();
    this.chooseType.$el.detach();
    this.chooseWhere.$el.detach();
    this.codeSearch.$el.detach();

    const screen = this.blockSource.getCurrentScreen();

    this.$el.html(
      this.template({
        hasSearch: this.hasSearch(screen),
        crumbs: this.getCrumbs(),
        title:
          this.blockSource.get("mode") === MODES.SWAP
            ? "Edit code"
            : "Add code",
      }),
    );

    this.$el.append(this.codeSearch.el);

    switch (screen) {
      case SCREENS.WHERE:
        this.$el.append(this.chooseWhere.el);
        break;
      case SCREENS.FILTER:
        this.$el.append(this.chooseType.el);
        break;
      case SCREENS.CODE:
        this.$el.append(this.chooseCode.el);
        break;
    }

    await new Promise(r => requestAnimationFrame(r));
    this.preventPositionOutsideWindow();
    this.autoFocus();
  },

  /**
   * Check whether the multi-toodal has search
   * @returns {Boolean}
   */
  hasSearch(screen) {
    return (
      screen !== SCREENS.WHERE &&
      this.blockSource.getBlockSource() === SOURCE_TYPE.CHEST &&
      this.blockSource.canChooseCode() &&
      !this.codeSearch.isVisible()
    );
  },

  /**
   * Handle the selection of a code block
   */
  async onSelectionChange() {
    if (!this.blockSource.get("selection")) {
      return;
    }

    const selection = this.blockSource.get("selection");
    const where = this.blockSource.get("where");
    let block;

    if (this.blockSource.get("mode") === MODES.SWAP) {
      block = this.blockSource.get("target");

      if (
        block.get("type") === selection.get("type") &&
        block.get("primitive")
      ) {
        // change the value of a primitive
        block.changeValue(selection);
      } else {
        // replacing a block with another
        const commands = block
          .get("commands")
          ?.get("code")
          ?.models?.slice()
          .reverse();
        const index = where.parent.get("code").models?.indexOf(block);
        const { blockRecycleBin } = require("globals/block-recycle-bin");
        const x = block.get("position").get("x");
        const y = block.get("position").get("y");

        block.move(blockRecycleBin);
        const newBlock = selection.move(where.parent, index || where.index, {
          x,
          y,
        });

        if (
          commands &&
          newBlock.get("commands") &&
          !newBlock.get("commands").get("locked")
        ) {
          // move all attached commands to the new block
          commands.forEach(command => command.move(newBlock.get("commands")));
        }

        block = newBlock;
      }
    } else {
      block = selection.move(where.parent, where.index, where.position);
    }

    if (where.position) {
      await new Promise(r => requestAnimationFrame(r));
      await focusZoom(block);
      block.separate();
    }

    this.blockSource.set("selection", null);
    this.close(block.view.el);
  },

  /**
   * Open this multiToodal
   * @param {Block|Argument|Scope} targetModel
   */
  async open(targetModel, mode, position) {
    this.close();

    // make sure we're in edit mode
    GAME.edit();

    if (!checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE")) {
      return;
    }

    if (BLOCK_CACHE_DYNAMIC.isDirty) {
      // if the cache is dirty, we open with a preloader
      this.el.classList.add("open");
      this.el.classList.add("is-loading");
      this.setPosition(targetModel?.view?.el);
    }

    this.blockSource.set({ mode: mode });
    await this.blockSource.generateBlockList();
    this.blockSource.set({ target: targetModel });

    if (position) {
      this.blockSource.get("where").position = position;
    }

    this.el.classList.add("open");
    this.el.classList.remove("is-loading");
    this.setPosition(targetModel?.view?.el);
    this.render();
  },

  async autoFocus() {
    await new Promise(r => requestAnimationFrame(r));
    document.activeElement.blur();
    if (this.$("[autofocus]:not([disabled])").length > 0) {
      await new Promise(r => requestAnimationFrame(r));
      await focusZoom(this.$("[autofocus]:not([disabled])").get(0));
      this.$("[autofocus]:not([disabled])").get(0).focus();
    } else {
      await new Promise(r => requestAnimationFrame(r));
      await focusZoom(this.$(".multi-toodal__close").get(0));
      this.$(".multi-toodal__close").get(0).focus();
    }
  },

  /**
   * Check whether the multi-toodal is open
   * @returns {Boolean}
   */
  isOpen() {
    return this.el.classList.contains("open");
  },

  /**
   * Close the multi-toodal
   * @param {Element} [focusEl] - optional element to move focus to after closing
   */
  async close(focusEl) {
    this.el.classList.remove("open");

    if (!focusEl) {
      focusEl = this.blockSource.get("target")?.view?.el;
    }

    if (focusEl) {
      await new Promise(r => requestAnimationFrame(r));
      await focusZoom(focusEl);
      focusEl.focus();
    }

    this.blockSource.set({ target: null });
  },

  onCloseClicked() {
    this.close();
  },

  onBreadcrumbClicked(e) {
    switch (e.currentTarget.dataset.for) {
      case "where":
        this.blockSource.set({
          search: null,
          filter: null,
          suggestions: null,
          where: null,
        });
        break;
      case "filter":
        this.blockSource.set({
          search: null,
          filter: null,
          suggestions: null,
        });
        break;
    }
  },

  onSearchClicked() {
    this.blockSource.set({
      search: "",
      filter: block => filterSearch(block, this.blockSource.get("search")),
      filterName: "Search",
      suggestions: null,
    });
  },

  onKeyDown(e) {
    if (e.key === "Escape") {
      this.close();
      e.preventDefault();
    }

    if (e.key === "Tab") {
      if (e.shiftKey) {
        this.focusPrev(e);
      } else {
        this.focusNext(e);
      }
      e.preventDefault();
    }
  },

  /**
   * Move focus to the next focusable element
   * Loops within the toodal - Keeping focus inside
   */
  focusNext() {
    const list = [
      ...this.el.querySelectorAll('[tabindex="0"]:not([disabled])'),
    ];
    const current = document.activeElement;
    const index = list.indexOf(current);

    if (index === list.length - 1) {
      list[0].focus();
    } else {
      list[index + 1].focus();
    }
  },

  /**
   * Move focus to the previous focusable element
   * Loops within the toodal - Keeping focus inside
   */
  focusPrev() {
    const list = [
      ...this.el.querySelectorAll('[tabindex="0"]:not([disabled])'),
    ];
    const current = document.activeElement;
    const index = list.indexOf(current);

    if (index === 0) {
      list[list.length - 1].focus();
    } else {
      list[index - 1].focus();
    }
  },

  /**
   * Position this element based on target element
   * @param {Element} target
   */
  setPosition(target) {
    if (!target || target.classList.contains("free-form")) {
      return this.positionAtCenter();
    }

    if (target.classList.contains("control-block")) {
      target = target.querySelector(":scope > control-block-head");
    }

    if (target.classList.contains("object-like-block")) {
      target = target.querySelector(":scope > block-tail");
    }

    const targetBox = target.getBoundingClientRect();
    const selfBox = this.el.getBoundingClientRect();
    const ltr = translate("dir") === "ltr";

    let y = targetBox.top;
    let x;
    let left = Math.floor(targetBox.left - selfBox.width - MARGIN);
    let right = Math.floor(targetBox.right + MARGIN);

    // position left or right based on RTL and available space
    if (ltr) {
      if (selfBox.width + right < window.innerWidth) {
        x = right;
      } else {
        x = left;
      }
    } else {
      if (left < 0) {
        x = right;
      } else {
        x = left;
      }
    }

    this.position.set({ x, y });
  },

  preventPositionOutsideWindow() {
    let x = this.position.get("x");
    let y = this.position.get("y");

    const selfBox = this.el.getBoundingClientRect();

    // if the toodal would still be positioned outside the viewport (because
    // there is not enough space on either side), pull it back inside
    if (y + selfBox.height + MARGIN > window.innerHeight) {
      y -= selfBox.height + y + MARGIN - window.innerHeight;
    }

    if (y < 0) {
      y = MARGIN;
    }

    if (x + selfBox.width + MARGIN > window.innerWidth) {
      x -= selfBox.width + x + MARGIN - window.innerWidth;
    }

    if (x < 0) {
      x = MARGIN;
    }

    this.position.set({ x, y });
  },

  positionAtCenter() {
    const selfBox = this.el.getBoundingClientRect();
    const x = window.innerWidth / 2 - selfBox.width / 2;
    const y = window.innerHeight / 2 - selfBox.height / 2;
    this.position.set({ x, y });
  },

  updateTransform() {
    const x = this.position.get("x");
    const y = this.position.get("y");
    this.el.style.transform = `translate(${x}px, ${y}px)`;
  },

  remove() {
    this.chooseCode.remove();
    this.chooseType.remove();
    this.chooseWhere.remove();
    this.codeSearch.remove();
    Backbone.View.prototype.remove.call(this);
  },
});

export const multiToodal = new MultiToodal();
document.body.appendChild(multiToodal.el);
