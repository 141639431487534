import CodeBlock from "./block-prototype";
import BlockScopeView from "./components/scope";
import BlockContentsView from "./components/block-contents";

export default CodeBlock.extend({
  tagName: "this-block",
  className:
    CodeBlock.prototype.className + " code-block this-block object-like-block",
  template: require("templates/block/block-types/this.hbs"),
  initialize() {
    CodeBlock.prototype.initialize.call(this);

    this.defineChildView(
      () =>
        new BlockContentsView({
          model: this.model,
        }),
      "args",
      "> block-body",
    );

    this.defineChildView(
      () =>
        new BlockScopeView({
          model: this.model.get("commands"),
          className:
            BlockScopeView.prototype.className + " horizontal commands",
        }),
      "commands",
      "> object-scope",
    );
  },
});
