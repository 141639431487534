import Modal from "../modal";
import { task } from "globals/task";
import interfaceChannel from "views/block/channels/interface-channel";
import { AppNamePlugin } from "./../shared/app-name";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "rename-app"].join(" "),

  template: require("./rename-app.hbs"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "submit .rename-app-form": "renameApp",
        "click .rename-app-confirm": "renameApp",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    this.$el.html(
      this.template({
        name: task.get("name"),
      }),
    );

    this.delegateEvents();
    this.checkName();
  },

  async renameApp(e) {
    e.preventDefault();

    try {
      task.set("name", this.getName());
      await task.myAppSave();
      this.close();
    } catch (e) {
      interfaceChannel.trigger("request:modal", "save-failure", e);
    }
  },
});
