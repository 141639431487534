import SET from "./../utils/object-properties";
import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";
import { number } from "../types/number";
import { console as blockConsole } from "globals/console";
import { translate } from "utils/localisation";
import WARNINGS from "./../../blocks/warnings";

/**
 * Set an object's property
 *
 * @param {Object} object - The object whose property to set
 * @param {String} prop - The key of the property
 * @param {*} val - The value of the property
 */
export default function set(object, prop, val) {
  if (!validate.object(object)) {
    throw Error(validate.error.command);
  }

  prop = string(prop);

  SET.call(object, prop, val);

  // store previous position
  // this is so that we can roll back position in case `stop` gets called on the object
  if (object.previousPosition) {
    if (prop === "x") {
      object.previousPosition.x = number(val);
    } else if (prop === "y") {
      object.previousPosition.y = number(val);
    }
  }

  // notify user when they try to make something move faster than its max speed
  if (prop === "speed" && Math.abs(val) > object.maxSpeed) {
    blockConsole.warn(
      translate(WARNINGS.RUNTIME_MAX_SPEED).replace(
        "{MAX_SPEED}",
        object.maxSpeed,
      ),
      "speeding-ticket",
    );
  }

  // call `renderText` if needed
  if (prop === "text" && typeof object.renderText === "function") {
    object.renderText(this, object.model, true);
  }
}
