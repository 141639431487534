import Modal from "../modal";
import { AppNamePlugin } from "./../shared/app-name";

import interfaceChannel from "views/block/channels/interface-channel";

import { app } from "app";

import { setCurrentTopic } from "globals/topic";
import { setCurrentUnit } from "globals/unit";
import { setCurrentLesson } from "globals/lesson";
import { task, setFreeCoderTask, resetFreeCoder } from "globals/task";
import { clearHistory } from "globals/action-history";
import MetaData from "models/metadata";

import { checkFlag } from "utils/flags";

export default Modal.extend(AppNamePlugin).extend({
  className: [Modal.prototype.className, "create-app with-banner"].join(" "),

  template: require("./create-app.hbs"),

  events: AppNamePlugin.events.extend(
    Object.assign(
      {
        "click .create-app": "createApp",
        "submit .create-app-form": "createApp",
      },
      Modal.prototype.events,
    ),
  ),

  render() {
    const meta = task.get("metadata");
    meta.generateThumbnailData();

    this.$el.html(
      this.template({
        banner:
          meta?.toJSON()?.thumbnail?.raw_data ||
          meta?.toJSON()?.thumbnail?.src ||
          null,
        isCreateApp: true,
      }),
    );

    this.delegateEvents();
    this.checkName();
  },

  /**
   * Creates a new user app or exports to free code depending on SAVE flag
   */
  async createApp(e) {
    e.preventDefault();

    const name = this.getName();

    await this.close();

    this._prepExport();

    // depending on the SAVE flag~
    // - we need to either go straight to the saved app
    // - OR go to the free coder and past in the copied code
    if (checkFlag("SAVE")) {
      this._exportToNewApp(name);
    } else {
      this._exportToFreeCode();
    }
    clearHistory();
  },

  /**
   * Prepares the task for export
   * - updates metadata
   * - copies to clipboard
   */
  _prepExport() {
    const metadata = new MetaData();
    metadata.prepFork(task);

    task
      .getComponent("models/block")
      .getBlocks()
      .forEach(block => {
        block.set({ locked: false, editable: true, critical: false });
      });

    task.copy({
      coder: true,
      wall: false,
      metadata: metadata.toJSON(),
      "background-code": false,
      grade: true,
    });
  },

  /**
   * Exports the task to the free coder - WITHOUT SAVING
   * (relies on the free coder to use what's on the clipboard)
   */
  _exportToFreeCode() {
    interfaceChannel.trigger("proceed-to-free-coder");
  },

  /**
   * Exports and saves to a new user app
   */
  async _exportToNewApp(name) {
    app.destroyLayout();
    resetFreeCoder("block");

    await Promise.all([
      setFreeCoderTask("block"),
      setCurrentLesson(null),
      setCurrentUnit(null),
      setCurrentTopic(null),
    ]);

    task.paste({});
    task.clearClipboard();
    task.get("metadata").prepFork(task);
    const result = await task.fork(name);
    interfaceChannel.trigger("proceed-to-user-app", result.id);
  },
});
