import Modal from "../modal";
import interfaceChannel from "views/block/channels/interface-channel";

export default Modal.extend({
  className: [Modal.prototype.className, "saved-as"].join(" "),

  template: require("./saved-as.hbs"),
  style: require("./saved-as.styl"),

  events: Object.assign({}, Modal.prototype.events),

  open(renderData) {
    this.renderData = renderData;
    Modal.prototype.open.call(this);
  },

  async render() {
    this.$el.html(this.template(this.renderData));
    this.delegateEvents();

    // await modal being closed
    await new Promise(r => this.$el.one("hidden.bs.modal", r));

    // then go to user app
    interfaceChannel.trigger("proceed-to-user-app", this.renderData.id);
  },
});
