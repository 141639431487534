import { app } from "app";
import Unit from "models/unit";
let unit = new Unit();

export default unit;
export { unit };

export async function setCurrentUnit(id) {
  if (!id || id === "-") {
    return unit.clear();
  }

  if (id === unit.get("id")) {
    return;
  }

  // flag current unit as not being active
  unit.set("active", false);

  // Use backbone relational to find or create new unit,
  // Then map this to the exported unit variable
  // If a unit with said ID has already been created, backbone relational will restore it, otherwise a new unit will be instantiated
  unit = Unit.findOrCreate({ id });

  // flag new unit as being active
  unit.set("active", true);

  await unit.fetch();
  app.expose("unit", unit);
}
