import { createObjects } from "./../creation/create-objects";
import {
  createStage,
  resizeCamera,
  resizeStage,
} from "./../creation/create-stage";
import Backbone from "custom/backbone-bundle";

const setup = function () {};

setup.prototype.init = function (callback) {
  this.game.callback = callback;
};

setup.prototype.create = function () {
  const game = this.game;
  game.stage.disableVisibilityChange = true; //keep stage active even when not in focus

  // destroy previous backbone listener
  if (game.listener) {
    game.listener.destroy();
  }

  // add a backbone model onto the game to listen for backbone events
  game.listener = new Backbone.Model({});

  createStage.call(game);
  resizeStage.call(game);

  // camera must be configured before objects so that their position can be relative to the camera
  game.pseudoCamera = { type: "camera", game };
  if (game.mode === "run") {
    resizeCamera.call(game);
  }

  createObjects.call(game);

  game.scale.reflowGame();
  game.scale.reflowCanvas();
  game.scale.refresh();

  const callback = game.callback;
  delete game.callback;
  if (typeof callback === "function") {
    callback();
  }
};

export default setup;
