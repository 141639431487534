var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"carousel-tab "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":69}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"carousel-text "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isReadSpeakerAvailable")),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":30},"end":{"line":39,"column":78}}})) != null ? stack1 : "")
    + "\" data-index=\""
    + alias2(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\">\n      <div class=\"carousel-readspeaker\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isReadSpeakerAvailable")),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "        <h2 class=\"carousel-title\"> "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":36},"end":{"line":48,"column":54}}}))
    + " </h2>\n      </div>\n      <span class=\"carousel-description\">\n        "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":51,"column":33}}}))
    + "\n      </span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"isReadSpeakerAvailable")),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":57,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "no-media";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"media-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"isImage") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"isVideo") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <img class=\"term-image\" src="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"src") : stack1), depth0))
    + " type="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + " />\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"video-container\">\n        <video class=\"video-js vjs-default-skin vjs-big-play-button vjs-big-play-centered vjs-16-9\" controls\n          preload=\"none\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"posterImageSrc") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":25},"end":{"line":32,"column":104}}})) != null ? stack1 : "")
    + ">\n          <source src="
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"src") : stack1), depth0))
    + " type="
    + alias2(__default(require("../../../../templates/helpers/videoType.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"src") : stack1),{"name":"videoType","hash":{},"data":data,"loc":{"start":{"line":33,"column":47},"end":{"line":33,"column":76}}}))
    + ">\n        </video>\n      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " poster="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"media") : depth0)) != null ? lookupProperty(stack1,"asset") : stack1)) != null ? lookupProperty(stack1,"posterImageSrc") : stack1), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    return "audio";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "        <span class=\" audio__btn\" tabindex=\"0\" role=\"button\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Read out loud",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":73},"end":{"line":42,"column":102}}}))
    + "\" title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Read out loud",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":111},"end":{"line":42,"column":140}}}))
    + "\">\n          <svg xmlns=\"http://www.w3.org/2000/svg\" alt=\"\">\n            <use xlink:href=\"#icon-sound_icon\"></use>\n          </svg>\n        </span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"readspeaker-placeholder init rsbtn rs_skip rs_preserve\" data-index=\""
    + container.escapeExpression(container.lambda((data && lookupProperty(data,"index")), depth0))
    + "\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"carousel-header\">\n  <h1>\n    <span class=\"drag-handle\">\n      <svg class=\"icon\">\n        <use xlink:href=\"#drag_vertical\"></use>\n      </svg>\n    </span>\n    <span class=\"title\">\n      "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Help",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":26}}}))
    + "\n    </span>\n  </h1>\n  <span class=\"dismiss\">\n    <svg>\n      <title>"
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":13},"end":{"line":14,"column":34}}}))
    + "</title>\n      <use xlink:href=\"#icon-x\"></use>\n    </svg>\n  </span>\n</div>\n\n<div class=\"carousel-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"terms") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n\n</div>\n<nav>\n  <div class=\"slider-progress\"></div>\n  <div class=\"nav-arrows\"></div>\n</nav>\n";
},"useData":true});