var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create your own app!",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":36}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"SAVE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":21,"column":18}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"create-app-form\">\n"
    + ((stack1 = container.invokePartial(require("../shared/app-name.hbs"),depth0,{"name":"../shared/app-name","hash":{"name":(depth0 != null ? lookupProperty(depth0,"name") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</form>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "<div class=\"info-message\">\n  <svg aria-hidden=true focusable=false >\n    <use xlink:href=\"#comet_message-information\"></use>\n  </svg>\n  <span>\n    "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Online save isn't available because you are using a shared log in. To store a copy of your work please use 'Save to device'. ",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":145}}}))
    + "\n  </span>\n</div>\n\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<button class=\"cancel\" data-dismiss=\"modal\" autofocus aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":66},"end":{"line":25,"column":88}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Cancel",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":90},"end":{"line":25,"column":112}}}))
    + "</button>\n<button class=\"confirm create-app "
    + ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(alias1,"SAVE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":34},"end":{"line":26,"column":90}}})) != null ? stack1 : "")
    + "\"\n  data-dismiss=\"modal\" aria-label=\""
    + ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(alias1,"SAVE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":27,"column":35},"end":{"line":27,"column":150}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(alias1,"SAVE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":32,"column":20}}})) != null ? stack1 : "")
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " form-submit ";
},"11":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save and go to app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":61},"end":{"line":27,"column":95}}}))
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    return " "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":105},"end":{"line":27,"column":131}}}))
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save and go to app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":29,"column":36}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "  "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create app",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":2},"end":{"line":31,"column":28}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":22,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":34,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});