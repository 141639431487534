export const AppNamePlugin = {
  get events() {
    return {
      extend: (props = {}) =>
        Object.assign(props, {
          "input input.app-name": "checkName",
        }),
    };
  },

  validateName(input) {
    return input && input.length >= 1 && input.length <= 50;
  },

  checkName() {
    this.$(".form-submit").prop("disabled", !this.validateName(this.getName()));
  },

  getName() {
    const name = this.$("input.app-name").val();

    if (name) {
      return name.trim();
    } else {
      return "";
    }
  },
};
