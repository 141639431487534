export default {
  type: "python-free",
  layout: "layouts/uk-coding",
  name: "Python",
  components: [require("fixtures/components/python-free-editor").default],
  taxonomy: {
    language: {
      python: true,
    },
    "use-type": {
      "free-code": true,
    },
  },
};
