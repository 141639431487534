import get from "./get";
import set from "./set";

import * as validate from "../utils/validate-inputs";

/**
 * Matches a property from one object to the other
 *
 * @param {Object} object - The object whose property to set
 * @param {String} prop - The key of the property that is changed
 * @param {Object} object - The object whose property is useed to matched to
 */
export default function matchTo(object, prop, objectMatched) {
  if (!validate.object(object) && !validate.object(objectMatched[0])) {
    throw Error(validate.error.command);
  }
  return set(object, prop, get(objectMatched[0], prop));
}
