import CodeBlock from "./block-prototype";
import gameChannel from "models/block/phaser-middleware/channels/game-channel";
import interfaceChannel from "views/block/channels/interface-channel";
import Tile from "./../utils/tile";
import { task } from "globals/task";
import { checkFlag } from "utils/flags";

export default CodeBlock.extend({
  tagName: "primitive-block",
  className: CodeBlock.prototype.className + " code-block primitive-block",
  template: require("templates/block/block-types/primitive.hbs"),

  initialize() {
    CodeBlock.prototype.initialize.call(this);

    this.listenTo(
      this.model,
      "change:value change:x change:y change:editable",
      this.render,
    );

    if (this.model.get("type") === "texture") {
      // for textures, we need to re-render whenever the stage is built, because the tilemap may have changed
      this.listenTo(gameChannel, "stage-built", this.render);
    }
  },

  async getRenderData() {
    var data = CodeBlock.prototype.getRenderData.call(this);
    let asset;

    //determine type
    switch (data.model.type) {
      case "vector":
        data.model.isVector = true;
        data.model.CSSangle = data.model.angle - 180;
        break;

      case "texture":
        data.model.isTexture = true;
        break;

      case "number":
        data.model.isNumber = true;
        break;

      case "string":
        data.model.isString = true;
        break;

      case "angle":
        data.model.isAngle = true;
        break;

      case "null":
        data.model.isNull = true;
        break;

      case "boolean":
        data.model.isBoolean = true;
        break;

      case "sound":
        asset = await task.getManifestItem(data.model.value);
        data.model.isSound = true;
        break;

      case "key":
        data.model.isKey = true;
        data.model.keyLabel = data.model.label;
        data.model.isSpacebar = data.model.value === 32;
        break;

      case "turn":
        data.model.isTurn = true;
        data.model.isClockwise = data.model.value === 10;
        break;
    }

    //determine null value
    switch (data.model.type) {
      case "vector":
        data.model.isNullValue = data.model.x === 0 && data.model.y === 0;
        break;

      case "string":
        data.model.isNullValue = !data.model.value;
        break;

      default:
        data.model.isNullValue = data.model.value === null;
    }

    data.label = asset ? asset.name : null;
    data.model.value = this.model.getPrimitiveValue();
    data.model.editable = this.model.isEditable();

    return data;
  },

  async render() {
    if (this.tile) {
      this.tile.$el.detach();
      this.tile.remove();
    }

    await CodeBlock.prototype.render.call(this);

    if (this.model.get("type") === "texture") {
      this.tile = new Tile(this.model.get("value"));
      this.$el.find("img").replaceWith(this.tile.el);
    }
  },

  onClick() {
    if (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE")) {
      if (this._isEmptyJigsaw()) {
        interfaceChannel.trigger("insert-code:open", this.model);
        return;
      }

      if (this.model.isEditable()) {
        interfaceChannel.trigger("swap-code:open", this.model);
        return;
      }
    } else {
      if (this.model.isEditable()) {
        interfaceChannel.trigger("toodal:open", this);
      }
      return;
    }
  },

  remove() {
    if (this.tile) {
      this.tile.remove();
    }
    CodeBlock.prototype.remove.call(this);
  },
});
