import Backbone from "custom/backbone-bundle";
import BlockArgument from "./argument";
import PrototypeModel from "models/prototype-model";
import TestablePrototype from "models/prototypes/testable";

/**
 * Arguments
 * this model represents a list of arguments
 * when run, the list of arguments are computed and their values returned as an array
 */
export default PrototypeModel.extend(TestablePrototype).extend({
  relations: TestablePrototype.relations.concat([
    {
      type: Backbone.HasMany,
      key: "arguments",
      parse: true,
      runTests: true,
      relatedModel: BlockArgument,
      reverseRelation: {
        key: "parent",
        includeInJSON: false,
      },
    },
  ]),

  defaults: {
    arguments: [],
  },

  getPseudoCode() {
    return (
      "(" +
      this.get("arguments")
        .map(arg => arg.getPseudoCode())
        .join(", ") +
      ")"
    );
  },

  getChildBlocks(list = []) {
    this.get("arguments").forEach(arg => arg.getChildBlocks(list));
    return list;
  },

  /** Computes all the arguments and returns their values as an array */
  // eslint-disable-next-line require-await
  async run(...args) {
    return Promise.all(this.get("arguments").map(arg => arg.run(...args)));
  },

  /** alternative name for run function */
  compute(...args) {
    return this.run(...args);
  },

  /** delete these arguments and any code inside of them */
  delete() {
    const list = this.get("arguments").map(arg => arg.delete());
    this.set("arguments", []);
    list.forEach(item => Backbone.Relational.store.unregister(item));
    Backbone.Relational.store.unregister(this);
  },

  /** given some input, test whether it is functionally the same as this */
  isSame(other) {
    if (!other || other.constructor !== other.constructor) {
      return false;
    }

    return this.get("arguments")
      .map((arg, i) => arg.isSame(other.get("arguments").at(i)))
      .reduce((a, b) => a && b, true);
  },

  parse(data) {
    //allow arguments to be defined in a `simple` way in the blueprints by converting them to the correct structure when parsed
    data.arguments = (data.arguments || []).map(arg => {
      if (Object.prototype.hasOwnProperty.call(arg, "type")) {
        arg = {
          code: arg,
        };
      }

      return arg;
    });

    delete data.placeholders;

    return PrototypeModel.prototype.parse.call(this, data);
  },
  getParentBlock() {
    return this.get("parent");
  },

  getHorizontalCodeChain(chain = []) {
    this.get("arguments").forEach(arg => arg.getHorizontalCodeChain(chain));
    return chain;
  },

  getHorizontalCodeChainParent() {
    return this.get("parent").getHorizontalCodeChainParent();
  },

  getVerticalCodeChainParent() {
    return this.get("parent").getVerticalCodeChainParent();
  },
});
