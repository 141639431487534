import Modal from "../modal";
import { router } from "../../../../../router";
import { AppURL } from "utils/url";

export default Modal.extend({
  className: [Modal.prototype.className, "goto-embedded-apps"].join(" "),

  template: require("./goto-embedded-apps.hbs"),

  events: Object.assign(
    { "click .goto-embedded-apps-confirmed": "goToEmbeddedApps" },
    Modal.prototype.events,
  ),

  async goToEmbeddedApps(e) {
    e.preventDefault();
    await this.close();
    router.navigate(new AppURL("/open/block").href, { trigger: true });
  },
});
