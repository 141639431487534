import CodeBlock from "./block-prototype";
import GAME from "models/block/game";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";

export default CodeBlock.extend({
  tagName: "variable-block",
  className: CodeBlock.prototype.className + " code-block",
  template: require("templates/block/block-types/variable.hbs"),

  async initialize() {
    this._loading = true;
    CodeBlock.prototype.initialize.call(this);
    this.findTarget();
    this.listenTo(this.model, "change:target", this.findTarget);
  },

  click: "onClick",

  async findTarget() {
    await GAME.promiseSetup();
    this._variableObject = (await this.model.findVariable())[0];
    this._loading = false;

    if (this._variableObject) {
      this._variableObject = this._variableObject.model;
      this.listenTo(this._variableObject, "change:name", this.render);
      this.listenTo(this._variableObject, "deleted", this.onDeleted);
      this.render();
    }
  },
  onDeleted() {
    this._variableObject = null;
    this.render();
  },

  getRenderData() {
    var data = CodeBlock.prototype.getRenderData.call(this);
    if (this._loading) {
      data.target = { loading: true };
    } else if (this._variableObject) {
      data.target = this._variableObject.toJSON();
    } else {
      data.target = { deleted: true };
    }
    data.isEditable = this.model.isEditable();
    return data;
  },

  onClick() {
    const isPublic = window.process.env.SHARED_APPS_ONLY === "true";

    if (isPublic) {
      return;
    }

    if (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE") && this.model?.isInCanvas()) {
      if (this._isEmptyJigsaw()) {
        interfaceChannel.trigger("insert-code:open", this.model);
        return;
      }
      interfaceChannel.trigger("swap-code:open", this.model);
      return;
    } else {
      if (this.model?.isInCanvas()) {
        interfaceChannel.trigger("toodal:open", this);
      }
      return;
    }
  },
});
