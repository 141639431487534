import * as validate from "./../utils/validate-inputs";
import { SIMULATED_KEYS } from "./../objects/key";
import { getKeyMirrors } from "../../blocks/primitive/types/key-mapping";

// returns true when a key is pressed
export default function isKeyPressed(keyCode) {
  if (!validate.key(keyCode)) {
    throw Error(validate.error.key);
  }

  const isDown = [
    // check the actual key
    this.input.keyboard.addKey(keyCode).isDown,

    // check the simulated key (object on the stage)
    SIMULATED_KEYS[keyCode],

    // check any mirrored keys
    ...getKeyMirrors(keyCode).map(
      keyCode => this.input.keyboard.addKey(keyCode).isDown,
    ),
  ].reduce((val, acc) => val || acc);

  return Boolean(isDown);
}
