import Backbone from "custom/backbone-bundle";
import $ from "jquery";
import _ from "underscore";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";

const MIN_WIDTH = 768;
const RESIZE = "resize";
const ROTATE = "rotate";
const NO_SUPPORT = "no-support";

const ViewportWarning = Backbone.View.extend({
  className: "viewport-warning",

  template: require("./viewport-warning.hbs"),
  style: require("./viewport-warning.styl"),

  initialize() {
    this.style.use();
    this.checkSize();
    $(window).on(
      "resize",
      _.debounce(() => this.checkSize(), 100),
    );
  },

  render(message = null) {
    this.$el.html(
      this.template({
        resize: message === RESIZE,
        rotate: message === ROTATE,
        noSupport: message === NO_SUPPORT,
      }),
    );
    this.$el.toggleClass("visible", Boolean(message));

    if (message) {
      interfaceChannel.trigger("viewport:warning");
    }
  },

  // check viewport size and determine if a message must be shown
  checkSize() {
    if (window.innerWidth >= MIN_WIDTH) {
      this.render();
    } else if (MIN_WIDTH <= screen.width) {
      this.render(RESIZE);
    } else if (MIN_WIDTH <= screen.height) {
      this.render(ROTATE);
    } else {
      this.render(NO_SUPPORT);
    }
  },
});

// only do this viewport checking if we're not on a shared app and the feature has been enabled
let checkFrame = false;
try {
  checkFrame =
    parent.location.pathname.indexOf("/dist/html-iframe/index.html") !== -1;
} catch (error) {
  // continue regardless of error
}

if (
  window.process.env.SHARED_APPS_ONLY === "false" &&
  checkFlag("VIEWPORT_WARNINGS") &&
  !checkFrame
) {
  const viewportWarning = new ViewportWarning();
  $(() => $("body").append(viewportWarning.$el));
}
