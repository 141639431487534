import { unpack } from "../types/variable";
import * as validate from "./../utils/validate-inputs";

/**
 * Returns the angle between two objects
 * @param {object} a object, pointer or app
 * @param {object} b object, pointer or app
 * @returns an angle in degrees
 */
export function angleBetween(a, b) {
  a = unpack(a);
  b = unpack(b);

  if (!validate.object(a) || !validate.object(b)) {
    throw Error(validate.error.object);
  }

  const world = a.game.world;
  let pointA;
  let pointB;

  if (validate.pointer(a)) {
    pointA = new Phaser.Point(
      a.position.x + this.camera.x,
      a.position.y + this.camera.y,
    );
  } else if (validate.physical(a)) {
    pointA = a.position;
  } else if (validate.app(a)) {
    pointA = new Phaser.Point(world.centerX, world.centerY);
  } else if (validate.camera(a)) {
    pointA = new Phaser.Point(a.x, a.y);
  }

  if (validate.pointer(b)) {
    pointB = new Phaser.Point(
      b.position.x + this.camera.x,
      b.position.y + this.camera.y,
    );
  } else if (validate.physical(b)) {
    pointB = b.position;
  } else if (validate.app(b)) {
    pointB = new Phaser.Point(world.centerX, world.centerY);
  } else if (validate.camera(b)) {
    pointB = new Phaser.Point(b.x, b.y);
  }

  return pointA.angle(pointB, true);
}
