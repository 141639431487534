import settings from "./settings";
import { user } from "./user";
import embedAction from "../utils/embed-action";

window.addEventListener("message", function(event) {
  // Events not from the expected origin should be ignored
  // TODO: We don't know what the expected origins are
  // if (event.origin !== expectedOrigin) return

  // For safety and security, it's a good idea to validate the data as much as possible
  if (typeof event.data.type !== "string") return;

  switch (event.data.type) {
    case "embed":
      settings.set({
        embed: {
          origin: event.origin,
          supported: event.data.supported || {},
        },
      });
      break;
    default:
      // eslint-disable-next-line no-console
      console.error("Unknown message type:", event.data.type);
  }
});

const embedOrigin = location.search.match(/[?&]embed-origin=([^?&;#]+)/);
if (embedOrigin) {
  parent.postMessage(
    {
      type: "load",
    },
    decodeURIComponent(embedOrigin[1]),
  );
}

// Record whether we have had a problem loading the user API
let userLoadError = false;

function userCheck() {
  if (!settings.get("embed")) {
    return;
  }

  // When using the "internal" authentication in an embedding, only redirect if the user ID is not generated by the POC
  const internalUserNotPOCAuth =
    user.get("authType") === "internal" &&
    user.get("user") &&
    !/^embedpoc-/.test(user.get("user").id);

  // When the user API fails to load in an embedding, try to authenticate
  if (userLoadError || internalUserNotPOCAuth) {
    embedAction({ type: "navigate", destination: "authenticate" });
  }
}

function userError() {
  userLoadError = true;
  userCheck();
}

// embed and user data could arrive in any order, so listen for all possibilities
settings.on("change:embed", userCheck);
user.on("change:authType", userCheck);
user.on("error", userError);
