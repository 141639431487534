import { error, physical, vector } from "./../utils/validate-inputs";

/**
 * Flips a sprite left or right depending on input vector
 *
 * @param {Object} object - The object to flip
 * @param {Vector} direction - The vector indicating the direction to flip towards
 */
export default function flip(object, direction) {
  if (!physical(object)) {
    throw Error(error.command);
  }

  if (!vector(direction)) {
    throw Error(error.vector);
  }

  var x = direction.x;
  var y = direction.y;

  if (x > 0) {
    object.scale.x = Math.abs(object.scale.x);
  } else if (x < 0) {
    object.scale.x = -Math.abs(object.scale.x);
  }

  if (y > 0) {
    object.scale.y = Math.abs(object.scale.y);
  } else if (y < 0) {
    object.scale.y = -Math.abs(object.scale.y);
  }
}
