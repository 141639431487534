import PrimitiveBlock from "./../primitive";

/** this model represents an angle primitive */
export default PrimitiveBlock.extend({
  initialize(options) {
    PrimitiveBlock.prototype.initialize.call(this, options);

    this.on("change:value", this._validateAngle);
    this._validateAngle();
  },

  getPseudoCode() {
    return `${this.get("value")}°`;
  },

  _validateAngle() {
    if (this.get("value") === null) {
      return;
    }
    const value = Number(this.get("value"));
    let wrapped = Phaser.Math.wrapAngle(value);

    //change wrapping to include 180
    if (value === 180) {
      wrapped = 180;
    }

    this.set("value", wrapped);
  },

  getPrimitiveValue() {
    return Number(this.get("value"));
  },

  getToodalOptions() {
    return {
      title: "Angle",
      style: "angle",
      cacheKey: "presets:angle",
      input: "number",
      blocks: [
        { value: -135 },
        { value: -90 },
        { value: -45 },
        { value: -180 },
        { value: null, "editorial-only": true },
        { value: 0 },
        { value: 135 },
        { value: 90 },
        { value: 45 },
      ].map(option => {
        return Object.assign(option, { type: "angle" });
      }),
    };
  },
});
