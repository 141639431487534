import { getApiUrl } from "utils/get-api-url";
import PrototypeModel from "models/prototype-model";

export default PrototypeModel.extend({
  initialize() {
    // no-op
    // We don't want a default ID to be set when this model initializes
  },

  url() {
    return getApiUrl(`app/topic/${this.get("id")}`);
  },
});
