var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Modify task",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":27}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isBlock") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":38,"column":7}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInstructions") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "")
    + "\n<p>\n    <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdvanced") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":53}}})) != null ? stack1 : "")
    + " class=\"discard-wall\">\n        "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Discard wall",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":23,"column":36}}}))
    + "\n    </button>\n</p>\n\n<p>\n    <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdvanced") : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":53}}})) != null ? stack1 : "")
    + " class=\"unlock-blocks\">\n        "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Unlock all blocks",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":41}}}))
    + "\n    </button>\n</p>\n\n<p>\n    <button class=\"background-to-foreground\">\n        "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Move all code to foreground",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":51}}}))
    + "\n    </button>\n</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<p>\n    <button class=\"remove-instructions\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Remove instructions",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":52},"end":{"line":9,"column":87}}}))
    + "\">\n        "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Remove instructions",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":43}}}))
    + "\n    </button>\n</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "<p>\n    <button class=\"add-instructions\">\n        "
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add instructions",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":40}}}))
    + "\n    </button>\n</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    return "<button class=\"confirm\" data-dismiss=\"modal\">"
    + container.escapeExpression(__default(require("../../../../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":42,"column":45},"end":{"line":42,"column":66}}}))
    + "</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n\n"
    + ((stack1 = container.invokePartial(require("../modal.hbs"),depth0,{"name":"../modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"main_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-title"],"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-content"],"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":39,"column":11}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"args":["modal-actions"],"data":data,"loc":{"start":{"line":41,"column":0},"end":{"line":43,"column":11}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});