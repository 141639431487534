import Backbone from "custom/backbone-bundle";
import { VideoView } from "../video/video";
import style from "./media-asset.styl";

style.use();

export const MediaAssetView = Backbone.View.extend({
  className: "media-asset",
  template: require("./media-asset.hbs"),

  initialize() {
    if (!(this.model instanceof Backbone.Model)) {
      this.model = new Backbone.Model(this.model);
    }
  },

  render() {
    if (this.video) {
      this.video.remove();
      delete this.video;
    }

    this.$el.html(
      this.template({
        src: this.model.get("src"),
        isImage: this.__isImage(),
        isVideo: this.__isVideo(),
      }),
    );

    if (this.__isVideo()) {
      this.video = new VideoView({ model: this.model });
      this.$el.append(this.video.el);
      this.video.render();
    }

    this.$el.toggleClass("video", this.__isVideo());
    this.$el.toggleClass("image", this.__isImage());
  },

  __isImage() {
    return this.model.get("type") === "image";
  },

  __isVideo() {
    return (
      this.model.get("type") === "video" ||
      this.model.get("type") === "video/mp4"
    );
  },

  remove() {
    if (this.video) {
      this.video.remove();
    }

    Backbone.View.prototype.remove.call(this);
  },
});
