import Task from "models/task";
import Lesson from "models/lesson";
import { user } from "globals/user";
import { getApiUrl } from "./get-api-url";
import { hasTaxonomy } from "./taxonomy";
import { translate } from "utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";
import { trackCodingEvent } from "utils/coding-event";
import _ from "underscore";
import { checkFlag } from "./flags";

/**
 * Stores the state of a Task
 * @param {Task} model
 */
export function saveState(model, options = {}) {
  if (!canBeStored(model)) {
    return;
  }

  if (options?.hasUserChanges) {
    model.set("hasUserChanges", true);
  }
  interfaceChannel.trigger("save-lesson-in-progress");

  const url = getApiUrl(`state/task/${model.get("id")}/${translate("locale")}`);
  const payload = model.toJSON();
  delete payload["asset-packs"]; // asset-packs are ignored by the API, no need to include them in the payload

  $.ajax(url, {
    data: JSON.stringify(payload),
    contentType: "application/json",
    type: "PUT",
  }).fail(() => interfaceChannel.trigger("save-failed"));
}

/**
 * Exports a throttled function for saving the model
 * @param {Task} model
 */
export const throttleSaveState = _.throttle(
  model => {
    if (checkFlag("SAVE_LESSON_STATE_ONCHANGE")) {
      saveState(model, { hasUserChanges: true });
    }
  },
  5000,
  {
    leading: true,
    trailing: true,
  },
);

/**
 * Resets the state of an individual Task or entire Lesson
 * @param {Lesson|Task} model
 * @param {(Number)} version - optional version number
 */
export async function resetState(model, version = "") {
  trackCodingEvent("reset", model);
  if (!user.canSaveUserProgress()) {
    return;
  }

  let url = "";
  if (model instanceof Task) {
    url = getApiUrl(
      `state/reset/task/${model.get("id")}/${translate("locale")}/${version}`,
    );
  } else if (model instanceof Lesson) {
    url = getApiUrl(
      `state/reset/lesson/${model.get("id")}/${translate("locale")}/${version}`,
    );
  }

  if (url) {
    return new Promise((resolve, reject) =>
      $.ajax(url, {
        data: {},
        contentType: "application/json",
        type: "PUT",
      })
        .done(resolve)
        .fail(reject),
    );
  }
}

/**
 * Checks whether state can be stored for this model
 * @param {Task} model
 */
export function canBeStored(model) {
  return Boolean(
    user.canSaveUserProgress() &&
      model &&
      model.fetched &&
      model.get("id") &&
      model instanceof Task &&
      !(
        hasTaxonomy(model, "use-type.free-code") ||
        hasTaxonomy(model, "use-type.user-generated")
      ),
  );
}
