import * as validate from "./../utils/validate-inputs";
import { isPlatformer } from "./../creation/enable-physics";

/**
 * Makes an object jump
 * Note: The object must have gravity applied to it, otherwise this will error
 *
 * @param {Object} object - The object that will jump
 */
export default function jump(object) {
  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  if (!isPlatformer(object)) {
    throw Error(validate.error.no_gravity);
  }

  // request a jump
  // The actual jump is performed by `creation/enable-physics` during the update loop
  object.initiateJump = true;
}
