import { sound } from "../types/sound";
import ERRORS from "../../blocks/errors";

/**
 * Plays a sound
 *
 * @param {Object} object - This argument is not used (but should be the speaker object)
 * @param {string} key - The phaserKey of the sound to play
 */
export default function playSound(object, key) {
  if (!this.objectGroup) {
    return;
  }

  key = sound(key);

  const soundObject = this.objectGroup.children.find(
    obj => obj.phaserKey === key && obj.audio,
  );

  if (!soundObject) {
    throw Error(ERRORS.RUNTIME_SOUND_EXPECTED);
  }

  const alreadyPlaying = this.sound._sounds.filter(sound => sound.isPlaying);

  if (alreadyPlaying.length) {
    alreadyPlaying.forEach(sound => sound.stop());
  }

  soundObject.audio.play();
}
