import * as validate from "./../utils/validate-inputs";

/**
 * Causes an object to explode!
 * Note: The graphic used by the explosion is not production-ready so this block
 * should not be available
 *
 * @param {Object} object - The object to explode
 */
export default function explode(object) {
  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  if (!object.visible) {
    return; //can't explode that which doesn't exist
  }

  var explosion = this.add.sprite(object.x, object.y, "particles/explosion");
  explosion.anchor.setTo(0.5, 0.5);
  explosion.animations.add("boom", null, 30, false, true);
  explosion.animations.play("boom", null, null, true);

  explosion.width = object.width;
  explosion.height = object.height;

  object.visible = false;
}
