import sanitizeHtml from "sanitize-html";

export default function(dirty, options) {
  const sanitizeOptions = {
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "ul",
      "ol",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "del",
    ],
  };
  if (options && options.fn) {
    return options.fn(sanitizeHtml(dirty, sanitizeOptions));
  } else {
    return sanitizeHtml(dirty.fn(this), sanitizeOptions);
  }
}
