export default {
  type: "object",
  groups: ["year 5", "year 6"],
  category: "pointer",
  target: { type: "pointer", key: "pointer" },
  "feature-flags": ["!APP_NEW_CODE_CHEST"],
  commands: {
    locked: true,
    code: [
      {
        "hard-lock": true,
        type: "command",
        fn: "get",
        args: {
          arguments: [
            {
              code: {
                "hard-lock": true,
                type: "string",
                value: "x",
                title: "property",
                editable: false,
              },
            },
          ],
        },
      },
    ],
  },
};
