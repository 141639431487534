import Backbone from "backbone"
import "backbone-relational"

/**
 * Performance fix for backbone relational
 */
Backbone.BlockingQueue.prototype.process = function processV2() {
  var queue = this._queue;
  this._queue = [];
  for (const item of queue) {
    item();
  }
};

export default Backbone