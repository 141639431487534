import Backbone from "custom/backbone-bundle";
import { task } from "globals/task";
import { settings } from "globals/settings";
import game from "models/block/game";
import {
  BLOCK_CACHE_ADD_OBJECT_BUTTONS,
  BLOCK_CACHE_DYNAMIC,
  BLOCK_CACHE_STATIC,
  BLOCK_CACHE_TOODAL,
  BLOCK_CACHE_ADD_OBJECT_BUTTONS_WALL,
} from "../../../../utils/block-cache/block-cache";
import {
  filterAvailableAddButtons,
  filterContextualArguments,
  filterSameTypeBlocks,
  filterSuggested,
} from "./block-filters";

export const MODES = {
  ADD: "add", // adding code (before, after or into scope)
  SWAP: "swap", // replacing code
  INSERT: "insert", // insert code (into argument or jigsaw)
};
export const SOURCE_TYPE = {
  WALL: "wall",
  CHEST: "chest",
  NONE: "none",
};
export const SCREENS = {
  WHERE: "where",
  FILTER: "filter",
  CODE: "code",
};

export const BlockSource = Backbone.Model.extend({
  defaults: {
    mode: MODES.ADD, // determines whether we are adding, swapping or inserting code
    where: null,
    filter: null,
    filterName: null,
    suggestions: null,
    target: null,
    selection: null,
    search: null,
  },

  initialize() {
    this.on("change:target", this.onTargetChange);
    this.on("change:filter", this.onFilterChange);
  },

  onTargetChange() {
    let target = this.get("target");

    if (!target) {
      this.set({
        mode: null,
        where: null,
        filter: null,
        suggestions: null,
        search: null,
      });
      return;
    }

    if (this.get("mode") === MODES.INSERT) {
      this.set({
        where: { parent: target },
        filter: null,
        suggestions: target.getArgumentSuggestions?.(),
      });
    } else if (this.get("mode") === MODES.SWAP) {
      this.set({
        where: { parent: target.getParent() },
        filter: block => filterSameTypeBlocks(block, target),
        filterName: target.get("target")?.type || target.get("type"),
        suggestions: target.getParent().getArgumentSuggestions?.(),
      });
    } else {
      this.set({
        where: null,
        filter: null,
        suggestions: target.getCommandSuggestions?.(),
      });
    }
  },

  onFilterChange() {
    if (!this.get("filter")) {
      this.set("filterName", null);
    }
  },

  /**
   * Get the current screen
   * @returns {string}
   */
  getCurrentScreen() {
    if (this.canChooseWhere() && !this.has("where")) {
      return SCREENS.WHERE;
    } else if (
      this.canChooseFilter() &&
      !this.has("filter") &&
      !this.has("suggestions")
    ) {
      return SCREENS.FILTER;
    } else {
      return SCREENS.CODE;
    }
  },

  /**
   * Get the previous screen
   * @returns {string|null}
   */
  getPreviousScreen() {
    if (this.getCurrentScreen() === SCREENS.FILTER && this.canChooseWhere()) {
      return SCREENS.WHERE;
    }

    if (this.getCurrentScreen() === SCREENS.CODE) {
      if (this.canChooseCode()) {
        if (this.canChooseFilter()) {
          return SCREENS.FILTER;
        }
        if (this.canChooseWhere()) {
          return SCREENS.WHERE;
        }
      }
    }

    return null;
  },

  getBlockSource() {
    return this.get("blocks")?.source || SOURCE_TYPE.NONE;
  },

  /**
   * Determines whether 'where' can be chosen
   * @returns {Boolean}
   */
  canChooseWhere() {
    return this.get("mode") === MODES.ADD;
  },

  /**
   * Determines whether 'filter' can be chosen
   * @returns {Boolean}
   */
  canChooseFilter() {
    return this.getBlockSource() === SOURCE_TYPE.CHEST;
  },

  /**
   * Determines whether 'code' can be chosen
   * @returns {Boolean}
   */
  canChooseCode() {
    return (
      !this.get("target").get("locked") && !this.get("target").get("hard-lock")
    );
  },

  /**
   * Check whether the block source needs to have a 'where' defined
   * @returns {Boolean}
   */
  requiresWhereSelection() {
    return !this.has("where");
  },

  /**
   * Check whether the block source needs to have a 'filter' defined
   * @returns {Boolean}
   */
  requiresFilterSelection() {
    return (
      this.getBlockSource() === SOURCE_TYPE.CHEST &&
      !this.has("filter") &&
      !this.has("suggestions")
    );
  },

  /**
   * Generates the list of available blocks
   * Must be called before doing any filtering
   * @async
   */
  async generateBlockList() {
    await game.promiseSetup();
    this.set("blocks", await this.__getBlocks());
  },

  getAvailableBlocks() {
    let list = this.get("blocks").collection;
    const settings = task
      .getComponent("models/block")
      ?.get("settings")
      ?.toJSON();
    list = list.filter(block => !block.get("used"));
    list = list.filter(block => !block.view?.isLocked?.());
    list = list.filter(block => filterAvailableAddButtons(block, settings));

    if (this.has("where")) {
      list = list.filter(block => this.get("where").parent.accepts(block));

      list = list.filter(block =>
        filterContextualArguments(block, this.get("where").parent),
      );

      if (this.get("where").parent.get("free-form")) {
        // normally, only events can be placed directly on the canvas
        const acceptedTypes = ["event"];

        // but when we are swapping, we also allow the type of the block that is being swapped
        if (this.get("mode") === MODES.SWAP) {
          acceptedTypes.push(this.get("target").get("type"));
        }

        list = list.filter(block => acceptedTypes.includes(block.get("type")));
      }
    }

    if (this.has("filter")) {
      list = list.filter(this.get("filter"));
    }

    // filter suggestions
    // NOTE: this has to be the very last check, so we can decide whether to show the suggestions or go back to the filter screen
    let suggested = [];

    if (this.__hasCodeSuggestions()) {
      suggested = list.filter(block =>
        filterSuggested(block, this.get("suggestions")),
      );
    }

    if (suggested.length > 0) {
      return suggested;
    } else {
      this.set("suggestions", null);
    }

    return list;
  },

  /**
   * Checks whether the block source has suggestions
   * @returns {Boolean}
   */
  __hasCodeSuggestions() {
    if (!this.get("suggestions")) {
      return false;
    }
    if (this.getBlockSource() !== SOURCE_TYPE.CHEST) {
      return false; // can only have suggestions if the code chest is available
    }

    if (this.get("mode") === MODES.SWAP) {
      return false; // no suggestions when swapping blocks
    }

    if (!this.has("where")) {
      return false; // can't offer suggestions without a `where`
    }

    return this.get("suggestions").length > 0;
  },

  /**
   * Get the source of code blocks (code wall or chest)
   * @async
   * @private
   * @returns {Backbone.Collection}
   */
  async __getBlocks() {
    this.set({ task: task });
    const blockCoder = this.get("task").getComponent("models/block");
    const hasCodeWall = blockCoder?.get("settings")?.get("has-code-wall");
    const hasCodeChest = blockCoder?.get("settings")?.get("has-code-chest");
    const isEditorialEditMode = settings.get("editable");

    if (isEditorialEditMode) {
      return {
        collection: await this.__getCodeChestBlocks(),
        source: SOURCE_TYPE.CHEST,
      };
    } else if (hasCodeWall) {
      return {
        collection: await this.__getWallBlocks(),
        source: SOURCE_TYPE.WALL,
      };
    } else if (hasCodeChest) {
      return {
        collection: await this.__getCodeChestBlocks(),
        source: SOURCE_TYPE.CHEST,
      };
    } else {
      return {
        collection: await this.__getWallBlocks(),
        source: SOURCE_TYPE.NONE,
      };
    }
  },

  async __getWallBlocks() {
    const blocks = [];
    const wallBlocks =
      task.getComponent("models/block")?.get("snippets")?.get("code")?.models ||
      [];
    const canvasBlocks = (
      task.getComponent("models/block")?.get("input").get("code")?.models || []
    ).filter(block => {
      return block.get("invalid-rotation") !== 0;
    });
    const objectBlocks = wallBlocks.filter(block =>
      ["object", "variable", "key"].includes(block.get("type")),
    );

    canvasBlocks.forEach(block => block.set("category", "canvas"));
    wallBlocks.forEach(block => block.set("category", "wall"));
    objectBlocks.forEach(block => block.set("category", "wall-objects"));

    blocks.push(...wallBlocks, ...canvasBlocks);

    await BLOCK_CACHE_ADD_OBJECT_BUTTONS_WALL.loadBlocks();
    blocks.push(
      ...BLOCK_CACHE_ADD_OBJECT_BUTTONS_WALL.blocks.map(view => view.model),
    );

    if (this.get("mode") === MODES.SWAP) {
      await BLOCK_CACHE_DYNAMIC.populateDynamic();
      blocks.push(
        ...BLOCK_CACHE_DYNAMIC.blocks
          .map(view => view.model)
          .filter(block => ["key", "texture"].includes(block.get("type")))
          // don't add the block if it's already available on the wall
          .filter(block => !blocks.find(other => other.isSame(block))),
      );

      await BLOCK_CACHE_TOODAL.loadBlocks();
      blocks.push(
        ...BLOCK_CACHE_TOODAL.blocks
          .map(view => view.model)
          // don't add the block if it's already available on the wall
          .filter(block => !blocks.find(other => other.isSame(block))),
      );
    }

    return new Backbone.Collection(blocks);
  },

  async __getCodeChestBlocks() {
    await BLOCK_CACHE_DYNAMIC.populateDynamic();
    await BLOCK_CACHE_STATIC.loadBlocks();
    await BLOCK_CACHE_TOODAL.loadBlocks();
    await BLOCK_CACHE_ADD_OBJECT_BUTTONS.loadBlocks();

    const blocks = [
      ...BLOCK_CACHE_DYNAMIC.blocks,
      ...BLOCK_CACHE_STATIC.blocks,
      ...BLOCK_CACHE_TOODAL.blocks,
      ...BLOCK_CACHE_ADD_OBJECT_BUTTONS.blocks,
    ].map(view => view.model);

    return new Backbone.Collection(blocks);
  },
});
