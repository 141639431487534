import Modal from "../modal";
import sanitizeString from "utils/sanitize-string";
import { app } from "app";
import save from "utils/save";
import { task } from "globals/task";
import { translate } from "utils/localisation";

var TASK_TYPES = [
  {
    type: "Python",
    subtype: "Drag & Drop",
    blueprint: require("fixtures/task-types/python-dnd").default,
  },
  {
    type: "Python",
    subtype: "Free text",
    blueprint: require("fixtures/task-types/python-free").default,
  },
  {
    type: "HTML",
    subtype: "Drag & Drop",
    blueprint: require("fixtures/task-types/html-dnd").default,
  },
  {
    type: "HTML",
    subtype: "Free text",
    blueprint: require("fixtures/task-types/html-free").default,
  },
  {
    type: "Block Coding",
    subtype: "Splash Screen",
    blueprint: require("fixtures/task-types/splash-screen").default,
  },
  {
    type: "Block Coding",
    subtype: "Normal",
    blueprint: require("fixtures/task-types/super-block-jigsaw").default,
  },
  {
    type: "Block Coding",
    subtype: "Advanced",
    blueprint: require("fixtures/task-types/super-block-default").default,
  },
];

TASK_TYPES.forEach(function(task) {
  task.sanitized = {
    type: sanitizeString(task.type),
    subtype: sanitizeString(task.subtype),
  };
});

export default Modal.extend({
  className: [Modal.prototype.className, "set-task-type"].join(" "),

  template: require("./set-task-type.hbs"),
  style: require("./set-task-type.styl"),

  events: {
    "click .type-selection:not([disabled])": "setTaskType",
  },

  render: function() {
    this.$el.html(
      this.template({
        types: TASK_TYPES,
      }),
    );
  },

  async setTaskType(event) {
    var index = $(event.currentTarget).attr("index");

    if (
      window.confirm(translate("This will clear any data set on this task."))
    ) {
      await this.close();

      app.destroyLayout();
      const data = JSON.parse(JSON.stringify(TASK_TYPES[index].blueprint));
      await task._ensureComponents(data);
      task.reset(data);
      save();
      app.setLayout();
    }
  },
});
