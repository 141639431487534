var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"separator") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"separator\">\n            <svg alt=\"\">\n              <use xlink:href=\"#nebula_caret-right\"></use>\n            </svg>\n          </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <button\n            class=\"multi-toodal__breadcrumb\"\n            data-for=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"for") : depth0), depth0))
    + "\"\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":41}}})) != null ? stack1 : "")
    + "\n            tabindex=\"0\">\n            "
    + alias1(__default(require("../../../../templates/helpers/translate.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":31}}}))
    + "\n          </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <button class=\"multi-toodal__search\" title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Search",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":70}}}))
    + "\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Search",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":84},"end":{"line":41,"column":106}}}))
    + "\"\n      tabindex=\"0\">\n      <svg>\n        <use xlink:href=\"#nebula_search\"></use>\n      </svg>\n    </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"multi-toodal__header\">\n  <div class=\"multi-toodal__header-start\">\n    <h1>\n      "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":25}}}))
    + "\n    </h1>\n  </div>\n  <div class=\"multi-toodal__header-end\">\n    <button class=\"multi-toodal__close\" title=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":47},"end":{"line":8,"column":68}}}))
    + "\" aria-label=\""
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Close",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":82},"end":{"line":8,"column":103}}}))
    + "\" tabindex=\"0\">\n      <svg alt=\"\">\n        <use xlink:href=\"#icon-x\"></use>\n      </svg>\n    </button>\n  </div>\n</header>\n\n<header class=\"multi-toodal__breadcrumbs\">\n  <div class=\"multi-toodal__header-start\">\n    <div class=\"crumbs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"crumbs") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":6},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n  <div class=\"multi-toodal__header-end\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasSearch") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</header>\n\n<section class=\"loading\">\n  <svg class=\"spinner\" alt=\"\">\n    <title>\n      "
    + alias2(__default(require("../../../../templates/helpers/translate.js")).call(alias1,"Loading",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":54,"column":6},"end":{"line":54,"column":29}}}))
    + "\n    </title>\n    <use xlink:href=\"#loader-spinner\"></use>\n  </svg>\n</section>\n";
},"useData":true});