import { BatchSpanProcessor } from "@opentelemetry/tracing";
import { WebTracerProvider } from "@opentelemetry/web";
import { getApiUrl } from "../utils/get-api-url";
import { StableRequestsInstrumentation } from "./otel/StableRequestsInstrumentation";
import { EnhancedSpanProcessor } from "./otel/EnhancedSpanProcessor";
import { RawSpanExporter } from "./otel/RawSpanExporter";
import { SampleSpanProcessor } from "./otel/SampleSpanProcessor";

export const stableRequests = new StableRequestsInstrumentation();
const provider = new WebTracerProvider({
  plugins: [stableRequests],
});
provider.addSpanProcessor(
  new EnhancedSpanProcessor(
    new SampleSpanProcessor(
      {
        sampleRates: {
          // Only collect 1-in-10 network requests in PROD (always collect overall page load)
          documentFetch: process.env.INFRA_ENV === "prod" ? 10 : 1,
          resourceFetch: process.env.INFRA_ENV === "prod" ? 10 : 1,
        },
      },
      new BatchSpanProcessor(
        new RawSpanExporter({
          url: getApiUrl("instrumentation/trace"),
        }),
        {
          bufferSize: 100,
          bufferTimeout: 5000,
        },
      ),
    ),
  ),
);
provider.register();
stableRequests.documentLoadStart();
