export default Object.assign(
  JSON.parse(
    JSON.stringify(
      require("models/block/blocks/command/blueprints/rotationStyle.json"),
    ),
  ), //make sure all the values are deeply cloned
  {
    secret: false,
    args: {
      arguments: [
        {
          code: {
            type: "string",
            title: "rotation style",
            value: "no-rotate",
            "hard-lock": true,
            editable: true,
          },
        },
      ],
    },
  },
);
