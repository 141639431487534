import _ from "underscore";

export default {
  type: "super-block",
  layout: "layouts/techbook",
  components: [
    _.extend({}, require("fixtures/components/instructions-techbook.json"), {
      video: {},
    }),
    _.extend(
      {},
      JSON.parse(
        JSON.stringify(require("fixtures/components/super-block-coder.json")),
      ),
      {
        width: 16,
        height: 14,

        "stage-wrap": false,
        "collide-bounds": false,

        objects: [],
      },
    ),
  ],
};
