import SET from "./set";
import * as validate from "./../utils/validate-inputs";
import gameChannel from "../channels/game-channel";

/**
 * Stops movement of an object (by setting speed to 0)
 *
 * Can also be called on the app to jump out of `run` mode
 *
 * @param {Object} object - The object to stop
 */
export default function stop(object) {
  if (validate.physical(object)) {
    /**
     * Note: due to the async nature of code blocks, some wizardry is required -
     * roll position back to previous frame
     * this is to allow the following pseudo code to work as expected:
     *   WHEN obj HITS tile
     *     obj.STOP
     */
    // TODO: if it's possible to detect when this was caused by a collision handler,
    // see if we can have the position of the object so that it touches the target
    // (as opposed to rolling back to previous position with a gap)
    object.x = object.previousPosition.x;
    object.y = object.previousPosition.y;

    // clear all stored overlaps to force the physics the re-check them
    object.currentOverlaps.length = 0;

    return SET.call(this, object, "speed", 0);
  } else if (validate.app(object)) {
    gameChannel.trigger("app-stop");
  } else {
    throw Error(validate.error.command);
  }
}
