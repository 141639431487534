import { KEYS } from "models/block/blocks/primitive/types/key-mapping";
import { translate } from "utils/localisation";
import { hasTaxonomy } from "utils/taxonomy";

/**
 * AssetManifest
 *
 * Provides structured data of all the assets currently available
 * Grouped by type and folder
 */
export class AssetManifest {
  constructor(task) {
    this.task = task;
  }

  get data() {
    return JSON.parse(JSON.stringify(this.__data));
  }

  count(type) {
    return this.__data[type].total;
  }

  has(type) {
    return this.count(type) > 0;
  }

  listFolders(type) {
    return Object.keys(this.__data[type].folders);
  }

  listItems(type, folder) {
    if (!folder) {
      return this.__data[type].assets.slice();
    } else {
      return this.__data[type].folders[folder].slice();
    }
  }

  async generate(manifest) {
    // filter out blacklisted assets for this locale
    manifest = manifest.filter(
      asset => !hasTaxonomy(asset, `locale-blacklist.${translate("locale")}`),
    );

    const objects = [...manifest, ...JSON.parse(JSON.stringify(KEYS))];

    this.__data = {};

    this.__addType(objects, "sound");
    this.__addType(objects, "image");
    this.__addType(objects, "button");
    this.__addType(objects, "sprite");
    this.__addType(objects, "background");
    this.__addType(objects, "key");
  }

  __addType(items, type) {
    items = items.filter(item => item.type === type);

    this.__data[type] = {
      total: items.length,
      assets: items,
      folders: {},
    };

    items.forEach(item => {
      item.folders.forEach(folder => this.__addFolder(item, type, folder));
    });
  }

  __addFolder(item, type, folder) {
    if (!this.__data[type].folders[folder]) {
      this.__data[type].folders[folder] = [];
    }
    this.__data[type].folders[folder].push(item);
  }
}
