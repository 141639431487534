import Modal from "../modal";
import reset from "utils/reset";

export default Modal.extend({
  className: [Modal.prototype.className, "reset"].join(" "),

  template: require("./reset.hbs"),

  events: Object.assign(
    { "click .reset-proceed": "onConfirmReset" },
    Modal.prototype.events,
  ),

  onConfirmReset() {
    reset();
  },
});
