export default {
  type: "object",
  category: "app",
  target: { type: "app", key: "app" },
  groups: ["year 3", "year 4", "year 5", "year 6"],
  "feature-flags": ["!APP_NEW_CODE_CHEST"],
  commands: {
    locked: true,
    code: [
      {
        "hard-lock": true,
        type: "command",
        fn: "feedback",
        args: {
          arguments: [
            {
              name: "feedback",
            },
          ],
        },
      },
    ],
  },
};
