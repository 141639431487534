import PrototypeModel from "models/prototype-model";

export const Test = PrototypeModel.extend({
  defaults: {
    operator: "OR",
    priority: 100,
    inverted: false,
  },

  initialize: function (options) {
    PrototypeModel.prototype.initialize.call(this, options);
    this.reset();
  },

  execute: function () {
    const fn = this.get("owner").getTestFunction(this.get("type"));
    const value = this.get("test");
    const options = this.get("options");

    return this._runTest(fn, value, options);
  },

  /**
   * Runs the tests
   * @return {Promise}        return a promise that will resolve or reject based on the results of the test
   */
  async _runTest(fn, value, options) {
    let result;

    if (Array.isArray(value)) {
      result = await this._testMultiple(fn, value, options);
    } else {
      result = await this._testOne(fn, value, options);
    }

    if (this.get("inverted")) {
      result = !result;
    }

    this.set("passing", result);

    return result ? Promise.resolve(this) : Promise.reject(this);
  },

  /**
   * run a single test
   * @param  {Function} fn       the test function
   * @param  {any}      value    the value to pass to the test function
   * @param  {Object}   options  an options object to pass to the test function
   * @return {Boolean}           true if the test passed
   */
  async _testOne(fn, value, options) {
    try {
      await fn(value, options);
      return true;
    } catch (e) {
      return false;
    }
  },

  /** test multiple values using AND/OR operator */
  async _testMultiple(fn, values, options) {
    const operator = this.get("operator");

    if (values.length === 0) {
      return true;
    }

    let result = await Promise.all(
      values.map(val => this._testOne(fn, val, options)),
    );

    // accumulate results into single value
    if (operator === "OR") {
      result = result.reduce((acc, val) => acc || val, false);
    } else {
      result = result.reduce((acc, val) => acc && val, true);
    }

    return result;
  },

  /** reset this test */
  reset: function () {
    this.unset("passing");
  },
});
