import * as validate from "./../utils/validate-inputs";
import { unpack } from "./../types/variable";

export default function equals(a, b) {
  a = unpack(a);
  b = unpack(b);

  // vector to vector
  if (validate.vector(a) && validate.vector(b)) {
    return a.x === b.x && a.y === b.y;
  }

  // angle to vector
  if (validate.angle(a) && validate.vector(b)) {
    return a === b.angle;
  } else if (validate.vector(a) && validate.angle(b)) {
    return a.angle === b;
  }

  // anything else
  // weak equality - go easy on the kids!
  return a == b;
}
