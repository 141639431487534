import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";

/**
 * Plays an animation on an object (assuming the object can)
 *
 * @param {Object} object - The object to animate
 * @param {String} label - The frame label to play
 *
 * @description https://phaser.io/docs/2.6.2/Phaser.AnimationManager.html#play
 */
export default function animate(object, label) {
  label = string(label);

  if (!validate.animated(object)) {
    throw Error(validate.error.command);
  }

  object.animations.play(label, null, true, false);
}
