var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<block-content class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":55}}})) != null ? stack1 : "")
    + "\" type=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\">\n  <label class=\"var\">"
    + alias2(__default(require("../../helpers/translate.js")).call(alias1,"var",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":21},"end":{"line":3,"column":40}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? lookupProperty(stack1,"deleted") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":10,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + "</block-content>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "editable";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <label class=\"deleted\">"
    + container.escapeExpression(__default(require("../../helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"DELETED",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":25},"end":{"line":5,"column":48}}}))
    + "</label>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? lookupProperty(stack1,"loading") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":10,"column":2}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "  <label class=\"loading\"></label>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <label class=\"block-label\">"
    + container.escapeExpression(__default(require("../../helpers/translateContent.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"translateContent","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":61}}}))
    + "</label>\n  ";
},"11":function(container,depth0,helpers,partials,data) {
    return "  <span class=\"three-dots\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./code-block.hbs"),depth0,{"name":"code-block","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});