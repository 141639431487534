import ERRORS from "./../../blocks/errors";

// this method limits object blocks to only those currently in scope
export default function findThis(scope, object) {
  while (scope && scope.filter) {
    const intersection = scope.filter(item => object.includes(item));

    if (intersection.length) {
      return intersection;
    }

    scope = scope[scope.length - 1];
  }

  throw Error(ERRORS.RUNTIME_INVALID_THIS);
}
