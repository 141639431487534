import "custom/baps";

(function() {
  "use strict";

  // eslint-disable-next-line no-undef
  if (baps.browser === "IE" && baps.browserVer < 9) {
    location.href = "no-support.html";
  }
})();
