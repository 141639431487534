import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import _ from "underscore";
import { unpack } from "../../phaser-middleware/types/variable";

/**
 * IF-ELSE block
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
    CodeBlock.prototype.createRelation("scope", "then"),
    CodeBlock.prototype.createRelation("scope", "else"),
  ]),

  blueprint: require("./blueprints/if-else.json"),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
    then: {},
    else: {},
    "control-block": true,
  }),

  constructor(attrs, options) {
    //support for deprecated data format
    if (attrs.if) {
      attrs.args = attrs.if;
      delete attrs.if;
    }

    return CodeBlock.prototype.constructor.call(this, attrs, options);
  },

  getPseudoCode(indent = "") {
    const args = this.get("args").getPseudoCode();
    const then = this.get("then").getPseudoCode(indent);
    const or = this.get("else").getPseudoCode(indent);

    return `${indent}IF${args} ${then}\n${indent}ELSE${or}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    this.get("then").getChildBlocks(list);
    this.get("else").getChildBlocks(list);
    return list;
  },

  async execute(scope) {
    // compute arguments
    const args = await this.get("args").compute(scope);

    if (unpack(args)) {
      return this.get("then").run(scope);
    } else {
      return this.get("else").run(scope);
    }
  },

  getGlossaryTaxonomy() {
    return [
      "glossary-type.code-block.control.if-else",
      "glossary-type.code-block.control",
      "glossary-type.code-block",
    ];
  },

  _placementMessage: ERRORS.PLACEMENT_CONTROL,
  _validatePlacement(parent) {
    return parent instanceof BlockScope && !parent.get("free-form");
  },
});
