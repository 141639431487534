import "./block/phaser-middleware/phaser-bundle";
import _ from "underscore";
import store from "globals/store";
import Component from "models/component";
import TestablePrototype from "models/prototypes/testable";
import PrototypeModel from "models/prototype-model";
import Backbone from "custom/backbone-bundle";
import { hasTaxonomy } from "utils/taxonomy";

import GAME from "./block/game";
import BUILD_STEP from "fixtures/block-grade-defaults/build-step.json";
import GRADE_ALL from "fixtures/block-grade-defaults/grade-all.json";
import GRADE_1 from "fixtures/block-grade-defaults/grade-1.json";
import GRADE_2 from "fixtures/block-grade-defaults/grade-2.json";
import GRADE_3 from "fixtures/block-grade-defaults/grade-3.json";
import GRADE_4 from "fixtures/block-grade-defaults/grade-4.json";
import GRADE_5 from "fixtures/block-grade-defaults/grade-5.json";
import GRADE_6 from "fixtures/block-grade-defaults/grade-6.json";

const THUMB_CACHE = document.createElement("canvas");
const MAX_THUMB_WIDTH = 400;
const MAX_THUMB_HEIGHT = 400;

function enforceType(prop, method) {
  if (Object.prototype.hasOwnProperty.call(this, prop)) {
    this[prop] = method(this[prop]);
  }
}

var MODES = {
  DEFAULT: "default", //this is the default mode, all blocks can be fully interacted with and content creators have fine-grained control over which blocks are locked/editable/scope-locked...
  JIGSAW: "jigsaw", //in this mode, blocks can be marked as 'jigsaw-blocks' by a content creator, this allows for the creation of simpler more scaffolded tasks
};

export default store["models/block"] = Component.extend(
  TestablePrototype,
).extend({
  relations: TestablePrototype.relations.concat([
    {
      // objects on the PHASER stage
      type: Backbone.HasMany,
      key: "objects",
      parse: true,
      runTests: true,
      relatedModel: require("./block/stage-object").default,
      reverseRelation: {
        key: "block",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },

    {
      // code-canvas
      type: Backbone.HasOne,
      key: "input",
      parse: true,
      runTests: true,
      relatedModel: require("./block/blocks/top-level-scopes/input").default,
      reverseRelation: {
        key: "block",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },
    {
      // code-canvas
      // holds the solution
      type: Backbone.HasOne,
      key: "solution-code",
      parse: true,
      relatedModel: require("./block/blocks/top-level-scopes/solution-code")
        .default,
      reverseRelation: {
        key: "block",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },
    {
      // background code-canvas
      // used by content creators to hide code that still needs to run
      type: Backbone.HasOne,
      key: "background-code",
      runTests: true,
      parse: true,
      relatedModel: require("./block/blocks/top-level-scopes/background-code")
        .default,
      reverseRelation: {
        key: "block",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },
    {
      // code-wall
      type: Backbone.HasOne,
      key: "snippets",
      runTests: true,
      parse: true,
      relatedModel: require("./block/blocks/top-level-scopes/snippets").default,
      reverseRelation: {
        key: "block",
        type: Backbone.HasOne,
        includeInJSON: false,
      },
    },
    {
      type: Backbone.HasOne,
      key: "settings",
      parse: true,
      relatedModel: PrototypeModel,
    },
  ]),

  defaults: function () {
    return {
      objects: [], // objects present on the stage
      input: {}, // input code - this is what runs the app
      "background-code": {}, // background code - validation and environment code
      "solution-code": {}, // solution code - can hold a potential solution code example
      snippets: {}, // benched code

      //settings relating to the user
      settings: {
        //code settings
        "edit-code": true, //prevents the code from being changed
        "has-code-chest": false, //grants access to the code chest
        "auto-add-object": false, // automatically add objects to code wall
        "has-code-wall": true, //is the code wall is present?
        "freeze-on-failure": false, //freeze the stage when the app fails
        "freeze-on-success": false, //freeze the stage when the app succeeds
        "code-chest-groups-all": true,
        "code-chest-groups-dynamic": true, // controls whether dynamic blocks are visible in the code chest, cannot be turned off by the user
        "debug-mode": false, //auto-run code at start

        //design settings
        "edit-design": false, //prevents the user from changing the design
        "stage-objects": false, //can objects be edited at all?
        "stage-objects-move": false, //can objects be moved?
        "stage-objects-size": false, //can objects be resized?
        "stage-objects-name": false, //can objects be renamed?
        "stage-objects-label": false, //can object labels be changed?
        "stage-objects-delete": false, //can objects be added/removed?
        "stage-objects-image": false, //can add/remove images
        "stage-objects-animation": false, // can add/remove animations
        "stage-objects-sprite": false, //can add/remove sprites
        "stage-objects-sound": false, //can add/remove sounds
        "stage-objects-button": false, //can add/remove buttons
        "stage-objects-key": false, //can add/remove keys
        "stage-objects-text": false, //can add/remove text
        "stage-objects-variable": false, //can add/remove variables
        "can-edit-stage": false, //can the stage itself be changed?
        "stage-name": false, //can it be renamed?
        "stage-background": false, //can the background change?
        "stage-bgr-color": false, //can the background color change?
        "stage-bgr-img": false, //can the background image change?
        "stage-paint": false, //can the user paint tiles?
        "stage-tile-set": false, //can the user change the tileset?
        "stage-grid": false, //can the user change the grid?
        "stage-wrap": false, //can the user change how objects wrap?
        "stage-size": false, //can the stage be resized?
        "stage-camera": false, //is the camera available?
      },
      "interaction-mode": MODES.DEFAULT, //determines the mode of interaction for blocks

      //stage configuration
      width: 16, //in tiles
      height: 16, //in tiles
      "hide-grid-run": true, //hides the grid in run mode
      "show-pixel-coordinates": false, //enables pixel coordinates on the grid
      "show-tile-coordinates": false, //enables tile coordinates on the grid
      "background-image": null,
      "background-color": "#0071BC",
      "background-gradient": null,
      "tile-set": null, //the tileset used for texturing the background tiles
      "tile-width": null, //size of tiles, determined by active tileset
      "tile-height": null,
      tiles: [], //list of tile indexes (determining their texture)
      "collision-indexes": [], //list of tile indexes that trigger collisions
      "stage-wrap": true, //determines if objects will wrap around the stage
      "collide-bounds": false, //determines if objects collide with stage edges
      "show-coordinates-none": true, //hides the pixel/tile coordinates along the grid

      //camera props
      "camera-enabled": false,
      "camera-width": 192,
      "camera-height": 192,
      "camera-x": 100, // NOTE: these values are the center position of the camera (the phaser camera is top-left aligned)
      "camera-y": 100,
      "camera-locked-aspect-ratio": true,

      //physics-related properties
      "tween-speed": 500, //speed of tweens
      "physics-scale": 100, //all physics related values will be scaled by this factor
      "max-speed": 8, //pixels/second * physics-scale
      "default-speed": 2, //pixels/second * physics-scale
      "default-jump-speed": 8, //pixels/second
    };
  },

  constructor(data = {}, options = {}) {
    // always parse this model
    options.parse = true;
    return Component.prototype.constructor.call(this, data, options);
  },

  initialize: function (options) {
    this.on("change:tile-set", this._updateTilesetConfig);

    Component.prototype.initialize.apply(this, [options]);

    this.runtimeListener = _.extend({}, Backbone.Events);

    this.defineStaticTest({ type: "test-runtime" });

    this.set({
      ready: false,
      running: false,
    });

    // note: we intentionally don't store this via .set because we don't want it
    // to persist between page loads
    this.__CACHED_THUMB = false;

    // NOTE: this is here because some tasks/apps may have been saved
    // incorrectly with both a background-image and a background-color
    if (this.get("background-image")) {
      this.set("background-color", null);
    }

    // default to show all code blocks
    if (!this.get("settings").has("code-chest-groups-all")) {
      this.get("settings").set("code-chest-groups-all", true);
    }
    if (!this.get("settings").has("code-chest-groups-dynamic")) {
      this.get("settings").set("code-chest-groups-dynamic", true);
    }
  },

  //block code is parsed differently than the standard code model
  parse: function (data) {
    delete data.state;
    delete data["code-chest"]; // this is a property that has been removed but may still be present in older data
    delete data.manifest; // some tasks are 'polluted' by legacy manifest data - remove it to clean the data if it gets saved

    enforceType.call(data, "width", Number);
    enforceType.call(data, "height", Number);

    enforceType.call(data, "stage-wrap", Boolean);
    enforceType.call(data, "collide-bounds", Boolean);

    enforceType.call(data, "tween-speed", Number);
    enforceType.call(data, "max-speed", Number);
    enforceType.call(data, "default-speed", Number);
    enforceType.call(data, "default-jump-speed", Number);

    // force parent settings to be true if any of their children are true
    if (data.settings) {
      if (data.settings["stage-bgr-color"] || data.settings["stage-bgr-img"]) {
        data.settings["stage-background"] = true;
      }

      if (
        data.settings["stage-size"] ||
        data.settings["stage-wrap"] ||
        data.settings["stage-grid"] ||
        data.settings["stage-camera"] ||
        data.settings["stage-tile-set"]
      ) {
        data.settings["can-edit-stage"] = true;
      }

      if (
        data.settings["stage-objects-image"] ||
        data.settings["stage-objects-animation"] ||
        data.settings["stage-objects-sprite"] ||
        data.settings["stage-objects-sound"] ||
        data.settings["stage-objects-button"] ||
        data.settings["stage-objects-key"] ||
        data.settings["stage-objects-text"] ||
        data.settings["stage-objects-variable"]
      ) {
        data.settings["stage-objects-delete"] = true;
      }

      if (
        data.settings["stage-objects-move"] ||
        data.settings["stage-objects-size"] ||
        data.settings["stage-objects-name"] ||
        data.settings["stage-objects-label"] ||
        data.settings["stage-objects-delete"]
      ) {
        data.settings["stage-objects"] = true;
      }

      if (
        data.settings["stage-objects"] ||
        data.settings["can-edit-stage"] ||
        data.settings["stage-background"] ||
        data.settings["stage-paint"]
      ) {
        data.settings["edit-design"] = true;
      }
    }

    return data;
  },

  getPseudoCode() {
    return this.get("input").getPseudoCode();
  },

  /**
   * Returns a list of all the code blocks on this code
   *
   * NOTE: This is computationally heavy, use sparingly and responsibly!
   */
  getBlocks() {
    return [
      ...this.get("input").getChildBlocks(),
      ...this.get("background-code").getChildBlocks(),
      ...this.get("snippets").getChildBlocks(),
    ];
  },

  // changes code settings to specific grade defaults
  setGradeDefaults(grade) {
    switch (grade) {
      case "all":
        this.set("settings", GRADE_ALL);
        break;
      case 1:
        this.set("settings", GRADE_1);
        break;
      case 2:
        this.set("settings", GRADE_2);
        break;
      case 3:
        this.set("settings", GRADE_3);
        break;
      case 4:
        this.set("settings", GRADE_4);
        break;
      case 5:
        this.set("settings", GRADE_5);
        break;
      case 6:
        this.set("settings", GRADE_6);
        break;
    }
  },

  // user apps always have the same config (based on grade) - make sure they are correct
  // note: this is called by the view -after- the model has fully initialized
  setAppDefaults() {
    if (!hasTaxonomy(this.get("task"), "use-type.user-generated")) {
      return;
    }

    const grade = this.get("task").getGrade();
    this.setGradeDefaults(grade);
  },

  // auto-generate solution for jigsaw-type block coding
  autoJigsawModeSolution() {
    this.set("solution-code", {});
    this.get("input")
      .get("code")
      .forEach(block => {
        if (block.get("distractor")) {
          return;
        }

        const clone = block._copy();
        this.get("solution-code").get("code").add(clone);
      });

    this.get("solution-code")
      .getChildBlocks()
      .forEach(block => {
        if (block.get("distractor")) {
          return block._delete();
        }

        block.set({
          "jigsaw-fill": true,
          droppable: false,
          interactive: false,
          jigsaw: false,
          locked: true,
        });
      });
  },

  // build steps always have the same config - make sure they are correct
  // note: this is called by the view -after- the model has fully initialized
  setBuildStepDefaults() {
    if (!hasTaxonomy(this.get("task"), "task-type.build")) {
      return;
    }

    this.get("settings").set(BUILD_STEP);

    // force specific config on each wall block
    const wallBlocks = this.get("snippets").getChildBlocks();
    wallBlocks.forEach(block =>
      block.set({
        locked: block.isNestedInWall(),
        clones: true,
        critical: false,
        editable: true,
      }),
    );
  },

  // stores a snapshot of the current game state
  generateCachedThumbnail(game) {
    this.__CACHED_THUMB = this.thumbnailSnapshot(game);
  },

  // get the cached thumbnail
  getCachedThumbnail() {
    if (this.__CACHED_THUMB) {
      return this.__CACHED_THUMB?.toDataURL();
    }
  },

  // force a new thumbnail to be taken from the current state
  // note: `getCachedThumbnail` is the preferred method to use, only use this
  // method if getCachedThumbnail would return null
  getNewThumbnail() {
    if (this.game) {
      return this.thumbnailSnapshot(this.game)?.toDataURL();
    }
  },

  // takes a snapshot of the game
  thumbnailSnapshot(game) {
    let width = game.canvas.width;
    let height = game.canvas.height;

    if (width === 0 || height === 0) {
      return null;
    }

    // resize if needed
    const resize = Math.max(width / MAX_THUMB_WIDTH, height / MAX_THUMB_HEIGHT);
    if (resize > 1) {
      width /= resize;
      height /= resize;
    }

    // copy game state to cache at the appropriate size
    THUMB_CACHE.width = width;
    THUMB_CACHE.height = height;
    try {
      THUMB_CACHE.getContext("2d").drawImage(
        this.game.canvas,
        0,
        0,
        width,
        height,
      );
    } catch (e) {
      return null;
    }

    return THUMB_CACHE;
  },

  // provides a list of all the assets that need to be preloaded
  // only assets that are on the initial stage state need to be preloaded
  // anything in the browse-objects list can be loaded dynamically as needed
  getPreloadAssets() {
    let keys = [];

    keys.push(...this.get("objects").pluck("phaserKey"));
    keys.push(this.get("background-image"));
    keys.push(this.get("tile-set"));

    // only unique values
    keys = [...new Set(keys)];

    return Promise.all(keys.map(key => this.get("task").getManifestItem(key)));
  },

  // starts the PhaserJS simulation
  async startSimulation() {
    this.listenTo(GAME, "start:Edit", this._prepEdit);
    this.listenTo(GAME, "start:Run", this._prepRun);

    this.listenToOnce(GAME, "state:Run state:Edit", () =>
      this.set("ready", true),
    );

    this.set({
      ready: false,
      running: false,
    });

    await this.get("task").fetchManifest();
    await this._updateTilesetConfig();
    await this.fixStageObjects();

    this.game = GAME.create(this);
  },

  // ends the PhaserJS simulation
  endSimulation() {
    this.stopListening(GAME, "start:Edit", this._prepEdit);
    this.stopListening(GAME, "start:Run", this._prepRun);

    GAME.destroy();
    delete this.game;
  },

  removePracticeStepErrors() {
    const task = this.get("task");
    const isPracticeStep = hasTaxonomy(task, "task-type.experimental");
    if (isPracticeStep) {
      this.getBlocks().forEach(block => {
        block.set("error", false);
        block.set("warning", false);
      });
    }
  },

  _prepEdit() {
    this.getBlocks().forEach(block => block.postRun());
  },

  _prepRun() {
    this.getBlocks().forEach(block => block.preRun());

    this.unpause();
    this.unset("jigsaw-error");

    this.test();
  },

  // de-activates any blocks that were left in their activated state
  _deactivateBlocks() {
    this.get("input")
      .getChildBlocks()
      .concat(this.get("background-code").getChildBlocks())
      .forEach(block => block.deactivate());
  },

  //switches the game to edit mode
  edit() {
    GAME.edit();
  },

  //runs the block code
  run() {
    GAME.run();
  },

  pause: function () {
    GAME.pause();
  },

  unpause: function () {
    GAME.resume();
  },

  // updates config based on the current tileset
  async _updateTilesetConfig() {
    if (!this.has("task")) {
      return;
    }

    const tileset = await this.get("task").getManifestItem(
      this.get("tile-set"),
    );

    if (tileset) {
      this.set({
        "tileset-config": tileset,
        "tile-width": tileset.tileWidth,
        "tile-height": tileset.tileHeight,
      });
    }
  },

  // makes sure all the objects on the stage have the correct data from the manifest
  // they may have incorrect data if the items in the manifest were changed - in that case, the object has to update itself to the latest data
  async fixStageObjects() {
    const promises = this.get("objects").map(async object => {
      // we prioritize finding objects by `phaserKey` but for backwards compatibility we need to fallback to `key`
      const key = object.get("phaserKey") || object.get("key");
      const data = await this.get("task").getManifestItem(key);
      object.fixData(data);
    });

    await Promise.all(promises);
  },

  //hooks for the game to be able to pass or fail the task based on code
  //both are mutually exclusive and whichever one happens first will stick
  pass: function () {
    this.trigger("runtime-success");

    if (this.get("settings").get("freeze-on-success")) {
      this.pause();
    }
  },
  fail: function () {
    this.trigger("runtime-failure");

    if (this.get("settings").get("freeze-on-failure")) {
      this.pause();
    }
  },

  //updates completion of task
  updateCompletion: function () {
    this.get("task").checkCompletion();
  },

  updateFail: function () {
    this.get("task").flagFailedTask();
  },

  //extend test function to fire validation events
  test: function () {
    //reset all tests
    this._getTests().forEach(function (test) {
      test.reset();
    });

    return TestablePrototype.test
      .apply(this)
      .then(this.updateCompletion.bind(this))
      .then(() => {})
      .catch(test => {
        if (
          test.get("type") === "jigsaw-fill" ||
          test.get("type") === "jigsaw-empty"
        ) {
          this.set("jigsaw-error", true);
        }

        this.updateFail();
      });
  },

  tests: _.extend({}, TestablePrototype.tests, {
    // tests for runtime success and failure
    "test-runtime": function () {
      const listener = this.runtimeListener;
      listener.stopListening();

      // returns a promise that will resolve or reject when the game validates at runtime
      return new Promise((resolve, reject) => {
        listener.listenToOnce(this, "runtime-success", function () {
          listener.stopListening();
          resolve();
        });

        listener.listenToOnce(this, "runtime-failure", function () {
          listener.stopListening();
          reject();
        });
      });
    },

    /**
     * @deprecated
     * This test has been deprecated and passes automatically
     * We can't fully remove this code as it's still used by old data
     */
    "interaction-required": function () {
      return Promise.resolve();
    },
  }),
});
