import { View } from "backbone";
import interfaceChannel from "../../channels/interface-channel";
import { translate } from "utils/localisation";
import GAME from "../../../../models/block/game";
import { focusZoom } from "utils/focus-zoom";

import style from "./context-menu.styl";
style.use();

/**
 * This view manages opening/closing/positioning of the context-menu
 */
const contextmenu = View.extend({
  template: require("./context-menu.hbs"),
  tagName: "context-menu",
  className: "context-menu",

  events: {
    "click .item": "itemClick",
    contextmenu: "onContextMenu",
    keydown: "onKeydown",
  },

  initialize() {
    this.$el.attr("dir", translate("dir"));
    this.listenTo(interfaceChannel, "context-menu:open", this.open);
    this.listenTo(interfaceChannel, "close-menu", this.close);
    window.addEventListener("keydown", e =>
      e.code === "Escape" ? this.close() : null,
    );
  },

  /** renders context menu with items */
  render(items) {
    this.$el.html(this.template({ items }));
  },

  /** opens the context-menu next to the selected view */
  async open(event, model) {
    this.$el.addClass("loading");

    this.__items = await model.getContextMenuItems();
    this.__blockView = model.view;
    this.__setOriginalEventPosition(event, model?.view?.el);

    if (!this.__items.filter(i => !i.isSeparator).length) {
      this.$el.removeClass("loading");
      return; // context menu would be empty, abort
    }

    // make sure we're in edit mode
    GAME.edit();

    this.render(this.__items);
    this.$el.removeClass("loading");
    this.$el.addClass("active");

    setTimeout(() => {
      this.position(
        this.__originalEventPosition.x,
        this.__originalEventPosition.y,
        model,
      );
      this.focus();
    }, 0);
  },

  __setOriginalEventPosition(event, el) {
    if (typeof event.pageX === "number" && typeof event.pageY === "number") {
      this.__originalEventPosition = { x: event.pageX, y: event.pageY };
    } else if (el) {
      const bounds = el.getBoundingClientRect();
      this.__originalEventPosition = {
        x: bounds.x + bounds.width / 2,
        y: bounds.y + bounds.height / 2,
      };
    } else {
      this.__originalEventPosition = {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };
    }
  },

  /** closes the context-menu */
  async close() {
    if (this.__blockView) {
      await focusZoom(this.__blockView.el);
      this.__blockView.el.focus();
      this.__blockView = null;
    }
    this.__items = [];
    this.$el.removeClass("active");
  },

  /** positions the context-menu at x*y coords */
  position(x, y, model) {
    let bounds = document.body.getBoundingClientRect();
    const self = this.el.getBoundingClientRect();
    const direction = translate("dir");

    // open to the left when not enough space right
    if (self.width + x > bounds.right) {
      x -= self.width;
    }

    // open above when not enough space below
    if (self.height + y > bounds.bottom) {
      y -= self.height;
    }
    if (!x && !y) {
      bounds = model?.view?.el.getBoundingClientRect();
      x = direction === "ltr" ? bounds.x + bounds.width / 2 : bounds.x;
      y = bounds.y;
    }

    this.el.style.transform = `translate(${x}px, ${y}px)`;
  },

  itemClick(e) {
    const index = Number($(e.target).closest(".item").data("index"));
    this.__items[index].action(this.__originalEventPosition);
    this.close();
  },

  onContextMenu(e) {
    e.preventDefault();
    e.stopPropagation();
  },

  focus(focusDirection = 0) {
    const currentItem = this.__items[this.__focusIndex || 0];
    const items = this.__items.filter(
      item => !item.isSeparator && !item.isDisabled && item.isAvailable,
    );
    let itemIndex = items.findIndex(item => item === currentItem);
    itemIndex = itemIndex + focusDirection;
    const nextItemIndex = this.__items.findIndex(
      item => items[(items.length + itemIndex) % items.length] === item,
    );
    this.$(`.item[data-index=${nextItemIndex}]`)[0]?.focus();
    this.__focusIndex = nextItemIndex;
  },

  onKeydown(e) {
    switch (e.code) {
      case "ArrowDown":
        this.focus(1);
        break;
      case "ArrowUp":
        this.focus(-1);
        break;
      case "Enter":
        this.itemClick(e);
        break;
      case "Escape":
        this.close();
    }
    e.preventDefault();
    e.stopPropagation();
  },
});

export const contextMenu = new contextmenu(); // Singleton View
document.body.appendChild(contextMenu.el);
