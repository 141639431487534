import Backbone from "custom/backbone-bundle";
import checkMobileOS from "utils/check-mobile-os";
import { getApiUrl } from "utils/get-api-url";

const FlagModel = Backbone.Model.extend({
  initialize() {
    this.on("sync", this.extraConditions);
  },

  // apply extra front-end conditions to flags where needed
  extraConditions() {
    this.set(
      "UPLOAD_DOWNLOAD",
      this.get("UPLOAD_DOWNLOAD") && checkMobileOS() !== "iOS",
    );
  },

  url() {
    return getApiUrl("flags");
  },
});

export const flags = new FlagModel();
