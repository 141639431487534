import $ from "jquery";
import Modal from "../modal";
import { ShareUrlPlugin } from "./../shared/share-url";

import { task } from "globals/task";
import { getApiUrl } from "utils/get-api-url";
import { getAbsoluteUrl } from "utils/get-absolute-url";
import { translate, ingestPayloadTranslations } from "utils/localisation";
import { getCodingLanguage } from "utils/get-coding-language";
import { hasTaxonomy } from "utils/taxonomy";
import interfaceChannel from "views/block/channels/interface-channel";

export default Modal.extend(ShareUrlPlugin).extend({
  className: [Modal.prototype.className, "app-info"].join(" "),

  template: require("./app-info.hbs"),
  style: require("./app-info.styl"),

  events: ShareUrlPlugin.events.extend(
    Object.assign(
      {
        "click .row-name .name": "navigatePreviousVersion",
        "keydown a.name": "navigatePreviousVersion",
      },
      Modal.prototype.events,
    ),
  ),

  async render() {
    const name = task.get("name");
    const share_url = `${getAbsoluteUrl(task.get("appUrl").share.path)}`;
    const isShared = !task.get("private");
    const isClone = task.get("isClone");
    const modified_date = task.get("user_modified_date");
    const creation_date =
      task.get("original_creation_date") || task.get("creation_date");

    const thumbnail = await this.fetchThumbnail();

    const previous_versions = await this.fetchPreviousVersions();

    const galleries = await this.fetchGalleriesForApp(task);
    this.$el.html(
      this.template({
        name,
        modified_date,
        creation_date,
        share_url,
        isShared,
        isClone,
        thumbnail,
        previous_versions,
        galleries,
      }),
    );

    this.delegateEvents();
  },

  /**
   * Fetches thumbnail of the app
   */
  async fetchThumbnail() {
    if (hasTaxonomy(task, "task-type.example-app")) {
      return `dist/images/thumb_generic_${getCodingLanguage(task)}.png`;
    } else {
      let thumbnail = task.get("metadata").get("thumbnail");
      thumbnail = thumbnail.saved ? thumbnail.src : null;

      if (!thumbnail) {
        const app = await $.get(getApiUrl(`shared/app/${task.get("id")}`));
        thumbnail = app.metadata.thumbnail.src;
      }

      return thumbnail;
    }
  },

  /**
   * Populate all the relevant information about previous versions of the app
   */
  async fetchPreviousVersions() {
    let previous = task.get("metadata").get("previous_versions");
    previous = await Promise.all(
      previous.map(async app => this.populateAppData(app)),
    );
    previous.forEach((app, i) => (app.index = i + 1));
    previous.reverse();
    return previous;
  },

  /**
   * Populate the data of all the galleries in which a specific app appears
   */
  async fetchGalleriesForApp(task) {
    const galleries = await this.fetchAppGalleryData(task.get("id"));

    return galleries.map(mapGallery => {
      if (
        mapGallery &&
        mapGallery.gallery &&
        mapGallery.gallery.galleryUrl &&
        mapGallery.gallery.galleryUrl.public &&
        mapGallery.gallery.galleryUrl.public.path
      ) {
        mapGallery.gallery.galleryUrl.public.path = `${mapGallery.gallery.galleryUrl.public.path}`;
      }
      return mapGallery;
    });
  },

  /**
   * Populates the data of a previous version with only the neccesary information
   *
   * @param {*} data - the metadata of a previous version
   */
  async populateAppData(data) {
    if (data.upload) {
      return { name: translate("Uploaded App") };
    } else {
      try {
        data = await this.fetchAppData(data.id);
        const isExampleApp = hasTaxonomy(data, "task-type.example-app");
        if (isExampleApp) {
          ingestPayloadTranslations(data);
        }
        const url = getAbsoluteUrl(
          data.private
            ? `${data.appUrl.edit.path}?locale=${translate("locale")}`
            : data.appUrl.share.path,
        );
        const isPublic = window.process.env.SHARED_APPS_ONLY === "true";
        return {
          id: data.id,
          name: isExampleApp ? translate(data.name) : data.name,
          modified_date: data.user_modified_date,
          url: isPublic ? null : url,
        };
      } catch (e) {
        return { name: translate("Deleted App") };
      }
    }
  },

  /**
   * Fetches a user app's data over the network
   *
   * @param {ObjectID} id
   */
  async fetchAppData(id) {
    return $.get(getApiUrl(`shared/app/${id}`));
  },
  /**
   * Fetches the galleries that a user app belongs to
   *
   * @param {ObjectID} id
   */
  async fetchAppGalleryData(id) {
    return $.get(getApiUrl(`shared/app/${id}/galleries`));
  },

  /**
   * Navigates previous version of the app from info panel history
   *
   * @param {Event} e
   */
  async navigatePreviousVersion(e) {
    const previousVersion = $(e.target).closest(".name");

    const id = previousVersion.data("id");
    const url = previousVersion.data("url");
    if (e.code === "Enter" || e.type === "click") {
      interfaceChannel.trigger("proceed-to-user-app", id, url);
    }
  },
});
