import BlockScope from "./../components/block-scope";
import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

/**
 * ASSIGN VARIABLE block
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    args: {},
  }),

  initialize(options) {
    CodeBlock.prototype.initialize.call(this, options);
  },

  blueprint: {
    fn: "assignVariable",
    label: "set {arg1} to {arg2}",
    description: "Set the value of a variable",
    category: "setter",
    args: {
      arguments: [
        {
          argumentSuggestions: [{ type: "variable" }],
        },
        {},
      ],
    },
  },

  async execute(scope) {
    const args = await this.get("args").run(scope);
    return game.exec(this.get("fn"), ...args);
  },

  getGlossaryTaxonomy() {
    return [
      `glossary-type.code-block.variable.assignVariable`,
      `glossary-type.code-block.variable`,
      "glossary-type.code-block",
    ];
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    return list;
  },

  _placementMessage: ERRORS.PLACEMENT_ASSIGN_VAR,
  _validatePlacement(parent) {
    return parent instanceof BlockScope && !parent.get("free-form");
  },
});
