//singleton model to get access to the current instance of the game
import "./phaser-middleware/phaser-bundle";
import Backbone from "custom/backbone-bundle";
import { app } from "app";
import { MAP } from "./phaser-middleware/mapping";
import ERRORS from "./blocks/errors";
import StateMachine from "javascript-state-machine";

window.PhaserGlobal = {
  hideBanner: true, //removes the phaser banner from the console
};

var Game = Backbone.Model.extend({
  defaults: {
    mode: "edit", // `edit` or `run`, controls whether the game will start in run or edit mode (this is must be set BEFORE the setup state)
    "auto-start": true, // determines whether the GAME will immediately go into `run` or `edit` mode after finishing `setup`
  },

  initialize() {
    /**
     * The state machine controlling the game
     * To visualize this, import it into an online GraphVis editor such as:
     * https://dreampuf.github.io/GraphvizOnline
      digraph "fsm" {
        "created";
        "booted";
        "preloaded";
        "ready";
        "running";
        "editing";
        "created" -> "none" [ label=" destroy " ];
        "booted" -> "none" [ label=" destroy " ];
        "preloaded" -> "none" [ label=" destroy " ];
        "ready" -> "none" [ label=" destroy " ];
        "running" -> "none" [ label=" destroy " ];
        "editing" -> "none" [ label=" destroy " ];
        "none" -> "created" [ label=" create " ];
        "created" -> "booted" [ label=" boot " ];
        "booted" -> "preloaded" [ label=" preload " ];
        "editing" -> "preloaded" [ label=" preload " ];
        "running" -> "preloaded" [ label=" preload " ];
        "preloaded" -> "ready" [ label=" setup " ];
        "ready" -> "editing" [ label=" edit " ];
        "ready" -> "running" [ label=" run " ];
      }
     **/
    this.fsm = new StateMachine({
      init: "none",
      transitions: [
        { name: "create", from: "none", to: "created" },
        { name: "boot", from: "created", to: "booted" },
        {
          name: "preload",
          from: ["booted", "editing", "running"],
          to: "preloaded",
        },
        { name: "setup", from: "preloaded", to: "ready" },
        { name: "edit", from: "ready", to: "editing" },
        { name: "run", from: "ready", to: "running" },
        {
          name: "destroy",
          from: [
            "created",
            "booted",
            "preloaded",
            "ready",
            "running",
            "editing",
          ],
          to: "none",
        },
      ],
      methods: {
        onCreate: () => this.__Create(),
        onBoot: () => this.__Boot(),
        onPreload: () => this.__Preload(),
        onSetup: () => this.__Setup(),
        onEdit: () => this.__Edit(),
        onRun: () => this.__Run(),
        onDestroy: () => this.__Destroy(),
      },
    });

    app.expose("GAME", this);
  },

  // creates a new game
  create(model) {
    if (this.fsm.can("create")) {
      this.fsm.create();
    }
    this.game.model = model;

    return this.game;
  },

  // dispose of the game
  destroy() {
    if (this.fsm.can("destroy")) {
      this.fsm.destroy();
    }
  },

  async __Destroy() {
    if (this.game.listener) {
      this.game.listener.destroy();
    }

    this.game.destroy(); //goodbye cruel world
    delete this.game;

    app.unexpose("GAME");
  },

  async __Create() {
    // create the game
    this.game = new Phaser.Game(
      800,
      600,
      Phaser.CANVAS,
      document.createElement("div"),
      null,
      null,
      true,
    );

    // define states
    this.game.state.add(
      "Boot",
      require("./phaser-middleware/states/boot").default,
    );
    this.game.state.add(
      "Preload",
      require("./phaser-middleware/states/preload").default,
    );
    this.game.state.add(
      "Setup",
      require("./phaser-middleware/states/setup").default,
    );
    this.game.state.add(
      "Edit",
      require("./phaser-middleware/states/edit").default,
    );
    this.game.state.add(
      "Run",
      require("./phaser-middleware/states/run").default,
    );

    // listen for changes
    this.game.state.onStateChange.add(this._stateListener, this);

    setTimeout(() => {
      if (this.fsm.can("boot")) {
        this.fsm.boot();
      }
    }, 0);
  },

  /** @private sets the state of the game to Boot */
  async __Boot() {
    if (!this.game || !this.game.state || !this.game.model) {
      return;
    }

    this.trigger("start:Boot");
    await new Promise(r => this.game.state.start("Boot", true, false, r));

    // prevent default touch events on canvas
    this.game.canvas.addEventListener("touchstart", e => e.preventDefault());
    this.game.canvas.addEventListener("touchend", e => e.preventDefault());
    this.game.canvas.addEventListener("touchmove", e => e.preventDefault());
    this.game.canvas.addEventListener("touchcancel", e => e.preventDefault());

    this.trigger("ready:Boot");

    setTimeout(() => {
      if (this.fsm.can("preload")) {
        this.fsm.preload();
      }
    }, 0);
  },

  /** @private sets the state of the game to Preload */
  async __Preload() {
    if (!this.game || !this.game.state || !this.game.model) {
      return;
    }

    if (this.game.state.current === "Preload") {
      return Promise.resolve();
    }

    this.trigger("start:Preload");
    const assets = await this.game.model.getPreloadAssets();
    await new Promise(r =>
      this.game.state.start("Preload", true, false, assets, r),
    );
    this.trigger("ready:Preload");

    setTimeout(() => {
      if (this.fsm.can("setup")) {
        this.fsm.setup();
      }
    }, 0);
  },

  /** @private sets the state of the game to Setup */
  async __Setup() {
    if (!this.game || !this.game.state || !this.game.model) {
      return;
    }

    if (this.game.state.current === "Setup") {
      return Promise.resolve();
    }

    this.game.mode = this.get("mode");

    this.trigger("start:Setup");
    await new Promise(r => this.game.state.start("Setup", true, false, r));
    this.trigger("ready:Setup");

    setTimeout(() => {
      if (this.get("auto-start")) {
        const mode = this.get("mode");
        if (this.fsm.can(mode)) {
          this.fsm[mode]();
        }
      }
    }, 0);
  },

  /** @private sets the state of the game to Edit */
  async __Edit() {
    if (!this.game || !this.game.state || !this.game.model) {
      return;
    }

    if (this.game.state.current === "Edit") {
      return Promise.resolve();
    }
    this.trigger("start:Edit");
    await new Promise(r => this.game.state.start("Edit", false, false, r));
    this.trigger("ready:Edit");
  },

  /** @private sets the state of the game to Run */
  async __Run() {
    if (!this.game || !this.game.state || !this.game.model) {
      return;
    }

    if (this.game.state.current === "Run") {
      return Promise.resolve();
    }
    this.trigger("start:Run");
    await new Promise(r => this.game.state.start("Run", false, false, r));
    this.trigger("ready:Run");
  },

  /**
   * Switch to Run mode
   * if possible switches immediately
   * if not possible, will switch the next time the game exits setup
   */
  run() {
    if (this.fsm.is("running")) {
      return; // already in the correct state
    } else if (this.fsm.can("run")) {
      this.fsm.run();
    } else {
      this.set("mode", "run");
      this.set("auto-start", true);

      if (this.fsm.can("preload")) {
        this.fsm.preload();
      }
    }
  },

  /**
   * Switch to Edit mode
   * if possible switches immediately
   * if not possible, will switch the next time the game exits setup
   */
  edit() {
    if (this.fsm.is("editing")) {
      return; // already in the correct state
    } else if (this.fsm.can("edit")) {
      this.fsm.edit();
    } else {
      this.set("mode", "edit");
      this.set("auto-start", true);

      if (this.fsm.can("preload")) {
        this.fsm.preload();
      }
    }
  },

  /**
   * Restart the game
   * transitions from running to running
   */
  restart() {
    if (this.fsm.can("preload")) {
      this.set("mode", "run");
      this.set("auto-start", false);
      this.fsm.preload();
    }
  },

  pause() {
    if (this.game) {
      this.game.paused = true;
    }
  },

  resume() {
    if (this.game) {
      this.game.paused = false;
    }
  },

  /**
   * Resumes the Audio context if it is suspended
   * This will usually happen on mobile devices
   *
   * Note: MUST be called within a user interaction event
   */
  resumeAudioContext() {
    const game = this.game;

    if (
      game &&
      game.sound &&
      game.sound.usingWebAudio &&
      game.sound.context.state === "suspended"
    ) {
      game.sound.context.resume();
    }
  },

  _stateListener(next) {
    this.trigger(`state:${next}`);
  },

  /**
   * Check whether the game is in a specific state
   */
  isState(name = "") {
    if (!this.game || !this.game.state || !this.game.state.current) {
      return false;
    }

    return this.game.state.current.toLowerCase() === name.toLowerCase();
  },

  /**
   * Promises the game canvas as soon as it's available
   */
  async promiseCanvas() {
    if (!this.game || !this.game.parent) {
      await new Promise(r =>
        this.once("ready:Boot", () => r(this.game.parent)),
      );
    }

    return this.game.parent;
  },

  /**
   * promises the game as soon as it's ready
   * The game is ready once it has entered edit or run mode
   * @async
   */
  async promiseGame() {
    if (this.fsm.is("running") || this.fsm.is("editing")) {
      return this.game;
    }

    return new Promise(r =>
      this.once("state:Run state:Edit", () => r(this.game)),
    );
  },

  /**
   * promises the game as soon as it's set up
   * @async
   */
  async promiseSetup() {
    if (
      this.fsm.is("running") ||
      this.fsm.is("editing") ||
      this.fsm.is("ready")
    ) {
      return this.game;
    }

    return new Promise(r =>
      this.once("state:Run state:Edit state:Setup", () => r(this.game)),
    );
  },

  /**
   * Require Edit mode
   * If the game is in edit mode, executes the function
   * Otherwise switches to edit mode and executes it once the switch is complete
   * @param {Function} fn - The function to execute
   */
  requireEditMode(fn) {
    if (this.isState("Edit")) {
      fn();
    } else {
      this.edit();
      this.listenToOnce(this, "ready:Edit", () => setTimeout(() => fn(), 250));
    }
  },

  /**
   * Runs a middleware function on the game
   *
   * @param {String} method - the name of the method
   * @param {*} scope - information about the scope
   * @param {...any} args - any extra arguments needed by the specific middleware function
   */
  async exec(method, ...args) {
    if (typeof MAP[method] === "function") {
      return MAP[method].call(this.game, ...args);
    } else {
      return Promise.reject(ERRORS.DEPRECATED);
    }
  },
});

// singleton game
export default new Game();
