import {
  translate,
  translateContent,
  translateInline,
  addTerm,
  unTranslate,
  hasTranslation,
  isTranslationKey,
  isContentKey,
  findInlineTranslations,
} from "utils/localisation";
import settings from "globals/settings";
import { topic } from "globals/topic";
import { unit } from "globals/unit";
import { lesson } from "globals/lesson";
import { task } from "globals/task";
import { flags } from "globals/flags";
import { debug } from "globals/debug";
import { glossary } from "globals/glossary";
import { console as blockConsole } from "globals/console";
import { user } from "globals/user";
import {
  actionHistory,
  designActionHistory,
  combinedActionHistory,
} from "globals/action-history";
import { activeLayout } from "globals/active-layout";
import { getUrlParam } from "utils/get-url-param";
import getTaskType from "utils/determine-task-type";
import embedAction from "utils/embed-action";
import interfaceChannel from "views/block/channels/interface-channel";
import { modalManager } from "views/block/ui-elements/modals/modal-manager";
import { track } from "tracking/track";
import { getAbsoluteUrl } from "./utils/get-absolute-url";
import { getApiUrl } from "./utils/get-api-url";
import { getGlobalPlatformFreeCoder } from "utils/get-global-platform-free-coder";
import { printBlocks } from "./utils/print-blocks";
import { preloader } from "views/layouts/preloader/preloader";
import $ from "jquery";
import "jquery-migrate";
import Backbone from "custom/backbone-bundle";
import { router } from "router";
import "views/viewport-warning/viewport-warning";
import { checkFlag } from "utils/flags";
import { createNewApp } from "utils/create-new-app";
import { saveState, resetState } from "utils/state";
import checkWidth from "utils/breakpoints-tracking";
import checkStorage from "utils/check-storage";

// Import icons as spritesheets
import "!svg-sprite-loader!../node_modules/@discoveryedu/comet-core/comet.svg";
import "!svg-sprite-loader!../node_modules/@discoveryedu/nebula-icons/nebula.svg";
var icons = require.context("!svg-sprite-loader!images/icons/", true, /\.svg$/);
icons.keys().forEach(function (key) {
  icons(key);
});

import proximaNova from "comet/fonts/proxima_nova.css";
import { AppURL } from "utils/url";
proximaNova.use();

// adds the current locale to the body as a class
document.body.classList.add(`locale-${translate("locale").toLowerCase()}`);
translate("locale")
  .split(/[_-]/g)
  .forEach(bit => {
    document.body.classList.add(`locale-${bit.toLowerCase()}`);
  });

class App {
  boot() {
    // init
    $(() => {
      $("body").append(modalManager.el);

      // check window width and return breakpoint class name
      checkWidth();

      // handle any route change
      // make sure idp parameter persists
      router.on("preserve-idp", () => {
        const url = Backbone.history.getFragment();
        const paramsIdp = getUrlParam("idp");

        const localIdp = checkStorage()
          ? sessionStorage.getItem("idp")
          : paramsIdp;

        if (paramsIdp) {
          if (localIdp !== paramsIdp) {
            sessionStorage.setItem("idp", paramsIdp);
          }
        } else if (localIdp !== null) {
          router.navigate(new AppURL(url).addQuery("idp", localIdp).href);
        }
      });

      // destroy the current layout
      // this should be called BEFORE changing the current task to prevent needless UI updates
      router.on("request:destroy-layout", () => {
        this.destroyLayout();
      });

      router.on("lesson-redirect", () => {
        // track lesson hit
        track({ type: "lesson" });
      });

      // change which step we're looking at
      router.on("goto:step", async () => {
        // attempt to import free code
        // must have taxonomy use-type.free-code for this to happen
        task.freeCoderAutoImport();

        await this.setLayout();

        App.setTitle(
          topic.get("name"),
          unit.get("name"),
          lesson.get("name"),
          task.getShortName(),
        );

        // switch out of edit mode
        settings.set("editable", false);
        task.start();

        track({ type: "navigation" }); // Track dimensions in Pendo
        // preload the next step to make navigation more seamless
        this.preloadNextStep();
      });

      // new app page layout
      router.on("goto:new", async () => {
        await this.setLayout(await this.chooseLayout("new-app"));
        track({ type: "new" });
      });

      router.on("goto:open", async type => {
        await this.setLayout(await this.chooseLayout("block/open"), false);
        switch (type) {
          // TODO: add support for python / html
          default:
            this.layout.render(
              "Open",
              `${
                window.process.env.WEBSITE_URL
              }/embedded/my-apps?locale=${translate("locale")}`,
            );
            break;
        }
        // TODO: tracking?
      });

      router.on("goto:intro", async type => {
        await this.setLayout(await this.chooseLayout("block/intro"), false);
        switch (type) {
          // TODO: add support for python / html
          default:
            this.layout.render(
              "Start coding",
              `${
                window.process.env.WEBSITE_URL
              }/embedded/intro?locale=${translate("locale")}`,
            );
            break;
        }
        // TODO: tracking?
      });

      // certificate layout
      router.on("goto:certificate", async () => {
        // TODO: re-implement
        // this.setLayout(await this.chooseLayout("certificate")).renderCertificate();
        // App.setTitle(
        //   topic.get("name"),
        //   unit.get("name"),
        //   lesson.get("name"),
        //   "Certificate",
        // );
        // track({ type: "certificate" });
      });

      // shared app
      router.on("goto:shared", async () => {
        await this.setLayout(await this.chooseLayout("block/shared"));
        App.setTitle(task.get("name"));
        track({ type: "navigation" });
      });

      // fork an app
      // creates a copy of the current app, then redirects to the copy
      router.on("fork", async () => {
        this.destroyLayout();
        preloader.complete("layout");
        interfaceChannel.trigger("request:modal", "fork-app");
        track({ type: "fork" });
      });

      // error page
      router.on("goto:error", async error => {
        await new Promise(r => setTimeout(r, 1000));
        await this.setLayout(await this.chooseLayout("error"));
        await preloader.discardAll();
        track({
          type: "error",
          errorData: {
            ...JSON.parse(
              JSON.stringify(error, Object.getOwnPropertyNames(error)),
            ),
            additional_info: {
              ...error.additional_info,
              "request.repsonseJSON": error.responseJSON,
            },
          },
        });

        throw error;
      });

      // navigate from a lesson to the free coder
      interfaceChannel.on("proceed-to-free-coder", () => {
        if (
          embedAction({
            type: "navigate",
            destination: "free-coder",
            language: "block",
          })
        ) {
          return; // we're doing an embedAction, abort the rest of the code
        }

        router.navigate(new AppURL("new/blank/block").href, { trigger: true });
      });

      interfaceChannel.on("proceed-to-user-app", async (id, url, target) => {
        if (
          embedAction({
            type: "navigate",
            destination: target === "_blank" ? "example-app" : "user-app",
            language: "block",
            appId: id,
          })
        ) {
          return; // we're doing an embedAction, abort the rest of the code
        }

        if (checkFlag("EMBEDDED_IN_DE_PLATFORM")) {
          url = new AppURL(`${await getGlobalPlatformFreeCoder("block")}`)
            .addQuery("cid", id)
            .addQuery("locale", translate("locale")).href;

          if (target === "_blank") {
            return window.open(url, "_blank");
          }
          return (window.location = url);
        }

        url = new AppURL(url || getAbsoluteUrl(`u/${id}`)).addQuery(
          "locale",
          translate("locale"),
        ).href;

        if (target === "_blank") {
          return window.open(url);
        }
        return (window.location = url);
      });

      // track embed dimensions
      if (settings.get("embed")) {
        this.trackDimensionContext();
      } else {
        settings.on("change:embed", this.trackDimensionContext);
      }

      //track user dimensions
      if (window.process.env.SHARED_APPS_ONLY !== "true") {
        if (user.fetched) {
          this.trackDimensionUser();
        } else {
          user.once("sync", this.trackDimensionUser);
        }
      }

      // authentication checks
      if (window.process.env.SHARED_APPS_ONLY !== "true") {
        if (user.fetched) {
          this.checkAuthentication();
        } else {
          user.once("sync", this.checkAuthentication);
        }
      }

      // start router
      Backbone.history.start({
        pushState: true,
        // we are grabbing the attribute directly from the element as opposed to getting its value because otherwise we'd get the full path
        root: $("base").attr("href"),
      });

      // global click events
      $(document).on("click", "a[internal]", App.internalLinkHandler);
      $(document).on(
        "click",
        "a[href]:not([internal])",
        App.externalLinkHandler,
      );
      $(document).on(
        "click",
        "span.glossary-term",
        App.glossaryTermClickHandler,
      );
      $(document).on(
        "keydown",
        "span.glossary-term",
        App.glossaryTermClickHandler,
      );
    });

    this.provide();
  }

  trackDimensionContext() {
    track({
      type: "dimensions",
      userContext: settings.get("embed").origin,
    });
  }

  trackDimensionUser() {
    const userData = user.get("user") || {};
    // Track dimensions in general analytics
    track({ type: "dimensions", userId: userData.id });
    track({ type: "dimensions", userRole: user.listRoles().join(",") });

    // Track dimensions in Boomerang
    if (typeof BOOMR !== "undefined" && BOOMR.addVar) {
      BOOMR.addVar("user.id", userData.id);
    }
  }

  checkAuthentication() {
    // This is the API requesting that the user authenticate with an external system
    if (user.get("authNeeded") === true) {
      // Only the API knows what that external system is, so we redirect to the API to start the process
      window.location.replace(
        getAbsoluteUrl(
          getApiUrl(
            `user/authenticate?return_url=${encodeURIComponent(location.href)}`,
          ),
        ),
      );
    }
  }

  // preloads the next step of a lesson
  async preloadNextStep() {
    const next = lesson && lesson.getNextStep();

    if (next) {
      // add a bit of delay for breathing room
      await new Promise(r => setTimeout(r, 1000));
      const result = await next.fetch();
      return result;
    }
  }

  /**
   * Determines which layout to use
   * @return {Backbone.View} returns a constructor for the correct layout
   */
  async chooseLayout(key) {
    this.askForExternalNavigation = false;
    const type = getTaskType(task);
    key = key
      ? key
      : [type.language, type.type]
          .filter(x => !!x)
          .join("/")
          .toLowerCase();

    switch (key) {
      case "block/shared":
        return import(
          /* webpackChunkName: "layout.shared-app" */
          "views/layouts/shared-app"
        );
      case "block/open":
      case "block/intro":
        return import(
          /* webpackChunkName: "layout.iframe" */
          "views/layouts/iframe/iframe"
        );
      case "error":
        return import(
          /* webpackChunkName: "layout.error" */
          "views/layouts/error"
        );
      case "new-app":
        return import(
          /* webpackChunkName: "layout.new-app" */
          "views/layouts/new-app"
        );
      case "splash":
      case "block/normal":
      case "block/advanced":
      case "python/drag and drop":
      case "python/free":
      case "html/drag and drop":
      case "html/free":
      default:
        this.askForExternalNavigation = true;
        return import(
          /* webpackChunkName: "layout.coder" */
          "views/layouts/coder/coder"
        );
    }
  }

  /** set the layout of the page */
  async setLayout(Constructor, render = true) {
    await preloader.reset("layout");

    if (!Constructor) {
      Constructor = await this.chooseLayout();
    }

    if (Constructor.__esModule) {
      Constructor = Constructor.default;
    }

    // destroy layout if there still is one
    this.destroyLayout();

    // create and append layout
    this.layout = new Constructor();
    this.layout.$el.appendTo("body");

    if (render) {
      this.layout.render();
    }

    await preloader.complete("layout");
    return this.layout;
  }

  // destroys the active layout
  destroyLayout() {
    if (this.layout) {
      this.layout.remove();
      this.askForExternalNavigation = false;
    }

    delete this.layout;
  }

  // intercept all clicks on internal links and use pushState
  // without this, clicking on a link would refresh the page and lose what's in memory
  static internalLinkHandler(e) {
    const href = $(e.target).closest("a")[0].href;
    if (e.metaKey) {
      // don't do anything if the user is holding down ctrl or cmd;
      // let the link open up in a new tab
      return;
    } else {
      e.preventDefault();
      window.history.pushState("", "", new AppURL(href).href);
      Backbone.history.checkUrl();
    }
  }

  // handle external link request from iframe
  async embedLinkHandler(link) {
    // external link
    if (/^https?:\/\//.test(link)) {
      window.location.href = new AppURL(getAbsoluteUrl(link)).href;
    } else {
      try {
        const embedMessage = JSON.parse(link);

        if (embedMessage.destination === "free-coder") {
          if (checkFlag("SAVE")) {
            const app = await createNewApp(embedMessage.stageSize);
            return interfaceChannel.trigger(
              "request:modal",
              "save-as",
              app,
              embedMessage.stageSize,
            );
          }

          const url = new AppURL("new/blank/block").addQuery(
            "stageSize",
            embedMessage.stageSize,
          ).href;
          return router.navigate(url, { trigger: true });
        }

        if (embedMessage.destination === "load-from-device") {
          return interfaceChannel.trigger("request:modal", "load-from-device");
        }

        if (embedMessage.destination === "user-app") {
          return interfaceChannel.trigger(
            "proceed-to-user-app",
            embedMessage.appId,
          );
        }

        if (!embedAction(embedMessage)) {
          return router.navigate(new AppURL(embedMessage.fallBackLink).href, {
            trigger: true,
          });
        }
      } catch {
        // internal link should use router
        router.navigate(new AppURL(link).href, { trigger: true });
      }
    }
  }

  // intercept all clicks on external links to instead open a confirmation modal
  static externalLinkHandler(e) {
    if (checkFlag("SAVE_LESSON_STATE_NAVIGATION")) {
      saveState(task);
    }

    const link = e.currentTarget.href;
    const target = $(e.currentTarget).attr("target");
    const noConfirm = Boolean(
      e.currentTarget.attributes["no-confirmation-modal"],
    );

    if (
      e.metaKey ||
      link.match(/\.pdf$/) ||
      app.askForExternalNavigation === false ||
      user.isContentCreator() ||
      target === "_blank" ||
      noConfirm
    ) {
      if (
        embedAction({
          type: "navigate",
          destination: "link",
          url: link,
        })
      ) {
        e.preventDefault();
        e.stopPropagation();
      }
    } else {
      e.preventDefault();
      e.stopPropagation();
      interfaceChannel.trigger("request:modal", "external-link", link);
    }
  }

  // opens a glossary term's definition modal
  static glossaryTermClickHandler(e) {
    if (
      e.type === "keydown" &&
      e.originalEvent.keyCode !== 13 &&
      e.originalEvent.keyCode !== 32
    ) {
      return; // ignore keydown events unless it's Enter / Space
    }

    const id = $(e.currentTarget).attr("data-glossary-term-id");

    if (!id) {
      return;
    }

    const term = glossary.get("glossary-terms").get(id);

    if (!term) {
      return;
    }

    interfaceChannel.trigger("request:modal", "glossary-term", {
      id: term.get("id"),
    });
  }

  // set the page title
  static setTitle(...args) {
    // translate & filter empties
    args = args.map(arg => translate(arg)).filter(arg => Boolean(arg));

    // concat
    const title = [translate("product")].concat(args).join(" - ");

    // set title
    $("title").text(title);
  }

  // expose a key/value
  expose(key, value) {
    if (!window[key]) {
      window[key] = value;
    }
    this[key] = value;
  }

  // remove an exposed value
  unexpose(key) {
    delete window[key];
    delete this[key];
  }

  provide() {
    //some global variables to make debugging easier
    this.expose("settings", settings);
    this.expose("resetState", resetState);
    this.expose("user", user);
    this.expose("router", router);
    this.expose("glossary", glossary);
    this.expose("flags", flags);
    this.expose("debug", debug);
    this.expose("modalManager", modalManager);
    this.expose("console", blockConsole);
    this.expose("actionHistory", actionHistory);
    this.expose("designActionHistory", designActionHistory);
    this.expose("combinedActionHistory", combinedActionHistory);
    this.expose("activeLayout", activeLayout);
    this.expose("printBlocks", printBlocks);
    this.expose("embedGoTo", link => this.embedLinkHandler(link));
    this.expose("preloader", preloader);
    this.expose("localisation", {
      translate,
      translateContent,
      translateInline,
      addTerm,
      unTranslate,
      hasTranslation,
      isTranslationKey,
      isContentKey,
      findInlineTranslations,
    });
    window.coding = this;
  }

  get version() {
    // eslint-disable-next-line no-undef
    return __VERSION__;
  }
}

export const app = new App();
