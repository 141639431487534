import * as validate from "./../utils/validate-inputs";

/**
 * Stops the animation of an object
 *
 * @param {Object} object - The object to stop animating
 */
export default function stropAnimation(object) {
  if (!validate.animated(object)) {
    throw Error(validate.error.command);
  }

  object.animations.stop();
}
