export default {
  type: "html-free",
  layout: "layouts/uk-coding",
  name: "HTML",
  components: [
    Object.assign(
      {},
      JSON.parse(
        JSON.stringify(require("fixtures/components/html-free-editor").default),
      ),
      {
        experimental: true,
      },
    ),
  ],
  taxonomy: {
    language: {
      html: true,
    },
    "use-type": {
      "free-code": true,
    },
  },
};
