"use strict";

const urlElement = document.createElement("a");

/**
 * @param {string} url
 */
export function getAbsoluteUrl(url) {
  urlElement.setAttribute("href", url);
  return urlElement.href;
}
