import { createObject, sortObjects } from "./../creation/create-objects";
import enableEdit, { drawCamera } from "./../creation/enable-edit";
import save from "utils/save";
import { designActionHistory } from "globals/action-history";
import gameChannel from "./../channels/game-channel";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";

// update position and visibility of selection highlight
function updateSelection(target) {
  const game = this.game;
  const selection = target && target.exists && target.visible;

  if (selection) {
    const bounds = target.getBounds();

    game.selection.topLeft.cameraOffset.copyFrom(bounds.topLeft);
    game.selection.topRight.cameraOffset.copyFrom(bounds.topRight);
    game.selection.bottomLeft.cameraOffset.copyFrom(bounds.bottomLeft);
    game.selection.bottomRight.cameraOffset.copyFrom(bounds.bottomRight);
  }

  game.selection.topLeft.visible =
    game.selection.topRight.visible =
    game.selection.bottomLeft.visible =
    game.selection.bottomRight.visible =
      selection;
}

//listen to backbone changes and reflect these on the stage
function bindModelListeners() {
  var game = this.game;

  //object removed
  game.listener.listenTo(game.model.get("objects"), "remove", model => {
    const objects = game.objectGroup.getAll("stageID", model.get("stageID"));
    objects.forEach(object => object.destroy());
    game.listener.stopListening(model);
    save({ silent: true });
    interfaceChannel.trigger("design-change");
    gameChannel.trigger("object-removed", model);
  });

  //object added
  game.listener.listenTo(game.model.get("objects"), "add", function (model) {
    var sprite = createObject(game, model);
    enableEdit.call(game, [sprite]);
    sortObjects.call(game);
    save({ silent: true });
    interfaceChannel.trigger("design-change");
    gameChannel.trigger("object-added", model);
  });

  game.listener.listenTo(
    interfaceChannel,
    "history:tiles-change",
    function (action) {
      const fromTiles = action.from.tiles;
      const toTiles = action.to.tiles;
      const tilesLength = game.map.width * game.map.height;

      for (let i = 0; i < tilesLength; i++) {
        const x = i % game.map.width;
        const y = Math.floor(i / game.map.width);
        const fromTile = fromTiles[i];
        const toTile = toTiles[i];

        if (fromTile !== toTile) {
          if (toTile === undefined) {
            game.map.removeTile(x, y, "backgroundTiles");
          } else {
            game.map.putTile(toTile, x, y, "backgroundTiles");
          }
        }
      }
    },
  );
}

// paint a single tile
function paintTile(x, y, color) {
  var game = this.game;
  var width = game.model.get("tile-width");
  var height = game.model.get("tile-height");

  game.map.removeTileWorldXY(x, y, width, height, "backgroundTiles");
  var tile = game.map.putTileWorldXY(
    color,
    x,
    y,
    width,
    height,
    "backgroundTiles",
  );

  if (!tile) {
    return;
  }

  var index = tile.y * game.map.width + tile.x;
  game.model.get("tiles")[index] = color;
  save({ silent: true });
  interfaceChannel.trigger("design-change");
}

// paint all tiles on a line between two points
function paintTiles(from, to, color) {
  // adjust for camera position
  from.x += this.game.camera.x;
  from.y += this.game.camera.y;
  to.x += this.game.camera.x;
  to.y += this.game.camera.y;

  const ray = new Phaser.Line(from.x, from.y, to.x, to.y);

  this.game.backgroundTilesLayer
    .getRayCastTiles(ray)
    .forEach(tile => paintTile.call(this, tile.worldX, tile.worldY, color));
}

function panCamera(from, to) {
  let x = this.game.camera.position.x;
  let y = this.game.camera.position.y;

  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    x += to.x - from.x;
    y += to.y - from.y;
  } else {
    const scale = this.game.camera.scale.x;
    x += (to.x - from.x) / scale;
    y += (to.y - from.y) / scale;
  }

  this.game.camera.x = x;
  this.game.camera.y = y;
}

function onInputDown(handler, pointer) {
  if (!this.previousTiles) {
    this.previousTiles = [...this.game.model.get("tiles")];
  }
  if (this.game.drawColor === -1) {
    gameChannel.trigger("object-selected", null);
    gameChannel.trigger("inputDown-empty");
  } else {
    paintTile.call(
      this,
      pointer.x + this.game.camera.x,
      pointer.y + this.game.camera.y,
      this.game.drawColor,
    );
  }

  // keep track of previous pointer position
  this.game.previousPointerPosition = new Phaser.Point(pointer.x, pointer.y);
}

function onInputUp() {
  delete this.game.previousPointerPosition;
  if (this.previousTiles) {
    const actionItem = {
      designModel: this.game.model,
      type: "design-change",
      from: { tiles: this.previousTiles },
      to: { tiles: [...this.game.model.get("tiles")] },
      trigger: "history:tiles-change",
    };

    designActionHistory.addItem(actionItem);
  }
  this.previousTiles = null;
}

function onInputMove(pointer, x, y) {
  const game = this.game;
  let color = game.drawColor;

  if (pointer.isDown && game.previousPointerPosition) {
    if (color !== -1) {
      // if we are drawing, attempt to paint all lines between the previous and current mouse position
      // this provides a smoother experience where it won't skip tiles if the user is drawing quickly
      paintTiles.call(this, { x, y }, game.previousPointerPosition, color);
    } else if (checkFlag("APP_DESIGN_ZOOM") && !game.model.get("is-pinching")) {
      panCamera.call(this, { x, y }, game.previousPointerPosition);
    }

    // keep track of previous pointer position
    game.previousPointerPosition = new Phaser.Point(x, y);
  }
}

function selectObject(model) {
  if (!model) {
    this.selected = null;
    return;
  }
  // when zoomed in, focus on selected object
  this.camera.x = model.get("x") - model.get("width");
  this.camera.y = model.get("y") - model.get("height");

  this.drawColor = -1;
  this.selected = this.objectGroup.getAll("stageID", model.get("stageID"))[0];
}

function updateRelativeCameraObjects(e, i, options) {
  if (options.isSystemChange) {
    return;
  }
  const MAP = {
    "camera-x": "x",
    "camera-y": "y",
  };
  Object.keys(e.changed).forEach(key => {
    const prev = e.previous(key);
    const diff = i - prev;

    this.model
      .get("objects")
      .filter(obj => obj.get("fixedToCamera"))
      .forEach(obj => {
        obj.set(MAP[key], obj.get(MAP[key]) + diff);
      });
  });
}

const edit = function () {};

edit.prototype.init = function (callback) {
  this.game.callback = callback; //we set the callback here, but it will only be resolved once the next state is ready
};

// initial setup and object/code creation
edit.prototype.create = function () {
  const game = this.game;

  game.input.keyboard.clearCaptures();

  // create camera prop
  if (checkFlag("APP_DESIGN_ZOOM_ENHANCEMENTS")) {
    game.camera_prop_bmd = game.add.bitmapData(
      game.model.get("width") * game.model.get("tile-width"),
      game.model.get("height") * game.model.get("tile-height"),
    );
  } else {
    game.camera_prop_bmd = game.add.bitmapData(game.width, game.height);
  }
  game.camera_prop = game.camera_prop_bmd.addToWorld();

  enableEdit.call(game, game.objectGroup.children);

  // create our selection highlight
  game.selection = {
    topLeft: game.add.sprite(0, 0, "UI:cursor", 0),
    topRight: game.add.sprite(0, 0, "UI:cursor", 1),
    bottomLeft: game.add.sprite(0, 0, "UI:cursor", 2),
    bottomRight: game.add.sprite(0, 0, "UI:cursor", 3),
  };
  game.selection.topLeft.anchor.setTo(0, 0);
  game.selection.topRight.anchor.setTo(1, 0);
  game.selection.bottomLeft.anchor.setTo(0, 1);
  game.selection.bottomRight.anchor.setTo(1, 1);
  game.selection.topLeft.fixedToCamera = true;
  game.selection.topRight.fixedToCamera = true;
  game.selection.bottomLeft.fixedToCamera = true;
  game.selection.bottomRight.fixedToCamera = true;
  game.selected = null;

  game.drawColor = -1;

  bindModelListeners.call(this); // listen for model changes

  game.listener.listenTo(
    gameChannel,
    "background:inputDown",
    onInputDown.bind(this),
  );
  game.input.onUp.add(onInputUp, this);
  game.input.addMoveCallback(onInputMove, this);

  game.listener.listenTo(
    gameChannel,
    "object-selected",
    selectObject.bind(game),
  );

  game.listener.listenTo(
    game.model,
    "change:camera-x change:camera-y",
    updateRelativeCameraObjects.bind(game),
  );

  const callback = game.callback;
  delete game.callback;
  if (typeof callback === "function") {
    callback();
  }
};

//main game-loop
edit.prototype.update = function () {
  const game = this.game;

  drawCamera(game.camera_prop_bmd);

  if (game.drawColor !== -1 && game.selected) {
    gameChannel.trigger("object-selected", null);
  }

  updateSelection.call(this, game.selected);

  if (game.pixelCoordinatesX) {
    game.pixelCoordinatesX.cameraOffset.x = -game.camera.x;
  }
  if (game.pixelCoordinatesY) {
    game.pixelCoordinatesY.cameraOffset.y = -game.camera.y;
  }
  if (game.tileCoordinatesX) {
    game.tileCoordinatesX.cameraOffset.x = -game.camera.x;
  }
  if (game.tileCoordinatesY) {
    game.tileCoordinatesY.cameraOffset.y = -game.camera.y;
  }
};

export default edit;
