import CodeBlock from "./../code-block";
import ERRORS from "./../errors";
import game from "../../game";
import _ from "underscore";

//load all blueprints from folder
const BLUEPRINTS = {};

let context = require.context("./blueprints/", true, /\.json$/);
context.keys().forEach(key => {
  const blueprint = context(key);

  BLUEPRINTS[blueprint.fn] = blueprint;
});
context = require.context("./blueprints/", true, /\.js$/);
context.keys().forEach(key => {
  const blueprint = context(key);

  BLUEPRINTS[blueprint.default.fn] = blueprint.default;
});

/**
 * COMMAND block
 * A command blocks always target an object block and calls a method from the PhaserJS middleware
 *
 * Chainable blocks return the current scope after executing, allowing them to be chained
 * Non-chainable blocks will return whatever value the middleware does, these are generally get-methods
 */
export default CodeBlock.extend({
  relations: CodeBlock.prototype.relations.concat([
    CodeBlock.prototype.createRelation("arguments", "args"),
  ]),

  defaults: _.extend({}, CodeBlock.prototype.defaults, {
    label: null,
    args: {},
    fn: null, //the middleware function
    chainable: false, //is this a chainable block?
    input: "object", //determines the shape of the head of the block, > or (
    output: "object", //determines the shape of the tail of the block, > or )
  }),

  initialize(options) {
    this.blueprint = BLUEPRINTS[this.get("fn")];
    CodeBlock.prototype.initialize.call(this, options);

    if (!this.get("chainable")) {
      this.set("output", "primitive");
    }
  },

  getPseudoCode() {
    const args = this.get("args").getPseudoCode();
    return `.${this.get("fn")}${args}`;
  },

  getChildBlocks(list = []) {
    CodeBlock.prototype.getChildBlocks.call(this, list);
    this.get("args").getChildBlocks(list);
    return list;
  },

  async execute(scope, object) {
    // compute args
    const args = await this.get("args").compute(scope);

    // run command
    const result = await game.exec(this.get("fn"), object, ...args);

    // if the command is chainable, return the object
    if (this.get("chainable")) {
      return object;
    }

    return result;
  },

  _placementMessage: ERRORS.PLACEMENT_COMMAND,
  _validatePlacement(parent) {
    return parent.get("object-scope");
  },
});

export { BLUEPRINTS };
