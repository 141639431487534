import { app } from "app";
import Lesson from "models/lesson";
let lesson = new Lesson();

export default lesson;
export { lesson };

export async function setCurrentLesson(id) {
  if (!id || id === "-") {
    return lesson.clear();
  }

  if (id === lesson.get("id")) {
    return;
  }

  // flag current lesson as not being active
  lesson.set("active", false);

  // Use backbone relational to find or create new lesson
  // Then map this to the exported lesson variable
  // if a lesson with said ID has already been created, backbone relational will restore it, otherwise a new lesson will be instantiated
  lesson = Lesson.findOrCreate({ id });

  // flag new lesson as being active
  lesson.set("active", true);

  await lesson.fetch();
  app.expose("lesson", lesson);
}
