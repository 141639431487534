// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/bg_body.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewport-warning{position:absolute;top:0;left:0;width:100vw;height:100vh;z-index:1060;background-color:#354967;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;color:#fff;display:flex;flex-direction:column;align-items:center;justify-content:center}.viewport-warning > *{margin:16px;font-size:20px;font-weight:bold}.viewport-warning > p{max-width:290px;text-align:center}.viewport-warning:not(.visible){display:none}", "",{"version":3,"sources":["webpack://./src/views/viewport-warning/viewport-warning.styl"],"names":[],"mappings":"AAEA,kBACE,iBAAU,CACV,KAAK,CACL,MAAM,CACN,WAAO,CACP,YAAQ,CACR,YAAS,CACT,wBAAkB,CAClB,wDAAgD,CAChD,uBAAqB,CACrB,UAAO,CACP,YAAS,CACT,qBAAgB,CAChB,kBAAa,CACb,sBAAiB,CAEjB,sBACE,WAAQ,CACR,cAAW,CACX,gBAAa,CAEf,sBACE,eAAW,CACX,iBAAY,CAEd,gCACE,YAAS","sourcesContent":["@import \"../../stylesheets/coding-3/variables.styl\"\n\n.viewport-warning\n  position: absolute\n  top: 0\n  left: 0\n  width: 100vw\n  height: 100vh\n  z-index: 1060\n  background-color: $coding-color-browse-toolbar\n  background-image: url('../../images/bg_body.png')\n  background-position: 50%\n  color: $coding-color-text-light\n  display: flex\n  flex-direction: column\n  align-items: center\n  justify-content: center\n\n  > *\n    margin: $coding-space-large\n    font-size: $coding-font-size-large\n    font-weight: bold\n\n  > p\n    max-width: 290px\n    text-align: center\n\n  &:not(.visible)\n    display: none\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
