import $ from "jquery";
import { View } from "backbone";
import { translate } from "utils/localisation";
import style from "./help-window.styl";
import interact from "interactjs";
import "slick-carousel";
import slickCarousel from "slick-carousel/slick/slick.css";
import slickTheme from "slick-carousel/slick/slick-theme.css";
import ReadSpeaker from "views/readspeaker";
import VideoStyle from "video.js/dist/video-js.min.css";
import { checkFlag } from "utils/flags";
import "custom/baps";

style.use();
slickCarousel.use();
slickTheme.use();
VideoStyle.use();

/**
 * This view manages opening/closing/positioning of the toodal
 */
const HelpWindow = View.extend({
  template: require("./help-window.hbs"),
  className: "help-window",

  events: {
    "click .dismiss": "close",
    "click .audio": "readHelp",
  },

  initialize() {
    this.$el.attr("dir", translate("dir"));
    this.x = -1;
    this.y = -1;
    this.activeVideos = [];
    this.activeCarousel = null;
    this.readers = [];

    interact(this.el).draggable({
      allowFrom: ".carousel-header h1",
      onmove: e => this._dragging(e),
      modifiers: [interact.modifiers.restrictRect({ restriction: "parent" })],
    });
    $(window).on("resize", e => this._windowResize(e));
  },

  readHelp(e) {
    const index = $(e.target).closest(".carousel-text").data("index");
    ReadSpeaker.remove();
    this._pauseVideo();
    this.$(`.readspeaker-placeholder[data-index=${index}]`).removeClass("init");
    return this.readers[index].read();
  },
  /**
   * Render
   * @param {Backbone.Model[]} terms A list of Glossary term backbone models
   */
  render(terms) {
    const isReadSpeakerAvailable =
      // eslint-disable-next-line no-undef
      baps.browser !== "IE" && ReadSpeaker.available;

    terms.forEach(term => {
      if (term.attributes.media.asset) {
        if (term.attributes.media.asset.type === "image") {
          term.attributes.media.asset.isImage = true;
        }
        if (term.attributes.media.asset.type === "video") {
          term.attributes.media.asset.isVideo = true;
        }
      }
    });

    this.$el.html(
      this.template({
        isReadSpeakerAvailable,
        terms: terms.map(term => term.toJSON()),
      }),
    );

    if (isReadSpeakerAvailable) {
      const ignore = [
        ...this.$(".audio__btn"),
        ...this.$("img"),
        ...this.$("video"),
      ];
      terms.forEach((term, i) => {
        if (ReadSpeaker.available) {
          this.readers[i] = new ReadSpeaker(
            this.$(".audio")[i],
            this.$(`.readspeaker-placeholder[data-index=${i}]`)[0],
          );
        }
      });
      ReadSpeaker.ignore(ignore);
    }

    this._initializeVideo();

    this._initializeCarousel();

    return terms;
  },

  /**
   * Opens the help window with a list of terms
   * @param {Backbone.Model[]} terms A list of Glossary term backbone models
   */
  async open(terms) {
    // safely close if there is an active help window
    if (this.$el.hasClass("active")) {
      this.close();
    }
    this.$el.addClass("active");
    this.render(terms);

    // center position if opening for the first time
    if (this.x === -1 || this.y === -1) {
      const bounds = document.body.getBoundingClientRect();
      const self = this.el.getBoundingClientRect();

      this.x = bounds.width / 2 - self.width / 2;
      this.y = bounds.height / 2 - self.height / 2;
      this._updatePosition();
    }
  },

  /** closes the help window */
  close() {
    this._stopVideo();
    this.removeReadSpeaker();
    this._removeCarousel();
    this.$el.removeClass("active");
  },

  /** updates the position of this window based on user drag */
  _dragging(e) {
    this.x += e.dx;
    this.y += e.dy;
    this._updatePosition();
  },

  /** prevents the window going off screen when the browser is resized */
  _windowResize() {
    const bounds = document.body.getBoundingClientRect();
    const self = this.el.getBoundingClientRect();

    if (self.x + self.width > bounds.width) {
      this.x = bounds.width - self.width;
    }
    if (self.y + self.height > bounds.height) {
      this.y = bounds.height - self.height;
    }

    this._updatePosition();
  },

  /** applies position styles */
  _updatePosition() {
    this.el.style.transform = `translate(${this.x}px, ${this.y}px)`;
  },

  _removeCarousel() {
    if (this.activeCarousel) {
      this.activeCarousel.slick("unslick"); //delete the old carousel before creating a new one
      this.activeCarousel = null;
    }
  },

  _stopVideo() {
    if (this.activeVideos) {
      this.activeVideos.forEach(video => video.dispose());
      this.activeVideos = [];
    }
  },

  _pauseVideo() {
    if (this.activeVideos) {
      this.activeVideos.forEach(video => video.pause());
    }
  },

  _initializeVideo() {
    this._stopVideo();
    const videojs = require("video.js").default;
    this.activeVideos = this.$(".video-js")
      .toArray()
      .map(v => {
        return videojs(v, {
          controlBar: {
            fullscreenToggle: checkFlag("FULLSCREEN_TOGGLE"),
            pictureInPictureToggle: false,
          },
        });
      });
  },

  _initializeCarousel() {
    this._removeCarousel();

    const config = {
      dots: true,
      infinite: false,
      arrows: true,
      slidesToShow: 1,
      adaptiveHeight: true,
      appendArrows: this.$(".nav-arrows"),
      appendDots: this.$(".slider-progress"),
      draggable: false,
      swipe: false,
      prevArrow:
        "<button type='button' class='prev-arrow'><svg class='item__icon' aria-labelledby='prev-arrow'> <title></title><use xlink:href='#comet_caret-left'></use></svg></button>",
      nextArrow:
        "<button type='button' class='next-arrow'><svg class='item__icon' aria-labelledby='next-arrow'> <title></title><use xlink:href='#comet_caret-right'></use></svg></button>",
    };

    if (this.el.dir === "ltr") {
      this.activeCarousel = this.$(".carousel-container").slick(config);
    } else {
      this.activeCarousel = this.$(".carousel-container").slick(
        Object.assign(config, {
          rtl: true,
          prevArrow:
            "<button type='button' class='prev-arrow'><svg class='item__icon' aria-labelledby='prev-arrow'> <title></title><use xlink:href='#comet_caret-right'></use></svg></button>",
          nextArrow:
            "<button type='button' class='next-arrow'><svg class='item__icon' aria-labelledby='next-arrow'> <title></title><use xlink:href='#comet_caret-left'></use></svg></button>",
        }),
      );
    }

    this.activeCarousel.on(
      "beforeChange",
      function () {
        this.$(`.readspeaker-placeholder`).addClass("init");
        ReadSpeaker.remove();
        this._pauseVideo();
      }.bind(this),
    );
  },
  removeReadSpeaker() {
    this.readers = [];
    ReadSpeaker.remove();
  },
});

export const helpWindow = new HelpWindow(); // Singleton View
document.body.appendChild(helpWindow.el);
