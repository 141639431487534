export default Object.assign(
  JSON.parse(
    JSON.stringify(require("models/block/blocks/command/blueprints/get.json")),
  ), //make sure all the values are deeply cloned
  {
    category: "get",
    args: {
      arguments: [
        {
          code: {
            type: "string",
            value: "",
            title: "property",
            "hard-lock": true,
            editable: true,
          },
        },
      ],
    },
  },
);
