import * as validate from "./../utils/validate-inputs";

export default function onClick(callback, object) {
  // apply event to all objects if more than one is passed to this function
  if (Array.isArray(object)) {
    return Promise.all(object.map(object => onClick(callback, object)));
  }

  if (validate.physical(object)) {
    // clicking on objects
    if (object.clickTarget) {
      object = object.clickTarget;
    }

    object.inputEnabled = true;
    object.input.useHandCursor = true;
    object.events.onInputDown.add(() => callback([object]));
  } else if (validate.app(object)) {
    // clicking on the stage
    object.input.onTap.add(() => callback());
  } else {
    throw Error(validate.error.physical);
  }
}
