import Modal from "../modal";
import { router } from "router";

import { clearHistory } from "globals/action-history";

import embedAction from "utils/embed-action";
import { AppURL } from "utils/url";
import translate from "utils/localisation";

export default Modal.extend({
  className: [Modal.prototype.className, "goto-my-apps"].join(" "),

  template: require("./goto-my-apps.hbs"),

  events: Object.assign(
    { "click .goto-my-apps-confirmed": "goToMyApps" },
    Modal.prototype.events,
  ),

  async goToMyApps() {
    await this.close();
    clearHistory();

    if (!embedAction({ type: "navigate", destination: "my-apps" })) {
      router.navigate(
        new AppURL("/open/block").addQuery("locale", translate("locale")).href,
        { trigger: true },
      );
    }
  },
});
