export default function Sound(game, model) {
  Phaser.Sprite.call(this, game, 0, 0, "UI:sound");
  this.visible = false;

  // check to see if the sound has already been added the game
  const [audio] = game.sound._sounds.filter(
    sound => sound.key === model.get("phaserKey"),
  );
  // adds the audio to the sprite object. Uses existing sound in game or adds new one
  this.audio = audio ? audio : game.add.sound(model.get("phaserKey"));
}

Sound.prototype = Object.create(Phaser.Sprite.prototype);
Sound.prototype.constructor = Sound;
