import Component from "views/prototypes/component";
import ParentView from "views/prototypes/parent-view";
import settings from "globals/settings";
import { console as blockConsole } from "globals/console";
import translate from "utils/localisation";
import interfaceChannel from "views/block/channels/interface-channel";
import { checkFlag } from "utils/flags";
import { task } from "globals/task";

export default Component.extend(ParentView.prototype).extend({
  className: "super-block",

  initialize() {
    ParentView.prototype.initialize.call(this);

    // create a 1-to-1 relationship between this block instance and its view
    this.el.model = this.model;
    this.model.view = this;

    this.$el.attr("dir", translate("dir"));
    this.$el.attr("aria-label", translate(this.model.get("type")));

    this.defineChildView(
      function () {
        const CodeBlock = require("./code-block").default;
        return CodeBlock(this.model.get("jigsaw-fill"));
      }.bind(this),
      "jigsaw-fill",
      "> jigsaw-fill-container",
    );

    this.listenTo(
      this.model,
      "change:locked change:used change:jigsaw change:distractor change:jigsaw-fill",
      this.render,
    );

    this.listenTo(
      this.model.get("position"),
      "change:x change:y",
      this._updatePosition,
    );

    // these things cause all blocks to be come locked - so listen for them
    this.listenTo(task, "change:completed", this.render);
    this.listenTo(settings, "change:editable", this.render);
    const blockCoder = task.getComponent("models/block");
    if (blockCoder) {
      this.listenTo(
        blockCoder.get("settings"),
        "change:edit-code",
        this.render,
      );
    }

    this.render();

    this.reflectSetting(this.model, "clones", "clones");
    this.reflectSetting(this.model, "error", "error");
    this.reflectSetting(this.model, "warning", "warning");
    this.reflectSetting(this.model, "active", "active");
    this.reflectSetting(this.model, "used", "used");
    this.reflectSetting(this.model, "jigsaw", "jigsaw");
    this.reflectSetting(this.model, "jigsaw-clone", "jigsaw-clone");
    this.reflectSetting(this.model, "jigsaw-fill", "jigsaw-filled");
    this.reflectSetting(this.model, "distractor", "distractor");
    this.reflectSetting(this.model, "distractor-clone", "distractor-clone");
    this.reflectSetting(this.model, "highlight", "highlight");
    this.reflectSetting(this.model, "duplicate", "duplicate");
    this.reflectSetting(this.model, "selected", "selected");
    this.reflectSetting(this.model, "top-level-only", "top-level-only");
    this.reflectSetting(this.model, "interactive", "interactive");
    this.reflectSetting(settings, "editable", "editing");

    // we bind these events in the View instead of the Model because we only
    // need to do this when it was triggered by the user (as opposed to when
    // Backbone loads)
    this.listenTo(this.model, "change:jigsaw", () => this.model.updateJigsaw());
    this.listenTo(this.model, "change:distractor", () =>
      this.model.updateDistractor(),
    );
  },

  getRenderData() {
    return {
      model: this.model.toJSON(),
      keywords: [
        translate(this.model.get("category")),
        translate(this.model.get("type")),
      ],
      icon: this.model.get("icon"),
      connectLeft: this.model.get("input") === "object",
      connectRight: this.model.get("output") === "object",
      isSprite:
        this.model.get("target") && this.model.get("target").type === "sprite",
      isEditable: this.model.isEditable(),
      isSound: this.model.isSound(),
      soundName: translate(this.model.get("name")),
    };
  },

  async render() {
    await new Promise(r => requestAnimationFrame(r));

    const data = await this.getRenderData();

    this.detachChildren();
    this.$el.html(this.template(data));
    this.attachChildren();

    this._tagPartials("interactive", this.model.get("interactive"));

    this._updateClickableState();
    this._updateDraggableState();
    this._updateDroppableState();
    this._updateVisibleState();
    this._updatePosition();
    this._updateFocusableState();
    blockConsole.fix(this.model);
  },

  _tagPartials(className, on) {
    this.$("> .block-section > .block-partial, > .block-partial").toggleClass(
      className,
      Boolean(on),
    );
  },

  _isEmptyJigsaw() {
    return Boolean(this.model.get("jigsaw") && !this.model.has("jigsaw-fill"));
  },

  _updateClickableState() {
    const isEditable =
      this.model.isEditable() ||
      (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE") && this._isEmptyJigsaw());
    this._tagPartials("clickable", isEditable);
    this.$("> object-scope").removeClass("clickable");
  },

  _updateDraggableState() {
    const isDraggable = this.isDraggable();
    this.$el.toggleClass("locked", !isDraggable);
    this._tagPartials("draggable", isDraggable);
  },

  _updateDroppableState() {
    this.$el.toggleClass("droppable", this._isEmptyJigsaw());
  },

  _updateVisibleState() {
    this.$el.toggleClass("hidden", this.isHidden());
  },

  _updateFocusableState() {
    if (this.model.get("interactive")) {
      this.el.tabIndex = 0;
    } else {
      this.el.tabIndex = -1;
    }
  },

  // determines whether this block can be dragged
  isDraggable() {
    return (
      !this.isLocked() &&
      !this.isJigsawPlaceholder() &&
      !this.model.get("used") &&
      !this.model.isNestedInWall()
    );
  },

  // determine whether this block is visible to the user
  isHidden() {
    return Boolean(
      this.model.get("distractor") &&
        !this.model.get("distractor-clone") &&
        !settings.get("editable"),
    );
  },

  // a placeholder block is a greyed out block that expects the user to drag a
  // block onto it, they cannot be interacted with directly
  isJigsawPlaceholder() {
    return (
      (this.model.get("jigsaw") && !this.model.get("jigsaw-clone")) ||
      (this.model.get("distractor") && !this.model.get("distractor-clone"))
    );
  },

  isJigsawPiece() {
    return this.model.get("jigsaw") && this.model.get("jigsaw-clone");
  },

  isDistractorPiece() {
    return this.model.get("distractor") && this.model.get("distractor-clone");
  },

  // determine whether this block is locked
  // a locked block cannot be dragged
  isLocked() {
    if (this.model.get("hard-lock")) {
      return true;
    }

    const blockCoder =
      task && task.get("components").findWhere({ model: "models/block" });
    const jigsawMode =
      blockCoder && blockCoder.get("interaction-mode") === "jigsaw";

    // lock all blocks if a task is locked
    if (task && task.get("locked")) {
      return true;
    }

    // enable all blocks while editing
    if (settings.get("editable")) {
      return false;
    }

    // lock all blocks to a user if they can't edit the code
    if (blockCoder && !blockCoder.get("settings").get("edit-code")) {
      return true;
    }

    // these can always be dragged
    if (this.isDistractorPiece() || this.isJigsawPiece()) {
      return false;
    }

    if (
      this.model.isInCanvas() &&
      jigsawMode &&
      !this.model.get("jigsaw-clone") &&
      !this.model.get("distractor-clone")
    ) {
      return true;
    }

    return this.model.get("locked");
  },

  _updatePosition() {
    let x = 0;
    let y = 0;
    let scale = 1;
    let rotation = 0;

    const parent = this.model.getParent();

    if (parent && parent.get("free-form")) {
      x = this.model.get("position").get("x");
      y = this.model.get("position").get("y");
      rotation = this.model.get("invalid-rotation");
    }

    this.$el.toggleClass("invalid-position", Boolean(rotation));

    this._setTransform(x, y, scale, rotation);
  },

  _setTransform(x = 0, y = 0, s = 1, rot = 0) {
    x = Math.floor(x);
    y = Math.floor(y);

    rot = Math.floor(rot);

    if (translate("dir") === "rtl") {
      x = -x;
    }

    this.el.style.webkitTransform = this.el.style.transform = `translate3d(${x}px, ${y}px, 0px) scale(${s}) rotate(${rot}deg)`;
  },

  remove() {
    delete this.el.model;

    ParentView.prototype.remove.call(this);
    Component.prototype.remove.call(this);
  },

  /**
   * Click handler for this block
   * This function is invoked by the `block-interaction-zone`
   * Individual block types may implement this method
   */
  onClick() {
    if (!this.model.isInWall()) {
      if (checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE")) {
        if (this._isEmptyJigsaw()) {
          interfaceChannel.trigger("insert-code:open", this.model);
        }
      }
    }
  },

  /**
   * Context menu handler for this block
   * This function is invoked by the `block-interaction-zone`
   */
  async onContextMenu(e) {
    if (!checkFlag("BLOCK_CONTEXT_MENU")) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    interfaceChannel.trigger("context-menu:open", e, this.model);
  },
});
