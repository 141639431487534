var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "  <img src=\"dist/images/icon_maximise_browser.png\">\n  <p>"
    + container.escapeExpression(__default(require("../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Please maximise your browser window to get the best experience.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":5},"end":{"line":5,"column":84}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <img src=\"dist/images/icon_rotate_device.png\">\n  <p>"
    + container.escapeExpression(__default(require("../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Please rotate your device to get the best experience.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":5},"end":{"line":9,"column":74}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <p>"
    + container.escapeExpression(__default(require("../../templates/helpers/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"Your device is too small. To get the best experience please use a larger device.",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":5},"end":{"line":12,"column":101}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\"dist/images/de_coding_logo_navbar.png\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resize") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"rotate") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":10,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noSupport") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":13,"column":7}}})) != null ? stack1 : "");
},"useData":true});