import ERRORS from "./../../blocks/errors";
import * as validate from "./../utils/validate-inputs";
import { string } from "../types/string";

/**
 * Changes the rotation style of an object
 *
 *
 * @param {Object} object - The object whose roations tyle to change
 * @param {'no-rotate'|'left-right'|'direction'} label - The rotation style to apply
 */
export default function rotationStyle(object, label) {
  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  label = string(label);

  switch (label) {
    case "no-rotate":
      object["align-to-direction"] = false;
      break;
    case "left-right":
      object["is-topdown"] = false;
      object["align-to-direction"] = true;
      break;
    case "direction":
      object["is-topdown"] = true;
      object["align-to-direction"] = true;
      break;
    default:
      throw Error(ERRORS.INVALID_ROTATION_STYLE);
  }
}
