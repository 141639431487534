import SET from "./set";
import * as validate from "./../utils/validate-inputs";
import { isTopdown } from "./../creation/enable-physics";

/**
 * Causes an object to move in a particular direction
 *
 * Note: This also causes the object to align itself to the direction it will move in
 * Note: Works differently depending on whether the object has gravity applied to it
 *
 * @param {Object} object - The object to move
 * @param {Vector} direction - The direction in which to move
 */
export default function move(object, direction) {
  if (!validate.vector(direction)) {
    throw Error(validate.error.vector);
  }

  // the move command makes the object align to its movement direction
  SET(object, "align-to-direction", true);

  // we are initiating a jump or on the initial frames of a jump
  if (object.jumpFrame || object.initiateJump) {
    return this;
  }

  const game = object.game;
  const speed = game.model.get("default-speed");
  const scale = game.model.get("physics-scale");

  if (isTopdown(object)) {
    // this is the traditional type of `move`
    SET(object, "heading", direction);
    SET(object, "speed", speed);
  } else {
    // objects with gravity applied to them can only move left/right
    object.body.velocity.x = direction.x * speed * scale;
  }
}
