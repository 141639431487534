/*
| @name baps.js
| @desc Examines the user's environment and provides details of the OS, browser and selected plugins.
*/

/* global ee, baps */
(function() {

	var temp,
		apVer = navigator.appVersion,
		apName = navigator.appName,
		ua = navigator.userAgent;
	
	baps = {
		browser: 'Unknown',
		browserVer: '0',
		platform: 'Unknown',
		platformVer: '0'
	};
	
	/* **** OPERATING SYSTEM **** */
	
	// Order matters! Mobile platforms usually also match their respective
	// desktop counterparts; e.g. Android also matches Linux, iOS devices also
	// match Mac. Mobile platforms are therefore listed first.
	
	/* ---- ANDROID ---- */
	if (/Android/.test(ua)) {
		baps.platform = 'Android';
		temp = ua.match(/Android (\d+(\.\d+)?)/);
		if (temp) baps.platformVer = temp[1];
		baps.mobile = true;
		
	/* ---- IPOD, IPHONE, IPAD ---- */
	} else if (/iPod|iPhone|iPad/.test(ua)) {
		baps.platform = 'iOS';
		temp = ua.match(/ OS (\d+(\_\d+)?)/);
		if (temp) baps.platformVer = temp[1].replace('_', '.');
		baps.mobile = true;
		
	/* ---- BLACKBERRY ---- */
	} else if (/BlackBerry/.test(ua)) {
		baps.platform = 'BlackBerry';
		temp = ua.match(/Version\/(\d+(\.\d+)?)/);
		if (temp) baps.platformVer = temp[1];
		baps.mobile = true;
		
	/* ---- WINDOWS PHONE ---- */
	} else if (/Windows Phone/.test(ua)) {
		baps.platform = 'Windows Phone';
		temp = ua.match(/Windows Phone( OS)? (\d+(\.\d+)?)/);
		if (temp) baps.platformVer = temp[2];
		baps.mobile = true;
		
	/* ---- CHROME OS ---- */
	} else if (/CrOS/.test(ua)) {
		baps.platform = 'Chrome OS';
		temp = ua.match(/CrOS \S+ (\d+(\.\d+)?)/);
		if (temp) baps.platformVer = temp[1];
		
	/* ---- LINUX ---- */
	} else if (/Linux/.test(ua)) {
		baps.platform = 'Linux';
		
	/* ---- MAC ---- */
	} else if (/Mac/.test(ua)) {
		baps.platform = 'Mac';
		temp = ua.match(/Mac OS X (\d+(\_\d+)?)/);
		if (temp) baps.platformVer = temp[1].replace('_', '.');
		
	/* ---- WINDOWS ---- */
	} else if (/Windows( NT)? \d/.test(ua)) {
		baps.platform = 'Windows';
		temp = ua.match(/Windows NT (\d+(\.\d+)?)/);
		if (temp) baps.platformVer = temp[1];
		
	}
	
	/* **** BROWSER **** */
	// Order matters! Because Edge has Chrome, Webkit, and Safari in the UA,
	// Edge is therefore tested first
	
	/* ---- OPERA ---- */
	if (apName == 'Opera') {
		baps.browser = 'Opera';
		temp = ua.match(/Version\/(\d+(\.\d+)?)/);
		if (temp) baps.browserVer = temp[1];
		baps.language = navigator.userLanguage;
		
	/* ---- INTERNET EXPLORER ---- */
	} else if (apName == 'Microsoft Internet Explorer' || /MSIE \d+/.test(apVer) || /Trident\/\d+/.test(apVer)) {
		var ie = true;
		baps.browser = 'IE';
		temp = apVer.match(/MSIE (\d+(\.\d+)?)/);
		if (!temp) temp = apVer.match(/rv:(\d+(\.\d+)?)/);
		if (temp) baps.browserVer = temp[1];
		temp = apVer.match(/Trident\/(\d+(\.\d+)?)/);
		if (temp) baps.browserEngineVer = temp[1];
		if (document.documentMode) baps.browserMode = document.documentMode;
		baps.language = navigator.userLanguage;
	
	/* ---- EDGE ---- */
	} else if (/Mozilla.*Edge/.test(ua)){
		baps.browser = 'Edge';
		temp = ua.match(/Edge\/(\d+)/);
		if(temp) baps.browserVer = temp[1];
		baps.language = navigator.language;
		
	/* ---- CHROME, SAFARI ---- */
	} else if (apVer.indexOf('WebKit') != -1) {
		baps.browser = apVer.indexOf('Chrome') != -1 ? 'Chrome' : 'Safari';
		if (baps.browser == 'Chrome' && !!window.externalHost) baps.browserLong = 'Chrome Frame';
		temp = apVer.match(/(Chrome|Version)\/(\d+(\.\d+)?)/);
		if (temp) baps.browserVer = temp[2];
		baps.language = navigator.language;
		
	/* ---- FIREFOX ---- */
	} else if (apName == 'Netscape') {
		baps.browser = 'Firefox';
		temp = ua.match(/Firefox\/(\d+(\.\d+)?)/);
		if (temp) baps.browserVer = temp[1];
		baps.language = navigator.language;
	}
	
	/* **** PLUGINS **** */
	
	/* ---- FLASH ---- */
	if (!ie) {
		temp = navigator.plugins['Shockwave Flash'];
		if (temp) {
			flash_ver = temp.description.match(/\d+(\.\d+)?/);
			baps.flash = flash_ver ? flash_ver[0] : 'installed (version unknown)';
		}
	} else {
		try { temp = new ActiveXObject('ShockwaveFlash.ShockwaveFlash'); } catch(e) { temp = ''; }
		if (temp) {
			var flash_ver = temp.GetVariable('$version');
			if (flash_ver) temp = flash_ver.match(/WIN (\d+),(\d+)/);
			if (temp) flash_ver = temp[1] + '.' + temp[2];
			baps.flash = flash_ver ? flash_ver : 'installed (version unknown)';
		}
	}
	
	/* **** GENERAL ENVIRONMENT **** */
	baps.cookiesEnabled = navigator.cookieEnabled;
	if (window.screen) {
		for (var i in screen) {
			if (typeof screen[i] === 'boolean' || typeof screen[i] === 'number' || typeof screen[i] === 'string') {
				baps[i] = screen[i];
			}
		}
	}
	
	/* **** PAGE FLAGS **** */
	var classAddFunc = function () {
		document.body.className +=
			' ' + baps.browser.toLowerCase() +
			' apVer-' + baps.browserVer.replace(/\./g, '') +
			' apVer-' + baps.browserVer.match(/[^\.]+/) +
			' platform-' + baps.platform.replace(/\s+/g, '-').toLowerCase() +
			(baps.mobile ? ' device-mobile' : '');
	};
	if (window.addEventListener) {
		document.addEventListener('DOMContentLoaded', classAddFunc, false);
	} else {
		window.attachEvent('onload', classAddFunc);
	}
	
})();

if (window.ee && ee.imDone) ee.imDone('baps');