import Backbone from "custom/backbone-bundle";
import { getApiUrl } from "utils/get-api-url";
import { getCodingLanguage } from "utils/get-coding-language";
import { hasTaxonomy, hasChildTaxonomy } from "utils/taxonomy";
import { ingestPayloadTranslations } from "utils/localisation";

const GlossaryItem = Backbone.RelationalModel.extend({});

export const Glossary = Backbone.RelationalModel.extend({
  relations: [
    {
      type: Backbone.HasMany,
      key: "glossary-terms",
      relatedModel: GlossaryItem,
    },
  ],

  _fetched: null,
  _loaded: null,

  /**
   * Fetches the Glossary if needed
   *
   * @async
   * @returns {Promise} a promise that resolves once the glossary is available
   */
  async fetch() {
    if (this._fetched !== this.url) {
      // update URL based on locale and current coding language
      this.url = getApiUrl("app", "glossary", getCodingLanguage());

      // create and store fetch promise
      this._loaded = new Promise((resolve, reject) =>
        Backbone.RelationalModel.prototype.fetch.call(this, {
          success: resolve,
          error: reject,
        }),
      );

      // remember that this URL has been fetched
      this._fetched = this.url;
    }

    await this._loaded;
  },

  parse(payload) {
    ingestPayloadTranslations(payload);
    return payload;
  },

  /**
   * Get all glossary items matching exact taxonomies
   *
   * Does an EXACT match of taxonomy
   * example: `glossary-type.code-block.command` will return items with
   * `glossary-type.code-block.command` but NOT items with
   * `glossary-type.code-block.command.alert`
   *
   * @param {string[]} taxonomies any number of taxonomies
   */
  getItems(...taxonomies) {
    return this.get("glossary-terms").filter(term =>
      taxonomies
        .map(
          taxonomy =>
            hasTaxonomy(term, taxonomy) && !hasChildTaxonomy(term, taxonomy),
        )
        .includes(true),
    );
  },
});
