export default Object.assign(
  JSON.parse(
    JSON.stringify(require("models/block/blocks/command/blueprints/get.json")),
  ), //make sure all the values are deeply cloned
  {
    groups: ["year 5", "year 6"],
    category: "speed",
    args: {
      arguments: [
        {
          code: {
            type: "string",
            value: "speed",
            title: "property",
            "hard-lock": true,
            editable: false,
          },
        },
      ],
    },
  },
);
