import BlockScope from "./../block-environment";
import Comparator from "./comparator";
import _ from "underscore";
import { checkFlag } from "utils/flags";
import interfaceChannel from "views/block/channels/interface-channel";

export const CodeCanvasModel = BlockScope.extend({
  constructor(attr, options) {
    attr = attr || {};
    attr["free-form"] = true;
    return BlockScope.prototype.constructor.call(this, attr, options);
  },

  initialize(attr, options) {
    BlockScope.prototype.initialize.call(this, attr, options);
    this.listenTo(this.get("code"), "add", () =>
      _.defer(() => this.get("code").sort()),
    );
    this.get("code").comparator = Comparator;
    this.get("code").sort();
  },

  async getContextMenuItems() {
    return [
      {
        name: "add-code",
        text: "Add code",
        icon: "circle-plus",
        isAvailable: checkFlag("BLOCK_CONTEXT_MENU_ADD_CODE"),
        isDisabled: !this.canHaveCodeAdded(),
        action: position => this.addCode(position),
      },
    ].filter(item => item.isAvailable);
  },

  /** Determines whether code can be added to the canvas */
  canHaveCodeAdded() {
    const blockCoder = this.get("block");

    if (!blockCoder) {
      return false;
    }

    if (blockCoder.get("interaction-mode") === "jigsaw") {
      return false;
    }

    return (
      blockCoder.get("settings").get("has-code-wall") ||
      blockCoder.get("settings").get("has-code-chest")
    );
  },

  addCode(position) {
    const bounds = this.view.el.getBoundingClientRect();
    const scale = this.get("scale");

    position.x -= bounds.x + bounds.width / 2;
    position.y -= bounds.y + bounds.height / 2;

    position.x *= 1 / scale;
    position.y *= 1 / scale;

    interfaceChannel.trigger("insert-code:open", this, position);
  },
});
