import * as validate from "./../utils/validate-inputs";
import { isPlatformer } from "./../creation/enable-physics";

export default function isOnGround(object) {
  // if we are checking multiple objects, true if any of them are on the ground
  if (Array.isArray(object)) {
    return object
      .map(item => isOnGround(item))
      .reduce((acc, val) => acc || val, false);
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  if (!isPlatformer(object)) {
    throw Error(validate.error.no_gravity);
  }

  return (
    (object.gravity > 0 && object.body.blocked.down) ||
    (object.gravity < 0 && object.body.blocked.up)
  );
}
