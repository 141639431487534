import { closeOpenedMenus } from "utils/close-opened-menus";

export class ClickableBody {
  constructor() {
    this.parent = parent;
  }

  onTouchStart(e) {
    if (!this.lastEvent || this.lastEvent instanceof TouchEvent) {
      this.lastEvent = e;
      closeOpenedMenus(e);
    }
  }

  onMouseDown(e) {
    if (!this.lastEvent || this.lastEvent instanceof MouseEvent) {
      this.lastEvent = e;
      closeOpenedMenus(e);
    }
  }
}
