import { closeOpenedMenus } from "utils/close-opened-menus";
/**
 * Handles interaction of a draggable canvas
 * Plugs into BlockInteractionZone
 */
export class DraggableCanvas {
  constructor(parent) {
    this.parent = parent;
  }

  onDragStart() {}
  onDragEnd() {}
  onMove(e) {
    closeOpenedMenus(e);

    const model = e.target.model;
    model.trigger("pan", { x: e.dx, y: e.dy });
  }
}
