import { context, ROOT_CONTEXT, setSpan } from "@opentelemetry/api";
import { hrTime, hrTimeDuration } from "@opentelemetry/core";
import { InstrumentationBase } from "@opentelemetry/instrumentation";
import { addSpanNetworkEvent, addSpanNetworkEvents } from "@opentelemetry/web";

/**
 * This instrumentation collects similar waterfall traces from page load and SPA loads
 */
export class StableRequestsInstrumentation extends InstrumentationBase {
  /** @type {import("@opentelemetry/api").Span} */
  _documentLoad;
  /** @type {import("@opentelemetry/api").Span} */
  _routeChange;
  /** @type {import("@opentelemetry/api").HrTime} */
  _routeChangeEnd;

  /** @type {number[]} */
  _entries;
  /** @type {NodeJS.Timeout} */
  _checkTimer;

  _checkStart() {
    if (!this._checkTimer) {
      this._entries = [];
      this._checkTimer = setInterval(() => this._check(), 1000);
    }
  }

  _check() {
    const entries = this._entries;
    entries.unshift(performance.getEntriesByType("resource").length);

    const loaded = window.document.readyState === "complete";
    const stable = entries[0] === entries[1] && entries[1] === entries[2];

    // Wait until three 1s sequential updates have the same number of resource entries, or we get to 60s
    if ((loaded && stable) || entries.length > 60) {
      this._checkEnd();
    }
  }

  _checkEnd() {
    clearInterval(this._checkTimer);

    const navigation = this._getNavigation();
    const rootSpan = this._documentLoad || this._routeChange;

    context.with(setSpan(ROOT_CONTEXT, rootSpan), () => {
      if (this._documentLoad) {
        this._addSpanNavigationEvents(rootSpan, navigation);
        this._createResourceSpan("documentFetch", navigation);
      }

      for (const resource of this._getResources(rootSpan)) {
        this._createResourceSpan("resourceFetch", resource);
      }
    });

    if (this._documentLoad) {
      this._documentLoad.end(navigation.loadEventEnd);
    }
    if (this._routeChange) {
      this._routeChange.end(this._routeChangeEnd);
    }

    this._documentLoad = undefined;
    this._routeChange = undefined;
    this._routeChangeEnd = undefined;

    this._entries = undefined;
    this._checkTimer = undefined;
  }

  /** @type {() => PerformanceNavigationTiming} */
  _getNavigation() {
    return performance.getEntriesByType("navigation")[0];
  }

  /** @type {(span: import("@opentelemetry/api").Span) => PerformanceResourceTiming[]}*/
  _getResources(span) {
    return performance
      .getEntriesByType("resource")
      .filter(
        resource =>
          hrTimeDuration(span["startTime"], hrTime(resource.startTime))[0] >= 0,
      );
  }

  /** @type {(span: import("@opentelemetry/api").Span, navigation: PerformanceNavigationTiming) => void} */
  _addSpanNavigationEvents(span, navigation) {
    addSpanNetworkEvent(span, "fetchStart", navigation);
    addSpanNetworkEvent(span, "unloadEventStart", navigation);
    addSpanNetworkEvent(span, "unloadEventEnd", navigation);
    addSpanNetworkEvent(span, "domInteractive", navigation);
    addSpanNetworkEvent(span, "domContentLoadedEventStart", navigation);
    addSpanNetworkEvent(span, "domContentLoadedEventEnd", navigation);
    addSpanNetworkEvent(span, "domComplete", navigation);
    addSpanNetworkEvent(span, "loadEventStart", navigation);
    addSpanNetworkEvent(span, "loadEventEnd", navigation);
  }

  /** @type {(name: string, resource: PerformanceResourceTiming | PerformanceNavigationTiming) => import("@opentelemetry/api").Span}*/
  _createResourceSpan(name, resource) {
    const span = this.tracer.startSpan(name, {
      startTime: resource.startTime,
    });
    span.setAttribute("http.url", resource.name);
    addSpanNetworkEvents(span, resource);
    span.end(resource.responseEnd);
    return span;
  }

  /** @type {(config?: import("@opentelemetry/instrumentation").InstrumentationConfig)}*/
  constructor(config) {
    super("stable-requests", "1.0", config);
  }

  init() {}

  enable() {}

  disable() {}

  documentLoadStart() {
    this._documentLoad = this.tracer.startSpan("documentLoad", {
      startTime: 0,
    });
    this._checkStart();
  }

  routeChangeStart() {
    // Make sure to completely end any existing route change span before we start another
    if (this._routeChange && this._routeChange.isRecording()) {
      if (!this._routeChangeEnd) {
        this.routeChangeEnd("Ended by StableRequestsInstrumentation");
      }
      this._routeChange.setAttribute("interrupted", true);
      this._routeChange.end(this._routeChangeEnd);
    }
    // Put the route change span as a child under the document load span, if one exists
    const parentContext = this._documentLoad
      ? setSpan(ROOT_CONTEXT, this._documentLoad)
      : ROOT_CONTEXT;
    context.with(parentContext, () => {
      this._routeChange = this.tracer.startSpan("routeChange");
      this._routeChangeEnd = undefined;
      this._checkStart();
    });
  }

  /** @type {(error?: string) => void} */
  routeChangeEnd(error) {
    this._routeChange.setAttribute("error", error);
    this._routeChangeEnd = hrTime();
  }
}
