export default {
  type: "object",
  category: "validation",
  "editorial-only": true,
  groups: ["year 1", "year 2", "year 3", "year 4", "year 5", "year 6"],
  target: { type: "app", key: "app" },
  commands: {
    locked: true,
    code: [
      {
        "hard-lock": true,
        type: "command",
        fn: "fail",
      },
    ],
  },
};
