import Modal from "../modal";

export default Modal.extend({
  className: [Modal.prototype.className, "load-from-device-failed"].join(" "),

  template: require("./load-from-device-failed.hbs"),
  style: require("./load-from-device-failed.styl"),

  open(renderData) {
    this.renderData = renderData;
    Modal.prototype.open.call(this);
  },

  render() {
    this.$el.html(this.template(this.renderData));

    this.delegateEvents();
  },
});
