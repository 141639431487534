import * as validate from "./../utils/validate-inputs";
import { number } from "./../types/number";

export default function round(val, decimal) {
  val = number(val);

  // for backwards-compatibility
  if (validate.string(decimal)) {
    switch (decimal) {
      case ".0":
        decimal = 0.1;
        break;
      case ".00":
        decimal = 0.01;
        break;
      case ".000":
        decimal = 0.001;
        break;
    }
  }

  if (!validate.number(decimal)) {
    throw Error(validate.error.number);
  }

  const p = 1 / decimal;
  return Math.round(val * p) / p;
}
