/**
 * Handles click interaction of code blocks
 */

export class ClickableBlock {
  constructor() {
    this.parent = parent;
    this.isHolding = false;
  }

  onHold(e) {
    if (e.pointerType === "mouse") {
      return; // ignore 'hold' events if they were done with the mouse
    }
    this.isHolding = true;
    return this.onContextMenu(e);
  }

  onClick(e) {
    if (this.isHolding) {
      return this.onContextMenu(e);
    }

    e.preventDefault();
    e.stopPropagation();

    if (e.target.closest(".phaser-sprite.sound.interactive")) {
      // ignore clicks if they are coming from a sound sprite
      return;
    }

    const blockEl = e.target.closest(".argument, .super-block");
    const isUsed = e.target.closest(".used");

    if (!blockEl.classList.contains("interactive")) {
      return; // prevent clicks on non-interactive blocks
    }

    if (e.pointerType === "mouse" && e.button !== 0) {
      return; // ignore anything other than left-click when using the mouse
    }

    if (!isUsed && blockEl && blockEl.model && blockEl.model.view) {
      blockEl.model.view.onClick(e);
    }
  }

  onContextMenu(e) {
    this.isHolding = false;
    document.body.style.webkitUserSelect = "none";

    const blockEl = e.target.closest(".super-block");
    const isUsed = e.target.closest(".used");

    if (blockEl && !blockEl.classList.contains("interactive")) {
      return; // prevent context menu opening on non-interactive blocks
    }

    if (!isUsed && blockEl && blockEl.model && blockEl.model.view) {
      blockEl.model.view.onContextMenu(e);
    }

    setTimeout(() => (document.body.style.webkitUserSelect = "initial"), 1000);
  }
}
