import checkStorage from "utils/check-storage";

import "./otel";

export async function track(data) {
  const internal = await import(
    /* webpackChunkName: "track.internal" */
    "./internal"
  );
  internal.track(data);

  const ga = await import(
    /* webpackChunkName: "track.ga" */
    "./ga"
  );
  const pendo = await import(
    /* webpackChunkName: "track.pendo" */
    "./pendo"
  );

  if (window.process.env.GA_ID) {
    ga.track(data, window.process.env.GA_ID);
  }

  // pendo uses sessionStorage internally, we need to track pendo only if sessionStorage is available.
  if (checkStorage()) {
    if (window.pendo && window.pendo.initialize) {
      pendo.track(data);
    }
  }
}
