import SET from "./set";
import * as validate from "./../utils/validate-inputs";

/**
 * Randomizes the Y position of an object (whithin stage bounds)
 * Takes the object's height into account so that it will be entirely within the stage
 *
 * @param {Object} object - The object to randomize
 */
export default function randomYPosition(object) {
  if (!(validate.physical(object) || validate.camera(object))) {
    throw Error(validate.error.command);
  }

  const stageHeight = this.world.height;
  const objectHeight = object.height;

  let y = 0;

  if (objectHeight > stageHeight) {
    y = Math.random() * stageHeight;
  } else {
    y = Math.random() * (stageHeight - objectHeight) + objectHeight / 2;
  }

  y = Math.floor(y);

  SET(object, "y", y);
}
