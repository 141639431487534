import translate from "utils/localisation";

const CACHE = document.createElement("canvas");
const dir = translate("dir");
CACHE.setAttribute("dir", dir);
document.body.appendChild(CACHE); // for some reason, RTL does not work unless the canvas is on the page
CACHE.style.visibility = "hidden";
CACHE.style.position = "absolute";
CACHE.style.left = "-9999px";

const FONTSIZE = 32;
const PADDING = 8;
const LINEWIDTH = 2;
const Y_OFFSET = -4;

export function makeText(text = "", style = {}) {
  const ctx = CACHE.getContext("2d");

  // measure the space we'll need
  styleFont(style, ctx);
  const width = ctx.measureText(text).width;

  // we add padding because shadows and glows will extend past the actual text
  CACHE.height = Math.ceil(FONTSIZE) + PADDING * 3; // we will have double padding on the top to align things better for all languages
  CACHE.width = width + PADDING * 3; // we will have double padding on the right to account for italic and drop shadows

  // after resizing the canvas, we need to set the styles again because the context will be lost
  styleFont(style, ctx);

  // add a contrasting background
  if (style.thumbnail) {
    ctx.fillStyle = getContrastingBackgroundColor(style.color);
    ctx.beginPath();
    ctx.roundRect(
      0 + PADDING / 2,
      0 + PADDING / 2,
      CACHE.width - PADDING,
      CACHE.height - PADDING,
      [16],
    );
    ctx.fill();
    ctx.fillStyle = style.color;
  }

  // draw text with dropshadow
  if (style["text-shadow"]) {
    dropShadow(ctx, 3, 3, 6, "rgba(0, 0, 0, 0.5)");
    drawText(ctx, text, style.underlined);
    dropShadow(ctx, 1, 1, 2, "rgba(0, 0, 0, 0.5)");
    drawText(ctx, text, style.underlined);
  }

  // draw text with glow
  if (style["text-glow"]) {
    dropShadow(ctx, 0, 0, 4, "rgba(255,255,255,1)");
    drawText(ctx, text, style.underlined);
  }

  // draw text with neither
  if (!style["text-glow"] && !style["text-shadow"]) {
    drawText(ctx, text, style.underlined);
  }

  return CACHE;
}

// applies a drop shadow to the context
function dropShadow(ctx, xOffset, yOffset, blur, color) {
  ctx.shadowOffsetX = xOffset;
  ctx.shadowOffsetY = yOffset;
  ctx.shadowColor = color;
  ctx.shadowBlur = blur;
}

// draws text to the context (with support for underlined)
function drawText(ctx, text, underlined = false) {
  if (underlined) {
    ctx.fillRect(
      PADDING,
      (CACHE.height * 3) / 4 + PADDING / 2 + 4 + Y_OFFSET,
      CACHE.width - PADDING * 2,
      LINEWIDTH,
    );
  }

  ctx.fillText(
    text,
    dir === "rtl" ? CACHE.width - PADDING : PADDING,
    CACHE.height / 2 + PADDING + Y_OFFSET,
  );
}

function hexToRgb(color) {
  return [
    parseInt(color.substr(1, 2), 16),
    parseInt(color.substr(3, 2), 16),
    parseInt(color.substr(5, 2), 16),
  ];
}

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

function luminance(r, g, b) {
  var a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

function contrast(rgb1, rgb2) {
  var lum1 = luminance(...rgb1);
  var lum2 = luminance(...rgb2);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

function getContrastingBackgroundColor(color) {
  if (contrast(hexToRgb(color), [29, 29, 29]) > 5) {
    return `rgb(29,29,29)`;
  } else {
    return `rgb(226,226,226)`;
  }
}

// applies font styles to the context
function styleFont(style = {}, ctx) {
  style.font = style.font || "Noto Kufi Arabic, sans-serif";
  style.color = style.color || "#000";

  ctx.textBaseline = "middle";

  const bold = style.bold ? "bold" : " ";
  const italic = style.italic ? "italic" : " ";
  ctx.font = `${bold} ${italic} ${FONTSIZE}px ${style.font}`;

  ctx.fillStyle = style.color;
}

export function makeTextBitmap(game, text, style) {
  const canvas = makeText(text, style);
  const bitmap = game.add.bitmapData(canvas.width, canvas.height);
  bitmap.copy(canvas);
  return bitmap;
}
