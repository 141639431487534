const findMatch = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  return regex.exec(url);
};

export const getUrlParam = (name, url) => {
  const results = findMatch(name, url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const hasUrlParam = (name, url) => {
  const results = findMatch(name, url);

  if (!results) {
    return false;
  }

  return true;
};
