import _ from "underscore";

import * as validate from "./../utils/validate-inputs";

/**
 * Enables collisions between object and target (as opposed to overlaps)
 * Note: this does NOT trigger a collision immediately
 *
 * This lets Phaser use it's built-in collision handling for high precision/performance
 *
 * @param {Object} object - The object that will collide with target
 * @param {Object|Tile} target - The target of collisions
 */
export default function collide(object, target) {
  if (Array.isArray(target)) {
    return Promise.all(target.map(target => collide(object, target)));
  }

  if (!validate.physical(object)) {
    throw Error(validate.error.command);
  }

  var list = object["collides-with"];
  var collider;

  if (validate.texture(target)) {
    collider = {
      type: "tile",
      index: target,
    };
  } else if (validate.physical(target)) {
    collider = {
      type: "object",
      target: target,
    };
  } else {
    throw Error(validate.error.collider);
  }

  //add collider to list, making sure only unique entries are in the list
  if (collider && !_.findWhere(list, collider)) {
    list.push(collider);
  }
}
