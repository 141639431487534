var Handlebars = require("handlebars/dist/handlebars.runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<section class=\"share-url\">\n  <h2 tabindex=\"-1\" role=\"heading\" aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":66}}}))
    + "\">"
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":87}}}))
    + "</h2>\n\n"
    + ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(alias1,"APPS_TOGGLE_PRIVATE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":23,"column":20}}})) != null ? stack1 : "")
    + "\n  <div class=\"share\">\n    <div class=\"link\">\n      <input\n        type=\"text\"\n        value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\"\n        spellcheck=\"false\"\n        dir=\"ltr\"\n        "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShared") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":33,"column":59}}})) != null ? stack1 : "")
    + " />\n    </div>\n    <button\n      class=\"confirm copy\"\n      aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Copy",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":38}}}))
    + "\"\n      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShared") : depth0),{"name":"unless","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":38,"column":57}}})) != null ? stack1 : "")
    + ">\n      <svg alt=\"\" aria-hidden=true focusable=false >\n        <use xlink:href=\"#comet_link\"></use>\n      </svg>\n\n      "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Copy",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":26}}}))
    + "\n\n    </button>\n  </div>\n</section>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../templates/helpers/ifAuthor.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ifAuthor","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":22,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isClone") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":21,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <p class=\"share-description\">\n    <label class=\"toggle\">\n      "
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Enable sharing",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":11,"column":36}}}))
    + "\n      <input\n        class=\"share-toggle\"\n        type=\"checkbox\"\n        aria-label=\""
    + alias2(__default(require("../../../../../templates/helpers/translate.js")).call(alias1,"Enable share",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":48}}}))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShared") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":38}}})) != null ? stack1 : "")
    + "\n        aria-pressed=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isShared") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":62}}})) != null ? stack1 : "")
    + "\">\n      <span class=\"toggle button-toggle\"></span>\n    </label>\n  </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "checked";
},"7":function(container,depth0,helpers,partials,data) {
    return "true";
},"9":function(container,depth0,helpers,partials,data) {
    return "false";
},"11":function(container,depth0,helpers,partials,data) {
    return "aria-disabled=\"true\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../templates/helpers/ifFeatureFlag.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"SHARE",{"name":"ifFeatureFlag","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":49,"column":18}}})) != null ? stack1 : "");
},"useData":true});