import Lesson from "models/lesson";
import Task from "models/task";
import { getApiUrl } from "./get-api-url";
import { user } from "globals/user";

export function trackCodingEvent(eventType, ...models) {
  if (user.isSharedAccount()) {
    return false;
  }

  let payload = models.map(model => createPayload(model, eventType));

  if (payload.length === 1) {
    payload = payload[0];
  }

  const url = getApiUrl(`progress/event`);

  if (user.get("user").id) {
    $.ajax(url, {
      data: JSON.stringify(payload),
      contentType: "application/json",
      type: "POST",
      retryDelay: 1000,
      error: function () {
        // we need to make sure that lesson completions get stored. We don't want
        // a temporary network issue to cause the completions to get out of sync
        // with user state
        // if an error occurs, we need to keep re-trying
        setTimeout(() => {
          $.ajax(this);
        }, this.retryDelay);
      },
    });
  }
}

function createPayload(model, eventType) {
  const item = { id: model.get("id") };

  if (model instanceof Lesson) {
    item.type = "Lesson";
  } else if (model instanceof Task) {
    item.type = "Task";
  }

  return { item, "event-type": eventType };
}
