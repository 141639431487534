var LANGUAGE_MAPPING = {
  python: "python",
  html: "htmlmixed",
  javascript: "javascript",
  css: "css",
};

export default function getCodeMirrorLanguage(string) {
  if (typeof string.get === "function") {
    string = string.get("language");
  }

  return LANGUAGE_MAPPING[string];
}
